import Loader from 'component/loader';
import useScript from 'hook/use-script';
import React from 'react';

const YaAuth = () => {
    useScript('https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js')
    .then(() => {
        // @ts-ignore
        YaSendSuggestToken(
        `${location.origin}`, {}
        );
    });

    return null;
};

export default YaAuth;
