import { createBrowserHistory } from 'history';

import { ILocationState } from './types/history';

const reLang: RegExp = /^(\/[a-z]{2}(?:-[a-z]{4})?)(?:\/|$)/;
let basename = '/';
let counter = 0;

if(reLang.test(window.location.pathname)) {
    const match = window.location.pathname.match(reLang);

    if(match && match[1]) {
        basename = match[1];
    }
}

const history = createBrowserHistory<ILocationState>({ basename });

if(history.location.state && history.location.state.modal) {
    history.replace(history.location.pathname, {
        ...history.location.state,
        modal: false
    });
}

history.listen((location, action) => {
    if(action === 'PUSH' || action === 'REPLACE' && (!location.state || !location.state.noScroll)) {
        window.scrollTo(0, 0);
    }

    if(action === 'PUSH') {
        counter++;
    }

    if(action === 'POP' || counter) {
        counter--;
    }

    if(counter === 0 && action === 'POP') {
        history.replace(history.location.pathname, history.location.state);
    }
});

export default history;
