import React, { Fragment } from 'react';

import Icon, { cn } from './';

export default Icon(() => (
    <path
        d="M502.3,71L371.1,33.3c-0.8-10.8-9.9-19.3-20.9-19.3H164c-10.8,0-19.7,8.2-20.9,18.7L9.7,71
        c-6.4,1.8-10.1,8.5-8.2,14.8L43,230.4c1.8,6.4,8.5,10.1,14.8,8.2l85.3-24.4v6.8c0,11.6,9.4,21,21,21h186.1c11.6,0,21-9.4,21-21v-6.2
        l83,23.8c6.4,1.8,13-1.9,14.8-8.2l41.5-144.6C512.4,79.5,508.7,72.9,502.3,71z M143,172.6l-93,26.6c-3.2,0.9-6.5-0.9-7.4-4.1
        L11.5,86.6c-0.9-3.2,0.9-6.5,4.1-7.4L143,42.6V172.6z M356.2,182.1c0,3.3-2.7,6-6,6H164c-3.3,0-6-2.7-6-6v-147c0-3.3,2.7-6,6-6
        h186.1c3.3,0,6,2.7,6,6v147H356.2z M500.5,86.6l-31.1,108.5c-0.9,3.2-4.2,5-7.4,4.1l-90.8-26v-130l125.2,35.9
        C499.6,80.1,501.4,83.4,500.5,86.6z"
    />
), {
    viewBox: '0 0 512 256'
});
