import React, { useMemo, useState } from 'react';
import Button from 'component/button';
import UI from 'component/ui';
import { PremiumAccount } from 'src/api/premium-accounts/types';
import { useClassnames } from 'hook/use-classnames';
import style from '../index.pcss';
import api from 'src/api';

interface IPropsItem {
    item: PremiumAccount;
    isPremium?: boolean;
    onOrderCreated(isSuccess: boolean, url?: string): void;
}

const PremiumAccountItem = (props: IPropsItem) => {
    const cn = useClassnames(style);
    const [value, setValue] = useState<string | null>(props.item.rates[0].id.toString());

    const onChangeValue = (e: { target: HTMLInputElement }) => {
        setValue(e.target.value);
    };

    const onClickCreatePayment = () => {
        if (value) {
            api.payments.createPremiumAccountsPayment(props.item.id, Number(value))
                .then((resp) => {
                    props.onOrderCreated(true, resp.data.url);
                    setTimeout(() => location.replace(resp.data.url), 1000);
                })
                .catch(() => {
                    props.onOrderCreated(false);
                });
        }
    };

    const elTerms = useMemo(() => {
        return (
            <>
                {props.item.terms.map((term) => {
                    const name = () => {
                        switch (term.item_type) {
                            case 'FREE_PHOTO':
                                return `Бесплатные фото в месяц: ${term.item_data.count}`;
                            case 'PREPAID_ALBUM':
                                return `Лимит бесплатного скачивания из альбома: ${term.item_data.count} фото`;
                        }
                    };

                    return (
                        <p key={`terms-${props.item.id}-${term.id}`}>
                            {name()}
                        </p>
                    );
                })}
            </>
        );
    }, [props.item.terms]);

    const elRatePeriod = useMemo(() => {
        return (
            <>
                {props.item.rates.map((rate, index) => {
                    const checked = index === 0 ? {
                        defaultChecked: true
                    } : null;

                    switch (rate.period) {
                        case 30:
                            return (
                                <label key={`rates-${props.item.id}-${rate.id}`} className={cn('premium__content__item__price-radio')}>
                                    <input
                                        type={'radio'}
                                        name={`radio-${props.item.id}`}
                                        value={`${rate.id}`}
                                        onChange={onChangeValue}
                                        {...checked}
                                    />
                                    <span>{`${Number(rate.price)} ₽ - при оплате за месяц`}</span>
                                </label>
                            );
                        case 90:
                            return (
                                <label key={`rates-${props.item.id}-${rate.id}`} className={cn('premium__content__item__price-radio')}>
                                    <input
                                        type={'radio'}
                                        name={`radio-${props.item.id}`}
                                        value={`${rate.id}`}
                                        onChange={onChangeValue}
                                    />
                                    <span>{`${(Number(rate.price) / 3).toFixed(1)} ₽ - при оплате за 3 месяца (${Number(rate.price)} руб)`}</span>
                                </label>
                            );
                        case 180:
                            return (
                                <label key={`rates-${props.item.id}-${rate.id}`} className={cn('premium__content__item__price-radio')}>
                                    <input
                                        type={'radio'}
                                        name={`radio-${props.item.id}`}
                                        value={`${rate.id}`}
                                        onChange={onChangeValue}
                                    />
                                    <span>{`${(Number(rate.price) / 6).toFixed(1)} ₽ - при оплате за полгода (${Number(rate.price)} руб)`}</span>
                                </label>
                            );
                        case 360:
                            return (
                                <label key={`rates-${props.item.id}-${rate.id}`} className={cn('premium__content__item__price-radio')}>
                                    <input
                                        type={'radio'}
                                        name={`radio-${props.item.id}`}
                                        value={`${rate.id}`}
                                        onChange={onChangeValue}
                                    />
                                    <span>{`${(Number(rate.price) / 12).toFixed(1)} ₽ - при оплате за год (${Number(rate.price)} руб)`}</span>
                                </label>
                            );
                    }
                })}
            </>
        );
    }, [props.item.rates]);

    return (
        <UI.Box padding={true}>
            <div className={cn('premium__content__item')}>
                <div className={cn('premium__content__item__title')}>
                    <h1>{props.item.name}</h1>
                    {elTerms}
                </div>
                <div className={cn('premium__content__item__price')}>
                    {elRatePeriod}
                </div>
            </div>
            <div className={cn('premium__content__item__controls')}>
                <Button
                    className={cn('premium__content__item__controls-submit')}
                    onClick={onClickCreatePayment}
                    disabled={props.isPremium}
                >
                    Выбрать
                </Button>
            </div>
        </UI.Box>
    );
};

export default PremiumAccountItem;
