import React from 'react';

import Icon, { cn } from './';

export default Icon(() => (
    <path
        d="M87.8,63.8c-1.2,0-2.2,1-2.2,2.2v9.2c0,5.7-4.6,10.3-10.3,10.3h-10c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2
        h10C83.4,90,90,83.4,90,75.3V66C90,64.8,89,63.8,87.8,63.8z M75.3,0h-10c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2h10
        C81,4.4,85.6,9,85.6,14.7v10.7c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2V14.7C90,6.6,83.4,0,75.3,0z M64,43.9c1.5,0,2.7-1.2,2.8-2.7
        V34c0-1.6-1.2-2.8-2.8-2.8s-2.8,1.3-2.8,2.8v7.1C61.2,42.7,62.5,43.9,64,43.9z M58.8,63.3c-3.6,3.6-8.4,5.6-13.3,5.6h-0.8
        c-5,0-9.7-2-13.3-5.6c-0.8-0.9-2.2-0.9-3.1,0c-0.9,0.8-0.9,2.2,0,3.1c4.5,4.5,10.3,6.9,16.4,6.9h0.8c6.1,0,12-2.4,16.4-6.9
        c0.9-0.8,0.9-2.2,0-3.1C61.1,62.4,59.7,62.4,58.8,63.3z M39.5,57.2H40c4.5,0,8.1-3.6,8.1-8V34c0-1.2-1-2.2-2.2-2.2
        c-1.2,0-2.2,1-2.2,2.2v15.1c0,2.1-1.7,3.7-3.7,3.7h-0.5c-1.2,0-2.2,1-2.2,2.2C37.3,56.2,38.3,57.2,39.5,57.2z M24.7,31.2
        c-1.6,0-2.8,1.3-2.8,2.8v7.1c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.2,2.8-2.7V34C27.5,32.4,26.2,31.2,24.7,31.2z M2.3,27.7
        c1.2,0,2.2-1,2.2-2.2V14.7C4.5,9,9.1,4.4,14.8,4.4h10c1.2,0,2.2-1,2.2-2.2S26,0,24.8,0h-10C6.7,0,0.1,6.6,0.1,14.7v10.7
        C0.1,26.7,1.1,27.7,2.3,27.7z M24.7,85.6h-10C9,85.6,4.4,81,4.4,75.3V66c0-1.2-1-2.2-2.2-2.2C1,63.8,0,64.8,0,66v9.2
        C0,83.4,6.6,90,14.7,90h10c1.3,0,2.2-1,2.2-2.2C26.9,86.6,25.9,85.6,24.7,85.6z"
    />
), {
    viewBox: '0 0 90 90'
});
