export interface INormalizeObject {
    // tslint:disable-next-line no-any
    [key: string]: any
}

export const normalizeObject = (clearingObject: INormalizeObject): INormalizeObject => {
    const keys: Array<string> = Object.keys(clearingObject);
    const clearedObject: INormalizeObject = {};

    if(keys.length) {
        keys.forEach((key) => {
            const prop = clearingObject[key];

            if(String(prop) !== 'null' && typeof prop !== 'undefined') {
                if(typeof prop === 'string' && prop.length === 0) {
                    return null;
                }

                if(prop === 'true' || prop === 'false') {
                    return (clearedObject[key] = prop === 'true');
                }

                clearedObject[key] = prop;
            }
        });
    }

    return clearedObject;
};
