import { AxiosRequestConfig } from 'axios';
import { Data as CheckRegitstrationConfirmToken } from './types/api/account/registration/check-token/post/code-200';
import { Data as UserSelfInfo } from './types/api/account/self-user-info/get/code-200';
import { Data as CheckToken } from './types/api/account/password/reset/check-token/post/code-200';

import { request } from './';

export const yandexAuth = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/account/yandex-auth/',
        method: 'post',
        ...config
    });
};

export const login = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/account/login/',
        method: 'post',
        ...config
    });
};

export const logout = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/account/logout/',
        method: 'post',
        ...config
    });
};

export const registration = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/account/registration/',
        method: 'post',
        ...config
    });
};

export const checkRegitstrationConfirmToken = (config?: AxiosRequestConfig) => {
    return request<CheckRegitstrationConfirmToken>({
        url   : '/api/account/registration/check-token/',
        method: 'post',
        ...config
    });
};

export const confirmRegistration = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/account/registration/confirm/',
        method: 'post',
        ...config
    });
};

export const userSelfInfo = (config?: AxiosRequestConfig) => {
    return request<UserSelfInfo>({
        url: '/api/account/self-user-info/',
        ...config
    });
};

export const updateUserInfo = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/account/update-user-info/',
        method: 'post',
        ...config
    });
};

export const resetPassword = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/account/password/reset/',
        method: 'post',
        ...config
    });
};

export const resetPasswordConfirm = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/account/password/reset/confirm/',
        method: 'post',
        ...config
    });
};

export const checkPasswordToken = (config?: AxiosRequestConfig) => {
    return request<CheckToken>({
        url   : '/api/account/password/reset/check-token/',
        method: 'post',
        ...config
    });
};

export default {
    yandexAuth,
    login,
    logout,
    registration,
    checkRegitstrationConfirmToken,
    confirmRegistration,
    userSelfInfo
};
