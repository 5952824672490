import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { CreatePremiumAccountPayment, PaymentsAPIType } from './types';

const createPremiumAccountsPayment = (
    account_id: number,
    rate_id: number
): Promise<AxiosResponse<CreatePremiumAccountPayment>> => {
    return httpClient.post('/api/v2/payments/premium-accounts/', {
        account_id,
        rate_id
    });
};

export default {
    createPremiumAccountsPayment
} as PaymentsAPIType;
