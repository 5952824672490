import React, { useState, useEffect, useMemo, FC, ChangeEvent, ReactNode } from 'react';
import * as moment from 'moment';
import ReactSelect, { components } from 'react-select';
import { ValueType } from 'react-select/src/types';

import { useClassnames } from 'hook/use-classnames';
import qaAttributes from 'component/helper/qa-attributes';
import i18n from 'component/core/i18n';

import Error from 'component/error';

import { IProps, TError, IValue } from './types';
import style from './index.pcss';

const Input: FC<IProps> = (props) => {
    const MONTHS: Array<IValue> = useMemo(() => [{
        label: i18n.t('components.form.date-input.jan'),
        value: '0'
    }, {
        label: i18n.t('components.form.date-input.feb'),
        value: '1'
    }, {
        label: i18n.t('components.form.date-input.mar'),
        value: '2'
    }, {
        label: i18n.t('components.form.date-input.apr'),
        value: '3'
    }, {
        label: i18n.t('components.form.date-input.may'),
        value: '4'
    }, {
        label: i18n.t('components.form.date-input.jun'),
        value: '5'
    }, {
        label: i18n.t('components.form.date-input.jul'),
        value: '6'
    }, {
        label: i18n.t('components.form.date-input.aug'),
        value: '7'
    }, {
        label: i18n.t('components.form.date-input.sep'),
        value: '8'
    }, {
        label: i18n.t('components.form.date-input.oct'),
        value: '9'
    }, {
        label: i18n.t('components.form.date-input.nov'),
        value: '10'
    }, {
        label: i18n.t('components.form.date-input.dec'),
        value: '11'
    }], []);
    const cn = useClassnames(style, props.className, true);
    const momentDate = moment('');
    const monthNumber = momentDate.get('month');

    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.defaultValue || '');
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.defaultValue);
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [day, setDay] = useState<number>(props.isClear ? 0 : momentDate.get('date'));
    const [year, setYear] = useState<number>(props.isClear ? 0 : momentDate.get('year'));
    const [month, setMonth] = useState<number>(props.isClear ? 0 : monthNumber);
    const [hour, setHour] = useState<string>(props.isClear ? '' : momentDate.format('HH'));
    const [minutes, setMinutes] = useState<string>(props.isClear ? '' : momentDate.format('mm'));
    const [monthSelect, setMonthSelect] = useState<ValueType<IValue>>(props.isClear ? [] : MONTHS[monthNumber]);
    const [suggests, setSuggests] = useState<Array<IValue>>(MONTHS);

    // tslint:disable-next-line
    const SelectInput: FC<any> = useMemo(() => ({ children, ...args }) => (
        <components.Input {...qaAttributes(props['data-qa'] ? `date:month:${props['data-qa']}` : 'date:month')} {...args}>
            {children}
        </components.Input>
    ), []);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;
        const dateArray = props.isDateTime ? [year, month, day, hour, minutes] : [year, month, day];
        const isDateValid = moment(dateArray).isValid();

        if(props.required) {
            newIsValid = !!value;

            if(!newIsValid) {
                newErrorInternal = i18n.t('components.form.input-date.error');
            }
        }

        if (props.minYear && year) {
            newIsValid = Number(year) >= props.minYear;

            if (!newIsValid) {
                newErrorInternal = i18n.t('components.form.input-date.error_year_min', {
                    minYear: props.minYear
                });
            }
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        if(newIsValid && !isDateValid && !!value) {
            newIsValid = false;
            newErrorInternal = i18n.t('components.form.input-date.error');
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    const onValidateDate = (): void => {
        let newValue = '';

        if(day && (month || month === 0) && year && !props.isDateTime) {
            const newDate = new Date(year, month, day);

            newValue = moment(newDate).format('YYYY-MM-DD');
        }

        if(day && (month || month === 0) && year && props.isDateTime) {
            const newDate = new Date(year, month, day, Number(hour), Number(minutes));

            newValue = moment.utc(newDate).format();
        }

        setValue(newValue);

        if(props.onChange) {
            props.onChange(newValue);
        }
    };

    // useEffect((): void => {
    //     setErrorExternal(props.error || null);
    //     checkValidity();
    // }, [props.error, isWatch, errorExternal]);

    useEffect(() => {
        if(props.defaultValue) {
            const date = new Date(props.defaultValue);
            const momentValue = moment(date);
            const newMonthNumber = momentValue.get('month');

            setValue(props.defaultValue);
            setDay(momentValue.get('date'));
            setMonth(newMonthNumber);
            setYear(momentValue.get('year'));
            setHour(momentValue.format('HH'));
            setMinutes(momentValue.format('mm'));
            setMonthSelect(MONTHS[newMonthNumber]);
        }
    }, [props.defaultValue]);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            clear   : () => {
                setValue('');
                setIsWatch(false);
            },
            isValid   : checkValidity(),
            isAutoFill: false,
            value
        });
    }, [value, day, month, year, hour, minutes, errorExternal]);

    useEffect(() => {
        return () => {
            props.registry.remove(props.name);
        };
    }, []);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }

        onValidateDate();
    }, [value, isValid, day, month, year, hour, minutes]);

    const elLabel = (): ReactNode => {
        if(props.children) {
            return (
                <label
                    htmlFor="day"
                    className={cn('input-date__label', {
                        'input-date__label_required': props.required
                    })}
                >
                    {props.children}
                </label>
            );
        }
    };

    const onChangeMonth = (inputValue: ValueType<IValue>): void => {
        if(errorExternal) {
            setErrorExternal(null);
        }

        const payload = inputValue as IValue;

        if(!Array.isArray(inputValue)) {
            setMonthSelect(inputValue);
            setMonth(Number(payload.value));
            onValidateDate();
        }
    };

    const onChangeInput = (name: string) => (e: ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        const inputValue = e.currentTarget.valueAsNumber;
        const dateTimeValue = e.currentTarget.value;

        if(errorExternal) {
            setErrorExternal(null);
        }

        switch (name) {
            case 'day': {
                setDay(inputValue);
                break;
            }

            case 'year': {
                setYear(inputValue);
                break;
            }

            case 'hour': {
                setHour(dateTimeValue);
                break;
            }

            case 'minutes': {
                setMinutes(dateTimeValue);
                break;
            }
        }

        onValidateDate();
    };

    const onFocus = (): void => {
        setIsFocus(true);
    };

    const onBlur = (): void => {
        setIsFocus(false);

        if(!isWatch) {
            setIsWatch(true);
        }
    };

    const onInputChange = (inputValue: string): void => {
        const newSuggests = MONTHS.filter((suggest: IValue) => {
            return suggest.label && suggest.label.toLowerCase().includes(inputValue.toLowerCase());
        });

        setSuggests(newSuggests);
    };

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('input-date__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    const elDateTime = (): ReactNode => {
        if(props.isDateTime) {
            return (
                <div className={cn('input-date__datetime')}>
                    <input
                        className={cn('input-date__field', 'input-date__field_hour', {
                            'input-date__field_invalid': (isWatch && errorInternal) || errorExternal
                        })}
                        {...qaAttributes(props['data-qa'] ? `date:hour:${props['data-qa']}` : 'date:hour')}
                        disabled={props.disabled}
                        required={props.required}
                        id="hour"
                        name="date_input_hour"
                        min={0}
                        max={23}
                        type="number"
                        value={hour}
                        onChange={onChangeInput('hour')}
                        onBlur={onBlur}
                    />
                    <span className={cn('input-date__datetime-divider')}>:</span>
                    <input
                        className={cn('input-date__field', 'input-date__field_minutes', {
                            'input-date__field_invalid'   : (isWatch && errorInternal) || errorExternal,
                            'input-date__field_full-width': props.fieldsDirection
                        })}
                        {...qaAttributes(props['data-qa'] ? `date:minutes:${props['data-qa']}` : 'date:minutes')}
                        disabled={props.disabled}
                        required={props.required}
                        id="minutes"
                        name="date_input_minutes"
                        min={0}
                        max={59}
                        type="number"
                        value={minutes}
                        onChange={onChangeInput('minutes')}
                        onBlur={onBlur}
                    />
                </div>
            );
        }
    };

    return (
        <div className={cn('input-date', `input-date_${props.direction}`)}>
            {elLabel()}
            <div
                className={cn('input-date__wrapper', {
                    [`input-date__wrapper_${props.fieldsDirection}`]: props.fieldsDirection
                })}
            >
                <input
                    className={cn('input-date__field', 'input-date__field_day', {
                        'input-date__field_invalid'   : (isWatch && errorInternal) || errorExternal,
                        'input-date__field_full-width': props.fieldsDirection
                    })}
                    {...qaAttributes(props['data-qa'] ? `date:day:${props['data-qa']}` : 'date:day')}
                    disabled={props.disabled}
                    required={props.required}
                    id="day"
                    name="date_input_day"
                    min={1}
                    max={31}
                    type="number"
                    placeholder={i18n.t('components.form.date-input.placeholder.day')}
                    value={!!day && day.toString() || ''}
                    onChange={onChangeInput('day')}
                    onBlur={onBlur}
                />
                <ReactSelect
                    className={cn('input-date__field_select', 'input-date__field_month', {
                        'input-date__field_invalid'   : (isWatch && errorInternal) || errorExternal,
                        'input-date__field_full-width': props.fieldsDirection
                    })}
                    isMulti={false}
                    isSearchable={true}
                    isDisabled={props.disabled}
                    value={monthSelect}
                    name="date_input_month"
                    defaultValue={monthSelect}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    options={suggests}
                    onInputChange={onInputChange}
                    placeholder={i18n.t('components.form.date-input.placeholder.month')}
                    components={{ Input: SelectInput }}
                    onChange={onChangeMonth}
                    styles={{
                        placeholder: () => ({
                            color     : '#a9a9a9',
                            fontSize  : '14px',
                            position  : 'absolute',
                            top       : '50%',
                            lineHeight: '40px',
                            marginTop : '-20px',
                            marginLeft: '4px'
                        }),
                        container: () => ({
                            border      : 'solid 1px #d9d9d9',
                            borderRadius: '4px',
                            minHeight   : '40px',
                            boxSizing   : 'border-box',
                            position    : 'relative',
                            // eslint-disable-next-line no-nested-ternary
                            borderColor : (isWatch && errorInternal) || errorExternal ? '#ff6d6d' : isFocus ? '#f7b322' : '#d9d9d9',
                            // eslint-disable-next-line no-nested-ternary
                            boxShadow   : (isWatch && errorInternal) || errorExternal ? '0 0 0 1px #ff6d6d' : isFocus ? '0 0 0 1px #f7b322' : 'none'
                        }),
                        control: () => ({
                            alignItems     : 'center',
                            backgroundColor: props.disabled ? 'rgba(224, 224, 224, 0.25)' : '#fff',
                            display        : 'flex',
                            flexWrap       : 'wrap',
                            justifyContent : 'center',
                            outline        : 'none',
                            position       : 'relative',
                            transition     : 'all 100ms',
                            minHeight      : '38px',
                            height         : '100%',
                            borderRadius   : '4px'
                        }),
                        multiValue: () => ({
                            display        : 'block',
                            height         : '24px',
                            lineHeight     : '24px',
                            backgroundColor: 'rgba(0, 112, 233, 0.15)',
                            fontSize       : '12px',
                            padding        : '0 24px 0 10px',
                            color          : '#0070e9',
                            whiteSpace     : 'nowrap',
                            overflow       : 'hidden',
                            textOverflow   : 'ellipsis',
                            borderRadius   : '12px',
                            textAlign      : 'center',
                            fontWeight     : 500,
                            position       : 'relative',
                            margin         : '1px 3px'
                        }),
                        multiValueLabel : () => ({}),
                        multiValueRemove: () => ({
                            borderRadius   : '50%',
                            backgroundColor: '#fff',
                            fill           : '#0070e9',
                            boxSizing      : 'border-box',
                            position       : 'absolute',
                            right          : '4px',
                            top            : '4px',
                            bottom         : '4px',
                            cursor         : 'pointer',
                            padding        : '4px'
                        })
                    }}
                />
                <input
                    className={cn('input-date__field', 'input-date__field_year', {
                        'input-date__field_invalid'   : (isWatch && errorInternal) || errorExternal,
                        'input-date__field_full-width': props.fieldsDirection
                    })}
                    disabled={props.disabled}
                    required={props.required}
                    min={props.minYear}
                    placeholder={i18n.t('components.form.date-input.placeholder.year')}
                    value={!!year && year.toString() || ''}
                    type="number"
                    name="date_input_year"
                    onChange={onChangeInput('year')}
                    onBlur={onBlur}
                />
            </div>
            {elDateTime()}
            {elError()}
        </div>
    );
};

Input.defaultProps = {
    minYear  : moment().get('year'),
    direction: 'row',
    required : false
};

export default Input;
