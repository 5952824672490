import React, { FC } from 'react';
import { useClassnames } from 'hook/use-classnames';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'component/button';
import Modal from 'component/modal';

import { IProps } from './types';
import style from './index.pcss';

const ModalMessageSetPhone: FC<IProps> = (props) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();

    const onClickRedirectDashboard = (): void => {
        history.push('/dashboard');

        return;
    };

    return (
        <Modal onClickClose={props.onClickClose}>
            <div className={cn('set-phone__modal-phone')}>
                <div className={cn('set-phone__phone-content')}>
                    <h1>{t('components.sidebar-action.forms.modal.title')}</h1>
                    <div className={cn('set-phone__phone-text')}>
                        {t('components.sidebar-action.forms.modal.text')}
                    </div>
                </div>
                <div className={cn('set-phone__phone-buttons')}>
                    <Button
                        className={cn('set-phone__phone-cancel')}
                        isSecondary={true}
                        onClick={props.onClickClose}
                    >
                        {t('components.sidebar-action.forms.modal.buttons.cancel')}
                    </Button>
                    <Button onClick={onClickRedirectDashboard}>
                        {t('components.sidebar-action.forms.modal.buttons.phone')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalMessageSetPhone;
