import React, { ReactElement, useContext, useReducer } from 'react';

import { IContextProps } from './types';
import { show, hide, setText, setType } from './actions';
import reducer from './reducer';

const AlertContext = React.createContext<IContextProps>({
    visible: false,
    text: '',
    type: 'default',
    show:  (text, type) => { return undefined; },
    hide:  () => { return undefined; }
});

interface IProps {
    children?: ReactElement
}

export const useAlert = () => {
    return useContext(AlertContext);
};

export const AlertProvider = (props: IProps) => {
    const [state, dispatch] = useReducer(reducer, {
        visible: false,
        text: '',
        type: 'default'
    });

    const showAlert = (text: string, type?: string) => {
        if (type) {
            dispatch(setType(type));
        } else {
            dispatch(setType('default'));
        }

        dispatch(setText(text));
        dispatch(show());
    };

    const hideAlert = () => {
        dispatch(hide());
    };

    return (
        <AlertContext.Provider
            value={{
                visible: state.visible,
                text: state.text,
                type: state.type,
                show: showAlert,
                hide: hideAlert
            }}
        >
            {props.children}
        </AlertContext.Provider>
    );
};
