import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const ArrowDirDown: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props} viewBox="0 0 12 8">
        <path d="M1.41 0.580078L6 5.17008L10.59 0.580078L12 2.00008L6 8.00008L0 2.00008L1.41 0.580078Z" fill="#B6BCC4" />
    </svg>
);

export default ArrowDirDown;
