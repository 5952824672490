import React, { PureComponent, ReactNode } from 'react';
import * as classnames from 'classnames/bind';
import qaAttributes from 'component/helper/qa-attributes';

import { IProps } from './types/sidebar';
import style from './sidebar.pcss';

class Sidebar extends PureComponent<IProps> {

    cn = classnames.bind(style);

    render(): ReactNode {
        return <div className={this.cn('sidebar', this.props.className)} {...qaAttributes(this.props['data-qa'] || 'ui-sidebar')}>{this.props.children}</div>;
    }

}

export default Sidebar;
