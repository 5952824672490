import React, { PureComponent, createRef, RefObject } from 'react';
import * as classnames from 'classnames/bind';

import Loader from 'component/loader';

import observer from './observer';
import { IDefaultProps, IProps, IState } from './types';
import style from './index.pcss';

class LazyLoad extends PureComponent<IProps, IState> {

    static defaultProps: IDefaultProps = {
        component: 'div'
    };

    _unobserve?: () => void;

    $root: RefObject<HTMLDivElement> = createRef();

    cn = classnames.bind(style);

    state: IState = {
        show: false
    };

    componentDidMount(): void {
        if(this.$root.current) {
            this._unobserve = observer.observe(this.$root.current, this.onObserve);
        }
    }

    componentWillUnmount(): void {
        this.unobserve();
    }

    unobserve = (): void => {
        if(this._unobserve) {
            this._unobserve();

            delete this._unobserve;
        }
    };

    onObserve = () => {
        this.unobserve();

        this.setState({ show: true });
    };

    render() {
        if(this.state.show) {
            const { component, ...props } = this.props;
            const Component = component;

            // @ts-ignore
            return <Component {...props} />;
        }

        return (
            <div className={this.cn('lazy-load', this.props.className)} ref={this.$root}>
                <Loader />
            </div>
        );
    }

}

export default LazyLoad;
