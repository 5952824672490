import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import axios from 'axios';
import { stringify } from 'query-string';
import debounce from 'lodash.debounce';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Subscribe from 'component/subscribe';
import SearchResult from 'component/search-result';
import InputEvents from 'component/form/input-events';
import InputLocation from 'component/form/input-location';
import Button from 'component/button';
import Form, { useRegistry } from 'component/form';
import Loader from 'component/loader';
import style from './index.pcss';
import useIntersect from 'hook/use-intersect';
import history from 'component/core/history';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import PersonEdit from 'component/person-edit';
import api from 'src/api';
import { Event, EventsSearchFilter } from 'src/api/events/types';
import SearchPartnerModal from './search-partner/search-partner-modal';
import { Person } from 'src/api/persons/types';
import { PersonStatistic } from 'src/api/statistics/types';
import { Page } from 'src/api/base';
import PersonMerge from 'component/person-merge';
import { Photo, PhotosSearchFilter } from 'src/api/photos/types';
import PersonControlIcons from './control-icons';
import ReactTooltip from 'react-tooltip';
import { AdListData } from 'src/api/ads/types';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { getNormalizedQuery } from 'component/advertisements/utils';

const EVENTS_LIMIT = 10;

const Person = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { form, field } = useRegistry();

    const { id }: { id: string } = useParams();

    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());
    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showMergeModal, setShowMergeModal] = useState<boolean>(false);
    const [showSearchPartner, setShowSearchPartner] = useState<boolean>(false);
    const [validity, setValidity] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [person, setPerson] = useState<Person | null>(null);
    const [isPersonLoading, setIsPersonLoading] = useState<boolean>(true);
    const [list, setList] = useState<Array<Event>>([]);
    const [eventCount, setEventCount] = useState<number>(0);
    const [personStatistic, setPersonStatistic] = useState<PersonStatistic | null>(null);
    const [subscription, setSubscription] = useState<number | null>(null);
    const [statisticsPending, setStatisticsPending] = useState<boolean>(false);
    const [isSellSuit, setIsSellSuit] = useState<boolean>(false);

    const [totalPhotos, setTotalPhotos] = useState<number>(0);
    const [lastPhotos, setLastPhotos] = useState<Array<Photo>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isNextPage, setIsNextPage] = useState<boolean>(true);

    const [isEventListLoading, setIsEventListLoading] = useState<boolean>(false);
    const [isEventListLoadMore, setIsEventListLoadMore] = useState<boolean>(false);

    useEffect(() => {
        if (!isPersonLoading && !person) {
            history.push('/404');
        }
    }, [isPersonLoading]);

    useEffect(() => {
        if (id && !isPersonLoading && person) {
            setStatisticsPending(true);
            api.statistic.getPersonStatistics(Number(id))
            .then((resp) => setPersonStatistic(resp.data))
            .catch((err) => setPersonStatistic(null))
            .finally(() => setStatisticsPending(false));
        }
    }, [id, isPersonLoading]);

    useEffect(() => {
        if (!isPersonLoading && person) {
            _requestSubscription();

            const page: Page = {
                pageNumber: 1
            };

            const data: AdListData = {
                person_id: [id]
            };

            api.ads.getAdList(page, data)
                .then((resp) => {
                    setIsSellSuit(!!resp.data.count);
                });
        }
    }, [isPersonLoading]);

    useEffect(() => {
        if (id && !isPersonLoading && person) {
            _requestPhotos();
        }
    }, [id, isPersonLoading]);

    const _requestPhotos = (): void => {
        const page: Page = {
            pageNumber: 1,
            pageSize: 5
        };

        const filter: PhotosSearchFilter = {
            person_id: [id]
        };

        api.latestPhotos.getLatestPhotosList(page, filter)
            .then((resp) => {
                setLastPhotos(resp.data.results);
                setTotalPhotos(resp.data.count);
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    const _request = (): void => {
        setPending(true);

        api.persons.getPersonItem(Number(id))
            .then((resp: {data: Person}) => {
                setPerson(resp.data);

                if (resp.data.is_subscribed) {
                    setSubscription(resp.data.id);
                }

                setPending(false);
                setIsPersonLoading(false);

                setIsEventListLoading(true);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setPending(false);
                    setIsPersonLoading(false);
                }
            });
    };

    useEffect(() => {
        if (isEventListLoading || isEventListLoadMore) {
            const page: Page = {
                pageNumber: currentPage,
                pageSize: EVENTS_LIMIT
            };

            const filter: EventsSearchFilter = {
                is_empty: 0,
                person_id: [Number(id)],
                ...(queryParams.event_id && { event_id: queryParams.event_id }),
                ...(queryParams.location_id && { location_id: queryParams.location_id })
            };

            if (isNextPage) {
                api.events.getEventsList(page, filter)
                    .then((resp) => {
                        setList(
                            isEventListLoadMore
                                ? [...list, ...resp.data.results]
                                : resp.data.results
                        );
                        setEventCount(resp.data.count);
                        setIsEventListLoading(false);
                        setIsEventListLoadMore(false);
                        if (resp.data.next !== null) {
                            setCurrentPage((prev) => prev + 1);
                        } else {
                            setIsNextPage(false);
                        }
                    })
                    .catch((err) => {
                        if(!axios.isCancel(err)) {
                            setIsEventListLoading(false);
                            setIsEventListLoadMore(false);
                        }
                    });
            }
        }
    }, [isEventListLoading, isEventListLoadMore]);

    const _requestSubscription = () => {
        api.persons.getPersonSubscription(Number(id))
        .then((resp) => {
            setSubscription(resp.data.id || null);
        });
    };

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        if(form.checkValidity() && !isPersonLoading && person) {
            setCurrentPage(1);
            setIsNextPage(true);
            setIsEventListLoading(true);
        }
    }, [JSON.stringify(queryParams), isPersonLoading]);

    useEffect(() => {
        _request();
    }, [id]);

    const onChangeForm = debounce(useCallback(() => {
        const payload = form.getPayload();
        const data = {
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.event?.value && { event_id: payload.event.value })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [validity, JSON.stringify(form.getPayload()), JSON.stringify(person)]), 300);

    const onReset = useCallback(() => {
        form.clearForm();
        window.scrollTo(0, 0);

        setIsNextPage(true);
        setCurrentPage(1);
        setIsEventListLoading(true);
    }, []);

    const onClickEditPerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/persons/${id}`);

            return;
        }

        setShowEditModal(true);
    }, [isAuth]);

    const onClickMergePerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/persons/${id}`);

            return;
        }

        setShowMergeModal(true);
    }, [isAuth]);

    const onClickSearchPartner = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/persons/${id}`);

            return;
        }

        setShowSearchPartner(true);
    }, [isAuth]);

    const onClickApproveSearchPartner = () => {
        history.push(`/find-partner?search=${person?.first_name || ''} ${person?.last_name || ''}`);
    };

    const onClickSellSuit = () => {
        if (!isAuth) {
            history.push(`/login?from=/persons/${id}`);

            return;
        }

        history.push('/advertisements');
    };

    const onCloseModal = useCallback(() => {
        setShowEditModal(false);
        setShowMergeModal(false);
        setShowSearchPartner(false);
    }, []);

    const elPersonEdit = useMemo(() => {
        if (showEditModal && person) {
            return (
                <PersonEdit
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }

        if (showMergeModal && person) {
            return (
                <PersonMerge
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }
    }, [showEditModal, showMergeModal, JSON.stringify(person)]);

    const elSearchPartner = useMemo(() => {
        if (showSearchPartner && userId) {
            return (
                <SearchPartnerModal
                    id={Number(id)}
                    onCloseModal={onCloseModal}
                />
            );
        }
    }, [showSearchPartner, JSON.stringify(person)]);

    const  elSubscription = useMemo(() => {
        if(!isPersonLoading && !pending && person?.id) {
            return <Subscribe type="person" subscription={subscription} persons={[person?.id]} className={cn('person__subscription')} />;
        }
    }, [JSON.stringify(person), person, isPersonLoading, JSON.stringify(subscription)]);

    const elImageBlock = useMemo(() => {
        if(pending) {
            return <Loader />;
        }

        if(person?.photo) {
            return (
                <div className={cn('person__image-block')}>
                    <img alt="Person photo" src={person.photo} className={cn('person__image')} />
                </div>
            );
        }
    }, [JSON.stringify(person), pending]);

    const elAgeLocation = useMemo(() => {
        let text = '';

        if(person?.location) {
            text = `${person?.location.name}`;
        }

        return <span className={cn('person__age')}>{text}</span>;
    }, [JSON.stringify(person)]);

    const elPersonStatistics = useMemo(() => {
        return (
            <>
            <div className={cn('person__info-item')}>
                <span className={cn('person__info-name')}>{t('route.person.info.events')}</span>
                <span className={cn('person__info-value')}>{
                    statisticsPending ? <Loader text={' '} className={cn('person__info-loader')} /> : personStatistic?.event_count
                }</span>
            </div>
            <div className={cn('person__info-item')}>
                <span className={cn('person__info-name')}>{t('route.person.info.photos')}</span>
                <span className={cn('person__info-value')}>{
                    statisticsPending ? <Loader text={' '} className={cn('person__info-loader')} /> : personStatistic?.photo_count
                }</span>
            </div>
            </>
        );
    }, [JSON.stringify(personStatistic), statisticsPending]);

    const $LoadMoreEvent = useIntersect((entry) => {
        if(entry.isIntersecting && isNextPage) {
            setIsEventListLoadMore(true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elLoadMoreEvent = () => {
        if (!isEventListLoading && !isEventListLoadMore && list.length) {
            if (eventCount > list.length) {
                return <Loader ref={$LoadMoreEvent} />;
            }
        }

        if (isEventListLoadMore && list.length) {
            return <Loader />;
        }
    };

    const elList = useMemo(() => {
        if(isEventListLoading || isPersonLoading) {
            return (
                <UI.Box padding={true} className={cn('person__empty')}>
                    <Loader />
                </UI.Box>
            );
        }

        if(list?.length && !isEventListLoading) {
            const query = {search: `${(person?.first_name && person?.last_name) ? person.full_name : person?.id}`, person_id: [Number(id)]};

            return (
                <div className={cn('person__results')}>
                    {list.map((item) => (
                        <SearchResult
                            photo_attachments={item.photos}
                            key={item.id}
                            link={`/events/${item.id}`}
                            suit_count={item.sell_suit_count}
                            title={item.name}
                            place={item.location_name}
                            date={item.date}
                            eventId={item.id}
                            query={stringify(query)}
                        />
                    ))}
                    {elLoadMoreEvent()}
                </div>
            );
        }

        return (
            <UI.Box padding={true} className={cn('person__empty')}>
                {t('route.person.results.empty')}
            </UI.Box>
        );
    }, [JSON.stringify(list), person, isNextPage, isEventListLoadMore, isEventListLoading]);

    const elLastPhoto = useMemo(() => {
        if (lastPhotos.length) {
            return (
                <SearchResult
                    title={'Фото за последние 7 дней'}
                    link={'/persons/photos'}
                    photo_attachments={lastPhotos.map((item) => item.photo_url)}
                    photos_count={totalPhotos}
                    persons={[Number(id)]}
                    query={`&person_id=${id}`}
                />
            );
        }
    }, [id, lastPhotos, totalPhotos]);

    const elLocation = () => {
        let defaultLocation = null;

        if(queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        return (
            <InputLocation
                registry={field}
                clearable={true}
                defaultValue={defaultLocation}
                name="location"
                children={t('global.form.items.city')}
                direction="column"
                className={cn('person__input-block')}
            />
        );
    };

    return (
        <UI.Main className={cn('person')}>
            {elPersonEdit}
            {elSearchPartner}
            <div className={cn('person__grid')}>
                <div className={cn('person__sidebar')}>
                    <div className={cn('person__search-container', (person?.is_partner_profile || isSellSuit) && 'person__search-container-active')}>
                        <UI.Box padding={true} className={cn('person__box')}>
                            {elImageBlock}
                            <div className={cn('person__data')}>
                                <div className={cn('person__name-age')}>
                                    <div className={cn('person__name__container')}>
                                        <h4 className={cn('person__name')}>
                                            {(person?.first_name && person?.last_name) ? person.full_name : person?.id}
                                        </h4>
                                        <PersonControlIcons
                                            person={person}
                                            onClickEditPerson={onClickEditPerson}
                                            onClickMergePerson={onClickMergePerson}
                                            onClickSearchPartner={onClickSearchPartner}
                                            isActive={person?.is_partner_profile}
                                        />
                                    </div>
                                    {elAgeLocation}
                                </div>
                                <div className={cn('person__info')}>
                                    {elPersonStatistics}
                                </div>
                                <div className={cn('person__btn')}>
                                    {person?.is_partner_profile && (
                                        <div className={cn('person__btn-container')}>
                                            <div className={cn('person__search-partner')}>
                                                <ReactTooltip
                                                    id="search-partner"
                                                    place="top"
                                                    effect="solid"
                                                    border={true}
                                                    borderColor="#dee1e4"
                                                    backgroundColor="#fff"
                                                    className={cn('person__search-partner-tooltip-partner')}
                                                >
                                                    {'Просмотреть анкету'}
                                                </ReactTooltip>
                                                <button data-tip={true} data-type="light" data-for="search-partner" className={cn('person__search-partner-show', pending ? 'person__search-partner-show-gray' : '')} onClick={onClickApproveSearchPartner}>
                                                    {'Ищу партнера'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {isSellSuit && (
                                        <div className={cn('person__search-partner', 'person__search-partner__sell')}>
                                            <ReactTooltip
                                                id="sell-suit"
                                                place="top"
                                                effect="solid"
                                                border={true}
                                                borderColor="#dee1e4"
                                                backgroundColor="#fff"
                                                className={cn('person__search-partner-tooltip')}
                                            >
                                                {'Эта персона продает костюм, представленный на фотографиях события'}
                                            </ReactTooltip>
                                            <button
                                                data-tip={true}
                                                data-type="light"
                                                data-for="sell-suit"
                                                className={cn('person__search-partner-show', pending ? 'person__search-partner-show-gray' : '')}
                                                onClick={onClickSellSuit}
                                            >
                                                {'Продаю костюм'}
                                            </button>
                                        </div>
                                    )}
                                    {elSubscription}
                                </div>
                            </div>
                            {/* {elAd} */}
                        </UI.Box>
                    </div>
                    <Form
                        registry={form}
                        onChangeValidity={setValidity}
                        onChange={onChangeForm}
                    >
                        <UI.Box padding={true} className={cn('person__box')}>
                            <UI.BoxHeader>{t('route.person.sidebar.header')}</UI.BoxHeader>
                            <InputEvents
                                registry={field}
                                name="event"
                                clearable={true}
                                children={t('global.form.items.event')}
                                direction="column"
                                className={cn('person__input-block')}
                                default_id={queryParams.event_id}
                                person_id={[id]}
                            />
                            {elLocation()}
                            <Button
                                onClick={onReset}
                                type="reset"
                                className={cn('person__button-submit')}
                                disabled={pending}
                            >
                                {t('route.search.sidebar.button-reset')}
                            </Button>
                        </UI.Box>
                    </Form>
                </div>
                <div className={cn('person__content')}>
                    {elLastPhoto}
                    {elList}
                </div>
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Person;
