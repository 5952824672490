import uuid from 'uuid/v4';

import { IPayload } from './types';
import { TFormOnChange, IRegistryStore, IRegistryStoreField, IReturn } from './types/registry';

const store: IRegistryStore = {
    form: {
        onChange: new Map(),
        isValid: new Map(),
        inited: new Map()
    },
    fields: new Map()
};

export default (): IReturn => {
    const uid = uuid();

    return {
        form: {
            setOnChange: (onChange: TFormOnChange) => {
                if(store.form.onChange.has(uid)) {
                    console.warn('Replace form handler `onChange` uid: %s', uid);
                }

                store.form.onChange.set(uid, onChange);
            },
            getFields: () => store.fields.get(uid),
            checkValidity: () => {
                const fields = store.fields.get(uid);
                let isValidity = true;

                if(fields) {
                    for(const [key, field] of fields.entries()) {
                        if(!field.isValid) {
                            isValidity = false;

                            break;
                        }
                    }
                }

                return isValidity;
            },
            getPayload: () => {
                const payload: IPayload = {};
                const fields = store.fields.get(uid);

                if(fields) {
                    fields.forEach((field, name) => {
                        payload[name] = field.value;
                    });
                }

                return payload;
            },
            clearForm: () => {
                const fields = store.fields.get(uid);

                if(fields) {
                    for(const [key, field] of fields.entries()) {
                        field.clear();
                    }
                }
            }
        },
        field: {
            set: (key: string, value: IRegistryStoreField) => {
                const item = store.fields.get(uid);

                if(!item) {
                    store.fields.set(uid, new Map([[key, value]]));
                } else {
                    item.set(key, value);
                }
            },
            remove: (key: string) => {
                const item = store.fields.get(uid);

                if(item && item.has(key)) {
                    item.delete(key);
                }
            },
            onChange: () => store.form.onChange.get(uid)
        }
    };
};
