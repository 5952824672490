import React from 'react';
import { useTranslation } from 'react-i18next';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';

import style from './index.pcss';

const About = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    return (
        <UI.Main className={cn('about')}>
            <h1 className={cn('about__header')}>{t('route.about.header')}</h1>
            <div className={cn('about__content')}>
                <div className={cn('about__container')}>
                    <h2 className={cn('about__block-header')}>
                        {t('route.about.company.name')}
                    </h2>
                    <p>{t('route.about.company.legal-address')}</p>
                    <p>{t('route.about.company.address')}</p>
                    <p>{t('route.about.company.inn')}</p>
                    <p>{t('route.about.company.kpp')}</p>
                    <p>{t('route.about.company.ogrn')}</p>
                    <p>{t('route.about.company.invoice')}</p>
                    <p>{t('route.about.company.bank')}</p>
                    <p>{t('route.about.company.bik')}</p>
                    <p>{t('route.about.company.corr-invoice')}</p>
                    <p>{t('route.about.company.phone')}</p>
                    <p>{t('route.about.company.email')}</p>
                </div>
            </div>
        </UI.Main>
    );
};

export default About;
