import React, { useState } from 'react';
import UI from 'component/ui';
import style from '../index.pcss';
import { useClassnames } from 'hook/use-classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEyeDropper, faPersonBooth, faPhone, faTshirt, faUser, faVenusMars } from '@fortawesome/free-solid-svg-icons';
import { genderOptions, suitSizeOptions } from 'component/advertisements/utils';
import { AdItem } from 'src/api/ads/types';
import InputColor from 'component/form/input-color';
import { IReturnField, IReturnForm } from 'component/form/types/registry';
import Form from 'component/form';
import Input from 'component/form/input';
import InputSelect from 'component/form/input-select';
import Textarea from 'component/form/textarea';
import InputRange from 'component/form/input-range';

interface IProps {
    item: AdItem;
    form: IReturnForm;
    field: IReturnField
    onClickSubmit(): void;
    onClickCancel(): void;
}

const EditAdModalCard = (props: IProps) => {
    const cn = useClassnames(style);
    const [error, setError] = useState<boolean>(false);

    const additionalPhoneCheckValidity = () => {
        const fields = props.form.getFields();

        if (fields) {
            const additional_phone = fields.get('additional_phone_number');

            if (additional_phone) {
                if (additional_phone.value.length < 10 && additional_phone.value.length !== 0) {
                    setError(true);

                    return {
                        isValid: false,
                        error: 'Введите корректный номер'
                    };
                }

                setError(false);

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    return (
        <Form
            registry={props.form}
        >
            <UI.Main className={cn('ad-modal__content__info')}>
                <div className={cn('ad-modal__content__info__item')}>
                    <div className={cn('ad-modal__content__info__item-title')}>
                        <FontAwesomeIcon
                            icon={faEyeDropper}
                            className={cn('ad-modal__content__info__item-title-icon')}
                            title={'Цвет'}
                        />
                    </div>
                    <InputColor
                        registry={props.field}
                        className={cn('edit-ad-modal__content__info__item-color')}
                        name="color"
                        direction="row"
                        required={true}
                        defaultValue={[props.item.color.id]}
                        onlyOneSelect={true}
                    />
                </div>
                <div className={cn('ad-modal__content__info__item')}>
                    <div className={cn('ad-modal__content__info__item-title')}>
                        <FontAwesomeIcon
                            icon={faUser}
                            className={cn('ad-modal__content__info__item-title-icon')}
                            title={'Рост'}
                        />
                    </div>
                    <InputRange
                        registry={props.field}
                        minValue={50}
                        maxValue={250}
                        name="height"
                        direction="column"
                        value_from={props.item.min_height}
                        value_to={props.item.max_height}
                    />
                </div>
                <div className={cn('ad-modal__content__info__item')}>
                    <div className={cn('ad-modal__content__info__item-title')}>
                        <FontAwesomeIcon
                            icon={faTshirt}
                            className={cn('ad-modal__content__info__item-title-icon')}
                            title={'Размер'}
                        />
                    </div>
                    <InputSelect
                        registry={props.field}
                        className={cn('edit-ad-modal__content__info__item-select')}
                        name="suit_size"
                        direction="column"
                        options={suitSizeOptions}
                        defaultValue={suitSizeOptions.find((item) => item.value === props.item.suit_size)}
                        required={true}
                    />
                </div>
                <div className={cn('ad-modal__content__info__item')}>
                    <div className={cn('ad-modal__content__info__item-title')}>
                        <FontAwesomeIcon
                            icon={faVenusMars}
                            className={cn('ad-modal__content__info__item-title-icon')}
                            title={'Пол'}
                        />
                    </div>
                    <InputSelect
                        registry={props.field}
                        className={cn('edit-ad-modal__content__info__item-select')}
                        name="gender"
                        direction="column"
                        options={genderOptions}
                        defaultValue={genderOptions.find((item) => item.value === props.item.gender)}
                        required={true}
                    />
                </div>
                <div className={cn('ad-modal__content__info__column-item')}>
                    <div className={cn('ad-modal__content__info__column-item-title')}>
                        Контактная информация
                    </div>
                </div>
                <div className={cn('ad-modal__content__info__item')}>
                            <div className={cn('ad-modal__content__info__item-title')}>
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className={cn('ad-modal__content__info__item-title-icon')}
                                    title={'Имя продавца'}
                                />
                            </div>
                            <div className={cn('ad-modal__content__info__item-main')}>
                                {props.item.user.name}
                            </div>
                        </div>
                <div className={cn('ad-modal__content__info__item')}>
                    <div className={cn('ad-modal__content__info__item-title')}>
                        <FontAwesomeIcon
                            icon={faPhone}
                            className={cn('ad-modal__content__info__item-title-icon')}
                            title={'Номер телефона'}
                        />
                    </div>
                    <div className={cn('ad-modal__content__info__item-main')}>
                        {props.item.phone_number}
                    </div>
                </div>
                <div className={cn('ad-modal__content__info__item')}>
                    <div className={cn('ad-modal__content__info__item-title')}>
                        <FontAwesomeIcon
                            icon={faPhone}
                            className={cn('ad-modal__content__info__item-title-icon')}
                            title={'Дополнительный номер телефона'}
                        />
                    </div>
                    <Input
                        registry={props.field}
                        defaultValue={props.item.additional_phone_number}
                        name="additional_phone_number"
                        direction="column"
                        placeholder="(XXX) XXX-XX-XX"
                        onlyNumbers={true}
                        checkValidity={additionalPhoneCheckValidity}
                    />
                </div>
                <div className={cn('ad-modal__content__info__item')}>
                    <div className={cn('ad-modal__content__info__item-title')}>
                        <FontAwesomeIcon
                            icon={faComment}
                            className={cn('ad-modal__content__info__item-title-icon')}
                            title={'Эл. почта'}
                        />
                    </div>
                    <div className={cn('ad-modal__content__info__item-main')}>
                        {props.item.email}
                    </div>
                </div>
                <div className={cn('ad-modal__content__info__column-item')}>
                    <div className={cn('ad-modal__content__info__column-item-title')}>
                        Комментарий
                    </div>
                    <Textarea
                        registry={props.field}
                        defaultValue={props.item.comment}
                        name="comment"
                        direction="column"
                        maxLength={150}
                    />
                </div>
            </UI.Main>
        </Form>
    );
};

export default EditAdModalCard;
