import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import { PremiumAccount, PremiumAccountsAPIType, PremiumAccountsFilter } from './types';

const getPremiumAccountsList = (
    page: Page,
    filter?: PremiumAccountsFilter
): Promise<AxiosResponse<PaginationResponse<PremiumAccount>>> => {
    return httpClient.get<PaginationResponse<PremiumAccount>>('/api/v2/premium-accounts/', {
        params: {
            page: page.pageNumber,
            ...({ page_size: page.pageSize ? page.pageSize : 40 }),
            ...(filter?.search && { search: filter.search }),
            ...(filter?.ordering && { ordering: filter.ordering })
        }
    });
};

export default {
    getPremiumAccountsList
} as PremiumAccountsAPIType;
