import React, { ImgHTMLAttributes, RefAttributes, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import ErrorBlock from 'component/error';
import style from './index.pcss';
import api from 'src/api';
import { GetOrderPhotoItemData, OrderRetrieve } from 'src/api/order/types';
import qaAttributes from 'component/helper/qa-attributes';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import Modal from 'component/modal';

const History = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const { id }: { id?: string } = useParams();

    const [error, setError] = useState<string | null>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderRetrieve>();
    const [paymentStatus, setPaymentStatus] = useState<string>('');
    const [isArchiveLoading, setIsArchiveLoading] = useState<boolean>(false);

    useEffect(() => {
        setPending(true);

        api.order.getOrderItem(Number(id))
            .then((resp) => {
                setPaymentStatus('PAYED');
                setOrder(resp.data);
                setPending(false);
            })
            .catch((err) => {
                console.error(err);
                setError(err.message || t('route.order-info.error'));
                setPending(false);
            });
    }, [id]);

    const elBlockHeader = useMemo(() => {
        if(id) {
            return <h2 className={cn('order-info__block-header')}>{t('route.order-info.block-header', { id })}</h2>;
        }
    }, [id]);

    const onClickDownloadArchive = () => {
        if (!isArchiveLoading) {
            setIsArchiveLoading(true);
            api.order.getOrderArchiveItem(Number(id))
                .then((resp) => {
                    const href = URL.createObjectURL(resp.data);

                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `order-${id}-archive`);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    setIsArchiveLoading(false);
                })
                .catch((e) => {
                    setIsArchiveLoading(false);
                });
        }
    };

    const onClickDownloadPhoto = (photo_id: number) => {
        const data: GetOrderPhotoItemData = {
            order_pk: Number(id),
            id: photo_id
        };

        api.order.getOrderPhotoItem(data)
            .then((resp) => {
                const href = URL.createObjectURL(resp.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `order-${id}-photo-${photo_id}.jpeg`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    };

    const elOrder = useMemo(() => {
        if(order?.items.length) {
            return (
                <div className={cn('order-info__photo-list')}>
                    {order.items.map((item, index) => {
                        const author = item.photo.photographer.name ? item.photo.photographer.name : t('route.order-info.item.author-empty');
                        const event = item.photo?.event?.name || t('route.order-info.item.event-empty');
                        const price = Number(item.price);

                        const params: RefAttributes<HTMLImageElement> & ImgHTMLAttributes<HTMLImageElement> = {
                            ...qaAttributes('carousel:thumbs:image'),
                            src      : item.photo.thumbnail,
                            alt      : item.photo.id.toString(),
                            className: cn('order-info__item-image')
                        };

                        return (
                            <div className={cn('order-info__list-item')} key={item.photo.id}>
                                <img {...params} />
                                <div className={cn('order-info__item-content')}>
                                    <div className={cn('order-info__item-block')}>
                                        <p className={cn('order-info__item-text')}>{event}</p>
                                        <p className={cn('order-info__item-text-sub')}>{t('route.order-info.item.author', { name: author })}</p>
                                        <div
                                            className={cn('order-info__item-text-sub', 'order-info__item-text-sub_remove')}
                                            onClick={() => onClickDownloadPhoto(item.id)}
                                        >
                                            {t('route.order-info.item.download')}
                                        </div>
                                    </div>
                                    <span className={cn('order-info__item-price')}>{t('route.order-info.item.price', { count: price })}</span>
                                </div>
                            </div>
                        );
                    })}
                    {elError}
                </div>
            );
        }
    }, [JSON.stringify(order)]);

    const elStatus = useMemo(() => {
        let text = '';

        switch(paymentStatus) {
            case 'PAYED': {
                text = t('route.order-info.status.payed');
                break;
            }

            case 'CANCELLED': {
                text = t('route.order-info.status.cancelled');
                break;
            }

            case 'ERROR': {
                text = t('route.order-info.status.error');
                break;
            }

            case 'INITED': {
                text = t('route.order-info.status.inited');
                break;
            }

            case 'INVOICED': {
                text = t('route.order-info.status.invoiced');
                break;
            }

            case 'PLACED': {
                text = t('route.order-info.status.placed');
                break;
            }
        }

        return <span className={cn('order-info__status')}>{text}</span>;
    }, [paymentStatus]);

    const elContent = useMemo(() => {
        if (pending) {
            return <Loader />;
        }

        return (
            <div className={cn('order-info__content-box')}>
                <div className={cn('order-info__header-block')}>
                    {elBlockHeader}
                    {elStatus}
                </div>
                {elOrder}
            </div>
        );
    }, [JSON.stringify(order), paymentStatus, pending]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('order-info__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    const elLoadingArchiveModal = useMemo(() => {
        if (isArchiveLoading) {
            return (
                <Modal>
                    Загрузка архива началась! Пожалуйста, не закрывайте страницу и дождитесь полной загрузки архива.
                    <Loader />
                </Modal>
            );
        }
    }, [isArchiveLoading]);

    if (!isAuth) {
        history.push('/login');
    }

    return (
        <UI.Main className={cn('order-info')}>
            {elLoadingArchiveModal}
            <h1 className={cn('order-info__header')}>{t('route.order-info.header')}</h1>
            <div className={cn('order-info__grid')}>
                <UI.Box padding={true} className={cn('order-info__content')}>
                    {elContent}
                    {elError}
                </UI.Box>
                <UI.Box padding={true} className={cn('order-info__sidebar')}>
                    <Button
                        className={cn('order-info__button')}
                        disabled={!order?.items.length || paymentStatus !== 'PAYED' || isArchiveLoading}
                        onClick={onClickDownloadArchive}
                    >
                        {t('route.order-info.download')}
                    </Button>
                    <Button
                        to="/history"
                        isSecondary={true}
                        className={cn('order-info__button', 'order-info__button_secondary')}
                    >
                        {t('route.order-info.history')}
                    </Button>
                </UI.Box>
            </div>
        </UI.Main>
    );
};

export default History;
