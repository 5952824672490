import { createAction } from '@reduxjs/toolkit';

export const show = createAction('@alert/SHOW');

export const hide = createAction('@alert/HIDE');

export const setText = createAction<string>('@alert/SET-TEXT');

export const setType = createAction<string>('@alert/SET-TYPE');

export default {
    show,
    hide,
    setText,
    setType
};
