import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'locale/en/index.json';
import ru from 'locale/ru/index.json';

i18n
    .use(initReactI18next)
    .init({
        debug        : __DEVELOPMENT__,
        lng          : 'ru',
        fallbackLng  : __DEVELOPMENT__ ? false : 'ru',
        interpolation: { escapeValue: false },
        keySeparator : '.',
        resources    : {
            en: { translation: en },
            ru: { translation: ru }
        }
    });

export default i18n;
