import React, { MouseEvent, useEffect, useState } from 'react';
import { useClassnames } from 'hook/use-classnames';

import style from 'component/checkbox/style.pcss';
import { IProps } from 'component/checkbox/types';

const Checkbox = (props: IProps) => {
    const cn = useClassnames(style, props.className, true);

    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        props.isChecked && setIsChecked(props.isChecked);
    }, [props.isChecked]);

    const onClick = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        setIsChecked((prev) => !prev);

        props.onClick && props.onClick();
    };

    return (
        <div
            onClick={onClick}
            className={cn('checkbox', {
                'checkbox__checked': isChecked
            })}
        />
    );
};

Checkbox.defaultProps = {
    isChecked: false
};

export default Checkbox;
