import { createReducer } from '@reduxjs/toolkit';

import { selected, next, prev, setTotal } from './actions';

export interface IStore {
    current: number,
    total: number
}

const initialState = {
    current: 0,
    total  : 0
};

export default createReducer<IStore>(initialState, (builder) => {
    builder
        .addCase(next, (store) => {
            const current = store.current + 1;

            if(current < store.total) {
                return {
                    ...store,
                    current
                };
            }

            return {
                ...store,
                current: 0
            };
        })
        .addCase(prev, (store) => {
            const current = store.current - 1;

            if(current >= 0) {
                return {
                    ...store,
                    current
                };
            }

            return {
                ...store,
                current: store.total - 1
            };
        })
        .addCase(selected, (store, action) => ({
            ...store,
            current: action.payload
        }))
        .addCase(setTotal, (store, action) => ({
            ...store,
            total: action.payload
        }));
});
