import { createReducer } from '@reduxjs/toolkit';

import { setTask, resetTask } from './actions';
import { IStore } from './types/reducer';

export const key = 'task' as const;

export default createReducer<IStore>({
    count: 0
}, (builder) => {
    builder
        .addCase(setTask, (store, action) => {
            if (action.payload) {
                store.count = action.payload;

                return store;
            }

            return store;
        })
        .addCase(resetTask, (store) => {
            store.count = 0;

            return store;
        });
});
