import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const ArrowBack: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props}>
        <path d="M12,1c1.5,0,2.9,0.6,3.9,1.6c1,1,1.6,2.4,1.6,3.9s-0.6,2.9-1.6,3.9c-1,1-2.4,1.6-3.9,1.6s-2.9-0.6-3.9-1.6 c-1-1-1.6-2.4-1.6-3.9s0.6-2.9,1.6-3.9C9.1,1.6,10.5,1,12,1z M12,14.8c6.1,0,11,2.5,11,5.5V23H1v-2.8C1,17.2,5.9,14.8,12,14.8z" />
    </svg>
);

export default ArrowBack;
