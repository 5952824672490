import axios, { AxiosRequestConfig } from 'axios';
import qs from 'query-string';

import appConfig from 'config';

// import validation from 'component/api/validation';

export const request = <T = void>(config?: AxiosRequestConfig): Promise<T> => {
    return axios({
        method: 'get',
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
        ...config,
        headers: {
            'cache-control': 'no-cache', // Что бы браузер не кешировал запросы которые отменили (abort)
            ...config?.headers
        }
    })
        .then(
            (response) => {
                // validation(response.config.url, response.config.method, response.status, response.data);
                if(response.data) {
                    if(response.data.data) {
                        return response.data.data;
                    }

                    return response.data;
                } else {
                    return void(0);
                }
            },
            (error) => {
                // if(!axios.isCancel(error) && error.response) {
                //     validation(error.config.url, error.config.method, error.response.status, error.response.data);
                // }

                throw error.response?.data || error;
            }
        );
};

export default { request };
