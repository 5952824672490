import { IParams, IPromises, TReturn } from './types';

const promises: IPromises = {};

export default (paths: string | Array<string>, params?: IParams): Array<TReturn> | TReturn | void => {
    const $document = document;

    const add = (path: string): TReturn => {
        if(!promises[path]) {
            const $script: HTMLScriptElement = $document.createElement('script');
            const $fragment = $document.createDocumentFragment();

            $script.src = path;
            $script.async = params ? !!params.async : false;
            $script.defer = params ? !!params.defer : false;
            $script.className = 'script-load';

            $fragment.appendChild($script);

            promises[path] = new Promise((resolve, reject) => {
                $script.addEventListener('load', (event) => {
                    if(event.target) {
                        resolve(() => {
                            $document.head.removeChild($script);

                            // tslint:disable-next-line no-dynamic-delete
                            delete promises[path];
                        });
                    } else {
                        reject(event);
                    }
                });

                $script.addEventListener('error', (event) => {
                    if($document.head) {
                        $document.head.removeChild($script);
                    }

                    // tslint:disable-next-line no-dynamic-delete
                    delete promises[path];

                    reject(event);
                });
            });

            promises[path].catch((event) => {
                console.warn('Script-load error event:', event);
            });

            $document.head && $document.head.appendChild($fragment);
        }

        return promises[path];
    };

    const inspector = (scripts: HTMLCollectionOf<HTMLScriptElement>, path: string): TReturn => {
        // tslint:disable-next-line prefer-for-of
        for(let i = 0; i < scripts.length; i++) {
            if(path === scripts[i].src && promises[path]) {
                return promises[path];
            }
        }

        return add(path);
    };

    const load = (scripts: HTMLCollectionOf<HTMLScriptElement>, path: string): TReturn => {
        return scripts.length ? inspector(scripts, path) : add(path);
    };

    if($document.head) {
        const $scripts = $document.head.getElementsByClassName('script-load') as HTMLCollectionOf<HTMLScriptElement>;

        if(typeof paths === 'string') {
            return load($scripts, paths);
        } else if(Array.isArray(paths)) {
            return paths.map((path) => load($scripts, path));
        } else {
            console.warn('Неправильный формат данных! Требуется строка или массив, получено: ', typeof paths, paths);
        }
    }
};
