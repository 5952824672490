import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { Page, PaginationResponse } from '../base';
import { SubscriptionsAPIType, SubscriptionsCreateData, SubscriptionsItem, SubscriptionsListData } from './types';

const getSubscriptionsList = (page: Page, data?: SubscriptionsListData): Promise<AxiosResponse<PaginationResponse<SubscriptionsItem>>> => {
    return httpClient.get<PaginationResponse<SubscriptionsItem>>('/api/v2/subscriptions/', {
        params: {
            page: page.pageNumber,
            ...({ page_size: page.pageSize ? page.pageSize : 40 }),
            ...data
        }
    });
};

const createSubscription = (data: SubscriptionsCreateData): Promise<AxiosResponse<SubscriptionsItem>> => {
    return httpClient.post<SubscriptionsItem>('/api/v2/subscriptions/', data);
};

const deleteSubscription = (subscription_id: number): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/subscriptions/${subscription_id}/`);
};

export default {
    getSubscriptionsList,
    createSubscription,
    deleteSubscription
} as SubscriptionsAPIType;
