import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Download: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props}>
        <path d="M2.9,23h18.1v-2.6H2.9V23z M21.1,8.8h-5.2V1H8.1v7.8H2.9l9.1,9.1L21.1,8.8z" />
    </svg>
);

export default Download;
