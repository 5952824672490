import { IStore } from 'store/reducers/types/reducers';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { DataWithdrawalListItem } from 'component/api/types/api/payment/withdrawal/without-rejected/get/code-200';
import { getWithdrawalsWithoutRejected } from 'component/api/payment';
import { key as keyUser } from 'store/reducers/user/reducer';
import { useCancelToken } from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import Error from 'component/error';
import Loader from 'component/loader';
import UI from 'component/ui';

import style from './style.pcss';

const Reports = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const tokenWithdrawals = useCancelToken();

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const [pendingWithdrawals, setPendingWithdrawals] = useState<boolean>(true);
    const [total, setTotal] = useState<number>(0);
    const [withdrawals, setWithdrawals] = useState<Array<DataWithdrawalListItem>>([]);
    const [errorWithdrawal, setErrorWithdrawal] = useState<string | null>(null);

    const _requestWithdrawals = () => {
        setPendingWithdrawals(true);

        getWithdrawalsWithoutRejected({
            cancelToken: tokenWithdrawals.new()
        })
            .then((resp) => {
                setTotal(resp.total_count);
                setWithdrawals(resp.withdrawal_list);
                setPendingWithdrawals(false);
            })
            .catch((err) => {
                if (!axios.isCancel(err)) {
                    console.error(err);

                    setErrorWithdrawal(err.message);
                    setPendingWithdrawals(false);
                }
            });
    };

    useEffect(() => {
        _requestWithdrawals();
    }, []);

    useEffect(() => {
        return () => {
            tokenWithdrawals.remove();
        };
    }, []);

    const elReports = () => {
        if (pendingWithdrawals) {
            return <Loader />;
        }

        if (errorWithdrawal) {
            return <Error>{errorWithdrawal}</Error>;
        }

        if (!pendingWithdrawals && !withdrawals.length) {
            return <p>{t('route.reports.empty')}</p>;
        }

        return (
            <div className={cn('reports__report-list')}>
                {withdrawals.map((item) => (
                    <a
                        href={`fp/payment/generate-report/${item.withdrawal_id}/`}
                        target="_blank"
                        className={cn('reports__list-item')}
                        key={item.withdrawal_id}
                    >
                        <span className={cn('reports__item-text')}>{t('route.reports.reports-header.report-number', { id: item.withdrawal_id })}</span>
                        <span className={cn('reports__item-text')}>{t('route.reports.reports-header.report-date', { date: moment(item.created_at).format('LL') })}</span>
                        <span className={cn('reports__item-text')}>{t('route.reports.reports-header.report-price', { count: item.amount })}</span>
                    </a>
                ))}
                <div className={cn('reports__item-count')}>{t('route.reports.count', { count: withdrawals.length, total })}</div>
            </div>
        );
    };

    if (!userId) {
        return <Redirect to="/login" />;
    }

    return (
        <UI.Main className={cn('reports')}>
            <h1 className={cn('reports__header')}>{t('route.reports.header')}</h1>
            <UI.Box padding={true} className={cn('reports__content')}>
                {elReports()}
            </UI.Box>
        </UI.Main>
    );
};

export default Reports;
