import React from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Cross = (props: IProps) => (
    <svg {...defaultProps} {...props} viewBox="0 0 24 24">
        <path d="M19.2 5.3h-3.3V3.7c0-.9-.8-1.7-1.7-1.7h-5c-.9 0-1.7.8-1.7 1.7v1.7H4.2c-.9-.1-1.7.7-1.7 1.6v1.7c0 .9.8 1.7 1.7 1.7v8.3c0 1.8 1.5 3.3 3.3 3.3h8.3c1.8 0 3.3-1.5 3.3-3.3v-8.3c.9 0 1.7-.8 1.7-1.7V7c0-.9-.7-1.7-1.6-1.7zm-10-1.6h5v1.7h-5V3.7zm8.3 15c0 .9-.8 1.7-1.7 1.7H7.5c-.9 0-1.7-.8-1.7-1.7v-8.3h11.7v8.3zm1.7-10h-15V7h15v1.7zM7.5 17.8v-5c0-.5.4-.8.8-.8.5 0 .8.4.8.8v5c0 .5-.4.8-.8.8-.4.1-.8-.3-.8-.8zm3.3 0v-5c0-.5.4-.8.8-.8.5 0 .8.4.8.8v5c0 .5-.4.8-.8.8-.4.1-.8-.3-.8-.8zm3.4 0v-5c0-.5.4-.8.8-.8s.8.4.8.8v5c0 .5-.4.8-.8.8s-.8-.3-.8-.8z" />
    </svg>
);

export default Cross;
