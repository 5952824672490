import React, { FC } from 'react';
import { useClassnames } from 'hook/use-classnames';

import Avatar from 'component/avatar';
import Button from 'component/button';

import { IProps } from 'component/person-list/list-item/types';
import style from 'component/person-list/list-item/style.pcss';

const ListItem: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const elButton = () => {
        if (props.isButton) {
            return (
                <div>
                    <Button onClick={props.onClickButton} children={props.btnText} />
                </div>
            );
        }
    };

    const elContent = () => {
        if (props.person) {

            if (props.nameAsLink) {
                return (
                    <div>
                        <a
                            target="_blank"
                            href={props.linkTo}
                            title={props.linkText}
                        >
                            {props.person.name}
                        </a>
                    </div>
                );
            }

            return (
                <div>
                    {props.person.name}
                </div>
            );
        }
    };

    return (
        <div className={cn('list-item')}>
            <Avatar imgSrc={props.imgSrc} size={48} />
            <div className={cn('list-item__content')}>
                {elContent()}
                {props.children}
                {elButton()}
            </div>
        </div>
    );
};

ListItem.defaultProps = {
    isButton: false,
    btnText: 'SUBMIT',
    nameAsLink: false,
    linkText: 'link'
};

export default ListItem;
