import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import TimePicker, { TimePickerValue } from 'react-time-picker';

import { IProps } from 'component/form/input-time/types';
import { useClassnames } from 'hook/use-classnames';
import style from './styles.pcss';
import { TError } from 'component/form/input-interval/types';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const stringToDate = (value: string): Date => {
    const t = new Date();
    const [h, m] = value.split(':');

    t.setHours(Number(h), Number(m), 0, 0);

    return t;
};

const InputTime: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const [time, setTime] = useState<Date | null>(
        props.defaultValue
            ? typeof props.defaultValue === 'string'
                ? stringToDate(props.defaultValue)
                : props.defaultValue
            : null
    );
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);

    // const checkValidity = (): boolean => {
    //     return true;
    // };

    const onChange = (value: TimePickerValue): void => {
        if (typeof value === 'string') {
            setTime(stringToDate(value));
        } else {
            setTime(value as Date);
        }
    };

    const elLabel = () => {
        if (props.label) {
            return (
                <strong
                    className={cn('time__label', {
                        'time__label-required': props.required
                    })}
                >
                    {props.label}
                </strong>
            );
        }
    };

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [time]);

    useEffect(() => () => {
        props.registry.remove(props.name);
    }, []);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            clear   : () => {
                setTime(null);
            },
            value: time ? moment(time).format('HH:mm:ss') : null,
            isValid: true,
            isAutoFill: false
        });
    }, [time]);

    const onClickPlus = (e: MouseEvent): void => {
        e.preventDefault();

        const t = new Date();

        t.setHours(10, 0, 0, 0);

        if (time) {
            t.setHours(time.getHours() + 1, time.getMinutes(), 0, 0);
        }

        setTime(t);
    };

    const onClickMines = (e: MouseEvent): void => {
        e.preventDefault();

        const t = new Date();

        t.setHours(10, 0, 0, 0);

        if (time) {
            t.setHours(time.getHours() - 1, time.getMinutes(), 0, 0);
        }

        setTime(t);
    };

    const elInput = useMemo(() => {
        return (
            <TimePicker
                className={cn('time__field')}
                onChange={onChange}
                value={time ? time : ''}
                disableClock={true}
                format={props.format}
                clearIcon={null}
                locale={'ru-RU'}
            />
        );
    }, [time]);

    return (
        <label className={cn('time', `time_${props.direction}`)}>
            {elLabel()}
            <div className={cn('time__wrapper')}>
                <button className={cn('time__btn')}>
                    <FontAwesomeIcon icon={faMinusCircle} onClick={onClickMines} />
                </button>
                {elInput}
                <button className={cn('time__btn')}>
                    <FontAwesomeIcon icon={faPlusCircle} onClick={onClickPlus} />
                </button>
            </div>
        </label>
    );
};

InputTime.defaultProps = {
    direction: 'row',
    format: 'HH:mm',
    defaultValue: ''
};

export default InputTime;
