import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Directory: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props} viewBox="0 0 20 16">
        <path d="M8 0H2C0.89 0 0 0.89 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V4C20 2.89 19.1 2 18 2H10L8 0Z" fill="#B6BCC4" />
    </svg>
);

export default Directory;
