import { AxiosResponse } from 'axios';
import { EventStatistic, PersonStatistic, StatisticAPIType } from './types';
import httpClient from 'src/utils/http-сlient';

const getEventStatistics = (
    id: number
): Promise<AxiosResponse<EventStatistic>> => {
    return httpClient.get<EventStatistic>(`/api/v2/statistics/event/${id}/`, {});
};

const getPersonStatistics = (
    id: number
): Promise<AxiosResponse<PersonStatistic>> => {
    return httpClient.get<PersonStatistic>(`/api/v2/statistics/persons/${id}/`);
};

export default {
    getEventStatistics,
    getPersonStatistics
} as StatisticAPIType;
