import React from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Search = (props: IProps) => (
    <svg {...defaultProps} {...props} viewBox="0 0 24 24">
        <path d="M10.5 4a6.5 6.5 0 016.5 6.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l3 3-1.5 1.5-3-3v-.79l-.27-.27A6.516 6.516 0 0110.5 17a6.5 6.5 0 110-13zm0 2C8 6 6 8 6 10.5S8 15 10.5 15s4.5-2 4.5-4.5S13 6 10.5 6z" fill="#C5CAD0" />
    </svg>
);

export default Search;
