import { createReducer } from '@reduxjs/toolkit';

import { set, setWidth } from './actions';
import { IStore } from './types/reducer';

const MAX_MOBILE = 670;
const MAX_TABLET = 1023;

const initialState: IStore = {
    mobile  : false,
    tablet  : false,
    isRetina: false,
    desktop : true
};

const changeFlags = (width: number, source: IStore): IStore => {
    if(width) {
        source.mobile = width <= MAX_MOBILE;
        source.tablet = width > MAX_MOBILE && width <= MAX_TABLET;
        source.desktop = width > MAX_TABLET;
    }

    return source;
};

export const key: 'deviceInfo' = 'deviceInfo';

export default createReducer<IStore>(initialState, (builder) => {
    builder
        .addCase(set, (store, action) => {
            const result: IStore = { ...store };

            if(action.payload.width) {
                changeFlags(action.payload.width, result);
            }

            if(action.payload.pixelRatio) {
                result.isRetina = action.payload.pixelRatio >= 2;
            }

            return result;
        })
        .addCase(setWidth, (store, action) => {
            const result: IStore = { ...store };

            if(action.payload) {
                changeFlags(action.payload, result);
            }

            return result;
        });
});
