import React, { useCallback, useState, useMemo } from 'react';
import { useClassnames } from 'hook/use-classnames';
import Modal from 'component/modal';
import Input from '../input';
import Button from 'component/button';
import style from './index.pcss';
import { useRegistry } from '..';
import { useAlert } from 'component/alert/provider';
import { Page } from 'src/api/base';
import { YandexTasksListCreateData, YandexTasksListCreateInfo, YandexTasksListData } from 'src/api/tasks/types';
import { useDispatch } from 'react-redux';
import { setTask } from 'src/store/reducers/task/actions';
import api from 'src/api';
import Presets from './presets';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    onCloseModal(): void;
}

const ModalExternalLink = (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const registry = useRegistry();
    const dispatch = useDispatch();
    const { show, hide } = useAlert();
    const [isLoadingLink, setIsLoadingLink] = useState<boolean>(false);

    const getUploadPresets = () => {
        const fields = registry.form.getFields();

        return {
            year: fields && fields.get('upload_year') || null,
            event: fields && fields.get('upload_tournament') || null,
            price: fields && fields.get('upload_price') || null,
            album: fields && fields.get('upload_album') || null,
            is_save_params: fields && fields.get('save_params') || null,
            download_link: fields && fields.get('download_link') || null,
            recursive: fields && fields.get('recursive') || null
        };
    };

    const onClickSubmitLink = useCallback(() => {
        const presets = getUploadPresets();
        let paramsValid = true;

        if (presets.album && !presets.album.value.value) {
            presets.album.setError('Укажите альбом');
            paramsValid = false;
        }

        if (presets.year && presets.year.value?.value && !presets.event?.value?.value) {
            presets.event?.setError('Укажите событие');
            paramsValid = false;
        }

        if (presets.download_link && presets.download_link.value.split('.ru/')[0] !== 'https://disk.yandex') {
            presets.download_link?.setError('Неверная ссылка');
            paramsValid = false;
        }

        if (!paramsValid) {
            return;
        }

        const createData: YandexTasksListCreateData = {
            ...(presets.price && presets.price.value && {price: presets.price.value}),
            ...(presets.album && presets.album.value && {album_id: presets.album.value.value}),
            ...(presets.event && presets.event.value && {event_id: presets.event.value.value})
        };

        if (presets.download_link && presets.download_link.value && paramsValid) {
            setIsLoadingLink(true);
            const data: YandexTasksListCreateInfo = {
                share_link: presets.download_link.value,
                ...(presets.recursive && { recursive: presets.recursive.value })
            };

            api.tasks.createYandexTask(data, createData)
                .then(() => {
                    setIsLoadingLink(false);
                    const page: Page = {
                        pageNumber: 1
                    };

                    const filter: YandexTasksListData = {
                        seen: false
                    };

                    api.tasks.getYandexTasksList(page, filter)
                        .then((resp) => {
                            dispatch(setTask(resp.data.count));
                        });
                })
                .then(() => {
                    props.onCloseModal();
                    show('Файлы будут загружены в ближайшее время', 'success');

                    setTimeout(() => { hide(); }, 3000);
                })
                .catch((err) => {
                    console.warn(err);
                    setIsLoadingLink(false);
                    props.onCloseModal();
                    show('Что-то пошло не так', 'warning');

                    setTimeout(() => { hide(); }, 3000);
                });
        }

    }, []);

    const elParams = useMemo(() => {
            return (
                <Presets registry={registry} isLink={true} className={cn('input__placeholder__params')} />
            );
    }, []);

    return (
        <Modal onClickClose={props.onCloseModal}>
            <h3 className={cn('input-external__header')}>Вставьте ссылку для скачивания</h3>
            <p className={cn('input__modal__text')}>
                <span>Загрузка фотографий с помощью ссылки на Яндекс.Диск</span> <br />
                Для загрузки фотографий с Яндекс.Диска вставьте ссылку и нажмите загрузить
            </p>
            <ReactTooltip
                id="modal-warning"
                place="right"
                effect="solid"
                clickable={true}
                border={true}
                borderColor="#dee1e4"
                backgroundColor="#fff"
                className={cn('input__modal__warning-tooltip')}
            >
                Если у вас фотографий более 2000 штук, то, пожалуйста, создайте несколько папок <br />
                и запустите загрузку по нескольких ссылкам параллельно.
            </ReactTooltip>
            <span
                className={cn('input__modal__warning')}
                data-type="light"
                data-tip={true}
                data-for="modal-warning"
            >
                Лимит не более 2000 фотографий в папке и подпапке по одной ссылке.
                <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
            <div className={cn('input__placeholder__link')}>
                <Input
                    registry={registry.field}
                    name="download_link"
                    type="text"
                    className={cn('input__placeholder__link__text-field')}
                    placeholder={t('route.upload.sidebar.modal.link_placeholder')}
                />
            </div>
            {elParams}
            <div className={cn('input__placeholder__submit')}>
                <Button
                    type="button"
                    className={cn('input__upload-button')}
                    isSmall={true}
                    onClick={onClickSubmitLink}
                    disabled={isLoadingLink}
                >
                    {isLoadingLink ? 'Загрузка...' : 'Загрузить'}
                </Button>
            </div>
        </Modal>
    );
};

export default ModalExternalLink;
