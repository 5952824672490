import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { Page, PaginationResponse } from '../base';
import { GetOrderPhotoItemData, OrderAPIType, OrderCreated, OrderList, OrderListFilter, OrderPhotoStatus, OrderRetrieve } from './types';

const getOrderList = (
    page: Page,
    filter?: OrderListFilter
): Promise<AxiosResponse<PaginationResponse<OrderList>>> => {
    return httpClient.get<PaginationResponse<OrderList>>('/api/v2/orders/', {
        params: {
            page: page.pageNumber,
            page_size: page.pageSize || 20,
            ...(filter && {
                search: filter.search,
                payment_status: filter.payment_status
            })
        }
    });
};

const createOrder = (reference_price: string): Promise<AxiosResponse<OrderCreated>> => {
    return httpClient.post<OrderCreated>('/api/v2/orders/', {
        reference_price
    });
};

const getOrderPhotoItem = (data: GetOrderPhotoItemData): Promise<AxiosResponse> => {
    return httpClient.get(`/api/v2/orders/${data.order_pk}/items/${data.id}/photo/`, {
        responseType: 'blob'
    });
};

const getOrderArchiveItem = (order_pk: number): Promise<AxiosResponse> => {
    return httpClient.get(`/api/v2/orders/${order_pk}/items/archive/`, {
        responseType: 'blob'
    });
};

const getOrderItem = (id: number): Promise<AxiosResponse<OrderRetrieve>> => {
    return httpClient.get<OrderRetrieve>(`/api/v2/orders/${id}/`);
};

const getOrderPhotoStatus = (id: number): Promise<AxiosResponse<OrderPhotoStatus>> => {
    return httpClient.get<OrderPhotoStatus>(`/api/v2/orders/photos/${id}/status/`);
};

export default {
    getOrderPhotoStatus,
    getOrderList,
    createOrder,
    getOrderPhotoItem,
    getOrderArchiveItem,
    getOrderItem
} as OrderAPIType;
