import React, { useState, useEffect, FC, ReactNode } from 'react';
import moment from 'moment';

import useClassnames from 'hook/use-classnames';
import i18n from 'component/core/i18n';
import Error from 'component/error';

import DatePicker, { registerLocale } from 'react-datepicker';
// tslint:disable-next-line no-submodule-imports
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);

import { IProps, TError } from './types';
import style from './index.pcss';
// tslint:disable-next-line no-submodule-imports
import 'react-datepicker/dist/react-datepicker.css';

const InputInterval: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isWatch, setIsWatch] = useState<boolean>(false);
    const [firstDate, setFristDate] = useState<Date | null>(props.firstDate || null);
    const [secondDate, setSecondDate] = useState<Date | null>(props.secondDate || null);
    const [errorInternal, setErrorInternal] = useState<TError>(null);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        const isDateStartValid = moment(firstDate).isValid() && moment(secondDate).isValid();
        if(isDateStartValid) {
            const isDateEndDateAfterStart = isDateStartValid && moment(secondDate).isSameOrAfter(firstDate);

            if(!isDateEndDateAfterStart) {
                newErrorInternal = 'Неправильный формат';

                newIsValid = false;
            }
        }

        if(props.required) {
            newIsValid = !!firstDate || !!secondDate;

            if(!newIsValid) {
                newErrorInternal = i18n.t('components.form.range_input.empty');
            }
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect(() => {
        checkValidity();
    }, [isWatch]);

    useEffect(() => () => {
        props.registry.remove(props.name);
    }, []);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            clear   : () => {
                setFristDate(null);
                setSecondDate(null);
                setIsWatch(false);
            },
            value: {
                date_from: firstDate ? moment(firstDate).format(
                    props.type === 'date'
                        ? 'YYYY-MM-DD'
                        : 'YYYY-MM-DDTHH:mm:ss'
                ) : null,
                date_to  : secondDate ? moment(secondDate).format(
                    props.type === 'date'
                        ? 'YYYY-MM-DD'
                        : 'YYYY-MM-DDT23:59:59'
                ) : null
            },
            isValid: checkValidity(),
            isAutoFill: false
        });
    }, [firstDate, secondDate]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [firstDate, secondDate, isValid]);

    const elLabel = (): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('date-range__label', {
                        'date-range__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    };

    const onFocus = () => {
        setIsWatch(true);
    };

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('date-range__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    return (
        <label
            className={cn('date-range', `date-range_${props.direction}`)}
        >
            {elLabel()}
            <div className={cn('date-range__wrapper')}>
            <DatePicker
                selected={firstDate}
                onFocus={onFocus}
                onChange={setFristDate}
                selectsStart={true}
                wrapperClassName={cn('date-range__input-wrapper')}
                className={cn('date-range__input-field', {
                    'date-range__input-field_invalid': (isWatch && errorInternal) || errorExternal
                })}
                showYearDropdown={true}
                endDate={secondDate}
            />
            <span className={cn('date-range__input-separator')}>—</span>
            <DatePicker
                selected={secondDate}
                onChange={setSecondDate}
                selectsEnd={true}
                onFocus={onFocus}
                wrapperClassName={cn('date-range__input-wrapper')}
                className={cn('date-range__input-field', {
                    'date-range__input-field_invalid': (isWatch && errorInternal) || errorExternal
                })}
                showYearDropdown={true}
                startDate={firstDate}
                minDate={firstDate}
            />
            </div>
            {elError()}
        </label>
    );
};

InputInterval.defaultProps = {
    direction   : 'row',
    type        : 'date'
};

export default InputInterval;
