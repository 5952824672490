import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Ok: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props}>
        <polygon points="8.5,21.4 0,12.8 3.3,9.6 8.5,14.8 20.7,2.6 24,5.9 " />
    </svg>
);

export default Ok;
