import React, { useState, useEffect, FC, ReactNode } from 'react';

import useClassnames from 'hook/use-classnames';
import i18n from 'component/core/i18n';
import Error from 'component/error';
import Checkbox from 'component/form/checkbox';
import { useRegistry } from 'component/form';

import { IProps, TError, TColor } from './types';
import style from './index.pcss';

const red_id = 1;
const orange_id = 2;
const yellow_id = 3;
const green_id = 4;
const cyan_id = 5;
const blue_id = 6;
const violet_id = 7;
const black_id = 8;
const white_id = 9;

const InputColor: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { field } = useRegistry();

    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [isWatch, setIsWatch] = useState<boolean>(
        props.defaultValue?.includes(orange_id) ||
        props.defaultValue?.includes(yellow_id) ||
        props.defaultValue?.includes(cyan_id) ||
        props.defaultValue?.includes(green_id) ||
        props.defaultValue?.includes(blue_id) ||
        props.defaultValue?.includes(violet_id) ||
        props.defaultValue?.includes(white_id) ||
        props.defaultValue?.includes(black_id) ||
        props.defaultValue?.includes(red_id) || false
    );

    const [red, setRed] = useState<boolean>(props.defaultValue?.includes(red_id) || false);
    const [orange, setOrange] = useState<boolean>(props.defaultValue?.includes(orange_id) || false);
    const [yellow, setYellow] = useState<boolean>(props.defaultValue?.includes(yellow_id) || false);
    const [cyan, setCyan] = useState<boolean>(props.defaultValue?.includes(cyan_id) || false);
    const [green, setGreen] = useState<boolean>(props.defaultValue?.includes(green_id) || false);
    const [blue, setBlue] = useState<boolean>(props.defaultValue?.includes(blue_id) || false);
    const [violet, setViolet] = useState<boolean>(props.defaultValue?.includes(violet_id) || false);
    const [white, setWhite] = useState<boolean>(props.defaultValue?.includes(white_id) || false);
    const [black, setBlack] = useState<boolean>(props.defaultValue?.includes(black_id) || false);

    const clearOtherColors = (color: string) => {
        if (color !== 'red') {
            setRed(false);
        }
        if (color !== 'orange') {
            setOrange(false);
        }
        if (color !== 'yellow') {
            setYellow(false);
        }
        if (color !== 'cyan') {
            setCyan(false);
        }
        if (color !== 'green') {
            setGreen(false);
        }
        if (color !== 'blue') {
            setBlue(false);
        }
        if (color !== 'violet') {
            setViolet(false);
        }
        if (color !== 'white') {
            setWhite(false);
        }
        if (color !== 'black') {
            setBlack(false);
        }
    };

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;
        const checkValues = true;

        if(props.required) {
            newIsValid = blue || red || white || orange || yellow || cyan || green || violet || black;

            if(!newIsValid) {
                newErrorInternal = i18n.t('components.form.range_input.empty');
            }
        }

        if(!checkValues) {
            newIsValid = false;

            newErrorInternal = i18n.t('components.form.range_input.incorrect');
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect(() => {
        checkValidity();
    }, [isWatch]);

    useEffect(() => () => {
        props.registry.remove(props.name);
    }, []);

    useEffect(() => {
        const values = [];

        if (blue) { values.push(blue_id); }
        if (red) { values.push(red_id); }
        if (white) { values.push(white_id); }
        if (orange) { values.push(orange_id); }
        if (yellow) { values.push(yellow_id); }
        if (cyan) { values.push(cyan_id); }
        if (green) { values.push(green_id); }
        if (violet) { values.push(violet_id); }
        if (black) { values.push(black_id); }

        props.registry.set(props.name, {
            setError: setErrorExternal,
            clear   : () => {
                setBlue(false);
                setRed(false);
                setWhite(false);
                setOrange(false);
                setYellow(false);
                setCyan(false);
                setGreen(false);
                setViolet(false);
                setBlack(false);

                setIsWatch(false);
            },
            value: values,
            isValid: checkValidity(),
            isAutoFill: false
        });
    }, [blue, red, white, orange, yellow, cyan, green, violet, black]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [blue, red, white, orange, yellow, cyan, green, violet, black, isValid]);

    const elLabel = (): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('color__label', {
                        'color__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    };

    const onChange = () => {
        if (errorExternal) {
            setErrorExternal(null);
        }
    };

    const onChangeBlue = (check: boolean): void => {
        setBlue(check);
        if (props.onlyOneSelect) {
            clearOtherColors('blue');
        }
        onChange();
    };

    const onChangeRed = (check: boolean) => {
        setRed(check);
        if (props.onlyOneSelect) {
            clearOtherColors('red');
        }
        onChange();
    };

    const onChangeWhite = (check: boolean) => {
        setWhite(check);
        if (props.onlyOneSelect) {
            clearOtherColors('white');
        }
        onChange();
    };

    const onChangeOrange = (check: boolean) => {
        setOrange(check);
        if (props.onlyOneSelect) {
            clearOtherColors('orange');
        }
        onChange();
    };

    const onChangeYellow = (check: boolean) => {
        setYellow(check);
        if (props.onlyOneSelect) {
            clearOtherColors('yellow');
        }
        onChange();
    };

    const onChangeCyan = (check: boolean) => {
        setCyan(check);
        if (props.onlyOneSelect) {
            clearOtherColors('cyan');
        }
        onChange();
    };

    const onChangeGreen = (check: boolean) => {
        setGreen(check);
        if (props.onlyOneSelect) {
            clearOtherColors('green');
        }
        onChange();
    };

    const onChangeViolet = (check: boolean) => {
        setViolet(check);
        if (props.onlyOneSelect) {
            clearOtherColors('violet');
        }
        onChange();
    };

    const onChangeBlack = (check: boolean) => {
        setBlack(check);
        if (props.onlyOneSelect) {
            clearOtherColors('black');
        }
        onChange();
    };

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('color__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    // @ts-ignore
    const elCheckBox = (name: TColor) => {
        let onChangeValue;
        let styleName = '';
        let colorStatus;
        switch (name) {
            case blue_id: styleName = 'blue'; onChangeValue = onChangeBlue; colorStatus = blue; break;
            case red_id: styleName = 'red'; onChangeValue = onChangeRed; colorStatus = red; break;
            case white_id: styleName = 'white'; onChangeValue = onChangeWhite; colorStatus = white; break;
            case orange_id: styleName = 'orange'; onChangeValue = onChangeOrange; colorStatus = orange; break;
            case yellow_id: styleName = 'yellow'; onChangeValue = onChangeYellow; colorStatus = yellow; break;
            case cyan_id: styleName = 'cyan'; onChangeValue = onChangeCyan; colorStatus = cyan; break;
            case green_id: styleName = 'green'; onChangeValue = onChangeGreen; colorStatus = green; break;
            case violet_id: styleName = 'violet'; onChangeValue = onChangeViolet; colorStatus = violet; break;
            case black_id: styleName = 'black'; onChangeValue = onChangeBlack; colorStatus = black; break;
        }

        return (
            <Checkbox
                className={{input: cn('color__checkbox')}}
                classNames={{input: cn(`color__checkbox-${styleName}`)}}
                registry={field}
                name={`color-${name}`}
                defaultValue={props.defaultValue?.includes(name) || false}
                disabled={props.disabled}
                onChange={onChangeValue}
                onlyOneSelect={props.onlyOneSelect}
                checked={props.onlyOneSelect ? colorStatus : false}
            />
        );
    };

    return (
        <label className={cn('color', `color_${props.direction}`)}>
            {elLabel()}
            <div className={cn('color__wrapper')}>
                {elCheckBox(red_id)}
                {elCheckBox(orange_id)}
                {elCheckBox(yellow_id)}
                {elCheckBox(cyan_id)}
                {elCheckBox(green_id)}
                {elCheckBox(blue_id)}
                {elCheckBox(violet_id)}
                {elCheckBox(white_id)}
                {elCheckBox(black_id)}
            </div>
            {elError()}
        </label>
    );
};

InputColor.defaultProps = {
    direction   : 'row'
};

export default InputColor;
