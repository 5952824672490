import React, { FC, useState, ReactNode, useEffect, useMemo, useCallback } from 'react';
import { Redirect, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import cancelToken from 'component/core/cancel-token';
import i18n from 'component/core/i18n';
import { useClassnames } from 'hook/use-classnames';

import UI from 'component/ui';
import PasswordSet from 'component/password-set';
import Loader from 'component/loader';
import IconError from 'component/icon/error';
import IconSuccess from 'component/icon/apply';
import { IStore } from 'store/reducers/types/reducers';
import { IError } from 'component/form/types';

import { set, reset } from 'store/reducers/user/actions';
import { checkPasswordToken, resetPasswordConfirm, userSelfInfo } from 'component/api/user';
import { key as keyUser } from 'store/reducers/user/reducer';

import style from './index.pcss';
import api from 'src/api';

const ResetConfirmPassword: FC = () => {
    const cn = useClassnames(style);
    const checkToken = useMemo(cancelToken.create, []);
    const setPasswordToken = useMemo(cancelToken.create, []);
    const selfInfoToken = useMemo(cancelToken.create, []);
    const dispatch = useDispatch();
    const { uid, token } = useParams<{ uid: string; token: string }>();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [checkPending, setCheckPending] = useState<boolean>(true);
    const [tokenValidity, setTokenValididty] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [passwordChangeStatus, setPasswordChangeStatus] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError> | null>(null);
    const [error, setError] = useState<string | null>(null);

    const _requestCheckToken = useCallback(() => {
        setCheckPending(true);

        checkPasswordToken({
            cancelToken: checkToken.new(),
            data       : {
                uid,
                token
            }
        })
            .then(({ is_token_valid }) => {
                setCheckPending(false);
                setTokenValididty(is_token_valid);
            })
            .catch((errorData) => {
                if(!axios.isCancel(errorData)) {
                    console.error(errorData);

                    setCheckPending(false);
                    setTokenValididty(false);
                }
            });

        return true;
    }, []);

    useEffect(() => {
        _requestCheckToken();

        return () => {
            setPasswordToken.remove();
            selfInfoToken.remove();
            checkToken.remove();
        };
    }, []);

    const onSubmit = useCallback((new_password: string) => {
        setPending(true);

        resetPasswordConfirm({
            cancelToken: setPasswordToken.new(),
            data       : {
                uid,
                token,
                new_password
            }
        })
            .then(() => {
                setPasswordChangeStatus(true);

                api.accounts.getAccountRetrieve()
                    .then((payload) => {
                        setPending(false);
                        dispatch(set(payload.data));
                    })
                    .catch((errorData) => {
                        if(!axios.isCancel(errorData)) {
                            setPending(false);
                            setError('An unexpected error has occurred. :(');

                            dispatch(reset());
                        }
                    });
            })
            .catch((errorData) => {
                if(!axios.isCancel(errorData)) {
                    console.error(errorData);

                    setPending(false);
                    setErrors(errorData.errors);
                    setError(errorData.message);
                }
            });
    }, []);

    const elContent = (): ReactNode => {
        if(checkPending && !tokenValidity) {
            return <Loader />;
        }

        if(!checkPending && !tokenValidity) {
            return (
                <div className={cn('reset-password__text-block')}>
                    <IconError className={cn('reset-password__text-block-icon', 'reset-password__text-block-icon_red')} />
                    <h2 className={cn('reset-password__text-block-title')}>{i18n.t('route.confirm-password.wrong_token.title')}</h2>
                    <Link
                        to="/password/reset"
                        className={cn('reset-password__text-block-text', 'reset-password__text-block-link')}
                    >
                        {i18n.t('route.confirm-password.wrong_token.text')}
                    </Link>
                </div>
            );
        }

        if(passwordChangeStatus) {
            return (
                <div className={cn('reset-password__text-block')}>
                    <IconSuccess className={cn('reset-password__text-block-icon', 'reset-password__text-block-icon_green')} />
                    <h2 className={cn('reset-password__text-block-title')}>{i18n.t('route.confirm-password.success.title')}</h2>
                </div>
            );
        }

        return <PasswordSet pending={pending} error={error} errors={errors} onSubmit={onSubmit} />;
    };

    if(isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <UI.Main className={cn('reset-password__ui-main')}>
            <Helmet
                title={i18n.t('helmet.title.reset-password')}
                meta={[{
                    name   : 'document-state',
                    content: 'static'
                }]}
            />

            <UI.Box padding={true} className={cn('reset-password__ui-box')}>
                {elContent()}
            </UI.Box>
        </UI.Main>
    );
};

export default ResetConfirmPassword;
