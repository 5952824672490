import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Form from 'component/form';
import InputBanner from 'component/form/input-banner';
import Input from 'component/form/input';
import InputAdThemeSelect from 'component/form/input-ad-theme';
import Button from 'component/button';
import useClassnames from 'hook/use-classnames';
import { DataFilesItem } from 'component/api/types/api/tmp-file/get-files-list/get/code-200';

import style from './styles.pcss';
import { IProps } from './types';

const BannerForm: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const { t } = useTranslation();

    const onFileLoaded = (file: DataFilesItem): void => {
        if (props.onFileLoaded) {
            props.onFileLoaded(file);
        }
    };

    const elBannerInput = () => {
        let defaultValue = null;

        if (props.banner) {
            defaultValue = {
                url: props.banner.image_url
            };
        }

        return (
            <InputBanner
                name="banner"
                label={t('components.form.banner.form.inputs.banner')}
                required={true}
                registry={props.registry.field}
                defaultValue={defaultValue}
                onFileLoaded={onFileLoaded}
                skipRecognition={true}
            />
        );
    };

    return (
        <div>
            {elBannerInput()}
            <Form
                registry={props.registry.form}
                onSubmit={props.onSubmit}
            >
                <Input
                    registry={props.registry.field}
                    name="name"
                    type="text"
                    className={cn('form__input-block')}
                    direction={'column'}
                    required={true}
                    children={t('components.form.banner.form.inputs.name')}
                    defaultValue={props.banner?.name || ''}
                />
                <InputAdThemeSelect
                    registry={props.registry.field}
                    name="theme"
                    required={true}
                    children={t('components.form.banner.form.inputs.theme')}
                    direction={'column'}
                    default_id={props.banner?.theme?.id || 0}
                />
                <Input
                    registry={props.registry.field}
                    name="text"
                    type="text"
                    className={cn('form__input-block')}
                    direction={'column'}
                    required={true}
                    children={t('components.form.banner.form.inputs.text')}
                    defaultValue={props.banner?.text || ''}
                />
                <Input
                    registry={props.registry.field}
                    name="link"
                    type="text"
                    className={cn('form__input-block')}
                    direction={'column'}
                    required={false}
                    children={t('components.form.banner.form.inputs.link')}
                    defaultValue={props.banner?.link || ''}
                />
                <div className={cn('form__button')}>
                    <Button type="submit" isLoading={props.pending}>
                        {props.button}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default BannerForm;
