import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';
import { Data as CreateData } from 'component/api/types/api/photo/photo/create/post/code-200';
import { Data as EditData  } from 'component/api/types/api/photo/photo/edit/post/code-200';
import { Data as BulkEditData } from 'component/api/types/api/photo/photo/bulk-edit/post/code-200';
import { Data as OwnPhotoListData  } from 'component/api/types/api/photo/get-own-photo-list/get/code-200';
import { Data as OwnPhotoToSell } from 'component/api/types/api/photo/get-own-photo-to-sell/count/get/code-200';
import { Data as DataPhotoList } from 'component/api/types/api/photo/get-photo-list/get/code-200';
import { Data as DataSearchPhotos } from 'component/api/types/api/photo/smart-search/photos/get/code-200';
import { Data as DataDownloadArchive } from 'component/api/types/api/photo/photo/download/get/code-200';
import { Data as DataPhotoColors } from 'component/api/types/api/photo/get-photo-colors/get/code-200';
import { Data as DataPhotoNumbers } from 'component/api/types/api/photo/get-photo-numbers/get/code-200';
import { Data as DataAlbumPhotos } from 'component/api/types/api/photo/get-album-photo-list/get/code-200';

export const createPhoto = (config?: AxiosRequestConfig) => {
    return request<CreateData>({
        url   : '/api/photo/photo/create/',
        method: 'post',
        ...config
    });
};

export const deletePhoto = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/photo/photo/delete/',
        method: 'post',
        ...config
    });
};

export const editPhoto = (config?: AxiosRequestConfig) => {
    return request<EditData>({
        url   : '/api/photo/photo/edit/',
        method: 'post',
        ...config
    });
};

export const bulkEditPhoto = (config?: AxiosRequestConfig) => {
    return request<BulkEditData>({
        url   : '/api/photo/photo/bulk-edit/',
        method: 'post',
        ...config
    });
};

export const getOwnPhotoList = (config?: AxiosRequestConfig) => {
    return request<OwnPhotoListData>({
        url   : '/api/photo/get-own-photo-list/',
        ...config
    });
};

export const getAlbumPhotoList = (config?: AxiosRequestConfig) => {
    return request<DataAlbumPhotos>({
        url   : '/api/photo/get-album-photo-list/',
        ...config
    });
};

export const getOwnPhotoToSell = (config?: AxiosRequestConfig) => {
    return request<OwnPhotoToSell>({
        url   : '/api/photo/get-own-photo-to-sell/count/',
        ...config
    });
};

export const getPhotoList = (config?: AxiosRequestConfig) => {
    return request<DataPhotoList>({
        url: '/api/photo/get-photo-list/',
        ...config
    });
};

export const getPhotosArchive = (config?: AxiosRequestConfig) => {
    return request<DataDownloadArchive>({
        url: '/api/photo/photo/download/',
        ...config
    });
};

export const smartSearchPhotos = (config?: AxiosRequestConfig) => {
    return request<DataSearchPhotos>({
        url: '/api/photo/smart-search/photos/',
        ...config
    });
};

export const getPhotoColors = (config?: AxiosRequestConfig) => {
    return request<DataPhotoColors>({
        url: '/api/photo/get-photo-colors/',
        ...config
    });
};

export const getPhotoNumbers = (config?: AxiosRequestConfig) => {
    return request<DataPhotoNumbers>({
        url: '/api/photo/get-photo-numbers/',
        ...config
    });
};
