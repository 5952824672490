import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Button from 'component/button';

import style from './index.pcss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

export default () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    return (
        <div className={cn('footer')}>
            <UI.Main className={cn('footer__content')}>
                <div className={cn('footer__left')}>
                    <div className={cn('footer__column')}>
                        <Link to="/search" className={cn('footer__nav-link')}>{t('components.footer.left.search')}</Link>
                        <Link to="/subscriptions" className={cn('footer__nav-link')}>{t('components.footer.left.sub')}</Link>
                        <Link to="/faq" className={cn('footer__nav-link')}>F.A.Q</Link>
                    </div>
                    <div className={cn('footer__column')}>
                        <Link to="/condition" className={cn('footer__nav-link')}>{t('components.footer.left.condition')}</Link>
                        <Link to="/about" className={cn('footer__nav-link')}>{t('components.footer.left.about')}</Link>
                    </div>
                </div>
                <div className={cn('footer__center')}>
                    <a href="https://t.me/faceport" target="_blank" className={cn('footer__icon-description')}> Мы в Telegram <FontAwesomeIcon icon={faTelegram} className={cn('footer__icon')} /> </a>
                </div>
                <div className={cn('footer__right')}>
                    <Button
                        isSecondary={true}
                        className={cn('footer__contact-us')}
                        onClick={(() => window.open('mailto:support@faceport.ru'))}
                    >
                        {t('components.footer.contact-us')}
                    </Button>
                </div>
            </UI.Main>
        </div>
    );
};
