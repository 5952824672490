import { AxiosRequestConfig } from 'axios';

import { request } from './index';
import { DataFilesItem } from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { Data as DataFile } from 'component/api/types/api/tmp-file/get-file/get/code-200';

export const tmpFile = (config?: AxiosRequestConfig) => {
    return request<DataFilesItem>({
        url   : '/fp/tmp-file/upload/',
        method: 'post',
        ...config
    });
};

export const getFile = (config?: AxiosRequestConfig) => {
    return request<DataFile>({
        url   : '/api/tmp-file/get-file/',
        ...config
    });
};
