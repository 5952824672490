import React, { useMemo, useEffect } from 'react';

import useClassnames from 'hook/use-classnames';
import Footer from 'component/footer';
import Header from 'component/header';
import qaAttributes from 'component/helper/qa-attributes';

import './img/favicon.ico';
import './img/favicon-16x16.png';
import './img/favicon-32x32.png';
import { IProps } from './types';
import style from './index.pcss';
import Alert from 'component/alert';
import { useAlert } from 'component/alert/provider';
import { logout } from 'component/api/user';
import throttle from 'lodash.throttle';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import api from 'src/api';

export default (props: IProps) => {
    const cn = useMemo(() => useClassnames(style), []);
    const alert = useAlert();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const renewToken = () => {
        const refreshToken = localStorage.getItem('jwt_token_refresh');
        if (refreshToken && new Date() <= new Date(JSON.parse(atob(refreshToken.split('.')[1])).exp * 1000)) {
            api.auth.authTokenRefreshCreate(refreshToken)
              .then((resp) => {
                localStorage.setItem('jwt_token', resp.data.access);
                localStorage.setItem('jwt_token_refresh', resp.data.refresh);
              })
              .catch(() => {
                  localStorage.removeItem('jwt_token');
                  localStorage.removeItem('jwt_token_refresh');
                  logout();
              });

        }
    };

    const throttledRenewToken = throttle(renewToken, 40000000, { trailing: false });

    useEffect(() => {
        if (isAuth) {
            const renewalInterval = setInterval(() => {
                throttledRenewToken();
            }, 40000000);

            return () => {
                clearInterval(renewalInterval);
            };
        }

    }, [isAuth]);

    const elAlert = useMemo(() => {
        if (alert.visible) {
            return <Alert />;
        }
    }, [
        alert.visible
    ]);

    return (
        <div className={cn('layout')}>
            <div className={cn('layout__alert')}>
                {elAlert}
            </div>
            <Header />
            <div className={cn('layout__inner')} {...qaAttributes('content')}>
                {props.children}
            </div>
            <Footer />
        </div>
    );
};
