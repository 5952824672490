import React, { FC } from 'react';

import { defaultProps } from './base';
import { IProps } from './types';

const Close: FC<IProps> = (props)  => (
    <svg {...defaultProps} {...props} viewBox="0 0 512 512">
        <path d="M512,51.566L460.434,0L256,204.434L51.566,0L0,51.566L204.434,256L0,460.434L51.566,512L256,307.566 L460.434,512L512,460.434L307.566,256L512,51.566z" />
    </svg>
);

export default Close;
