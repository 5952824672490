import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { useClassnames } from 'hook/use-classnames';
import LazyLoad from 'component/lazy-load';
import Attachments from 'component/attachments';

import { IProps } from './types';
import style from './index.pcss';

const Post: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();

    const elAttachments = useMemo(() => {
        if(Array.isArray(props.photos) && props.photos.length) {
            const attachments = props.photos.map((item) => ({
                url: item
            }));

            return (
                <LazyLoad className={cn('post__attachments')}>
                    <Attachments
                        className={cn('post__attachments')}
                        attachmentsCount={props.photo_count || attachments.length}
                        limit={3}
                        link={`/events/${props.id}`}
                        children={attachments}
                        isLink={true}
                        eventId={props.id}
                        isNewFormat={true}
                    />
                </LazyLoad>
            );
        }
    }, [JSON.stringify(props.photos)]);

    const elDate = useMemo(() => {
        if(props.date) {
            return (
                <div className={cn('post__content-text')}>
                    <span>{props.location_name}, {moment(props.date).format('ll')}</span>
                </div>
            );
        }
    }, [props.date, props.location_name]);

    return (
        <div className={cn('post')}>
            {elAttachments}
            <div className={cn('post__content')}>
                <Link to={`/events/${props.id}`} className={cn('post__header')}>{props.name}</Link>
                {elDate}
            </div>
        </div>
    );
};

export default Post;
