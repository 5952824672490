import React, { FC, ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useClassnames } from 'hook/use-classnames';

import IconMailSent from 'component/icon/mail-sent';

import { IProps } from './types';
import style from './index.pcss';

const SentEmail: FC<IProps> = (props) => {
    const cn = useClassnames(style);
    const { t, i18n } = useTranslation();

    const elText = useMemo((): ReactNode => {
        if(props.email) {
            return (
                <div>
                    <Trans
                        i18n={i18n}
                        i18nKey="components.mail-sent.text-with-email"
                        values={{ email: props.email }}
                    >
                        We sent a verification link to&nbsp;
                        <strong className={cn('mail-sent__email')}>
                            {props.email}
                        </strong>
                        .
                    </Trans>
                </div>
            );
        }

        return t('components.mail-sent.text');
    }, [props.email, i18n.language]);

    return (
        <div className={cn('mail-sent')}>
            <IconMailSent className={cn('mail-sent__icon')} />
            <h2 className={cn('mail-sent__title')}>{t('components.mail-sent.title')}, <span style={{color: '#ff5487'}}>а также папку СПАМ.</span></h2>
            <p className={cn('mail-sent__text')}>{elText}</p>
            <div className={cn('mail-sent__sub-text')}>
                <Trans
                    i18n={i18n}
                    i18nKey="components.mail-sent.sub-text"
                >
                    If that doesn't help contact our support at&nbsp;
                    <span className={cn('mail-sent__sub-text-mail')}>support@faceport.ru</span>
                </Trans>
            </div>
        </div>
    );
};

export default SentEmail;
