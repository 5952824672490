import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { Page, PhotoPaginationResponse } from '../base';
import { GetLatestPhotosListData, LatestPhotoItem, LatestPhotosAPIType } from './types';
import queryString from 'query-string';

const getLatestPhotosList = (
    page: Page,
    data?: GetLatestPhotosListData
): Promise<AxiosResponse<PhotoPaginationResponse<LatestPhotoItem>>> => {
    return httpClient.get<PhotoPaginationResponse<LatestPhotoItem>>('/api/v2/latest-photos/', {
        params: {
            page: page.pageNumber,
            page_size: page.pageSize || 20,
            ...data
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

export default {
    getLatestPhotosList
} as LatestPhotosAPIType;
