import React, { FC, useMemo, useState, useRef, RefAttributes, ImgHTMLAttributes } from 'react';
import { Photo } from 'src/api/photos/types';
import Carousel, { RenderArrowProps } from 'react-elastic-carousel';
import Magnifier from 'react-magnifier';
import qaAttributes from 'component/helper/qa-attributes';
import { IStyle, useClassnames } from 'hook/use-classnames';
import style from './index.pcss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import Loader from 'component/loader';
import { useParams } from 'react-router';
import IconArrowLeft from 'component/icon/arrow-left';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';

interface IProps {
    total: number;
    list: Array<Photo>;
    className?: IStyle | string;
    status?: string;
    currentPhotoIndex: number;
    onClickNext(): void;
    onClickPrev(): void;
    onPhotoClick(photo_id: number): void;
    isLoadingPrev?: boolean;
    isLoadingNext?: boolean;
    startPage: number;
    prevPage?: number | null;
    nextPage?: number | null;
    refObjectNext: React.RefObject<Element>;
    is_invited_album?: boolean;
}

interface IPhotoProps {
    active: boolean;
    item: Photo;
    onPhotoClick?(): void;
    refObjectNext: React.RefObject<Element>;
    isLast?: boolean;
    nextPage?: boolean;
    isLoading?: boolean;
}

const CarouselPhotoItem = (props: IPhotoProps) => {
    const cn = useClassnames(style);

    const elLoaderAfterPages = () => {
        if (!props.isLoading) {
            return <Loader className={cn('carousel__loader')} ref={props.refObjectNext} />;
        }

    };

    const params: RefAttributes<HTMLImageElement> & ImgHTMLAttributes<HTMLImageElement> = {
        ...qaAttributes('carousel:thumbs:image'),
        src      : `${props.item.photo_url}?photo_size=200x112`,
        alt      : props.item.id.toString()
    };

    return (
        <div
            className={cn('carousel__item', props.active && 'carousel__item-active')}
            onClick={props.onPhotoClick}
        >
            <img {...params} />
            {(props.isLast && props.nextPage) ? elLoaderAfterPages() : null}
        </div>
    );
};

const PhotoCarousel: FC<IProps> = (props) => {
    const cn = useClassnames(style);
    const { id, photoId }: { id: string, photoId: string } = useParams();
    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].tablet);
    // const [albumPhotoId, setAlbumPhotoId] = useState<number | null>(null);

    // if (props.is_invited_album) {
    //     const { photoId }: { photoId: string } = useParams();
    //     setAlbumPhotoId(Number(photoId));
    // }

    const getItemCount = (): number => {
        if (isTablet || isMobile) {
            return 3;
        }

        return 9;
    };

    const currentIndex = (!props.prevPage && props.nextPage === 3) ? 0 : (isTablet || isMobile) ? 12 : getItemCount();
    const carouselRef = useRef(null);
    const [currentPhoto, setCurrentPhoto] = useState<Photo>(photoId ?
        props.list[props.list.map((item) => item.id).indexOf(Number(photoId))] :
        props.list[props.list.map((item) => item.id).indexOf(Number(id))]);

    const goto = (page: number) => {
        // @ts-ignore
        carouselRef.current?.goTo(Number(page));
    };

    const onClickNext = (callback: () => void, isEdge: boolean) => () => {
        if (isEdge && !props.nextPage) {
            if (!props.prevPage) {
                return goto(0);
            }

            if (isTablet || isMobile) {
                return goto(3);
            }

            return goto(9);
        }

        if (isEdge && props.nextPage) {
            props.onClickNext();
        }
        callback();
    };

    const onClickPrev = (callback: () => void, isEdge: boolean) => () => {
        if (isEdge && !props.prevPage) {
            if (isTablet || isMobile) {
                if (!props.nextPage) {
                    return goto(props.list.length - 3);
                }

                return goto(props.list.length - 6);
            }

            if (!props.nextPage) {
                return goto(props.list.length - 9);
            }

            return goto(props.list.length - 18);
        }

        if (isEdge && props.prevPage) {
            props.onClickPrev();
        }
        callback();
    };

    const elNavArrows = ({ type, onClick, isEdge}: RenderArrowProps) => {
        const isLoading = type === 'PREV' && props.isLoadingPrev || type !== 'PREV' && props.isLoadingNext;

        const pointer = type === 'PREV'
            ? <FontAwesomeIcon icon={faChevronCircleLeft} size="lg" />
            : <FontAwesomeIcon icon={faChevronCircleRight} size="lg" />;
        const callback = type === 'PREV'
            ? onClickPrev(onClick, isEdge)
            : onClickNext(onClick, isEdge);

        return (
            <div
                className={cn('carousel__btn', {
                    'carousel__btn-disable': isLoading,
                    'carousel__btn-left': type === 'PREV',
                    'carousel__btn-right': type !== 'PREV'
                })}
            >
                <button
                    onClick={callback}
                    disabled={isLoading}
                >
                    {isLoading ? <Loader text=" " className={cn('carousel__btn__loader')} /> : pointer}
                </button>
            </div>
        );
    };

    const onClickPrevPhoto = () => {
        props.list.forEach((item, index) => {
            if (photoId ? item.id === Number(photoId) : item.id === Number(id)) {
                if (item.id === props.list[0].id) {
                    if (isTablet || isMobile) {
                        if (!props.nextPage) {
                            props.onPhotoClick(props.list[props.list.length - 1].id);
                            setCurrentPhoto(props.list[props.list.length - 1]);

                            return goto(props.list.length - 3);
                        }

                        props.onPhotoClick(props.list[props.list.length - 4].id);
                        setCurrentPhoto(props.list[props.list.length - 4]);

                        return goto(props.list.length - 6);
                    }

                    if (!props.nextPage) {
                        props.onPhotoClick(props.list[props.list.length - 1].id);
                        setCurrentPhoto(props.list[props.list.length - 1]);

                        return goto(props.list.length - 9);
                    }

                    props.onPhotoClick(props.list[props.list.length - 10].id);
                    setCurrentPhoto(props.list[props.list.length - 10]);

                    return goto(props.list.length - 18);
                }

                props.onPhotoClick(props.list[index - 1].id);
                setCurrentPhoto(props.list[index - 1]);

                if (props.onClickPrev && props.list[index - 2] === props.list[0]) {
                    props.onClickPrev();
                }

                if (isTablet || isMobile) {
                    return goto(index - 2);
                }

                return goto(index - 8);
            }
        });

    };

    const onClickNextPhoto = () => {
        props.list.forEach((item, index) => {
            if (photoId ? item.id === Number(photoId) : item.id === Number(id)) {
                if (index === props.list.length - 1) {
                    if (!props.prevPage) {
                        props.onPhotoClick(props.list[0].id);
                        setCurrentPhoto(props.list[0]);

                        return goto(0);
                    }

                    if (isTablet || isMobile) {
                        props.onPhotoClick(props.list[3].id);
                        setCurrentPhoto(props.list[3]);

                        return goto(3);
                    }

                    props.onPhotoClick(props.list[9].id);
                    setCurrentPhoto(props.list[9]);

                    return goto(9);
                }

                props.onPhotoClick(props.list[index + 1].id);
                setCurrentPhoto(props.list[index + 1]);

                if (props.onClickNext && props.list[index + 2] === props.list[props.list.length - 1]) {
                    props.onClickNext();
                }
                goto(index);
            }
        });
    };

    const elCurrentPhoto = useMemo(() => {
        // const isDisablePrev = !props.prevPage && currentPhoto === props.list[0];
        // const isDisableNext = !props.nextPage && currentPhoto === props.list[props.list.length - 1];

        return (
            <div className={cn('carousel__current__container')}>
                <div
                    className={cn('carousel__current__btn', 'carousel__current__btn-left')}
                >
                    <button
                        onClick={onClickPrevPhoto}
                    >
                        <IconArrowLeft
                            width={20}
                            height={20}
                            className={cn('carousel__control')}
                        />
                    </button>
                </div>
                <div key={currentPhoto.id} className={cn('carousel__current')}>
                    <Magnifier
                        height={'100%'}
                        max-height={'60vh'}
                        width={'100%'}
                        src={`${currentPhoto.photo_url}`}
                        zoomImgSrc={props.status === 'OWNER' ? currentPhoto.photo_url : `${currentPhoto.photo_url}?photo_size=780x`}
                        {...qaAttributes('carousel:current:image')}
                    />
                </div>
                <div
                    className={cn('carousel__current__btn', 'carousel__current__btn-right')}
                >
                    <button
                        onClick={onClickNextPhoto}
                    >
                        <IconArrowLeft
                            width={20}
                            height={20}
                            className={cn('carousel__control', 'carousel__control-next')}
                        />
                    </button>
                </div>
            </div>
        );
    }, [currentPhoto, props.status]);

    const elCarousel = useMemo(() => {
        return (
            <Carousel
                className={cn('carousel__pagination')}
                isRTL={false}
                enableMouseSwipe={false}
                pagination={false}
                renderArrow={elNavArrows}
                itemsToShow={getItemCount()}
                itemsToScroll={getItemCount()}
                initialActiveIndex={currentIndex}
                ref={carouselRef}
            >
                {props.list.map((item) => {
                    const isActivePage = photoId ? item.id === Number(photoId) : item.id === Number(id);

                    const onPageClick = () => {
                        setCurrentPhoto(item);

                        props.onPhotoClick(item.id);
                    };

                    return (
                        <CarouselPhotoItem
                            key={item.id}
                            item={item}
                            active={isActivePage}
                            onPhotoClick={onPageClick}
                            refObjectNext={props.refObjectNext}
                            isLast={item === props.list[props.list.length - 1]}
                            isLoading={props.isLoadingNext || props.isLoadingPrev}
                            nextPage={!!props.nextPage}
                        />
                    );
                })}
            </Carousel>
        );
    }, [currentIndex, props.list, props.isLoadingNext, props.isLoadingPrev, props.nextPage, props.refObjectNext, id, photoId]);

    return (
        <>
            {elCurrentPhoto}
            {elCarousel}
        </>
    );
};

export default PhotoCarousel;
