import React from 'react';

import Icon from './';

export default Icon(() => (
    <path
        d="M17.5,13.1h-4.4v4.4h-2.2v-4.4H6.5v-2.2h4.4V6.5h2.2v4.4h4.4V13.1z M12,1c-1.445,0-2.875,0.285-4.21,0.837
        C6.456,2.39,5.243,3.2,4.222,4.222C2.159,6.285,1,9.083,1,12c0,2.917,1.159,5.715,3.222,7.778C5.243,20.8,6.456,21.61,7.79,22.163
        C9.125,22.715,10.555,23,12,23c2.917,0,5.715-1.159,7.778-3.222C21.841,17.715,23,14.917,23,12c0-1.445-0.285-2.875-0.837-4.21
        C21.61,6.456,20.8,5.243,19.778,4.222C18.757,3.2,17.544,2.39,16.209,1.837C14.875,1.285,13.445,1,12,1z"
    />
));
