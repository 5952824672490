import ProgressBar from 'component/progress-bar';
import { useClassnames } from 'hook/use-classnames';
import React, { useEffect, useMemo, useState } from 'react';
import style from './index.pcss';
import { YandexTasksListData, YandexTasksListItem, YandexTasksRetrieve } from 'src/api/tasks/types';
import api from 'src/api';
import { Page } from 'src/api/base';
import { useDispatch } from 'react-redux';
import { resetTask } from 'src/store/reducers/task/actions';
import IconClose from 'component/icon/close';
import IconStop from 'component/icon/stop';
import ReactTooltip from 'react-tooltip';

interface IProps {
    id: string;
    item: YandexTasksListItem
    isSeen: boolean;
    isOpen?: boolean;
    onClickOpenErrors: React.Dispatch<React.SetStateAction<string | null>>;
    onClickUpdateList(): void;
}

const DownloadModalItem = (props: IProps) => {
    const cn = useClassnames(style);
    const dispatch = useDispatch();

    const [isSeen, setIsSeen] = useState<boolean>(false);
    const [isSeenEye, setIsSeenEye] = useState<boolean>(false);
    const [isErrorExpanded, setIsErrorExpanded] = useState<boolean>(false);
    const [task, setTask] = useState<YandexTasksRetrieve>();

    const onClickExpandError = () => {
        setIsErrorExpanded((prev) => !prev);
    };

    useEffect(() => {
        const interval: ReturnType<typeof setInterval> = setInterval(() => {
            if (props.isOpen) {
                api.tasks.getYandexTasksItem(props.id)
                    .then((resp) => {
                        setTask(resp.data);
                        setIsSeen(resp.data.seen);
                        switch (resp.data.status) {
                            case 'SUCCESS':
                                return clearInterval(interval);
                            case 'ERROR':
                                return clearInterval(interval);
                            case 'FAILED_TO_INIT':
                                return clearInterval(interval);
                            case 'STOPPED':
                                return clearInterval(interval);
                        }

                        return resp.data.downloaded_count !== resp.data.photo_count;
                    });
            } else {
                return clearInterval(interval);
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const onClickSeen = () => {
        if (!task?.seen && !isSeen) {
            api.tasks.createSeenYandexTask(props.id)
                .then(() => {
                    const page: Page = {
                        pageNumber: 1
                    };

                    const filter: YandexTasksListData = {
                        seen: false
                    };

                    setIsSeenEye(true);
                    setTimeout(() => setIsSeen(true), 500);

                    api.tasks.getYandexTasksList(page, filter)
                        .then((resp) => {
                            if (!resp.data.results.length) {
                                dispatch(resetTask());
                            }
                        });
                });
        }
    };

    const onClickStop = () => {
        api.tasks.createStopYandexTask(props.id)
            .then(() => {
                api.tasks.getYandexTasksItem(props.id)
                    .then((resp) => {
                        setTask(resp.data);
                        setIsSeen(resp.data.seen);
                    });
            });
    };

    const onClickOpenErrors = () => {
        props.onClickOpenErrors(props.id);
    };

    const elDownloadTitle = useMemo(() => {
        return (
            <div className={cn('download__modal__content__item-title')}>
                <div className={cn('download__modal__content__item-title-text')}>
                    <h4 onClick={onClickExpandError}>{props.item.folder || task?.folder || (task?.status === ('ERROR' || 'FAILED_TO_INIT') && 'Ошибка') || 'Обработка...'}</h4>
                    {task && isErrorExpanded && (
                        <div className={cn('download__modal__content__item-title-error')}>
                            <p>Найдено: {task.photo_count}</p>
                            <p>Пропущено: {task.skipped_count}</p>
                            <p>Загружено: {task.downloaded_count}</p>
                            {!!task.skipped_count && (
                                <div className={cn('download__modal__content__item-title-error-text')} onClick={onClickOpenErrors}>
                                    Посмотреть детали отчета
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <ProgressBar error={task?.status === 'ERROR'} stopped={task?.status === 'STOPPED'} progress={task ? (task.downloaded_count + task.skipped_count) / task?.photo_count * 100 : 0} />
            </div>
        );
    }, [task, isErrorExpanded]);

    if (isSeen) {
        return null;
    }

    return (
        <div className={cn('download__modal__content__item')}>
            {elDownloadTitle}
            <div className={cn('download__modal__content__item-controls')}>
                {task && ((task.skipped_count + task.downloaded_count) === task.photo_count || task.status === 'FAILED_TO_INIT' || task.status === 'STOPPED' || task.status === 'ERROR') && (
                    <IconClose
                        onClick={onClickSeen}
                        className={cn('download__modal__content__item-controls-close')}
                    />
                )}
                {task && task.status === 'IN_PROGRESS' && (task.skipped_count + task.downloaded_count) < task.photo_count && (
                    <>
                        <ReactTooltip
                            id="stop-task"
                            place="right"
                            effect="solid"
                            clickable={true}
                            border={true}
                            borderColor="#dee1e4"
                            backgroundColor="#fff"
                            className={cn('download__modal__content__item-controls-stop-tooltip')}
                        >
                            Остановить загрузку
                        </ReactTooltip>
                        <IconStop
                            data-type="light"
                            data-tip={true}
                            data-for="stop-task"
                            onClick={onClickStop}
                            className={cn('download__modal__content__item-controls-stop')}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default DownloadModalItem;
