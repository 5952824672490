import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useClassnames } from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';
import ErrorBlock from 'component/error';
import Loader from 'component/loader';
import UI from 'component/ui';
import { deleteBanner, getOwnBannerList, } from 'component/api/banners';
import CreateBannerForm from 'component/form/banner-form/create-form';
import { useRegistry } from 'component/form';
import Modal from 'component/modal';
import { DataBannerListItem } from 'component/api/types/api/photo_ad/banner/get_own_banner_list/get/code-200';
import { IPayload } from 'component/form/types';
import UpdateBannerForm from 'component/form/banner-form/update-form';
import Button from 'component/button';

import style from './styles.pcss';

const BANNER_LIMIT = 10;

const Banners = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const token = useCancelToken();
    const registry = useRegistry();

    const [error, setError] = useState<string | null>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [list, setList] = useState<Array<DataBannerListItem>>([]);
    const [selected, setSelected] = useState<DataBannerListItem | null>(null);

    const [createModal, setCreateModal] = useState<boolean>(false);
    const [updateModal, setUpdateModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        _request();
    }, []);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const _request = useCallback(() => {
        setPending(true);

        getOwnBannerList({
            cancelToken: token.new()
        })
            .then((resp) => {
                setTotal(resp.total_count);
                setList(resp.banner_list);
                setPending(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                    setPending(false);
                }
            });
    }, []);

    const onClickEdit = useCallback((banner: DataBannerListItem) => (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setSelected(banner);
        setUpdateModal(true);
    }, []);

    const onClickDel = useCallback((banner: DataBannerListItem) => (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setSelected(banner);
        setDeleteModal(true);
    }, []);

    const elControlBtn = (banner: DataBannerListItem) => {
        return (
            <div className={cn('banners__list-control')}>
                <FontAwesomeIcon
                    icon={faEdit}
                    className={cn('banners__list-edit')}
                    onClick={onClickEdit(banner)}
                />
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className={cn('banners__list-del')}
                    onClick={onClickDel(banner)}
                />
            </div>
        );
    };

    const elBannerList = useMemo(() => {
        if(list.length) {
            return (
                <div className={cn('banners__list')}>
                    {list.map((item) => (
                        <div className={cn('banners__list-item')} key={item.id}>
                            <span className={cn('banners__list-item__text')}>{item.id}</span>
                            <span className={cn('banners__list-item__text')}>{item.name}</span>
                            <span className={cn('banners__list-item__text')}>{item.theme?.name}</span>
                            <span className={cn('banners__list-item__text')}>
                                {item.link || '-'}
                            </span>
                            <span>{elControlBtn(item)}</span>
                        </div>
                    ))}
                </div>
            );
        }

        return <div className={cn('banners__list-empty')}>{t('route.banners.non-banner')}</div>;
    }, [JSON.stringify(list)]);

    const onSubmit = (payload: IPayload) => {
        setCreateModal(false);
        setUpdateModal(false);

        _request();
    };

    const onClickApplyDel = useCallback(() => {
        deleteBanner({
            cancelToken: token.new(),
            data: {
                banner_id: selected?.id
            }
        }).then(() => {
            setDeleteModal(false);
            _request();
        }).catch((err) => {
            if(!axios.isCancel(err)) {
                console.error(err);
                setDeleteModal(false);
            }
        });
    }, [selected]);

    const elModal = useMemo(() => {
        if (createModal) {
            return (
                <Modal onClickClose={() => setCreateModal(false)}>
                    <h2>{t('route.banners.create-banner')}</h2>
                    <CreateBannerForm registry={registry} onSubmit={onSubmit} />
                </Modal>
            );
        }

        if (updateModal && selected) {
            return (
                <Modal onClickClose={() => setUpdateModal(false)}>
                    <h2>{t('route.banners.update-banner')}</h2>
                    <UpdateBannerForm registry={registry} banner={selected} onSubmit={onSubmit} />
                </Modal>
            );
        }

        if (deleteModal && selected) {
            return (
                <Modal onClickClose={() => setDeleteModal(false)}>
                    <h2>{t('route.banners.delete', {name: selected.name})}</h2>
                    <div className={cn('banners__del')}>
                        <Button onClick={() => setDeleteModal(false)}>Нет</Button>
                        <Button onClick={onClickApplyDel}>Да</Button>
                    </div>
                </Modal>
            );
        }
    }, [createModal, updateModal, deleteModal, selected]);

    const elAddBtn = useMemo(() => {
        return (
            <div className={cn('banners__btn')}>
                <Button
                    disabled={(list.length === BANNER_LIMIT)}
                    onClick={() => setCreateModal(true)}
                >
                    {t('route.banners.add-banner')}
                </Button>
            </div>
        );
    }, [list]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('banners__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    if(pending) {
        return <Loader className={cn('banners__loader')} />;
    }

    return (
        <UI.Main className={cn('banners')}>
            <h1 className={cn('banners__header')}>{t('route.banners.header')}</h1>
            <UI.Box padding={true} className={cn('banners__content')}>
                {elBannerList}
                {elError}
            </UI.Box>
            {elAddBtn}
            {elModal}
        </UI.Main>
    );
};

export default Banners;
