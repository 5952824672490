import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { CloudAPIType, CreateYandexPhoto, YandexDiskItems, YandexDiskItemsData, YandexDiskItemsTotal, YandexDiskItemsTotalData } from './types';
import queryString from 'query-string';

const getYandexDiskItems = (payload: YandexDiskItemsData): Promise<AxiosResponse<YandexDiskItems>> => {
    return httpClient.get<YandexDiskItems>('/api/v2/cloud/yandex/disk/items/', {
        params: {
            ...(payload.include_download_job_status && { include_download_job_status: payload.include_download_job_status}),
            ...(payload.item_type && { item_type: payload.item_type}),
            ...(payload.limit && { limit: payload.limit }),
            ...(payload.offset && { offset: payload.offset }),
            ...(payload.path && { path: payload.path })
        }
    });
};

const getYandexDiskItemsTotal = (payload: YandexDiskItemsTotalData): Promise<AxiosResponse<YandexDiskItemsTotal>> => {
    return httpClient.get<YandexDiskItemsTotal>('api/v2/cloud/yandex/disk/items/estimate/', {
        params: {
            ...(payload.extension && { extension: payload.extension}),
            ...(payload.item_type && { item_type: payload.item_type}),
            ...(payload.limit && { limit: payload.limit }),
            ...(payload.offset && { offset: payload.offset }),
            ...(payload.paths && { paths: payload.paths })
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

const createYandexPhoto = (
    paths: Array<string>,
    photo_data?: {
        price: number,
        event_id: number,
        album_id?: number
    }
): Promise<AxiosResponse<CreateYandexPhoto>> => {
    return httpClient.post<CreateYandexPhoto>('/api/v2/cloud/yandex/photo/', {
        paths,
        photo_data
    });
};

export default {
    getYandexDiskItems,
    getYandexDiskItemsTotal,
    createYandexPhoto
} as CloudAPIType;
