import React from 'react';
import { useTranslation } from 'react-i18next';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';

import style from './index.pcss';

// @ts-ignore
import condition from './docs/condition.pdf';
// @ts-ignore
import img1 from './img/1_auth.png';
// @ts-ignore
import img2 from './img/2_registr.png';
// @ts-ignore
import img3 from './img/3_add_basket.png';
// @ts-ignore
import img4 from './img/4_icon_basket.png';
// @ts-ignore
import img5 from './img/5_basket.png';
// @ts-ignore
import img6 from './img/6_pay_order.png';
// @ts-ignore
// import img7 from './img/7_pay_ok.png';
// @ts-ignore
import img8 from './img/7_pay_photo.png';
// @ts-ignore
import offer from '../cart/docs/offer.pdf';

const Condition = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    return (
        <UI.Main className={cn('condition')}>
            <h1 className={cn('condition__header')}>{t('route.condition.header')}</h1>
            <div className={cn('condition__content')}>
                <h2 className={cn('condition__block-header')}>
                    {t('route.condition.text.top')}
                </h2>
                <ol>
                    <li>
                        <p>{t('route.condition.text.1')}</p>
                        <p className={cn('condition__p-img')}>
                            <img src={img1} alt="" className={cn('condition__img')} />
                        </p>
                    </li>
                    <li>
                        <p>{t('route.condition.text.2')}</p>
                        <p className={cn('condition__p-img')}>
                            <img src={img2} alt="" className={cn('condition__img')} />
                        </p>
                    </li>
                    <li>
                        <p>{t('route.condition.text.3')}</p>
                        <p className={cn('condition__p-img')}>
                            <img src={img3} alt="" className={cn('condition__img')} />
                        </p>
                    </li>
                    <li>
                        <p>{t('route.condition.text.4')}</p>
                    </li>
                    <li>
                        <p>{t('route.condition.text.5')}</p>
                        <p className={cn('condition__p-img')}>
                            <img src={img4} alt="" className={cn('condition__img')} />
                        </p>
                    </li>
                    <li>
                        <p>
                            {t('route.condition.text.6')}
                        </p>
                        <p className={cn('condition__p-img')}>
                            <img src={img5} alt="" className={cn('condition__img')} />
                        </p>
                    </li>
                    <li>
                        <p>{t('route.condition.text.7')}</p>
                    </li>
                    <li>
                        <p>{t('route.condition.text.8')}</p>
                        <p className={cn('condition__p-img')}>
                            <img src={img6} alt="" className={cn('condition__img')} />
                        </p>
                    </li>
                    <li>
                        <p>{t('route.condition.text.9')}</p>
                        <p className={cn('condition__p-img')}>
                            <img src={img8} alt="" className={cn('condition__img')} />
                        </p>
                    </li>
                </ol>
                <p>{t('route.condition.text.bottom')}</p>
                <h2 className={cn('condition__block-header')}>
                    {t('route.condition.text.cashback.title')}
                </h2>
                <p>
                    <a
                        href={condition}
                        target="_blank"
                        className={cn('condition__link')}
                    >
                        {t('route.condition.text.cashback.text')}
                    </a>
                </p>
                <h2 className={cn('condition__block-header')}>
                    {t('route.condition.text.offer.title')}
                </h2>
                <p>
                    <a
                        href={offer}
                        target="_blank"
                        className={cn('condition__link')}
                    >
                        {t('route.condition.text.offer.text')}
                    </a>
                </p>
                <h2 className={cn('condition__block-header')}>
                    {t('route.condition.text.safety.title')}
                </h2>
                <p>
                    {t('route.condition.text.safety.text.1')}
                </p>
                <p>
                    {t('route.condition.text.safety.text.2')}
                </p>
            </div>
        </UI.Main>
    );
};

export default Condition;
