import React, { useRef, useEffect, FC, FormEvent, useState, useMemo } from 'react';
import { Trans } from 'react-i18next';
import debounce from 'lodash.debounce';
import { get } from 'js-cookie';

import i18n from 'component/core/i18n';
import config from 'config';
import ReCaptcha from 'component/recaptcha';
import useClassnames from 'hook/use-classnames';

import registry from './registry';
import { IProps, IPayload } from './types';
import style from './index.pcss';

const isQA = get('qa') === 'enable';

const Form: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const $root = useRef<HTMLFormElement>(null);

    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);

    const onChange = debounce(() => {
        const fields = props.registry.getFields();

        if(fields) {
            props.onChangeValidity && props.onChangeValidity(!!props.registry.checkValidity());
        }

        if(props.onChange) {
            props.onChange();
        }
    }, 50);

    const onSubmit = (e: FormEvent): void => {
        e && e.preventDefault();

        const fields = props.registry.getFields();
        const payload: IPayload = {};

        if(fields && $root.current) {
            const data = new FormData($root.current);

            fields.forEach((field, name) => {
                payload[name] = field.isAutoFill ? data.get(name) : field.value;
            });

            if(props.recaptcha) {
                payload.captcha_token = isQA ? config.recaptcha['debug-token'] : reCaptchaToken;
            }
        }

        if(props.onSubmit) {
            props.onSubmit(payload);

            props.recaptcha && recaptchaReload();
        }
    };

    const onReset = (e: FormEvent): void => {
        e && e.preventDefault();

        props.registry.clearForm();
    };

    const recaptchaReload = () => {
        if(window.widgetId !== undefined && !!window.grecaptcha) {
            window.grecaptcha.reset(window.widgetId);
            window.grecaptcha.execute(window.widgetId);
        }
    };

    const elRecaptchaText = () => {
        if(props.recaptcha && !isQA) {
            return (
                <p className={cn('form__recaptcha-text')}>
                    <Trans
                        i18n={i18n}
                        i18nKey="components.form.captcha-privacy-text"
                    >
                        This site is protected by reCAPTCHA and the Google&nbsp;
                        <a className={cn('form__recaptcha-link')} href="https://policies.google.com/privacy">Privacy Policy</a>
                        and&nbsp;
                        <a className={cn('form__recaptcha-link')} href="https://policies.google.com/terms">Terms of Service</a>
                        apply.
                    </Trans>
                </p>
            );
        }
    };

    const elRecaptcha = () => {
        if(props.recaptcha && !isQA) {
            return (
                <ReCaptcha
                    size="invisible"
                    callback={setReCaptchaToken}
                />
            );
        }
    };

    useEffect(() => {
        props.registry.setOnChange(onChange);
    }, [props.onChange, props.onChangeValidity]);

    useEffect(() => {
        const fields = props.registry.getFields();

        if(fields) {
            fields.forEach((field, key) => {
                if(props.errors && props.errors.length) {
                    const item = props.errors.find((error) => error.key === key);

                    field.setError(item ? item.message : null);
                } else {
                    field.setError(null);
                }
            });
        }
    }, [props.errors]);

    return (
        <div className={cn('form')}>
            {elRecaptcha()}
            <form
                className={cn('form__element')}
                onSubmit={onSubmit}
                onReset={onReset}
                ref={$root}
                // onChange={onChange}
            >
                {props.children}
            </form>
            {elRecaptchaText()}
        </div>
    );
};

export const createRegistry = registry;

export const useRegistry = () => useMemo(registry, []);

export default Form;
