import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';
import { Data as OwnBannerList } from 'component/api/types/api/photo_ad/banner/get_own_banner_list/get/code-200';
import { Data as CreateBannerData } from 'component/api/types/api/photo_ad/banner/create/post/code-200';
import { Data as UpdateBannerData } from 'component/api/types/api/photo_ad/banner/update/post/code-200';
import { Data as PhotoBannerData } from 'component/api/types/api/photo_ad/photo_ad/get_photo_ad_by_photo_id/get/code-200';

export const getOwnBannerList = (config?: AxiosRequestConfig) => {
    return request<OwnBannerList>({
        url   : '/api/photo_ad/banner/get_own_banner_list/',
        ...config
    });
};

export const createBanner = (config?: AxiosRequestConfig) => {
    return request<CreateBannerData>({
        url   : '/api/photo_ad/banner/create/',
        method: 'post',
        ...config
    });
};

export const updateBanner = (config?: AxiosRequestConfig) => {
    return request<UpdateBannerData>({
        url   : '/api/photo_ad/banner/update/',
        method: 'post',
        ...config
    });
};

export const deleteBanner = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/photo_ad/banner/delete/',
        method: 'post',
        ...config
    });
};

export const getPhotoBanner = (config?: AxiosRequestConfig) => {
    return request<PhotoBannerData>({
        url   : '/api/photo_ad/photo_ad/get_photo_ad_by_photo_id/',
        ...config
    });
};

export const createPhotoBanner = (config?: AxiosRequestConfig) => {
    return request<PhotoBannerData>({
        url   : '/api/photo_ad/photo_ad/create/',
        method: 'post',
        ...config
    });
};
