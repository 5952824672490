import { createAction } from '@reduxjs/toolkit';

import { IStore } from './types/reducer';

export const set = createAction<IStore>(
    '@@local-config/SET'
);

export const setSimilarPersonList = createAction<boolean>(
    '@@local-config/SET-SIMILAR-PERSON-LIST'
);

export default {
    set,
    setSimilarPersonList
};
