import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import { SharedAlbumItem, SharedAlbumListItem, SharedAlbumPhoto, SharedAlbumsAPIType, GetSharedAlbumListData, GetSharedAlbumPhotoData, GetSharedAlbumPersonData, SharedAlbumPerson } from './types';
import queryString from 'query-string';

const getSharedAlbumList = (page: Page, data?: GetSharedAlbumListData): Promise<AxiosResponse<PaginationResponse<SharedAlbumListItem>>> => {
    return httpClient.get<PaginationResponse<SharedAlbumListItem>>('/api/v2/shared-albums/', {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...data
        }
    });
};

const getSharedAlbumRetrieve = (id: number): Promise<AxiosResponse<SharedAlbumItem>> => {
    return httpClient.get<SharedAlbumItem>(`/api/v2/shared-albums/${id}`);
};

const getSharedAlbumPersonList = (album_pk: number, page: Page, data?: GetSharedAlbumPersonData): Promise<AxiosResponse<PaginationResponse<SharedAlbumPerson>>> => {
    return httpClient.get<PaginationResponse<SharedAlbumPerson>>(`/api/v2/shared-albums/${album_pk}/persons/`, {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...data
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

const getSharedAlbumPhotoList = (album_pk: number, page: Page, data?: GetSharedAlbumPhotoData): Promise<AxiosResponse<PaginationResponse<SharedAlbumPhoto>>> => {
    return httpClient.get<PaginationResponse<SharedAlbumPhoto>>(`/api/v2/shared-albums/${album_pk}/photos/`, {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...data
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

export default {
    getSharedAlbumList,
    getSharedAlbumRetrieve,
    getSharedAlbumPersonList,
    getSharedAlbumPhotoList
} as SharedAlbumsAPIType;
