import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Person: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props} viewBox="0 0 512 512">
        <path
            d="M192.25,250.688c-7.045,0-13.801,2.799-18.782,7.78c-4.981,4.981-7.78,11.738-7.78,18.783
            s2.799,13.802,7.78,18.783c4.981,4.981,11.738,7.78,18.782,7.78c3.488,0,6.942-0.686,10.165-2.023
            c3.223-1.335,6.151-3.29,8.618-5.757c2.467-2.467,4.423-5.395,5.757-8.617c1.337-3.224,2.023-6.677,2.023-10.166
            s-0.686-6.942-2.023-10.166c-1.335-3.221-3.29-6.15-5.757-8.617c-2.466-2.467-5.395-4.422-8.618-5.757
            C199.192,251.374,195.738,250.688,192.25,250.688z M319.75,250.688c-7.044,0-13.802,2.799-18.783,7.78
            c-4.981,4.981-7.78,11.738-7.78,18.783s2.799,13.802,7.78,18.783c4.981,4.981,11.738,7.78,18.783,7.78s13.802-2.799,18.783-7.78
            c4.981-4.981,7.78-11.738,7.78-18.783s-2.799-13.802-7.78-18.783C333.552,253.486,326.794,250.688,319.75,250.688z M256,43.5
            c-27.906,0-55.539,5.497-81.32,16.175c-25.782,10.679-49.208,26.332-68.94,46.064C65.888,145.591,43.5,199.642,43.5,256
            c0,56.359,22.388,110.409,62.24,150.261c19.733,19.733,43.158,35.383,68.94,46.064C200.461,463.003,228.094,468.5,256,468.5
            c56.359,0,110.409-22.389,150.261-62.239C446.111,366.409,468.5,312.359,468.5,256c0-27.906-5.497-55.539-16.176-81.32
            c-10.68-25.782-26.331-49.208-46.064-68.94c-19.733-19.732-43.159-35.385-68.941-46.064C311.539,48.997,283.905,43.5,256,43.5z
             M256,426c-45.087,0-88.327-17.912-120.208-49.791C103.911,344.328,86,301.086,86,256c-0.637-6.163-0.637-12.112,0-18.275
            c50.575-22.1,90.738-63.113,111.775-114.113c39.737,56.312,104.337,89.887,173.4,89.887c16.15,0,32.088-1.913,47.812-5.525
            c26.562,90.525-24.862,184.663-114.962,211.012C287.875,423.662,272.15,426,256,426z M1,43.5c0-11.272,4.478-22.082,12.448-30.052
            C21.418,5.478,32.228,1,43.5,1h85v42.5h-85v85H1V43.5z M511,468.5c0,11.271-4.477,22.081-12.448,30.052
            C490.581,506.523,479.771,511,468.5,511h-85v-42.5h85v-85H511V468.5z M43.5,511c-11.272,0-22.082-4.477-30.052-12.448
            C5.478,490.581,1,479.771,1,468.5v-85h42.5v85h85V511H43.5z M468.5,1c11.271,0,22.081,4.478,30.052,12.448
            C506.523,21.418,511,32.228,511,43.5v85h-42.5v-85h-85V1H468.5z"
        />
    </svg>
);

export default Person;
