export class FileItem {
    file: File;
    formData: FormData;
    index: number;
    status: 'pending' | 'uploading' | 'creating' | 'success' | 'error' = 'pending';
    progress: number;
    error: string | null = null;
    id: string | null = null;

    constructor(file: File, index: number) {
        this.file = file;
        this.index = index;
        this.formData = new FormData();
        this.formData.append('file', file);
        this.progress = 0;
    }

    setStatus(status: 'pending' | 'uploading' | 'creating' | 'success' | 'error') {
        this.status = status;
    }

    setProgress(progress: number) {
        this.progress = progress;
    }

    setError(error: string | null) {
        this.error = error;
    }

    setId(id: string) {
        this.id = id;
    }

    getProgress() {
        return this.progress;
    }
}
