import React, { useState } from 'react';
import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { genderOptions, getNormalizedQuery, suitSizeOptions } from '../utils';
import UI from 'component/ui';
import Form from 'component/form';
import Button from 'component/button';
import InputColor from 'component/form/input-color';
import InputEvents from 'component/form/input-events/index-tsx-async';
import style from './index.pcss';
import InputSelect from 'component/form/input-select';
import Input from 'component/form/input';

const FilterFormCard = (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    const elColorInput = () => {
        let defaultValue = queryParams.color;

        if (Array.isArray(queryParams.color)) {
            defaultValue = queryParams.color.map(Number);
        }

        return (
            <InputColor
                registry={props.registry.field}
                name="color"
                children={t('global.form.items.colors')}
                direction="column"
                className={cn('filter-form__input-block')}
                defaultValue={defaultValue}
            />
        );
    };

    const elEvent = () => {
        const defaultValue = queryParams.event;
        const defaultEventName = queryParams.event_name;

        return (
            <InputEvents
                registry={props.registry.field}
                name="event"
                clearable={true}
                children={t('global.form.items.event')}
                direction="column"
                className={cn('filter-form__input-block')}
                defaultValue={defaultValue && {label: defaultEventName, value: defaultValue}}
            />
        );
    };

    const elHeight = () => {

        return (
            <Input
                registry={props.registry.field}
                className={cn('filter-form__input-block')}
                name="height"
                direction="column"
                children="Рост"
                placeholder="Введите рост"
                onlyNumbers={true}
                max={205}
                min={100}
            />
        );
    };

    const elSuitSize = () => {
        const defaultValue = queryParams.suit_size;

        return (
            <InputSelect
                registry={props.registry.field}
                className={cn('filter-form__input-block')}
                name="suit_size"
                direction="column"
                children={'Размер'}
                options={suitSizeOptions}
                defaultValue={defaultValue && {value: defaultValue, label: defaultValue}}
                clearable={true}
            />
        );
    };

    const elGender = () => {
        const defaultValue = genderOptions.find((item) => item.value === queryParams.gender);

        return (
            <InputSelect
                registry={props.registry.field}
                className={cn('filter-form__input-block')}
                name="gender"
                direction="column"
                children={'Пол'}
                options={genderOptions}
                defaultValue={defaultValue && {value: defaultValue.value, label: defaultValue.label}}
                clearable={true}
            />
        );
    };

    return (
        <div className={cn('advertisement__sidebar')}>
            <Form registry={props.registry.form} onChange={props.onChange} onSubmit={props.onSubmit} onChangeValidity={props.onChangeValidity}>
                <UI.Box padding={true} className={cn('advertisement__box')}>
                    {elColorInput()}
                    {elEvent()}
                    {elGender()}
                    {elSuitSize()}
                    {elHeight()}
                    <Button
                        type="reset"
                        className={cn('advertisement__button-submit')}
                        onClick={props.onReset}
                    >
                        Сбросить
                    </Button>
                </UI.Box>
            </Form>
        </div>
    );
};

export default FilterFormCard;
