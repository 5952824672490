import { parse } from 'query-string';
import { normalizeObject } from 'component/helper/normalize-object';

export const getColor = (photoColorId: number): string => {
    let styleName = '';
    const blue_id = 6;
    const red_id = 1;
    const white_id = 9;
    const orange_id = 2;
    const yellow_id = 3;
    const cyan_id = 5;
    const green_id = 4;
    const violet_id = 7;
    const black_id = 8;

    switch (photoColorId) {
        case blue_id: styleName = 'blue'; break;
        case red_id: styleName = 'red'; break;
        case white_id: styleName = 'white'; break;
        case orange_id: styleName = 'orange'; break;
        case yellow_id: styleName = 'yellow'; break;
        case cyan_id: styleName = 'cyan'; break;
        case green_id: styleName = 'green'; break;
        case violet_id: styleName = 'violet'; break;
        case black_id: styleName = 'black'; break;
    }

    return styleName;
};

export const suitSizeOptions = [
    {
        value: 'XXS',
        label: 'XXS'
    },
    {
        value: 'XS',
        label: 'XS'
    },
    {
        value: 'S',
        label: 'S'
    },
    {
        value: 'M',
        label: 'M'
    },
    {
        value: 'L',
        label: 'L'
    },
    {
        value: 'XL',
        label: 'XL'
    },
    {
        value: 'XXL',
        label: 'XXL'
    },
    {
        value: 'XXXL',
        label: 'XXXL'
    }
];

export const genderOptions = [
    {
        value: 'man',
        label: 'Мужской'
    },
    {
        value: 'woman',
        label: 'Женский'
    }
];

export const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};
