import { useClassnames } from 'hook/use-classnames';
import React from 'react';
import style from './index.pcss';

interface IProps {
    progress: number;
    error?: boolean;
    stopped?: boolean;
}

const ProgressBar = (props: IProps) => {
    const cn = useClassnames(style);
    const progressSuccess = {
        width: `${props.progress}%`
    };

    const progressStopped = {
        width: `100%`
    };

    const title = props.error && props.progress === 100 ? 'Не все фото загружены' : props.progress === 100 ? 'Готово' : '';
    const stopped = props.stopped && 'Загрузка прервана';

    return (
        <div className={cn('progress-bar', props.error && 'progress-bar__error')}>
            <div style={props.stopped ? progressStopped : progressSuccess} className={cn('progress-bar__line')}>
                <span className={cn('progress-bar__line-title')}>{stopped || title}</span>
            </div>
        </div>
    );
};

export default ProgressBar;
