import { createAction } from '@reduxjs/toolkit';
import { AccountRetrieve } from 'src/api/accounts/types';

export const set = createAction<Partial<AccountRetrieve>>(
    '@@user/SET'
);

export const reset = createAction(
    '@@user/RESET'
);

export default {
    set,
    reset
};
