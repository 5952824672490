import { AxiosRequestConfig } from 'axios';

import { Data as PersonData } from 'component/api/types/api/main/get-person/get/code-200';
import { Data as PersonListData } from 'component/api/types/api/main/get-person-list/get/code-200';
import { Data as ClubsData } from 'component/api/types/api/main/get-club-list/get/code-200';
import { Data as EventsData } from 'component/api/types/api/main/get-simple-event-list/get/code-200';
import { Data as LocationData } from 'component/api/types/api/main/get-location-list/get/code-200';
import { Data as YearsData } from 'component/api/types/api/main/get-events-years/get/code-200';
import { Data as SearchData } from 'component/api/types/api/main/smart-search/events/get/code-200';
import { Data as EventWithPhotoCountListData } from 'component/api/types/api/main/get-event-with-photo-count-list/get/code-200';
import { Data as EventInfoData } from 'component/api/types/api/main/get-event-info/get/code-200';
import { Data as DataDancePrograms } from 'component/api/types/api/main/get-dance-program-list/get/code-200';
import { Data as DataDanceGradesStandard } from 'component/api/types/api/main/dance-grade/standard/list/get/code-200';
import { Data as DataDanceGradesLatin } from 'component/api/types/api/main/dance-grade/latin/list/get/code-200';

import { request } from './';

export const getPersonList = (config?: AxiosRequestConfig) => {
    return request<PersonListData>({
        url   : '/api/main/get-person-list/',
        ...config
    });
};

export const getOwnPersonList = (config?: AxiosRequestConfig) => {
    return request<PersonListData>({
        url   : '/api/main/get-own-person-list/',
        ...config
    });
};

export const getPersonListByTmplFile = (config?: AxiosRequestConfig) => {
    return request<PersonListData>({
        url   : '/api/main/get-person-list-by-tmp-file/',
        ...config
    });
};

export const updatePerson = (config?: AxiosRequestConfig) => {
    return request<PersonData>({
        url   : '/api/main/person/edit/',
        method: 'post',
        ...config
    });
};

export const mergePerson = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/main/person/merge/',
        method: 'post',
        ...config
    });
};

export const getPerson = (config?: AxiosRequestConfig) => {
    return request<PersonData>({
        url: '/api/main/get-person/',
        ...config
    });
};

export const getClubs = (config?: AxiosRequestConfig) => {
    return request<ClubsData>({
        url   : '/api/main/get-club-list/',
        ...config
    });
};

export const getEvents = (config?: AxiosRequestConfig) => {
    return request<EventsData>({
        url   : '/api/main/get-simple-event-list/',
        ...config
    });
};

export const getLocations = (config?: AxiosRequestConfig) => {
    return request<LocationData>({
        url   : '/api/main/get-location-list/',
        ...config
    });
};

export const smartSearchEvents = (config?: AxiosRequestConfig) => {
    return request<SearchData>({
        url: '/api/main/smart-search/events/',
        ...config
    });
};

export const getEventWithPhotoCountList = (config?: AxiosRequestConfig) => {
    return request<EventWithPhotoCountListData>({
        url: '/api/main/get-event-with-photo-count-list/',
        ...config
    });
};

export const getSimpleEventList = (config?: AxiosRequestConfig) => {
    return request<EventWithPhotoCountListData>({
        url: '/api/main/get-simple-event-list/',
        ...config
    });
};

export const getEventInfo = (config?: AxiosRequestConfig) => {
    return request<EventInfoData>({
        url: '/api/main/get-event-info/',
        ...config
    });
};

export const getEventsYears = (config?: AxiosRequestConfig) => {
    return request<YearsData>({
        url: '/api/main/get-events-years/',
        ...config
    });
};

export const getDanceProgramList = (config?: AxiosRequestConfig) => {
    return request<DataDancePrograms>({
        url: '/api/main/get-dance-program-list/',
        ...config
    });
};

export const getDanceGradeLatinList = (config?: AxiosRequestConfig) => {
    return request<DataDanceGradesLatin>({
        url: '/api/main/dance-grade/latin/list/',
        ...config
    });
};

export const getDanceGradeStandardList = (config?: AxiosRequestConfig) => {
    return request<DataDanceGradesStandard>({
        url: '/api/main/dance-grade/standard/list/',
        ...config
    });
};

export const personMarkAreDifferent = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/main/person/mark_are_different/',
        method: 'post',
        ...config
    });
};
