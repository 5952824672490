import React, { FC, MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { IProps } from 'component/form/input-time-range/types';
import InputTime from 'component/form/input-time';

import style from './styles.pcss';
import { useClassnames } from 'hook/use-classnames';
import { TError } from 'component/form/input-range/types';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import i18n from 'component/core/i18n';
import Error from 'component/error';

const stringToDate = (value: string): Date => {
    const t = new Date();
    const [h, m] = value.split(':');

    t.setHours(Number(h), Number(m), 0, 0);

    return t;
};

const InputTimeRange: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const [isValid, setIsValid] = useState<boolean>(false);
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [timeFrom, setTimeFrom] = useState<Date | null>(
        props.timeFrom
            ? typeof props.timeFrom === 'string'
                ? stringToDate(props.timeFrom)
                : props.timeFrom
            : null
    );
    const [timeTo, setTimeTo] = useState<Date | null>(
        props.timeTo
            ? typeof props.timeTo === 'string'
                ? stringToDate(props.timeTo)
                : props.timeTo
            : null
    );

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [timeFrom, timeTo, isValid]);

    useEffect(() => () => {
        props.registry.remove(props.name);
    }, []);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            clear   : () => {
                setTimeFrom(null);
                setTimeTo(null);
            },
            value: {
                valueFrom: timeFrom ? moment(timeFrom).format('HH:mm:ss') : null,
                valueTo  : timeTo ? moment(timeTo).format('HH:mm:ss') : null
            },
            isValid: checkValidity(),
            isAutoFill: false
        });
    }, [timeFrom, timeTo]);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if (props.required) {
            newIsValid = !!timeFrom || !!timeTo;

            if (!newIsValid) {
                newErrorInternal = i18n.t('components.form.range_input.empty');
            }
        }

        if (timeFrom && timeTo) {
            if (timeTo?.getTime() < timeFrom?.getTime()) {
                newIsValid = false;
                newErrorInternal = 'Не правльно указан интервал времени';
            }
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    const elLabel = () => {
        if (props.label) {
            return (
                <strong
                    className={cn('time-range__label', {
                        'time-range__label-required': props.required
                    })}
                >
                    {props.label}
                </strong>
            );
        }
    };

    const elError = useMemo((): ReactNode => {
        if(errorInternal || errorExternal) {
            return (
                <Error elIcon={true} className={cn('time-range__error')}>
                    {errorInternal || errorExternal}
                </Error>
            );
        }
    }, [errorExternal, errorInternal]);

    const onClickPlus = (value: Date | null, setValue: (value: Date | null) => void) => (e: MouseEvent) => {
        e.preventDefault();

        const t = new Date();

        t.setHours(10, 0, 0, 0);

        if (value) {
            t.setHours(value.getHours(), value.getMinutes() + 1, 0, 0);
        }

        setValue(t);
    };

    const onClickMines = (value: Date | null, setValue: (value: Date | null) => void) => (e: MouseEvent) => {
        e.preventDefault();

        const t = new Date();

        t.setHours(10, 0, 0, 0);

        if (value) {
            t.setHours(value.getHours(), value.getMinutes() - 1, 0, 0);
        }

        setValue(t);
    };

    const checkValue = (value: TimePickerValue): Date => {
        if (typeof value === 'string') {
            return stringToDate(value);
        }

        return value;
    };

    return (
        <div className={cn('time-range')}>
            {elLabel()}
            <div className={cn('time-range__wrapper')}>
                <div className={cn('time-range__field')}>
                    <button className={cn('time-range__field-btn')}>
                        <FontAwesomeIcon icon={faMinusCircle} onClick={onClickMines(timeFrom, setTimeFrom)} />
                    </button>
                    <TimePicker
                        className={cn('time-range__field-input', {'time-range__field-input-invalid': !isValid})}
                        onChange={(value: TimePickerValue) => setTimeFrom(checkValue(value))}
                        value={timeFrom ? timeFrom : ''}
                        disableClock={true}
                        format={'HH:mm'}
                        clearIcon={null}
                        locale={'ru-RU'}
                    />
                    <button className={cn('time-range__field-btn')}>
                        <FontAwesomeIcon icon={faPlusCircle} onClick={onClickPlus(timeFrom, setTimeFrom)} />
                    </button>
                </div>
                <span className={cn('time-range__separator')}>—</span>
                <div className={cn('time-range__field')}>
                    <button className={cn('time-range__field-btn')}>
                        <FontAwesomeIcon icon={faMinusCircle} onClick={onClickMines(timeTo, setTimeTo)} />
                    </button>
                    <TimePicker
                        className={cn('time-range__field-input', {'time-range__field-input-invalid': !isValid})}
                        onChange={(value: TimePickerValue) => setTimeTo(checkValue(value))}
                        value={timeTo ? timeTo : ''}
                        disableClock={true}
                        format={'HH:mm'}
                        clearIcon={null}
                        locale={'ru-RU'}
                    />
                    <button className={cn('time-range__field-btn')}>
                        <FontAwesomeIcon icon={faPlusCircle} onClick={onClickPlus(timeTo, setTimeTo)} />
                    </button>
                </div>
            </div>
            {elError}
        </div>
    );
};

export default InputTimeRange;
