import React, { PureComponent, ReactNode } from 'react';
import * as classnames from 'classnames/bind';

import qaAttributes from 'component/helper/qa-attributes';

import { IProps } from './types/page-header';
import style from './page-header.pcss';

class PageHeader extends PureComponent<IProps> {

    cn = classnames.bind(style);

    render(): ReactNode {
        return (
            <div className={this.cn('page-header-wrapper', this.props.className)} {...qaAttributes(this.props['data-qa'] || 'ui-main')}>
                <h1 className={this.cn('page-header')}>{this.props.text}</h1>
                {this.props.children}
            </div>
        );
    }

}

export default PageHeader;
