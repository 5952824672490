import React, { PureComponent, ReactNode } from 'react';
import * as classnames from 'classnames/bind';
import qaAttributes from 'component/helper/qa-attributes';

import { IProps } from './types/content';
import style from './content.pcss';

class Content extends PureComponent<IProps> {

    cn = classnames.bind(style);

    render(): ReactNode {
        return <div className={this.cn('content', this.props.className)} {...qaAttributes(this.props['data-qa'] || 'ui-content')}>{this.props.children}</div>;
    }

}

export default Content;
