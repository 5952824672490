import React, { useState } from 'react';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';

import style from './index.pcss';
import Collapse from '@kunukn/react-collapse';

// @ts-ignore
import img1 from './img/faq1.png';
// @ts-ignore
import img2 from './img/faq2.png';

export type CollapseState = {
    index: number | null;
};

const FAQ = () => {
    const cn = useClassnames(style);

    const [collapseState, setCollapseState] = useState<CollapseState>({
        index: 0
    });

    const onToggle = (index: number) => setCollapseState((state) => ({ index: state.index === index ? null : index }));

    // @ts-ignore
    return (
        <UI.Main className={cn('faq')}>
            <h1 className={cn('faq__header')}>FAQ</h1>
            <div className={cn('faq__content')}>
                <h2 className={cn('faq__block-header')}>Часто задаваемые вопросы</h2>
                <div className={cn('faq__content-item')}>
                    <button
                        className={cn('faq__btn')}
                        onClick={() => onToggle(1)}
                    >
                        1. Как зарегистрироваться на faceport.ru
                    </button>
                    <Collapse className={cn('faq__collapse')} isOpen={collapseState.index === 1}>
                        <div className={cn('faq__content-video')}>
                            <iframe
                                width="720"
                                height="405"
                                src="https://rutube.ru/play/embed/939e0568d7023297b176fc733ef18b15"
                                frameBorder="0"
                                allow="clipboard-write;autoplay"
                                allowFullScreen={true}
                            />
                        </div>
                    </Collapse>
                </div>
                <div className={cn('faq__content-item')}>
                    <button
                        className={cn('faq__btn')}
                        onClick={() => onToggle(2)}
                    >
                        2. Как найти человека по фото
                    </button>
                    <Collapse className={cn('faq__collapse')} isOpen={collapseState.index === 2}>
                        <div className={cn('faq__content-video')}>
                            <iframe
                                width="720"
                                height="405"
                                src="https://rutube.ru/play/embed/d6547f819a050d94c9760f7d6f8d8d5b"
                                frameBorder="0"
                                allow="clipboard-write;autoplay"
                                allowFullScreen={true}
                            />
                        </div>
                    </Collapse>
                </div>
                <div className={cn('faq__content-item')}>
                    <button
                        className={cn('faq__btn')}
                        onClick={() => onToggle(3)}
                    >
                        3. Как найти свои фото по костюму и/или номеру пары
                    </button>
                    <Collapse className={cn('faq__collapse')} isOpen={collapseState.index === 3}>
                        <div className={cn('faq__content-video')}>
                            <iframe
                                width="720"
                                height="405"
                                src="https://rutube.ru/play/embed/031b262670133ec58d79334057dbf052"
                                frameBorder="0"
                                allow="clipboard-write;autoplay"
                                allowFullScreen={true}
                            />
                        </div>
                    </Collapse>
                </div>
                <div className={cn('faq__content-item')}>
                    <button
                        className={cn('faq__btn')}
                        onClick={() => onToggle(4)}
                    >
                        4. Как найти фото по времени съемки
                    </button>
                    <Collapse className={cn('faq__collapse')} isOpen={collapseState.index === 4}>
                        <div className={cn('faq__content-video')}>
                            <iframe
                                width="720"
                                height="405"
                                src="https://rutube.ru/play/embed/0403f8487ff880e81b24ab563a327a63"
                                frameBorder="0"
                                allow="clipboard-write;autoplay"
                                allowFullScreen={true}
                            />
                        </div>
                    </Collapse>
                </div>
                <div className={cn('faq__content-item')}>
                    <button
                        className={cn('faq__btn')}
                        onClick={() => onToggle(5)}
                    >
                        5. Как подписаться на страницу персоны
                    </button>
                    <Collapse className={cn('faq__collapse')} isOpen={collapseState.index === 5}>
                        <div className={cn('faq__content-video')}>
                            <iframe
                                width="720"
                                height="405"
                                src="https://rutube.ru/play/embed/d3dca6eb1ebd45786f557b17483f0f75"
                                frameBorder="0"
                                allow="clipboard-write;autoplay"
                                allowFullScreen={true}
                            />
                        </div>
                    </Collapse>
                </div>
                <div className={cn('faq__content-item')}>
                    <button
                        className={cn('faq__btn')}
                        onClick={() => onToggle(6)}
                    >
                        6. Как объединить персоны
                    </button>
                    <Collapse className={cn('faq__collapse')} isOpen={collapseState.index === 6}>
                        <div className={cn('faq__collapse-content')}>
                            <p>Выберите персону из списка похожих персон, которую Вы хотите объединить.</p>
                            <p className={cn('faq__p-img')}>
                                <img src={img1} alt="" className={cn('faq__img')} />
                            </p>
                            <p>Сравните персоны и подтвердите объединение или отмените.</p>
                            <p className={cn('faq__p-img')}>
                                <img src={img2} alt="" className={cn('faq__img')} />
                            </p>
                        </div>
                    </Collapse>
                </div>
            </div>
        </UI.Main>
    );
};

export default FAQ;
