import { useClassnames } from 'hook/use-classnames';
import React, { useMemo } from 'react';
import style from './style.pcss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    type?: string;
    text?: string;
    onClick(): void;
}

const CustomAlert = (props: IProps) => {
    const cn = useClassnames(style);

    const elIcon = useMemo(() => {
        return <FontAwesomeIcon icon={faExclamationCircle} />;
    }, []);

    return (
        <div className={cn('custom-alert')}>
            <div
                className={cn('alert__icon')}
            >
                {elIcon}
            </div>
            <div className={cn('alert__text')}>
                {props.text} <a href={'/premium-account'} target={'_blank'}>Подробнее</a>
            </div>
            <button
                className={cn('alert__button')}
                onClick={props.onClick}
            >
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
        </div>
    );
};

export default CustomAlert;
