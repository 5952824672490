import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useClassnames } from 'hook/use-classnames';

import Form from 'component/form';

import { IProps } from './types';
import style from './styles.pcss';
import Button from 'component/button';
import { useCancelToken } from 'component/core/cancel-token';
import InputSubscriptionTarifSelect from 'component/form/input-tarifs';
import { createAlbumPaidSubscriptionCartItem } from 'component/api/cart';
import { addCart } from 'store/reducers/cart/actions';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { IPayload } from 'component/form/types';

const TarifForm: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);

    const token = useCancelToken();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const onSubmit = useCallback((data: IPayload) => {
        const payload = {
            album_id: props.albumId,
            tarif_id: data.tarif.value
        };
    }, []);

    return (
        <div className={cn('form')}>
            <Form registry={props.registry.form} onSubmit={onSubmit} onChangeValidity={props.onChangeValidity}>
                <h2>Оплата альбома</h2>
                <InputSubscriptionTarifSelect
                    registry={props.registry.field}
                    name="tarif"
                    children={'Тариф'}
                    direction={'column'}
                    required={true}
                />
                <div className={cn('form__btn')}>
                    <Button type="submit">
                        В корзину
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default TarifForm;
