import React, { useState, useEffect, FC, AnimationEvent, ChangeEvent, KeyboardEvent, ReactNode, useMemo } from 'react';

import useClassnames from 'hook/use-classnames';
import i18n from 'component/core/i18n';
import EyeIcon from 'component/icon/eye';
import EyeIconCrossed from 'component/icon/eye-crossed';
import IconEmail from 'component/icon/email';
import IconLink from 'component/icon/link';
import IconSearch from 'component/icon/search';
import Error from 'component/error';

import { IProps, TError, IPattern } from './types';
import style from './index.pcss';
import Button from 'component/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const pattern: IPattern = {
    email   : /[-!#$%&'*+\/=?^`{}|~\w]+(\.[-!#$%&'*+\/=?^`{}|~\w]+)?@[a-z\d-]{0,61}(?:\..{2,})/i,
    password: /.+/
};

const ARROW_LEFT_KEY_CODE = 37;
const ARROW_RIGHT_KEY_CODE = 39;
const ESC_KEY_CODE = 27;
const DELETE_KEY_CODE = 8;
const TAB_KEY_CODE = 9;

const Input: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), [props.className]);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.defaultValue || '');
    const [isAutoFill, setIsAutoFill] = useState<boolean>(false);
    const [type, setType] = useState<string>(props.type || 'text');
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.defaultValue);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required && newIsValid) {
            newIsValid = !!value;

            if(!newIsValid) {
                newErrorInternal = i18n.t('components.form.input.error_default');
            }
        }

        if (props.min && value && newIsValid) {
            newIsValid = Number(value) >= props.min;

            if (!newIsValid) {
                newErrorInternal = i18n.t('components.form.input.error_min', {
                    minValue: props.min
                });
            }
        }

        if (props.max && value && newIsValid) {
            newIsValid = Number(value) <= props.max;

            if (!newIsValid) {
                newErrorInternal = i18n.t('components.form.input.error_max', {
                    maxValue: props.max
                });
            }
        }

        if(props.minLength && newIsValid) {
            newIsValid = value.length >= props.minLength;

            if(!newIsValid) {
                newErrorInternal = i18n.t('components.form.input.error_length', {
                    minLength: props.minLength
                });
            }
        }

        if(props.requiredLength && newIsValid) {
            newIsValid = value.length === props.requiredLength;

            if(!newIsValid) {
                newErrorInternal = i18n.t('components.form.input.error_required_length', {
                    requiredLength: props.firstSymbol ? props.requiredLength + 1 : props.requiredLength
                });
            }
        }

        if(props.validation && newIsValid) {
            if(props.type && pattern[props.type]) {
                newIsValid = value.search(pattern[props.type]) !== -1;

                if(!newIsValid) {
                    newErrorInternal = i18n.t('components.form.input.error', {
                        context: pattern[props.type] ? props.type : 'default'
                    });
                }
            }

            if(props.pattern && newIsValid) {
                newIsValid = value.search(props.pattern) !== -1;

                if(!newIsValid) {
                    newErrorInternal = props.patternError || 'Pattern error';
                }
            }
        }

        if (props.checkValidity) {
            const check = props.checkValidity();

            if (check && !check.isValid) {
                newIsValid = check.isValid;
                newErrorInternal = check.error;
            }
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        if(isAutoFill) {
            newIsValid = true;
            newErrorInternal = null;
        }

        if(!props.required && !value) {
            newIsValid = true;
            newErrorInternal = null;
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect((): void => {
        checkValidity();
    }, [isWatch]);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            clear   : () => {
                setValue('');
                setIsWatch(false);
                setIsAutoFill(false);
            },
            isValid: checkValidity(),
            value,
            isAutoFill
        });
    }, [value, isAutoFill, props.pattern]);

    useEffect(() => {
        return () => {
            props.registry.remove(props.name);
        };
    }, []);

    useEffect(() => {
        if(props.defaultValue) {
            setValue(props.defaultValue);
        }
    }, [props.defaultValue]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [isAutoFill, value, isValid]);

    const elLabel = (): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('input__label', {
                        'input__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    };

    const onAnimationStart = (e: AnimationEvent): void => {
        if(e.animationName === 'onInputAutoFillStart') {
            setIsAutoFill(true);
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e.target.value);

        if(errorExternal) {
            setErrorExternal(null);
        }

        if(isAutoFill) {
            setIsAutoFill(false);
        }
    };

    const onBlur = (): void => {
        if(!isWatch) {
            setIsWatch(true);
        }
    };

    const onKey = (e?: KeyboardEvent<HTMLInputElement>) => {
        if(e && props.onlyNumbers) {
            const isKeyCodes = e.keyCode !== ARROW_LEFT_KEY_CODE && e.keyCode !== TAB_KEY_CODE && e.keyCode !== ARROW_RIGHT_KEY_CODE && e.keyCode !== ESC_KEY_CODE && e.keyCode !== DELETE_KEY_CODE;

            if (isKeyCodes && e.key < '0' || isKeyCodes && e.key > '9') {
                e.preventDefault();
            }
        }

        if (props.onKeyDown && e) {
            props.onKeyDown(e);
        }
    };

    const onClickSubmitButton = () => {
        if (props.onClickSubmitButton) {
            props.onClickSubmitButton();
        }
    };

    const onClickPassword = (): void => {
        if(props.type === 'password') {
            setType(type === 'password' ? 'text' : 'password');
        }
    };

    const elIconEye = (): ReactNode => {
        if(props.type === 'password') {
            if(type !== 'password') {
                return (
                    <EyeIconCrossed
                        width={24}
                        height={24}
                        onClick={onClickPassword}
                        className={cn('input__icon', 'input__icon_right', 'input__icon_eye')}
                    />
                );
            }

            return (
                <EyeIcon
                    width={24}
                    height={24}
                    onClick={onClickPassword}
                    className={cn('input__icon', 'input__icon_right', 'input__icon_eye')}
                />
            );
        }
    };

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('input__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    const elIcon = useMemo(() => {
        switch(props.icon) {
            case 'email':
                return <IconEmail className={cn('input__icon')} />;
            case 'link':
                return <IconLink className={cn('input__icon')} />;
            case 'search':
                return <IconSearch className={cn('input__icon')} />;
        }
    }, [props.icon]);

    return (
        <label
            className={cn('input', 'input__extra-container', `input_${props.direction}`)}
        >
            <div className={cn(`input_${props.direction}-extra`)}>
                {elLabel()}
                <div className={cn('input__wrapper')}>
                    {elIcon}
                    {props.prefix && (
                        <span className={cn('input__prefix')}>{props.prefix}</span>
                    )}
                    <input
                        className={cn('input__field', {
                            'input__field_invalid' : (isWatch && !isValid) || errorExternal,
                            'input__field_icon' : props.icon,
                            'input__field_prefix' : props.prefix
                        })}
                        name={props.name}
                        autoComplete={props.autoComplete}
                        type={type}
                        disabled={props.disabled}
                        required={props.required}
                        placeholder={props.placeholder}
                        onAnimationStart={onAnimationStart}
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKey}
                        onKeyUp={onKey}
                        autoFocus={props.autoFocus}
                        onBlur={onBlur}
                        tabIndex={props.tabIndex}
                        maxLength={props.maxLength}
                        min={props.min}
                        max={props.max}
                    />
                    {props.isSubmitButton && (
                        <Button className={cn('input__submit-button')} onClick={onClickSubmitButton}>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    )}
                    {elIconEye()}
                </div>
            </div>
            {elError()}
        </label>
    );
};

Input.defaultProps = {
    direction   : 'row',
    validation  : true,
    autoComplete: 'on'
};

export default Input;
