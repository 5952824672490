import React, { useMemo, useState, useEffect, useCallback, Fragment, MouseEvent } from 'react';

import useIntersect from 'hook/use-intersect';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Loader from 'component/loader';
import api from 'src/api';
import { Event, EventsSearchFilter } from 'src/api/events/types';
import Result from 'component/search-result';

import style from './index.pcss';
import SearchForm from 'route/search/search-form';
import { useRegistry } from 'component/form';
import debounce from 'lodash.debounce';
import history from 'component/core/history';
import { parse, stringify } from 'query-string';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { normalizeObject } from 'component/helper/normalize-object';
import { PersonItem, PersonsSearchFilter } from 'src/api/persons/types';
import PersonCarousel from 'component/person-carousel';
import CarouselItem from 'component/person-carousel/item';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { Page } from 'src/api/base';
import { CreateTempFile } from 'src/api/files/types';

const EVENTS_LIMIT = 12;
const getNormalizedQuery = () => {
    return normalizeObject(parse(location.search));
};

const Search = () => {
    const cn = useClassnames(style);
    const registry = useRegistry();

    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].tablet);

    const [ isPending, setIsPending ] = useState(true);
    const [ isEventListPending, setIsEventListPending ] = useState(false);
    const [ isNextEventPage, setIsNextEventPage ] = useState(false);
    const [ isEventListMerge, setIsEventListMerge ] = useState(false);
    const [ eventList, setEventList ] = useState<Array<Event>>([]);
    const [ eventPage, setEventPage ] = useState(1);
    const [ queryParams, setQueryParams ] = useState<INormalizeObject>(getNormalizedQuery());
    const [ chosenPersonID, setChosenPersonID ] = useState<number | null>(null);
    const [ imageID, setImageID ] = useState<string | null>(null);
    const [ isPersonsPending, setIsPersonsPending ] = useState(false);
    const [ isPersonsMerge, setIsPersonsMerge ] = useState(false);
    const [ isPersonsNext, setIsPersonsNext ] = useState(false);
    const [ persons, setPersons ] = useState<Array<PersonItem>>([]);
    const [ personsPage, setPersonsPage ] = useState(1);

    const getPersonsLimit = (): number => {
        if (isMobile) {
            return 4;
        }

        if (isTablet) {
            return 6;
        }

        return 10;
    };

    useEffect(() => {
        if (isEventListPending) {
            const page: Page = {
                pageNumber: eventPage,
                pageSize: EVENTS_LIMIT
            };

            let filter: EventsSearchFilter = {
                is_empty: 0,
                ...(queryParams.location_id && { location_id: queryParams.location_id }),
                ...(queryParams.event_id && { event_id: queryParams.event_id }),
                ...(queryParams.year && { year: queryParams.year })
            };

            if (chosenPersonID !== null) {
                filter = {
                    person_id: [chosenPersonID],
                    ...filter
                };
            } else {
                filter = {
                    ...filter,
                    ...(queryParams.file_id && { file_id: queryParams.file_id }),
                    ...(queryParams.search && { search: queryParams.search })
                };
            }

            api.events.getEventsList(page, filter)
                .then((resp) => {
                    setIsNextEventPage(!Boolean(resp.data.next === null));
                    setEventList(
                        isEventListMerge
                        ? [...eventList, ...resp.data.results] : resp.data.results
                    );
                })
                .finally(() => {
                    setIsEventListPending(false);
                    setIsPending(false);
                });
        }
    }, [isEventListPending]);

    useEffect(() => {
        setEventPage(1);
        setIsEventListMerge(false);
        setIsEventListPending(true);
    }, [chosenPersonID]);

    useEffect(() => {
        setIsPending(true);
        setEventPage(1);
        setIsEventListMerge(false);
        setIsEventListPending(true);

        if (queryParams.search || queryParams.file_id) {
            setPersonsPage(1);
            setIsPersonsMerge(false);
            setIsPersonsPending(true);
        }
    }, [JSON.stringify(queryParams)]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        if (isPersonsPending) {
            const page: Page = {
                pageNumber: personsPage,
                pageSize: getPersonsLimit()
            };
            const filter: PersonsSearchFilter = {
                ...(queryParams.search && { search: queryParams.search }),
                ...(queryParams.file_id && { file_id: queryParams.file_id })
            };

            api.persons.getPersonsList(page, filter)
                .then((resp) => {
                    setPersons(
                        isPersonsMerge
                            ? [...persons, ...resp.data.results] : resp.data.results
                    );
                    setIsPersonsNext(!Boolean(resp.data.next === null));
                })
                .finally(() => {
                    setIsPersonsPending(false);
                });
        }
    }, [isPersonsPending]);

    const onFileLoaded = (file: CreateTempFile): void => {
        setImageID(file.id);
    };

    const onChangeFilterForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();
        const data = {
            ...(queryParams.search && payload.name && { search: queryParams.search }),
            ...(payload.photo && { file_id: payload.photo.id }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.event?.value && { event_id: payload.event.value }),
            ...(payload.year?.value && { year: payload.year.value })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [queryParams]), 300);

    const onSubmitFilterForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();
        const data = {
            ...(payload.photo && { file_id: payload.photo.id }),
            ...(payload.name && { search: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.event?.value && { event_id: payload.event.value }),
            ...(payload.year?.value && { year: payload.year.value })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, []), 300);

    const onClickPerson = useCallback((personID: number) => {
        if(chosenPersonID === personID) {
            setChosenPersonID(null);

            return;
        }

        setChosenPersonID(personID);
    }, [chosenPersonID]);

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting) {
            setIsEventListMerge(true);
            setEventPage((prevState) => prevState + 1);
            setIsEventListPending(true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elButtonBeforePosts = () => {
        if (eventList.length && !isEventListPending && isNextEventPage) {
            return <Loader ref={$bottomPreviousPosts} />;
        }
    };

    const onReset = () => {
        setChosenPersonID(null);
        registry.form.clearForm();
        window.scrollTo(0, 0);
    };

    const elSidebar = useMemo(() => {
        let defaultLocation = null;

        if (queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        let defaultYear = null;

        if (queryParams.year) {
            defaultYear = {
                value: queryParams.year,
                label: queryParams.year
            };
        }

        return (
            <div className={cn('search__sidebar')}>
                <SearchForm
                    onSubmit={onSubmitFilterForm}
                    registry={registry}
                    onChange={onChangeFilterForm}
                    onFileLoaded={onFileLoaded}
                    onReset={onReset}
                    defaultYear={defaultYear}
                    defaultEventID={queryParams.event_id}
                    defaultPersonName={queryParams.search}
                    defaultLocation={defaultLocation}
                />
            </div>
        );
    }, [JSON.stringify(queryParams)]);

    const elContent = useMemo(() => {
        if (!isEventListPending && eventList.length === 0) {
            return (
                <UI.Box padding={true}>
                    <UI.BoxHeader>Ничего не найдено</UI.BoxHeader>
                    <p>Попробуйте изменить условия поиска.</p>
                </UI.Box>
            );
        }

        return (
            <div className={cn('search__results')}>
                {
                    eventList.map((event) => (
                        <Result
                            key={event.id}
                            link={`/events/${event.id}`}
                            photos_count={event.photo_count}
                            place={event.location_name}
                            suit_count={event.sell_suit_count}
                            date={event.date}
                            title={event.name}
                            file_id={imageID}
                            query={stringify(getNormalizedQuery())}
                            // persons={personList?.map((person) => person.id)}
                            photo_attachments={event.photos}
                        />
                    ))
                }
                {elButtonBeforePosts()}
            </div>
        );
    }, [JSON.stringify(eventList), isEventListPending, isNextEventPage, imageID]);

    const onClickNext = useCallback(() => {
        if (isPersonsNext) {
            setPersonsPage((prevState) => prevState + 1);
            setIsPersonsMerge(true);
            setIsPersonsPending(true);
        }
    }, [isPersonsNext]);

    const elPersons = useMemo(() => {
        if (isPersonsPending && persons.length === 0) {
            return <Loader />;
        }

        if (persons.length) {
            return (
                <Fragment>
                    <UI.Box padding={true}>
                        <PersonCarousel onClickNext={onClickNext} isLoading={isPersonsPending}>
                            {
                                persons.map((item, index) => {
                                    const params = {
                                        item: {
                                            id: item.id,
                                            name: (item.first_name && item.last_name) ? item.full_name : item.id.toString(),
                                            photo_url: item.photo,
                                            is_partner_profile: item.is_partner_profile
                                        },
                                        // onClick: onClickPerson(item.id),
                                        isSelected: Boolean(item.id === chosenPersonID),
                                        isName: true
                                    };

                                    return <CarouselItem key={index} {...params} onClick={() => onClickPerson(item.id)} />;
                                })
                            }
                        </PersonCarousel>
                    </UI.Box>
                    <UI.Box padding={true}>
                        <p><b>Подпишитесь на свою персону и получайте бесплатные уведомления о новых фотографиях. Для подписки перейдите на страницу персоны.</b></p>
                    </UI.Box>
                </Fragment>
            );
        }
    }, [JSON.stringify(persons), isPersonsPending, chosenPersonID]);

    return (
        <UI.Main className={cn('search')}>
            {elSidebar}
            <div className={cn('search__content')}>
                {(queryParams.search || queryParams.file_id) && elPersons}
                {
                    isPending
                    ? <Loader />
                    : elContent
                }
            </div>
        </UI.Main>
    );
};

export default Search;
