import React, { useState, useEffect, FC, ReactNode, ChangeEvent } from 'react';

import useClassnames from 'hook/use-classnames';
import qaAttributes from 'component/helper/qa-attributes';
import i18n from 'component/core/i18n';
import Error from 'component/error';

import { IProps, TError } from './types';
import style from './index.pcss';

const InputRange: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.value_from || !!props.value_to);
    const [firstValue, setFirstValue] = useState<number | null>(props.value_from || null);
    const [secondValue, setSecondValue] = useState<number | null>(props.value_to || null);
    const [errorInternal, setErrorInternal] = useState<TError>(null);

    const checkFirstValue = (): boolean => {
        const minValue = props.minValue || 0;
        const maxValue = secondValue || props.maxValue || Infinity;

        return firstValue === null || firstValue >= minValue && firstValue <= maxValue;
    };

    const checkSecondValue = (): boolean => {
        const minValue = firstValue || props.minValue || 0;
        const maxValue = props.maxValue || Infinity;

        return secondValue === null || secondValue >= minValue && secondValue <= maxValue;
    };

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;
        const checkValues = checkFirstValue() && checkSecondValue();

        if(props.required) {
            newIsValid = !!firstValue || !!secondValue;

            if(!newIsValid) {
                newErrorInternal = i18n.t('components.form.range_input.empty');
            }
        }

        if(!checkValues) {
            newIsValid = false;

            newErrorInternal = i18n.t('components.form.range_input.incorrect');
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect(() => {
        checkValidity();
    }, [isWatch]);

    useEffect(() => () => {
        props.registry.remove(props.name);
    }, []);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            clear   : () => {
                setFirstValue(null);
                setSecondValue(null);
                setIsWatch(false);
            },
            value: {
                value_from: firstValue,
                value_to  : secondValue
            },
            isValid: checkValidity(),
            isAutoFill: false
        });
    }, [firstValue, secondValue]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [firstValue, secondValue, isValid]);

    const elLabel = (): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('range__label', {
                        'range__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    };

    const onChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
        if(name === 'first') {
            setFirstValue(e.target.value && Number(e.target.value) || null);
        } else {
            setSecondValue(e.target.value && Number(e.target.value) || null);
        }

        if(errorExternal) {
            setErrorExternal(null);
        }
    };

    const onBlur = () => {
        if(!isWatch) {
            setIsWatch(true);
        }
    };

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('range__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    return (
        <label
            className={cn('range', `range_${props.direction}`)}
        >
            {elLabel()}
            <div className={cn('range__wrapper')}>
                <label
                    className={cn('range__input')}
                >
                    <div className={cn('range__input-wrapper')}>
                        <input
                            className={cn('range__input-field', {
                                'range__input-field_invalid': isWatch && !isValid
                            })}
                            {...qaAttributes(props['data-qa'] ? `range:${props['data-qa']}:first` : 'range:first')}
                            name="range-input-first"
                            onChange={onChange('first')}
                            onBlur={onBlur}
                            value={firstValue || ''}
                            disabled={props.disabled}
                            tabIndex={props.tabIndexFirst}
                        />
                    </div>
                </label>
                <span className={cn('range__input-separator')}>—</span>
                <label
                    className={cn('range__input')}
                >
                    <div className={cn('range__input-wrapper')}>
                        <input
                            className={cn('range__input-field', {
                                'range__input-field_invalid': isWatch && !isValid
                            })}
                            {...qaAttributes(props['data-qa'] ? `range:${props['data-qa']}:second` : 'range:second')}
                            name="range-input-second"
                            onChange={onChange('second')}
                            onBlur={onBlur}
                            disabled={props.disabled}
                            value={secondValue || ''}
                            tabIndex={props.tabIndexSecond}
                        />
                    </div>
                </label>
            </div>
            {elError()}
        </label>
    );
};

InputRange.defaultProps = {
    direction   : 'row'
};

export default InputRange;
