import React, { useEffect, useState } from 'react';
import Modal from 'component/modal';
import api from 'src/api';
import { YandexTasksErrorsRetrieve } from 'src/api/tasks/types';
import Loader from 'component/loader';
import style from './index.pcss';
import { useClassnames } from 'hook/use-classnames';

interface IProps {
    id: string;
    onClickClose(): void;
}
const DownloadModalError = (props: IProps) => {
    const cn = useClassnames(style);
    const [errorsList, setErrorsList] = useState<Array<YandexTasksErrorsRetrieve>>([]);

    useEffect(() => {
        api.tasks.getYandexTasksErrors(props.id)
            .then((resp) => {
                setErrorsList(resp.data);
            })
            .catch((e) => {
                console.warn(e.message);
            });

        return setErrorsList([]);

    }, []);

    return (
        <Modal onClickClose={props.onClickClose}>
            <h2>Список ошибок</h2>
            <div className={cn('download__modal__errors')}>
                {errorsList.length ? (
                    errorsList.map((item, index) => {
                        return (
                            <div className={cn('download__modal__errors-item')} key={index}>
                                <p className={cn('download__modal__errors-item-title')}>{item.element}</p>
                                <span className={cn('download__modal__errors-item-error')}>{item.error}</span>
                            </div>
                        );
                    })
                ) : (
                    <Loader />
                )}
            </div>
        </Modal>
    );
};

export default DownloadModalError;
