import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Bell: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props} viewBox="0 0 512 512">
        <path
            d="M464.636,406.682v23.182H47.364v-23.182l46.364-46.364V221.227c0-71.864,47.059-135.15,115.909-155.55
            c0-2.318,0-4.405,0-6.723c0-12.296,4.885-24.089,13.58-32.784c8.695-8.695,20.488-13.58,32.784-13.58
            c12.296,0,24.088,4.885,32.784,13.58c8.695,8.695,13.58,20.488,13.58,32.784c0,2.318,0,4.405,0,6.723
            c68.85,20.4,115.909,83.686,115.909,155.55v139.091L464.636,406.682z M302.364,453.045c0,12.296-4.884,24.088-13.58,32.784
            c-8.695,8.696-20.488,13.58-32.784,13.58c-12.296,0-24.089-4.884-32.784-13.58c-8.695-8.695-13.58-20.488-13.58-32.784H302.364z
             M435.659,40.177l-32.918,32.918c39.641,39.177,61.895,92.495,61.895,148.132H511C511,153.305,484.109,87.932,435.659,40.177z
             M1,221.227h46.364c0-55.636,22.255-108.955,61.895-148.132L76.341,40.177C27.891,87.932,1,153.305,1,221.227z"
        />
    </svg>
);

export default Bell;
