import React, { PureComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import * as classnames from 'classnames/bind';
import qaAttributes from 'component/helper/qa-attributes';

import { IProps } from './types/box-header';
import style from './box-header.pcss';

class BoxHeader extends PureComponent<IProps> {

    cn = classnames.bind(style);

    render(): ReactNode {
        const props = {
            'className' : this.cn('box-header', this.props.className),
            'data-count': this.props.count,
            'children'  : (
                <strong
                    className={this.cn('box-header__children')}
                    {...qaAttributes(this.props['data-qa'] || 'ui-header')}
                >
                    {this.props.children}
                </strong>
            )
        };

        if(this.props.to) {
            return <Link to={this.props.to} {...props} />;
        }

        return <h6 {...props} />;
    }

}

export default BoxHeader;
