import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';
import { Data as CommonStatisticsData } from 'component/api/types/api/statistics/common/get/code-200';

export const getCommonStatistics = (config?: AxiosRequestConfig) => {
    return request<CommonStatisticsData>({
        url   : '/api/statistics/common/',
        ...config
    });
};
