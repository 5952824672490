import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import InputEvents from 'component/form/input-events/index-tsx-async';
import InputAlbums from 'component/form/input-albums';
import InputYears from 'component/form/input-years';
import Checkbox from 'component/form/checkbox';

import { IValue } from 'component/form/input-years/types';
import { IDefaultPresets, IPresetProps } from './types';
import style from './index.pcss';
import { Link } from 'react-router-dom';

const VideoPresets = (props: IPresetProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    const [year, setYear] = useState<number>(0);
    const [defaultPreset, setDefaultPreset] = useState<IDefaultPresets>({});

    const onChangeYear = (value: IValue): void => {
        if (value) {
            setYear(Number(value?.value));
        }
    };

    useEffect(() => {
        const defaultParams = localStorage.getItem('upload_params');

        if (defaultParams) {
            const params = JSON.parse(defaultParams);
            setDefaultPreset(params);
            setYear(Number(params.year.value));
        }

    }, []);

    const elTournament = useMemo(() => {
        let defaultValue;

        if (defaultPreset.event?.label && defaultPreset.event.value) {
            defaultValue = {
                label: defaultPreset.event?.label,
                value: defaultPreset.event.value
            };
        }

        return (
            <InputEvents
                registry={props.registry.field}
                name="upload_tournament"
                clearable={true}
                excludeEmpty={false}
                year={year}
                children={t('route.upload.sidebar.modal.video_event')}
                direction="column"
                disabled={!Boolean(year)}
                defaultValue={defaultValue}
                is_video={true}
            />
        );
    }, [year]);

    const elSaveParams = useMemo(() => {
        if (props.isSaveParams) {
            return (
                <Checkbox
                    className={cn('input__sidebar-price')}
                    name={'save_params'}
                    registry={props.registry.field}
                    children={'Сохранить параметры загрузки'}
                    defaultValue={!!defaultPreset.album}
                />
            );
        }
    }, [defaultPreset, props.isSaveParams]);

    let defaultYear;

    if (defaultPreset.year?.label && defaultPreset.year.value) {
        defaultYear = {
            label: defaultPreset.year?.label,
            value: defaultPreset.year.value
        };
    }

    let defaultAlbum;

    if (props.isHasAlbum) {
        defaultAlbum = {
            label: props.isHasAlbum.name,
            value: props.isHasAlbum.id.toString()
        };
    } else if (defaultPreset.album?.label && defaultPreset.album.value) {
        defaultAlbum = {
            label: defaultPreset.album?.label,
            value: defaultPreset.album.value
        };
    }

    return (
        <div className={cn('input__sidebar', props.className && `${props.className}`)}>
            <UI.BoxHeader>Параметры загрузки видео</UI.BoxHeader>
            <InputYears
                registry={props.registry.field}
                name="upload_year"
                clearable={true}
                children={t('route.upload.sidebar.modal.year')}
                direction="column"
                onChange={onChangeYear}
                defaultValue={defaultYear}
            />
            {elTournament}
            <InputAlbums
                registry={props.registry.field}
                name="upload_album"
                children="Альбом*"
                direction="column"
                defaultValue={defaultAlbum}
                is_video={true}
                disabled={!!props.isHasAlbum}
            />
            <span>Вы можете создать альбом в разделе <Link to={'/dashboard/photos'}>"Мои фотографии"</Link></span>
            {props.isLink && (
                <Checkbox
                    registry={props.registry.field}
                    name="recursive"
                    children={t('route.upload.sidebar.modal.recursive')}
                    className={cn('input__sidebar-price')}
                />
            )}
            {elSaveParams}
        </div>
    );
};

export default VideoPresets;
