import React, { useEffect, useMemo, useState, useCallback } from 'react';
import history from 'component/core/history';
import { useDispatch, useSelector } from 'react-redux';
import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import IconDelete from 'component/icon/delete';
import ArrowDown from 'component/icon/arrow-dir-down';
import Error from 'component/error';
import MiniInfo from 'component/mini-info';
import SimilarPersonList from 'component/similar-person-list';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyLocalConfig } from 'store/reducers/localConfig/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import { setSimilarPersonList } from 'store/reducers/localConfig/actions';
import { IProps } from './types';
import style from './index.pcss';
import api from 'src/api';
import SearchPartnerModal from 'route/person/search-partner/search-partner-modal';
import { Person } from 'src/api/persons/types';
import PersonControlIcons from 'route/person/control-icons';
import PersonEdit from 'component/person-edit';
import PersonMerge from 'component/person-merge';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { PhotographerRetrieve } from 'src/api/photographers/types';
import { Page } from 'src/api/base';
import { AdListData } from 'src/api/ads/types';

export default (props: IProps) => {
    const cn = useClassnames(style);
    const dispatch = useDispatch();
    const [tokenUnsubscribe, tokenEdit] = useCancelTokens(2);

    const similarPersonList = useSelector<IStore, boolean>((store) => store[keyLocalConfig].similarPersonList);
    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const [person, setPerson] = useState<Person | null>(null);
    const [photographer, setPhotographer] = useState<PhotographerRetrieve | null>(null);
    const [deletePending, setDeletePending] = useState<boolean>(false);

    const [showSearchPartner, setShowSearchPartner] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showMergeModal, setShowMergeModal] = useState<boolean>(false);
    const [isSellSuit, setIsSellSuit] = useState<boolean>(false);

    useEffect(() => {
        if (props.item.subscription_type === 'person') {
            api.persons.getPersonItem(props.item.object_id)
                .then((resp) => {
                    setPerson(resp.data);
                });

            const page: Page = {
                pageNumber: 1
            };

            const data: AdListData = {
                person_id: [props.item.object_id.toString()]
            };

            api.ads.getAdList(page, data)
                .then((resp) => {
                    setIsSellSuit(!!resp.data.count);
                });
        }

        if (props.item.subscription_type === 'user') {
            api.photographers.getPhotographerItem(props.item.object_id)
                .then((resp) => {
                    setPhotographer(resp.data);
                });
        }
    }, [props.item]);

    const onClickEditPerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/subscriptions`);

            return;
        }

        setShowEditModal(true);
    }, [isAuth]);

    const onClickMergePerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/subscriptions`);

            return;
        }

        setShowMergeModal(true);
    }, [isAuth]);

    const onClickSearchPartner = () => {
        if (!isAuth) {
            history.push(`/login?from=/subscriptions`);

            return;
        }

        setShowSearchPartner(true);
    };

    const onClickApproveSearchPartner = () => {
        if (person) {
            history.push(`/find-partner?search=${person?.first_name || ''} ${person?.last_name || ''}`);
        }
    };

    const onClickSellSuit = () => {
        if (!isAuth) {
            history.push(`/login?from=/subscriptions`);

            return;
        }

        history.push('/advertisements');
    };

    const onCloseModal = useCallback(() => {
        setShowEditModal(false);
        setShowMergeModal(false);
        setShowSearchPartner(false);
    }, []);

    const onShowHideSimilarPersonList = () => {
        dispatch(setSimilarPersonList(!similarPersonList));
    };

    const _unsubscribe = () => {
        if(!deletePending) {
            setDeletePending(true);

            api.subscriptions.deleteSubscription(props.item.id)
                .then(() => {
                    props.onRenewList && props.onRenewList();
                    setDeletePending(false);
                })
                .catch((e) => {
                    setDeletePending(false);
                    console.warn(e);
                });
        }
    };

    useEffect(() => {
        return () => {
            tokenEdit.remove();
            tokenUnsubscribe.remove();
        };
    }, []);

    const elPersonEdit = useMemo(() => {
        if (showEditModal && person) {
            return (
                <PersonEdit
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }

        if (showMergeModal && person) {
            return (
                <PersonMerge
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }
    }, [showEditModal, showMergeModal, JSON.stringify(person)]);

    const elPersonInfo = useMemo(() => {
        return (
            <div className={cn('subscription-info__tooltip')}>
                <span>
                    {person?.first_name} {person?.last_name}
                </span>
                <div className={cn('subscription-info__tooltip-table')}>
                    <div className={cn('subscription-info__tooltip-table-title')}>
                        <div>Полное имя: </div>
                        <div>Город: </div>
                        <div>Тренер: </div>
                    </div>
                    <div className={cn('subscription-info__tooltip-table-content')}>
                        <div>{person?.first_name ? person.full_name : 'не указано'}</div>
                        <div>{person?.location?.name ? person.location.name : 'не указан'}</div>
                        <div>{person?.trainer_name?.length ? person.trainer_name : 'не указан'}</div>
                    </div>
                </div>
            </div>
        );
    }, [person]);

    const elSearchPartner = useMemo(() => {
        if (showSearchPartner && props.item && userId) {
            return (
                <SearchPartnerModal
                    id={props.item.object_id}
                    onCloseModal={onCloseModal}
                    user={userId}
                />
            );
        }
    }, [showSearchPartner, JSON.stringify(props.item)]);

    const elTitle = () => {
        if(props.item.subscription_type === 'search') {
            return props.item.name.split(' ')[1] ? props.item.name : props.item.id;
        }

        if (props.item && person && props.item.subscription_type === 'person') {
            const isActive = person.is_partner_profile || isSellSuit;

            return (
                <div className={cn('subscription__person')}>
                    <MiniInfo
                        key={props.item.id}
                        className={cn('person-edit__list-item')}
                        avatar={{
                            imgSrc: person.photo,
                            linkTo: `/persons/${person.id}`
                        }}
                        titleTo={`/persons/${person.id}`}
                        descTo={`/persons/${person.id}`}
                        title={
                            person.first_name?.length &&
                            person.last_name?.length &&
                            person.full_name.split(' ')[1] ? person.full_name : person.id
                        }
                        isActive={!!isActive}
                    />
                    <ReactTooltip
                        id={`subs-edit-person-tooltip-${person?.id}`}
                        place="left"
                        effect="solid"
                        border={true}
                        borderColor="#dee1e4"
                        backgroundColor="#fff"
                        className={cn('subscription__person__sale-tooltip')}
                    >
                        Рассказать о персоне
                    </ReactTooltip>
                    <FontAwesomeIcon
                        className={cn('subscription__person__icon-default')}
                        data-tip={true}
                        data-type="light"
                        data-for={`subs-edit-person-tooltip-${person?.id}`}
                        icon={faEdit}
                        onClick={onClickEditPerson}
                    />
                    <ReactTooltip
                        id={`subs-person-info-tooltip-${person?.id}`}
                        place="left"
                        effect="solid"
                        border={true}
                        borderColor="#dee1e4"
                        backgroundColor="#fff"
                        className={cn('subscription__person__sale-tooltip')}
                    >
                        {elPersonInfo}
                    </ReactTooltip>
                    <FontAwesomeIcon
                        className={cn('subscription__person__icon-question')}
                        data-tip={true}
                        data-type="light"
                        data-for={`subs-person-info-tooltip-${person?.id}`}
                        icon={faQuestionCircle}
                    />
                </div>
            );
        }

        if(props.item && photographer && props.item.subscription_type === 'user') {
            const displayName = photographer.first_name && photographer.last_name
                ? `${photographer.first_name} ${photographer.last_name}` : photographer.first_name
                ? photographer.first_name : photographer.id;

            return (
                <MiniInfo
                    key={props.item.id}
                    className={cn('person-edit__list-item')}
                    title={displayName}
                    avatar={{
                        linkTo: `/photographer/${photographer.id}`
                    }}
                    titleTo={`/photographer/${photographer.id}`}
                    descTo={`/photographer/${photographer.id}`}
                />
            );
        }
    };

    return (
        <div className={cn('subscription')}>
            {elPersonEdit}
            {elSearchPartner}
            <div className={cn('subscription__content__container')}>
                <div className={cn('subscription__content')}>
                    <div className={cn('subscription__content-main')}>
                        {elTitle()}
                        <>
                            {person && props.item.subscription_type === 'person' && props.item && (
                                <div className={cn('subscription__search-partner')}>
                                    <PersonControlIcons
                                        person={person}
                                        onClickMergePerson={onClickMergePerson}
                                        onClickSearchPartner={onClickSearchPartner}
                                        isActive={person.is_partner_profile}
                                        isHideInfo={true}
                                    />
                                    {person.is_partner_profile && (
                                        <>
                                            <ReactTooltip
                                                id={`search-partner-${props.item.id}`}
                                                place="top"
                                                effect="solid"
                                                border={true}
                                                borderColor="#dee1e4"
                                                backgroundColor="#fff"
                                                className={cn('subscription__search-partner__tooltip-partner')}
                                            >
                                                {'Просмотреть анкету'}
                                            </ReactTooltip>
                                            <button
                                                data-tip={true}
                                                data-type="light"
                                                data-for={`search-partner-${props.item.id}`}
                                                className={
                                                    cn(
                                                        'subscription__search-partner__find_partner_btn',
                                                        'subscription__search-partner__find_partner_btn--deactivated',
                                                        {
                                                            'subscription__search-partner__find_partner_btn--activated': person.is_partner_profile
                                                        }
                                                    )}
                                                onClick={onClickApproveSearchPartner}
                                            >
                                                {'Ищу партнера'}
                                            </button>
                                        </>
                                    )}
                                    {isSellSuit && (
                                        <>
                                            <ReactTooltip
                                                id={`sell-suit-${props.item.id}`}
                                                place="top"
                                                effect="solid"
                                                border={true}
                                                borderColor="#dee1e4"
                                                backgroundColor="#fff"
                                                className={cn('subscription__search-partner__tooltip')}
                                            >
                                                {'Эта персона продает костюм, представленный на фотографиях события'}
                                            </ReactTooltip>
                                            <button
                                                data-tip={true}
                                                data-type="light"
                                                data-for={`sell-suit-${props.item.id}`}
                                                className={
                                                    cn(
                                                        'subscription__search-partner__find_partner_btn',
                                                        'subscription__search-partner__find_partner_btn--deactivated',
                                                        'subscription__search-partner__find_partner_btn--activated'
                                                    )}
                                                onClick={onClickSellSuit}
                                            >
                                                {'Продаю костюм'}
                                            </button>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    </div>
                    <div className={cn('subscription__menu')}>
                        <div
                            onClick={_unsubscribe}
                            className={cn('subscription__menu-item', 'subscription__menu-item_red', {
                                'subscription__menu-item_disabled': deletePending
                            })}
                            title="Удалить"
                        >
                            <IconDelete />
                        </div>
                        {(props.item.similar_person && props.item.similar_person.length) ? (
                            <div
                                onClick={onShowHideSimilarPersonList}
                                className={cn('subscription__menu-item', 'subscription__menu-item_arrow', {
                                    'subscription__menu-item_rotate': !similarPersonList
                                })}
                                title="Показать похожих"
                            >
                                <ArrowDown />
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
            {similarPersonList && person && (
                <SimilarPersonList
                    person={{
                        id: person && person.id,
                        first_name: person?.first_name?.length && person.first_name || undefined,
                        last_name: person?.last_name?.length && person?.last_name || undefined,
                        photo_url: person?.photo && person.photo || undefined
                    }}
                    similar_persons={
                        props.item.similar_person || []
                    }
                />
            )}
        </div>
    );
// tslint:disable-next-line:max-file-line-count
};
