import React, { FC } from 'react';
import Carousel, { RenderArrowProps } from 'react-elastic-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { useClassnames } from 'hook/use-classnames';

import { IProps } from './types';
import style from './index.pcss';
import Loader from 'component/loader';

const PersonCarousel: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].tablet);

    const getItemCount = (): number => {
        if (isMobile) {
            return 2;
        }

        if (isTablet) {
            return 3;
        }

        return 5;
    };

    const onClickNext = (callback: () => void) => () => {
        callback();

        if (props.onClickNext) {
            props.onClickNext();
        }
    };

    const onClickPrev = (callback: () => void) => () => {
        callback();

        if (props.onClickPrev) {
            props.onClickPrev();
        }
    };

    const elNavBtn = ({ type, onClick, isEdge }: RenderArrowProps) => {
        const pointer = type === 'PREV'
            ? <FontAwesomeIcon icon={faChevronCircleLeft} />
            : <FontAwesomeIcon icon={faChevronCircleRight} />;
        const callback = type === 'PREV'
            ? onClickPrev(onClick)
            : onClickNext(onClick);

        const isLoading = props.isLoading && type !== 'PREV';

        return (
            <div
                className={cn('carousel__btn', {
                    'carousel__btn-disable': isEdge || isLoading
                })}
            >
                {isLoading ? <Loader text=" " className={cn('carousel__btn__loader')} /> : (
                    <button
                        onClick={callback}
                        disabled={isEdge || isLoading}
                    >
                        {pointer}
                    </button>
                )}
            </div>
        );
    };

    const params = {
        isRTL: false,
        itemsToShow: getItemCount(),
        itemsToScroll: getItemCount(),
        pagination: false,
        enableMouseSwipe: false,
        renderArrow: elNavBtn,
        className: 'carousel__body'
    };

    return (
        <Carousel
            {...params}
        >
            {props.children}
        </Carousel>
    );
};

export default PersonCarousel;
