import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAlert } from 'component/alert/provider';
import { IStore } from 'store/reducers/types/reducers';
import { addCart, clearCart } from 'store/reducers/cart/actions';
import { key as cartKey } from 'store/reducers/cart/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import Main from 'component/ui/main';
import Loader from 'component/loader';
import Button from 'component/button';
import CartItem from 'component/cart/cartItem';
import api from 'src/api';
import style from './index.pcss';
import { CartItem as StoreCartItem } from 'src/api/cart/types';
// @ts-ignore
import offer from './docs/offer.pdf';
import qs from 'query-string';
import { CartItemsStore } from 'src/store/reducers/cart/types/reducer';
import Modal from 'component/modal';

const Cart = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { show, hide } = useAlert();

    const items = useSelector<IStore, Array<StoreCartItem> | undefined>((store) => store[cartKey].items);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [pending, setPending] = useState<boolean>(false);
    const [isOrderCreated, setIsOrderCreated] = useState<boolean>(false);
    const [orderLink, setOrderLink] = useState<string>('');

    const [cartItemsListPage, setCartItemsListPage] = useState<number>(1);
    const [isCartItemsListLoading, setIsCartItemsListLoading] = useState<boolean>(false);
    const [isCartItemsListLoadMore, setIsCartItemsListLoadMore] = useState<boolean>(false);
    const [cartItemsList, setCartItemsList] = useState<Array<StoreCartItem>>([]);

    useEffect(() => {
        if (isCartItemsListLoading || isCartItemsListLoadMore) {
            api.cart.getCartItemsList({
                pageNumber: cartItemsListPage
            })
            .then((resp) => {
                const filteredList: CartItemsStore = {
                    count: 0,
                    items: []
                };

                setCartItemsList((prev) => isCartItemsListLoadMore ?
                    [...prev, ...resp.data.results] : resp.data.results);
                if (!resp.data.next) {
                    const newList = isCartItemsListLoadMore ? [...cartItemsList, ...resp.data.results].map((item) => filteredList.items.push(item))
                        : resp.data.results.map((item) => filteredList.items.push(item));
                    filteredList.count = resp.data.count;
                    dispatch(addCart(filteredList));
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);
                } else {
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);

                    setCartItemsListPage((prev) => prev + 1);
                    setIsCartItemsListLoadMore(true);
                }
            });
        }
    }, [isCartItemsListLoading, isCartItemsListLoadMore]);

    const price = items?.reduce((acc, item) => acc + Number(item.price), 0);

    if (!isAuth) {
        history.push('/login?from=/cart');
    }

    const priceWithDiscount = () => {
        return items?.reduce((acc, item) => acc + Number(item.price) * (100 - item.discount) / 100, 0);
    };

    const _requestDestroyCartItem = (id: number) => {
        api.cart.destroyCartItem(id)
            .then(() => {
                setCartItemsListPage(1);
                setIsCartItemsListLoading(true);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const onClickRemove = useCallback((id: number) => (): void => {
        _requestDestroyCartItem(id);
    }, []);

    const onClickCreateOrder = () => {
        const orderPrice = priceWithDiscount();
        if (price && orderPrice) {
            setPending(true);

            api.order.createOrder(orderPrice.toFixed(2).toString())
              .then((resp) => {
                    setIsOrderCreated(true);

                    const params = {
                        MNT_SUCCESS_URL: `${location.origin}/history/${resp.data.order_id}/success`,
                        MNT_FAIL_URL: `${location.origin}/cart`
                    };

                    setOrderLink(`${resp.data.url}&${qs.stringify(params)}`);

                    setTimeout(() => window.location.href = `${resp.data.url}&${qs.stringify(params)}`, 1000);
                    // window.location.href = `${resp.data.url}&${qs.stringify(params)}`;
                })
              .catch((err) => {
                    console.error(err);
                    show('Что-то пошло не так', 'danger');
                    setTimeout(() => { hide(); }, 5000);
                })
              .finally(() => {
                    setPending(false);
                });
        }
    };

    const elMessage = useMemo(() => {
        if (items?.length) {
            return (
                <div className={cn('cart__message')}>
                    <p>
                        Заказы в корзине хранятся в течение <b>14 дней</b> с момента добавления первой фотографии.
                        По истечению данного срока корзина автоматически очищается.
                        Пожалуйста, завершите свой заказ в течение данного срока.
                    </p>
                </div>
            );
        }
    }, [JSON.stringify(items)]);

    const elContent = useMemo(() => {
        if (items && items.length) {
            return (
                <div className={cn('cart__list')}>
                    {items.map((item) => {
                        return <CartItem key={item.photo.id} item={item} onClickRemove={onClickRemove} />;
                    })}
                </div>
            );
        }

        return (
            <div className={cn('cart__empty')}>{t('route.cart.content.empty')}</div>
        );
    }, [JSON.stringify(items)]);

    const elLoader = useMemo(() => {
        if (pending) {
            return <Loader className={cn('cart__loading')} />;
        }
    }, [pending]);

    const elSidebar = useMemo(() => {
        const orderPrice = priceWithDiscount();

        return (
        <div className={cn('cart__sidebar')}>
            <h2 className={cn('cart__block-header')}>
                {t('route.cart.sidebar.header')}
            </h2>
            <div className={cn('cart__sidebar-items')}>
                <div className={cn('cart__sidebar-item', 'cart__sidebar-item_border')}>
                    <span className={cn('cart__sidebar-text')}>
                        {t('route.cart.sidebar.photo')}
                    </span>
                    <div className={cn('cart__sidebar-text')}>
                        {items?.length}
                    </div>
                </div>
                <div className={cn('cart__sidebar-item')}>
                    <span className={cn('cart__sidebar-text')}>
                        {t('route.cart.sidebar.sum')}
                    </span>
                    {orderPrice === price ? (
                        <div className={cn('cart__sidebar-text')}>
                            {t('route.cart.sidebar.sum-count', { count: price ? price : 0 })}
                        </div>
                    ) : (
                        <div>
                            <div className={cn('cart__sidebar-price-old')}>{price}</div>
                            <div className={cn('cart__sidebar-price-new')}>{orderPrice?.toFixed(2)}</div>
                        </div>
                    )}
                </div>
                <Button
                    type="button"
                    className={cn('cart__button')}
                    onClick={onClickCreateOrder}
                    disabled={Boolean(pending || !items)}
                    isLoading={pending}
                >
                    {t('route.cart.sidebar.button')}
                </Button>
                <p className={cn('cart__description')}>
                    {t('route.cart.sidebar.description-1')}
                    <a
                        href={offer}
                        target="_blank"
                        className={cn('cart__link')}
                    >
                        {t('route.cart.sidebar.offer')}
                    </a>
                    {t('route.cart.sidebar.description-2')}
                </p>
            </div>
        </div>
        );
    }, [JSON.stringify(items)]);

    const elModal = useMemo(() => {
        if (isOrderCreated) {
            return (
                <Modal>
                    <p className={cn('cart__modal__text')}>
                        Ваш заказ успешно создан! <br />
                        Скоро Вас перенаправим на платежный сервис. Если это это не произошло то перейдите по <a href={orderLink}>ссылке</a>
                    </p>
                </Modal>
            );
        }
    }, [isOrderCreated, orderLink]);

    return (
        <Main className={cn('cart')}>
        <h1 className={cn('cart__header')}>{t('route.cart.header')}</h1>
        {elMessage}
        {elModal}
        <div className={cn('cart__grid')}>
            <div className={cn('cart__content')}>
                <h2 className={cn('cart__block-header')}>{t('route.cart.content.header')}</h2>
                {elContent}
                {elLoader}
            </div>
            {elSidebar}
        </div>
        </Main>
    );
};

export default Cart;
