import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { PhotographerRetrieve, PhotographersAPIType } from './types';

const getPhotographerItem = (id: number): Promise<AxiosResponse<PhotographerRetrieve>> => {
    return httpClient.get<PhotographerRetrieve>(`/api/v2/photographers/${id}`);
};

export default {
    getPhotographerItem
} as PhotographersAPIType;
