import React, { useCallback, useEffect, useState } from 'react';
import style from './index.pcss';
import history from 'component/core/history';
import UI from 'component/ui';
import ADS from 'component/advertisements';
import { useClassnames } from 'hook/use-classnames';
import { useRegistry } from 'component/form';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import api from 'src/api';
import { Page } from 'src/api/base';
import { AdItem } from 'src/api/ads/types';
import Pagination from 'component/pagination';
import Loader from 'component/loader';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { getNormalizedQuery } from 'component/advertisements/utils';
import debounce from 'lodash.debounce';
import { stringify } from 'query-string';
import { GetAccountAdsListData } from 'src/api/accounts/types';

const PAGE_LIMIT = 10;
const MIN_HEIGHT = 100;
const MAX_HEIGHT = 205;
const Advertisements = () => {
    const cn = useClassnames(style);
    const registry = useRegistry();
    const userId = useSelector<IStore>((storeApp) => storeApp[keyUser].id);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    const [adList, setAdList] = useState<Array<AdItem>>([]);
    const [adTotal, setAdTotal] = useState<number>(0);
    const [currentListPage, setCurrentListPage] = useState<number>(1);
    const [isPendingAdList, setIsPendingAdList] = useState<boolean>(false);
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isPendingAdList) {
            const data: GetAccountAdsListData = {
                ...(queryParams.color && { color_id: queryParams.color }),
                ...(queryParams.event && { event_id: queryParams.event }),
                ...(queryParams.height && { height: queryParams.height  }),
                ...(queryParams.suit_size && { suit_size: queryParams.suit_size  }),
                ...(queryParams.gender && { gender: queryParams.gender  })
            };

            const page: Page = {
                pageSize: PAGE_LIMIT,
                pageNumber: currentListPage
            };

            api.ads.getAdList(page, data)
                .then((resp) => {
                    setAdTotal(resp.data.count);
                    setAdList(resp.data.results);

                    setIsPendingAdList(false);
                    setIsFirstLoading(false);
                })
                .catch(() => {
                    setIsPendingAdList(false);
                    setIsFirstLoading(false);
                });
        }
    }, [isPendingAdList]);

    useEffect(() => {
        setCurrentListPage(1);
        setIsPendingAdList(true);
    }, [queryParams]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    const onReset = () => {
        registry.form.clearForm();
        window.scrollTo(0, 0);
    };

    const onPageChanged = (page: number) => {
        if (!isPendingAdList) {
            setCurrentListPage(page);
            setIsPendingAdList(true);
        }
    };

    const onChangeForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();

        if (payload.height && (payload.height < MIN_HEIGHT || payload.height > MAX_HEIGHT)) {
            return;
        }

        const data = {
            ...(payload.color && { color: payload.color }),
            ...(payload.event && { event: payload.event.value }),
            ...(payload.height && { height: payload.height  }),
            ...(payload.suit_size && { suit_size: payload.suit_size.value  }),
            ...(payload.gender && { gender: payload.gender.value  })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [JSON.stringify(registry.form.getPayload())]), 300);

    const updateList = () => {
        setCurrentListPage(1);

        const data: GetAccountAdsListData = {
            ...(queryParams.color && { color_id: queryParams.color }),
            ...(queryParams.event && { event_id: queryParams.event }),
            ...(queryParams.height && { height: queryParams.height  }),
            ...(queryParams.suit_size && { suit_size: queryParams.suit_size  }),
            ...(queryParams.gender && { gender: queryParams.gender  })
        };

        const page: Page = {
            pageSize: PAGE_LIMIT,
            pageNumber: 1
        };

        api.ads.getAdList(page, data)
            .then((resp) => {
                setAdTotal(resp.data.count);
                setAdList(resp.data.results);
            });
    };

    return (
        <UI.Main className={cn('advertisement')}>
            <UI.PageHeader text="Продажа костюмов" />
            <UI.Content className={cn('advertisement__content')}>
                <div className={cn('advertisement__content__cards')}>
                    {adList.map((item) => {
                        const isOwner = userId === item.user.id;

                        return (
                            <ADS.Card
                                key={item.id}
                                className={cn('advertisement__content__cards-item')}
                                item={item}
                                isOwner={isOwner}
                                updateList={updateList}
                            />
                        );
                    })}
                    {!adList.length && !isFirstLoading && (
                        <UI.Content>
                            {queryParams.color || queryParams.event || queryParams.search ? 'Ничего не найдено...' : 'Список объявлений пуст...'}
                        </UI.Content>
                    )}
                    {(isPendingAdList || isFirstLoading) && (
                        <UI.Content>
                            <Loader />
                        </UI.Content>
                    )}
                    <Pagination
                        pageLimit={PAGE_LIMIT}
                        totalRecords={adTotal}
                        currentPage={currentListPage}
                        onPageChanged={onPageChanged}
                    />
                </div>
                <UI.Sidebar className={cn('advertisement__content__sidebar')}>
                    <ADS.Form
                        registry={registry}
                        onChange={onChangeForm}
                        onReset={onReset}
                    />
                </UI.Sidebar>
            </UI.Content>
        </UI.Main>
    );
};

export default Advertisements;
