import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';

import { Data as CreateOrderData } from 'component/api/types/api/payment/order/create/post/code-200';
import { Data as PlaceOrderData } from 'component/api/types/api/payment/order/place-order/post/code-200';
import { Data as InitPaymentData } from 'component/api/types/api/payment/order/init-payment/post/code-200';
import { Data as OrderHistoryData } from 'component/api/types/api/payment/order/history/get/code-200';
import { Data as OrderInfoData } from 'component/api/types/api/payment/order-info/get/code-200';
import { Data as DataRevenue } from 'component/api/types/api/payment/get-user-revenue/get/code-200';
import { Data as DataSoldPhotos } from 'component/api/types/api/payment/get-sold-photo/count/get/code-200';
import { Data as DataTopSells } from 'component/api/types/api/payment/get-top-sells/get/code-200';
import { Data as DataSells } from 'component/api/types/api/payment/get-sells-history/get/code-200';
import { Data as PlaceWithdrawalData } from 'component/api/types/api/payment/withdrawal/place-withdrawal/post/code-200';
import { Data as WithdrawalsDataWithoutRejected } from 'component/api/types/api/payment/withdrawal/without-rejected/get/code-200';
import { Data as CreatePhotoSaleData } from 'component/api/types/api/payment/sale/create/post/code-200';
import { Data as UpdatePhotoSaleData } from 'component/api/types/api/payment/sale/update/post/code-200';
import { Data as GetPhotoSaleByAlbumIdData } from 'component/api/types/api/payment/sale/get-photo-sales-by-album-id/get/code-200';
import { Data as GetPhotoSaleByPhotoIdData } from 'component/api/types/api/payment/sale/get-photo-sales-by-photo-id/get/code-200';
import { Data as GetLatestUserSalesData } from 'component/api/types/api/payment/get-latest-user-sales/get/code-200';

export const createOrder = (config?: AxiosRequestConfig) => {
    return request<CreateOrderData>({
        url   : '/api/payment/order/create/',
        method: 'post',
        ...config
    });
};

export const placeOrder = (config?: AxiosRequestConfig) => {
    return request<PlaceOrderData>({
        url   : '/api/payment/order/place-order/',
        method: 'post',
        ...config
    });
};

export const initPayment = (config?: AxiosRequestConfig) => {
    return request<InitPaymentData>({
        url   : '/api/payment/order/init-payment/',
        method: 'post',
        ...config
    });
};

export const getOrderHistory = (config?: AxiosRequestConfig) => {
    return request<OrderHistoryData>({
        url: '/api/payment/order/history/',
        ...config
    });
};
export const getUserRevenue = (config?: AxiosRequestConfig) => {
    return request<DataRevenue>({
        url: '/api/payment/get-user-revenue/',
        ...config
    });
};
export const getSoldPhotos = (config?: AxiosRequestConfig) => {
    return request<DataSoldPhotos>({
        url: '/api/payment/get-sold-photo/count/',
        ...config
    });
};
export const getTopSells = (config?: AxiosRequestConfig) => {
    return request<DataTopSells>({
        url: '/api/payment/get-top-sells',
        ...config
    });
};

export const getSells = (config?: AxiosRequestConfig) => {
    return request<DataSells>({
        url: '/api/payment/get-sells-history',
        ...config
    });
};

export const getOrderInfo = (config?: AxiosRequestConfig) => {
    return request<OrderInfoData>({
        url: '/api/payment/order-info/',
        ...config
    });
};

export const placeWithdrawal = (config?: AxiosRequestConfig) => {
    return request<PlaceWithdrawalData>({
        url: '/api/payment/withdrawal/place-withdrawal/',
        method: 'post',
        ...config
    });
};

export const getWithdrawalsWithoutRejected = (config?: AxiosRequestConfig) => {
    return request<WithdrawalsDataWithoutRejected>({
        url: '/api/payment/withdrawal/without-rejected/',
        ...config
    });
};

export const createPhotoSale = (config?: AxiosRequestConfig) => {
    return request<CreatePhotoSaleData>({
        url: '/api/payment/sale/create/',
        method: 'post',
        ...config
    });
};

export const getPhotoSaleByAlbumId = (config?: AxiosRequestConfig) => {
    return request<GetPhotoSaleByAlbumIdData>({
        url: '/api/payment/sale/get-photo-sales-by-album-id/',
        ...config
    });
};

export const getPhotoSaleByPhotoId = (config?: AxiosRequestConfig) => {
    return request<GetPhotoSaleByPhotoIdData>({
        url: '/api/payment/sale/get-photo-sales-by-photo-id/',
        ...config
    });
};

export const getLatestUserSales = (config?: AxiosRequestConfig) => {
    return request<GetLatestUserSalesData>({
        url: '/api/payment/get-latest-user-sales/',
        ...config
    });
};

export const updatePhotoSale = (config?: AxiosRequestConfig) => {
    return request<UpdatePhotoSaleData>({
        url: '/api/payment/sale/update/',
        method: 'post',
        ...config
    });
};

export const deletePhotoSale = (config?: AxiosRequestConfig) => {
    return request({
        url: '/api/payment/sale/delete/',
        method: 'post',
        ...config
    });
};
