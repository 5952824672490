import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import {
    AlbumItem,
    AlbumListItem,
    AlbumPerson,
    AlbumPhoto,
    AlbumSimpleListItem,
    AlbumsAPIType,
    BulkUpdateAlbumPhotos,
    BulkUpdateAlbumPhotosData,
    CreateAlbum,
    CreateAlbumItem,
    GetAlbumListData,
    GetAlbumPersonData,
    GetAlbumPhotoData,
    GetAlbumSimpleListData,
    PartialUpdateAlbumData
} from './types';
import queryString from 'query-string';

const getAlbumList = (page: Page, data?: GetAlbumListData): Promise<AxiosResponse<PaginationResponse<AlbumListItem>>> => {
    return httpClient.get<PaginationResponse<AlbumListItem>>('/api/v2/albums/', {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...data
        }
    });
};

const createAlbum = (data: CreateAlbum): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.post<CreateAlbumItem>('/api/v2/albums/', data);
};

const getAlbumPersonList = (album_pk: number, page: Page, data?: GetAlbumPersonData): Promise<AxiosResponse<PaginationResponse<AlbumPerson>>> => {
    return httpClient.get<PaginationResponse<AlbumPerson>>(`/api/v2/albums/${album_pk}/persons/`, {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...data
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

const getAlbumPhotoList = (album_pk: number, page: Page, data?: GetAlbumPhotoData): Promise<AxiosResponse<PaginationResponse<AlbumPhoto>>> => {
    return httpClient.get<PaginationResponse<AlbumPhoto>>(`/api/v2/albums/${album_pk}/photos/`, {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...data
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

const getAlbumRetrieve = (id: number): Promise<AxiosResponse<AlbumItem>> => {
    return httpClient.get<AlbumItem>(`/api/v2/albums/${id}/`);
};

const updateAlbum = (id: number, data: CreateAlbum): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.put<CreateAlbumItem>(`/api/v2/albums/${id}/`, data);
};

const partialUpdateAlbum = (id: number, data?: PartialUpdateAlbumData): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.patch<CreateAlbumItem>(`/api/v2/albums/${id}/`, data);
};

const destroyAlbum = (id: number): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/albums/${id}/`);
};

const bulkUpdateAlbumPhotos = (id: number, data: BulkUpdateAlbumPhotosData): Promise<AxiosResponse<BulkUpdateAlbumPhotos>> => {
    return httpClient.put<BulkUpdateAlbumPhotos>(`/api/v2/albums/${id}/bulk-photo-update/`, data);
};

const getAlbumSimpleList = (page: Page, data?: GetAlbumSimpleListData): Promise<AxiosResponse<PaginationResponse<AlbumSimpleListItem>>> => {
    return httpClient.get<PaginationResponse<AlbumSimpleListItem>>('/api/v2/albums/simple/', {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...data
        }
    });
};

export default {
    getAlbumList,
    createAlbum,
    getAlbumPersonList,
    getAlbumPhotoList,
    getAlbumRetrieve,
    updateAlbum,
    partialUpdateAlbum,
    destroyAlbum,
    bulkUpdateAlbumPhotos,
    getAlbumSimpleList
} as AlbumsAPIType;
