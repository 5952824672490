import React, { FC, useState, ReactNode, useEffect, useCallback } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useCancelTokens } from 'component/core/cancel-token';
import useClassnames from 'hook/use-classnames';

import UI from 'component/ui';
import Loader from 'component/loader';
import PasswordSet from 'component/password-set';
import IconLodaer from 'component/icon/loader';
import IconSuccess from 'component/icon/apply';
import { IError, IPayload } from 'component/form/types';
import { set, reset } from 'store/reducers/user/actions';
import {
    checkRegitstrationConfirmToken,
    confirmRegistration, updateUserInfo,
    userSelfInfo
} from 'component/api/user';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

import Meeting from '../meeting';
import style from './index.pcss';
import { AccountRetrieve } from 'src/api/accounts/types';
import api from 'src/api';

const ConfirmRegistration: FC = () => {
    const cn = useClassnames(style);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [checkToken, confirmToken, selfInfoToken, updateToken] = useCancelTokens(4);
    const { token }: { token: string } = useParams();

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const rolesStore = useSelector<IStore, AccountRetrieve['role'] | undefined>((store) => store[keyUser].role);
    const [tokenValidity, setTokenValididty] = useState<'ALREADY_CONFIRM' | 'EXPIRED' | 'INVALID' | 'VALID' | null>(null);
    const [pending, setPending] = useState<boolean>(true);
    const [submitPending, setSubmitPending] = useState<boolean>(false);
    const [confirmSuccess, setConfirmSuccess] = useState<boolean>(false);
    const [meetingSuccess, setMeetingSuccess] = useState<boolean>(false);
    const [redirected, setRedirected] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError> | null>(null);
    const [error, setError] = useState<string | null>(null);

    const login = useCallback(() => setTimeout(() => {
        if(!userId) {
            api.accounts.getAccountRetrieve()
                .then((payload) => {
                    setPending(false);
                    dispatch(set(payload.data));
                })
                .catch((errorData) => {
                    if(!axios.isCancel(errorData)) {
                        setPending(false);
                        setError('An unexpected error has occurred. :(');

                        dispatch(reset());
                    }
                });
        } else {
            setRedirected(true);
        }
    }, 3000), [userId]);

    const requestConfirmRegistration = useCallback((password: string) => {
        setSubmitPending(true);

        confirmRegistration({
            cancelToken: confirmToken.new(),
            data       : {
                token,
                password
            }
        })
            .then(() => {
                setConfirmSuccess(true);
                setSubmitPending(false);

                login();
            })
            .catch((errorData) => {
                if(!axios.isCancel(errorData)) {
                    console.error(errorData);

                    setSubmitPending(false);
                    setErrors(errorData.errors);
                    setError(errorData.message);
                }
            });
    }, []);

    const onSubmitMeeting = useCallback((last_name: string, first_name: string, role: AccountRetrieve['role']) => {
        setSubmitPending(true);

        updateUserInfo({
            data: {
                first_name,
                last_name,
                ...(role && { role })
            },
            cancelToken: updateToken.new()
        })
            .then(() => {
                setMeetingSuccess(true);
                setSubmitPending(false);
                setRedirected(true);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    dispatch(set({ role }));

                    setSubmitPending(false);
                    setErrors(err.errors);
                    setError(err.message);
                }
            });
    }, []);

    const requestCheckToken = useCallback(() => {
        setPending(true);

        checkRegitstrationConfirmToken({
            cancelToken: checkToken.new(),
            data       : { token }
        })
            .then((payload) => {
                setPending(false);
                setTokenValididty(payload.token_status);
            })
            .catch((errorData) => {
                if(!axios.isCancel(errorData)) {
                    console.error(errorData);

                    setPending(false);
                    setTokenValididty('INVALID');
                }
            });
    }, []);

    useEffect(() => {
        requestCheckToken();

        return () => {
            selfInfoToken.remove();
            confirmToken.remove();
            checkToken.remove();
            updateToken.remove();
        };
    }, []);

    const elContent = (): ReactNode => {
        if(pending) {
            return <Loader />;
        }

        if(confirmSuccess) {
            return <Meeting onSubmit={onSubmitMeeting} pending={submitPending} />;
        }

        if(confirmSuccess && meetingSuccess) {
            return (
                <div className={cn('confirm-registration__text-block')}>
                    <IconSuccess className={cn('confirm-registration__text-block-icon', 'confirm-registration__text-block-icon_green')} />
                    <h2 className={cn('confirm-registration__text-block-title')}>{t('route.registration.confirm.success.title')}</h2>
                    <span className={cn('confirm-registration__text-block-text')}>
                        <IconLodaer />
                        {t('route.registration.confirm.success.text')}
                    </span>
                </div>
            );
        }

        return <PasswordSet pending={submitPending} error={error} errors={errors} onSubmit={requestConfirmRegistration} />;
    };

    if(tokenValidity === 'INVALID') {
        return <Redirect to="/404" />;
    }

    if(tokenValidity === 'ALREADY_CONFIRM') {
        return <Redirect to="/" />;
    }

    if(redirected && meetingSuccess && confirmSuccess && userId && rolesStore) {
        if(rolesStore.includes('PHOTOGRAPHER')) {
            return <Redirect to="/dashboard" />;
        }

        return <Redirect to="/" />;
    }

    return (
        <UI.Main className={cn('confirm-registration__ui-main')}>
            <Helmet
                title={t('helmet.title.confirm-registration')}
                meta={[{
                    name   : 'document-state',
                    content: 'static'
                }]}
            />
            <UI.Box padding={true} className={cn('confirm-registration__ui-box')}>
                {elContent()}
            </UI.Box>
        </UI.Main>
    );
};

export default ConfirmRegistration;
