import React from 'react';

import { defaultProps } from './base';
import { IProps } from './types';

const ArrowFullLeft = (props: IProps) => (
    <svg {...defaultProps} {...props} viewBox="0 0 24 24">
        <path d="M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12z" fill="#B6BCC4" />
    </svg>
);

export default ArrowFullLeft;
