import React, { useState, useEffect, useRef, useMemo, FC, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';

import { useClassnames } from 'hook/use-classnames';
import qaAttributes from 'component/helper/qa-attributes';
import FaceIcon from 'component/icon/face';
import Loader from 'component/loader';
import Error from 'component/error';
import { useCancelToken } from 'component/core/cancel-token';
import UI from 'component/ui';
import IconOk from 'component/icon/ok';

import { IProps, TError } from './types';
import style from './index.pcss';
import Button from 'component/button';
import api from 'src/api';
import { CreateTempFile } from 'src/api/files/types';

const ERROR_CLEAR_TIMER = 5000;

const InputPhoto: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const token = useCancelToken();

    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [value, setValue] = useState<CreateTempFile | null>(props.defaultValue || null);
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [errorRequest, setErrorRequest] = useState<TError>(null);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.defaultValue);
    const [firstClick, setFirstClick] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [loadingStatus, setLoadingStatus] = useState<number>(0);
    const $label = useRef<HTMLLabelElement>(null);

    const onDrop = useCallback((files): void => {
        if(errorExternal) {
            setErrorExternal(null);
        }

        if(errorRequest) {
            setErrorRequest(null);
        }

        setPending(true);

        if(files) {
            const formData = new FormData();

            formData.append('file', files[0]);

            api.files.createTempFile(formData)
                .then((payload) => {
                    setPending(false);
                    setValue(payload.data);
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);

                        setErrorRequest(err.message || t('components.form.input-photo.error-default'));
                        setPending(false);
                    }
                });
        }
    }, [value]);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop });

    const firstClickRef = useRef<boolean>(firstClick);
    const isWatchRef = useRef<boolean>(isWatch);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required) {
            newIsValid = !!value;

            if(!newIsValid) {
                newErrorInternal = t('components.form.input-photo.error');
            }
        }

        if(newIsValid && errorRequest) {
            newErrorInternal = errorRequest;
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    const onWatch = (): void => {
        if(firstClickRef.current && !isWatch) {
            const watch = document.hasFocus();

            if(watch) {
                setIsWatch(watch);
            }
        }
    };

    useEffect(() => {
        if(props.defaultValue) {
            setValue(props.defaultValue);
        }
    }, [JSON.stringify(props.defaultValue)]);

    useEffect(() => {
        checkValidity();
    }, [isWatch, errorRequest]);

    useEffect(() => {
        props.registry.set(props.name, {
            value,
            setError  : setErrorExternal,
            isAutoFill: false,
            clear     : () => {
                setValue(null);
                setIsWatch(false);
            },
            isValid: checkValidity()
        });

        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }

        if(props.onFileLoaded) {
            props.onFileLoaded(value);
        }
    }, [JSON.stringify(value)]);

    useEffect(() => {
        firstClickRef.current = firstClick;
        isWatchRef.current = isWatch;
    }, [firstClick, isWatch]);

    useEffect(() => {
        window.addEventListener('blur', onWatch);
        window.addEventListener('focus', onWatch);

        return () => {
            window.removeEventListener('blur', onWatch);
            window.removeEventListener('focus', onWatch);

            props.registry.remove(props.name);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setErrorRequest(null);
        }, ERROR_CLEAR_TIMER);

        return () => {
            clearTimeout(timer);
        };
    }, [errorRequest]);

    const onClick = (): void => {
        if(!firstClick) {
            setFirstClick(true);
        }
    };

    const onClickLabel = (): void => {
        if($label?.current) {
            $label?.current.click();
        }
    };

    const elError = useMemo((): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('input__error')}>{errorInternal || errorExternal}</Error>;
        }
    }, [isWatch, errorInternal, errorExternal]);

    const elRecommendations = () => {
        return (
            <div className={cn('input__recommendations')}>
                <UI.BoxHeader>{t('global.recommendations.title')}</UI.BoxHeader>
                <div className={cn('input__recommendations-items')}>
                    <div className={cn('input__recommendations-item')}>
                        <IconOk className={cn('input__recommendations-icon')} />
                        <span className={cn('input__recommendations-text')}>
                            {t('global.recommendations.1')}
                        </span>
                    </div>
                    <div className={cn('input__recommendations-item')}>
                        <IconOk className={cn('input__recommendations-icon')} />
                        <span className={cn('input__recommendations-text')}>
                            {t('global.recommendations.2')}
                        </span>
                    </div>
                    <div className={cn('input__recommendations-item')}>
                        <IconOk className={cn('input__recommendations-icon')} />
                        <span className={cn('input__recommendations-text')}>
                            {t('global.recommendations.3')}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const elLabel = useMemo((): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('input__label', {
                        'input__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    }, [props.children, props.required]);

    const elLoader = useMemo(() => {
        if(pending) {
            return (
                <Loader
                    className={cn('input__loader')}
                    theme="pink"
                />
            );
        }
    }, [pending]);

    // const elControls = useMemo(() => {
    //     if(!value) {
    //         return (
    //             <div className={cn('input__controls')}>
    //                 {elPlaceholder}
    //             </div>
    //         );
    //     }
    // }, [JSON.stringify(value)]);

    const elPreviewContent = useMemo(() => {
        if(!value) {
            return (
                <div
                    className={cn('input__preview-content', {
                        'input__preview-content_placeholder-invalid': (isWatch && errorInternal) || errorExternal
                    })}
                >
                    <FaceIcon className={cn('input__icon')} />
                    <ReactTooltip
                        id="input-photo-recommendation"
                        place="left"
                        effect="solid"
                        clickable={true}
                        border={true}
                        borderColor="#dee1e4"
                        backgroundColor="#fff"
                        className={cn('input__recommendations-tooltip')}
                    >
                        {elRecommendations()}
                    </ReactTooltip>
                    <Button type="button" className={cn('input__button')} onClick={onClickLabel}>
                        {t('components.form.input-photo.button')}
                        <span
                            className={cn('input__question')}
                            data-type="light"
                            data-tip={true}
                            data-for="input-photo-recommendation"
                        >
                            ?
                        </span>
                    </Button>
                </div>
            );
        }
    }, [JSON.stringify(value), isWatch, errorExternal, errorInternal]);

    return (
        <div className={cn('input')}>
            <div className={cn('input__content')}>
                {elLoader}
                {elLabel}
                <div
                    {...getRootProps({
                        className: cn('input__wrapper', {
                            'input__wrapper_active'  : isDragActive,
                            'input__wrapper_image'   : !!value,
                            'input__wrapper_disabled': props.disabled
                        })
                    })}
                >
                    {elPreviewContent}
                    <input
                        {...qaAttributes(props['data-qa'] ? `photo:${props['data-qa']}` : 'photo')}
                        {...getInputProps({
                            accept   : props.accept,
                            name     : props.name,
                            id       : props.id || props.name,
                            multiple : true,
                            type     : 'file',
                            disabled : props.disabled || pending,
                            required : props.required,
                            value    : '',
                            title    : '',
                            className: cn('input__field'),
                            onClick
                        })}
                    />
                </div>
                {elError}
            </div>
            {/*{elControls}*/}
        </div>
    );
};

InputPhoto.defaultProps = {
    accept : '.jpg,.jpeg,.png,.cr2',
    hideBtn: false,
    maxSize: 50
};

// tslint:disable-next-line max-file-line-count
export default InputPhoto;
