import React, { Component, ReactNode, MouseEvent, KeyboardEvent } from 'react';
import { createPortal } from 'react-dom';
import * as classnames from 'classnames/bind';

import IconClose from 'component/icon/close';

import { IProps } from './types';
import style from './index.pcss';

class Modal extends Component<IProps> {

    cn = classnames.bind(style);

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
        document.body.style.height = 'inherit';
    }

    onClickClose = (e?: MouseEvent): void => {
        if(this.props.onClickClose) {
            this.props.onClickClose(e);
        }
    };

    onKeyDown = (e: KeyboardEvent): void => {
        if(e && e.keyCode === 27) {
            this.onClickClose();
        }
    };

    onClickContent = (e: MouseEvent): void => {
        e?.stopPropagation();
    };

    get elButtonClose(): ReactNode {
        if(this.props.onClickClose) {
            return (
                <div className={this.cn('modal__button-close')}>
                    <IconClose
                        height={22}
                        width={22}
                        onClick={this.onClickClose}
                        className={this.cn('modal__icon-close')}
                    />
                </div>
            );
        }
    }

    get elContent(): ReactNode {
        return (
            <div className={this.cn('modal', this.props.className)} onClick={this.onClickClose} onKeyDown={this.onKeyDown}>
                <div className={this.cn('modal__content', this.props.classNameContent)} onClick={this.onClickContent}>
                    {this.elButtonClose}
                    {this.props.children}
                </div>
            </div>
        );
    }

    render(): ReactNode {
        return createPortal(this.elContent, document.body);
    }

}

export default Modal;
