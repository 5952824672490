import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';
import { Data as AdThemeList } from 'component/api/types/api/photo_ad/theme/get_theme_list/get/code-200';

export const getAdThemeList = (config?: AxiosRequestConfig) => {
    return request<AdThemeList>({
        url   : '/api/photo_ad/theme/get_theme_list/',
        ...config
    });
};
