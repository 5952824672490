import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logout } from 'component/api/user';
import { authTokenRefreshCreate } from 'src/api/auth';

const httpClient = axios.create({
    // baseURL: process.env.APP_API_URL,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    },
    maxRedirects: 10
});

const authInterceptor = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('jwt_token');
    const refreshToken = localStorage.getItem('jwt_token_refresh');

    const tokenVerification = (): boolean => {
        if (refreshToken && token) {
            const expToken = JSON.parse(atob(token.split('.')[1])).exp;

            if (new Date() >= new Date(expToken * 1000)) {
                return false;
            }

            return true;
        }

        return false;
    };

    if (token && tokenVerification()) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
};

const errorInterceptor = (error: AxiosError) => {
    if (!error.response) {
        return Promise.reject(error);
    }

    const originalRequest = error.config;

    switch (error.response.status) {
        case 401: {
            try {
                const refreshToken = localStorage.getItem('jwt_token_refresh');
                if (refreshToken && new Date() <= new Date(JSON.parse(atob(refreshToken.split('.')[1])).exp * 1000)) {
                    authTokenRefreshCreate(refreshToken)
                    .then((resp) => {
                        localStorage.setItem('jwt_token', resp.data.access);
                        localStorage.setItem('jwt_token_refresh', resp.data.refresh);
                    })
                    .then(() => {
                        return httpClient.request(originalRequest);
                    })
                    .catch((e) => {
                        localStorage.removeItem('jwt_token');
                        localStorage.removeItem('jwt_token_refresh');
                        logout();
                    });
                } else {
                    console.warn('Refresh token is not valid');
                    localStorage.removeItem('jwt_token');
                    localStorage.removeItem('jwt_token_refresh');
                    logout();
                }
            } catch (error) {
                console.warn(error);
            }
            break;
            }
        default:
            break;
    }

    return Promise.reject(error);
};

const responseInterceptor = (response: AxiosResponse) => {
    return response;
};

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
