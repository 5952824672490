import React, { useEffect, useMemo, useState } from 'react';
import UI from 'component/ui';
import { PremiumAccount } from 'src/api/premium-accounts/types';
import api from 'src/api';
import style from './index.pcss';
import { Page } from 'src/api/base';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import { useClassnames } from 'hook/use-classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PremiumAccountRetrieve } from 'src/api/accounts/types';
import moment from 'moment';
import Modal from 'component/modal';
import { useAlert } from 'component/alert/provider';
import Loader from 'component/loader';
import PremiumAccountItem from './item';

const PremiumAccount = () => {
    const cn = useClassnames(style);
    const history = useHistory();
    const { show, hide } = useAlert();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [premiumList, setPremiumList] = useState<Array<PremiumAccount>>();
    const [premiumAccount, setPremiumAccount] = useState<PremiumAccountRetrieve | null>();
    const [isOrderCreated, setIsOrderCreated] = useState<boolean>(false);
    const [orderLink, setOrderLink] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        api.accounts.getPremiumAccountRetrieve()
            .then((resp) => {
                setPremiumAccount(resp.data);
            })
            .catch(() => {
                console.error('Not premium account');
            });
    }, []);

    useEffect(() => {
        const page: Page = {
            pageNumber: 1,
            pageSize: 40
        };

        api.premium.getPremiumAccountsList(page)
            .then((resp) => {
                setPremiumList(resp.data.results);
                setIsLoading(false);
            });
    }, []);

    const onOrderCreated = (isSuccess: boolean, url?: string) => {
        if (isSuccess && url) {
            setOrderLink(url);
            setIsOrderCreated(true);
        }

        if (!isSuccess) {
            show('Что-то пошло не так', 'danger');
            setTimeout(() => { hide(); }, 5000);
        }
    };

    const elCurrentAccount = useMemo(() => {
        if (premiumAccount) {
            return (
                <div className={cn('premium__current')}>
                    <h4 className={cn('premium__current__name')}>
                        Ваш текущий premium аккаунт: <span className={cn('premium__current__name-premium')}>{premiumAccount.name}</span>
                    </h4>
                    {premiumAccount.data.map((item, index) => {
                        return (
                            <p key={`data-${index}`}>{item.name}: {item.item_data.count}</p>
                        );
                    })}
                    <p className={cn('premium__current__date')}>Истекает: {moment(premiumAccount.expired_at).format('ll')}</p>

                </div>
            );
        }
    }, [premiumAccount]);

    const elContent = useMemo(() => {
        if (isLoading) {
            return (
                <div className={cn('premium__content__loader')}>
                    <Loader />
                </div>
            );
        }

        return (
            <>
                <div className={cn('premium__content')}>
                    {premiumList ? premiumList.map((item) => {
                        if (item.rates.length && item.terms.length) {
                            return (
                                <PremiumAccountItem
                                    key={`item-${item.id}`}
                                    item={item}
                                    isPremium={!!premiumAccount}
                                    onOrderCreated={onOrderCreated}
                                />
                            );
                        }
                    }) :
                        'Ничего не найдено'
                    }
                </div>
            </>
        );
    }, [premiumList, premiumAccount, isLoading]);

    const elModal = useMemo(() => {
        if (isOrderCreated) {
            return (
                <Modal>
                    <p className={cn('premium__modal__text')}>
                        Ваш заказ успешно создан! <br />
                        Скоро Вас перенаправим на платежный сервис. Если это это не произошло то перейдите по <a href={orderLink}>ссылке</a>
                    </p>
                </Modal>
            );
        }
    }, [isOrderCreated]);

    if (!isAuth) {
        history.push('/login');
    }

    return (
        <UI.Main className={cn('premium')}>
            {elModal}
            <div className={cn('premium__header')}>
                <UI.PageHeader className={cn('premium__header-title')} text={'Premium аккаунт'} />
                <h4 className={cn('premium__header-subheader')}>Выберите свой тарифный план и получайте ежемесячно фотографии бесплатно!</h4>
            </div>
            {elCurrentAccount}
            {elContent}
        </UI.Main>
    );
};

export default PremiumAccount;
