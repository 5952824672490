import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';
import { Data as CreateData } from 'component/api/types/api/photo/album/create/post/code-200';
import { Data as GetData } from 'component/api/types/api/photo/album/get-album-list/get/code-200';
import { Data as EditData } from 'component/api/types/api/photo/album/edit/post/code-200';
import { Data as DataAlbumWithPhotoList } from 'component/api/types/api/photo/album/get-album-with-photos-list/get/code-200';
import { Data as DataAlbumCheckInviteToken } from 'component/api/types/api/photo/album/check-invite-token/post/code-200';
import { Data as DataAlbumCreateInviteToken } from 'component/api/types/api/photo/album/create-invite-token/post/code-200';
import { Data as DataUserAccessList } from 'component/api/types/api/photo/album/get-user-access-list/get/code-200';
import { Data as DataAlbumAccessRequestList } from 'component/api/types/api/photo/album/get-access-request-list/get/code-200';
import { Data as DataAvailableAlbumList } from 'component/api/types/api/photo/album/get-available-album-list/get/code-200';
import { Data as DataAvailableAlbumWithPhotosList } from 'component/api/types/api/photo/album/get-available-album-with-photos-list/get/code-200';
import { Data as DataConfirmInvite } from 'component/api/types/api/photo/album/confirm-invite/post/code-200';
import { Data as DataAlbum } from 'component/api/types/api/photo/album/get-album-by-id/get/code-200';

export const createAlbum = (config?: AxiosRequestConfig) => {
    return request<CreateData>({
        url   : '/api/photo/album/create/',
        method: 'post',
        ...config
    });
};

export const getAlbumList = (config?: AxiosRequestConfig) => {
    return request<GetData>({
        url   : '/api/photo/album/get-album-list/',
        ...config
    });
};

export const getAlbumById = (config?: AxiosRequestConfig) => {
    return request<DataAlbum>({
        url   : '/api/photo/album/get-album-by-id/',
        ...config
    });
};

export const deleteAlbum = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/photo/album/delete/',
        method: 'post',
        ...config
    });
};

export const editAlbum = (config?: AxiosRequestConfig) => {
    return request<EditData>({
        url   : '/api/photo/album/edit/',
        method: 'post',
        ...config
    });
};

export const getAlbumWithPhotoList = (config?: AxiosRequestConfig) => {
    return request<DataAlbumWithPhotoList>({
        url: '/api/photo/album/get-album-with-photos-list/',
        ...config
    });
};

export const rejectAccessRequestAlbum = (config?: AxiosRequestConfig) => {
    return request({
        url: '/api/photo/album/reject-access-request/',
        method: 'post',
        ...config
    });
};

export const acceptAccessRequestAlbum = (config?: AxiosRequestConfig) => {
    return request({
        url: '/api/photo/album/accept-access-request/',
        method: 'post',
        ...config
    });
};

export const deleteUserAccessAlbum = (config?: AxiosRequestConfig) => {
    return request({
        url: '/api/photo/album/delete-user-access/',
        method: 'post',
        ...config
    });
};

export const confirmInviteAlbum = (config?: AxiosRequestConfig) => {
    return request<DataConfirmInvite>({
        url: '/api/photo/album/confirm-invite/',
        method: 'post',
        ...config
    });
};

export const checkInviteTokenAlbum = (config?: AxiosRequestConfig) => {
    return request<DataAlbumCheckInviteToken>({
        url: '/api/photo/album/check-invite-token/',
        method: 'post',
        ...config
    });
};

export const sendInviteAlbum = (config?: AxiosRequestConfig) => {
    return request({
        url: '/api/photo/album/send-invite/',
        method: 'post',
        ...config
    });
};

export const createInviteTokenAlbum = (config?: AxiosRequestConfig) => {
    return request<DataAlbumCreateInviteToken>({
        url: '/api/photo/album/create-invite-token/',
        method: 'post',
        ...config
    });
};

export const getUserAccessListAlbum = (config?: AxiosRequestConfig) => {
    return request<DataUserAccessList>({
        url: '/api/photo/album/get-user-access-list/',
        ...config
    });
};

export const getAccessRequestListAlbum = (config?: AxiosRequestConfig) => {
    return request<DataAlbumAccessRequestList>({
        url: '/api/photo/album/get-access-request-list/',
        ...config
    });
};

export const getAvailableAlbumList = (config?: AxiosRequestConfig) => {
    return request<DataAvailableAlbumList>({
        url: '/api/photo/album/get-available-album-list/',
        ...config
    });
};

export const getAvailableAlbumWithPhotosList = (config?: AxiosRequestConfig) => {
    return request<DataAvailableAlbumWithPhotosList>({
        url: '/api/photo/album/get-available-album-with-photos-list/',
        ...config
    });
};

export const changeUserAlbumRights = (config?: AxiosRequestConfig) => {
    return request({
        url: '/api/photo/album/change-user-album-rights/',
        method: 'post',
        ...config
    });
};
