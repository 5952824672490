import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Burger: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props}>
        <path d="M0,4h24v2.7H0V4z M0,10.7h24v2.7H0V10.7z M0,17.3h24V20H0V17.3z" />
    </svg>
);

export default Burger;
