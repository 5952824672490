import React, { FC } from 'react';
import { defaultProps } from './base';
import { IProps } from './types';

const SearchPartnerIcon: FC<IProps>  = (props) => (
    <svg style={{width: '18px', height: '18px'}}{...defaultProps} {...props} width="100.000000pt" height="125.000000pt" viewBox="0 0 100.000000 125.000000" fill="none">
        <g transform="translate(0.000000,125.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M574 1224 c-69 -34 -86 -128 -33 -188 76 -87 212 -36 212 78 0 95 -93 153 -179 110z" />
            <path d="M274 1186 c-44 -19 -64 -53 -64 -104 0 -37 6 -50 34 -78 28 -28 42 -34 75 -34 76 0 131 77 107 149 -20 62 -93 94 -152 67z" />
            <path d="M503 955 c-52 -23 -63 -57 -63 -204 l0 -130 30 -34 30 -35 0 -176 0 -176 49 0 48 0 13 93 c7 50 16 95 20 100 3 4 12 -30 19 -75 18 -120 16 -118 62 -118 l39 0 0 180 c0 165 1 180 19 189 37 20 51 72 51 191 0 126 -11 159 -63 190 -26 16 -50 20 -127 20 -58 -1 -107 -6 -127 -15z" />
            <path d="M224 943 c-12 -2 -34 -18 -50 -34 -28 -29 -29 -35 -32 -140 -4 -116 4 -153 39 -185 17 -15 19 -32 19 -181 l0 -163 43 0 43 0 14 98 c15 107 20 104 40 -25 l12 -73 43 0 43 0 -5 153 c-5 133 -8 156 -27 182 -19 27 -21 44 -21 175 0 80 4 157 9 173 l8 27 -78 -1 c-44 -1 -89 -4 -100 -6z" />
            <path d="M78 290 c-44 -24 -78 -65 -78 -95 0 -52 90 -108 232 -144 119 -31 264 -43 440 -39 147 3 168 6 208 27 111 57 130 114 59 174 -48 42 -85 56 -66 26 14 -23 4 -43 -37 -73 -26 -20 -41 -21 -224 -20 -219 1 -361 23 -452 69 -52 26 -65 46 -50 76 13 23 14 23 -32 -1z" />
        </g>
    </svg>
);

export default SearchPartnerIcon;
