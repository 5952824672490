import React, { useState, useMemo, useEffect, useCallback } from 'react';
import style from './index.pcss';
import { IProps } from './types';
import { parse } from 'query-string';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { normalizeObject } from 'component/helper/normalize-object';
import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import Avatar from 'component/avatar';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import { useHistory } from 'react-router';
import SearchPartnerModal from '../search-partner/search-partner-modal';
import PersonControlIcons from '../control-icons';
import api from 'src/api';
import { Person } from 'src/api/persons/types';
import PersonEdit from 'component/person-edit';
import PersonMerge from 'component/person-merge';
import ReactTooltip from 'react-tooltip';
import SellSuitModal from '../../../components/advertisements/sell-suit/sell-suit-modal';
import { Page } from 'src/api/base';
import { AdListData } from 'src/api/ads/types';
import { Link } from 'react-router-dom';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

export const PersonPhotoItem = (props: IProps) => {
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const userId = useSelector<IStore>((storeApp) => storeApp[keyUser].id);

    const [person, setPerson] = useState<Person | null>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showMergeModal, setShowMergeModal] = useState<boolean>(false);
    const [showSearchPartnerModal, setShowSearchPartnerModal] = useState<boolean>(false);
    const [showSellSuitModal, setShowSellSuitModal] = useState<boolean>(false);
    const [isSellSuit, setIsSellSuit] = useState<boolean>(false);
    const [isSellSuitAvailable, setIsSellSuitAvailable] = useState<boolean>(true);

    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        api.persons.getPersonItem(Number(props.item.id))
            .then((resp: {data: Person}) => {
                setPerson(resp.data);
            });

        const page: Page = {
            pageNumber: 1
        };
        const data: AdListData = {
            person_id: [props.item.id.toString()]
        };
        api.ads.getAdList(page, data)
            .then((resp) => {
                setIsSellSuit(!!resp.data.count);
                resp.data.results.map((item) => {
                    if (!props.event || item.event.id === props.event?.id && item.photo === props.id && item.user.id === Number(userId)) {
                        return setIsSellSuitAvailable(false);
                    } else {
                        setIsSellSuitAvailable(true);
                    }
                });
            });
    }, [props.event]);

    const onClickEditPerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/persons/photos/${props.id}${queryParams.event_id ? `?event_id=${queryParams.event_id}` : ''}`);

            return;
        }

        setShowEditModal(true);
    }, [isAuth]);

    const onClickMergePerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/persons/photos/${props.id}${queryParams.event_id ? `?event_id=${queryParams.event_id}` : ''}`);

            return;
        }

        setShowMergeModal(true);
    }, [isAuth]);

    const onClickSearchPartner = () => {
        if (!isAuth) {
            history.push(`/login?from=/persons/photos/${props.id}${queryParams.event_id ? `?event_id=${queryParams.event_id}` : ''}`);

            return;
        }

        setShowSearchPartnerModal(true);
    };

    const onClickApproveSearchPartner = () => {
        history.push(`/find-partner?search=${props.item.first_name || ''} ${props.item.last_name || ''}`);
    };

    const onClickSellSuit = () => {
        if (!isAuth) {
            history.push(`/login?from=/persons/photos/${props.id}${queryParams.event_id ? `?event_id=${queryParams.event_id}` : ''}`);

            return;
        }

        setShowSellSuitModal(true);
    };

    const onCloseModal = useCallback(() => {
        setShowEditModal(false);
        setShowMergeModal(false);
        setShowSearchPartnerModal(false);
        setShowSellSuitModal(false);
    }, []);

    const elPersonEdit = useMemo(() => {
        if (showEditModal && person) {
            return (
                <PersonEdit
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }

        if (showMergeModal && person) {
            return (
                <PersonMerge
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }
    }, [showEditModal, showMergeModal, JSON.stringify(person)]);

    const elSearchPartner = useMemo(() => {
        if (showSearchPartnerModal) {
            return (
                <SearchPartnerModal
                    id={props.item.id}
                    onCloseModal={onCloseModal}
                />
            );
        }
    }, [showSearchPartnerModal]);

    const elSellSuit = useMemo(() => {
        if (showSellSuitModal) {
            return (
                <SellSuitModal
                    event={props.event?.id}
                    photo={props.id}
                    person={props.item.id}
                    onCloseModal={onCloseModal}
                />
            );
        }
    }, [showSellSuitModal, props.event]);

    return (
            <div key={props.item.id} className={cn('list-item')}>
                {elPersonEdit}
                {elSearchPartner}
                {elSellSuit}
                <div className={cn('list-item__wrapper__container', (props.isActive || isSellSuit) && 'list-item__wrapper__container-active')}>
                    <div className={cn('list-item__wrapper')}>
                        <a target="_blank" href={`/persons/${props.item.id}`} title="Перейти на страницу персоны">
                            <Avatar imgSrc={props.item.photo} size={48} />
                        </a>
                        <div className={cn('list-item__content')}>
                            {(props.item.first_name && props.item.last_name) ? props.item.full_name : props.item.id}
                            {person && (
                                <PersonControlIcons
                                    className={cn('list-item__search-partner-info')}
                                    person={person}
                                    onClickEditPerson={onClickEditPerson}
                                    onClickMergePerson={onClickMergePerson}
                                    onClickSearchPartner={onClickSearchPartner}
                                    onClickSellSuit={onClickSellSuit}
                                    isActive={person.is_partner_profile}
                                    isSellSuit={isSellSuitAvailable}
                                />
                            )}
                            {props.item.is_partner_profile && (
                                <>
                                    <ReactTooltip
                                        id={`search-partner-${props.item.id}`}
                                        place="top"
                                        effect="solid"
                                        border={true}
                                        borderColor="#dee1e4"
                                        backgroundColor="#fff"
                                        className={cn('person-photo__sale-tooltip-partner')}
                                    >
                                        {'Просмотреть анкету'}
                                    </ReactTooltip>
                                    <button
                                        data-tip={true}
                                        data-type="light"
                                        data-for={`search-partner-${props.item.id}`}
                                        className={cn('list-item__search-partner-active')}
                                        onClick={onClickApproveSearchPartner}
                                    >
                                        Ищу партнера
                                    </button>
                                </>
                            )}
                            {isSellSuit && (
                                <>
                                    <ReactTooltip
                                        id={`sell-suit-${props.item.id}`}
                                        place="top"
                                        effect="solid"
                                        border={true}
                                        borderColor="#dee1e4"
                                        backgroundColor="#fff"
                                        className={cn('person-photo__sale-tooltip')}
                                    >
                                        {'Эта персона продает костюм, представленный на данной фотографии'}
                                    </ReactTooltip>
                                    <Link
                                        data-tip={true}
                                        data-type="light"
                                        data-for={`sell-suit-${props.item.id}`}
                                        className={cn('list-item__search-partner-active')}
                                        to={`/advertisements/`}
                                        target={'_blank'}
                                    >
                                        Продаю костюм
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
    );
};
