import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import useClassnames from 'hook/use-classnames';
import ButtonCircular from 'component/button/circular';

import { IProps } from './types';
import style from './styles.pcss';

const ActionButton: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const [visibleButtons, setVisibleButtons] = useState<boolean>(false);

    const onClick = (event: MouseEvent) => {
        const element = event.target as HTMLElement;
        const tag = element.tagName;

        if (tag !== 'BUTTON' && tag !== 'svg' && tag !== 'path') {
            setVisibleButtons(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick);
        };
    }, []);

    const onClickBtn = useCallback(() => {
        setVisibleButtons((prev) => !prev);
    }, []);

    const elItems = useMemo(() => {
        return (
            <div
                className={cn('btn__items', {
                    'btn__items-show': visibleButtons
                })}
            >
                {props.children}
            </div>
        );
    }, [visibleButtons]);

    return (
        <div
            className={cn('btn', {
                'btn-active': visibleButtons
            })}
        >
            {elItems}
            <ButtonCircular onClick={onClickBtn}>
                <FontAwesomeIcon
                    className={cn('add-icon')}
                    icon={props.icon ? props.icon : faCog}
                />
            </ButtonCircular>
        </div>
    );
};

export default ActionButton;
