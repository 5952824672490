import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import useClassnames from 'hook/use-classnames';
import { DataFilesItem } from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { IPayload } from 'component/form/types';
import { createBanner } from 'component/api/banners';
import { useCancelToken } from 'component/core/cancel-token';
import BannerForm from 'component/form/banner-form';
import { useAlert } from 'component/alert/provider';

import { IProps } from './types';
import style from './styles.pcss';

const CreateBannerForm: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const { t } = useTranslation();
    const { show } = useAlert();
    const token = useCancelToken();

    const [image, setImage] = useState<DataFilesItem | null>(null);
    const [pending, setPending] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const onFileLoaded = (file: DataFilesItem): void => {
        setImage(file);
    };

    const onSubmit = useCallback((formData: IPayload) => {
        if (!pending) {
            setPending(true);

            if (!image) {
                show(t('components.form.banner.form.messages.not-banner'), 'danger');
                setPending(false);

                return;
            }

            const payload = formData;
            const data = {
                ...(payload.name && {'name': payload.name}),
                ...(payload.theme && {'theme_id': payload.theme.value}),
                ...(payload.text && {'text': payload.text}),
                ...(payload.link && {'link': payload.link}),
                ...(image && {'tmp_file_id': image.id})
            };

            createBanner({
                cancelToken: token.new(),
                data
            }).then(() => {
                setPending(false);

                show(t('components.form.banner.form.messages.create-success'), 'success');

                if (props.onSubmit) {
                    props.onSubmit(formData);
                }
            }).catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    show(t('components.form.banner.form.messages.create-error'), 'warning');

                    setPending(false);
                }
            });
        }
    }, [image]);

    return (
        <BannerForm
            registry={props.registry}
            button={t('components.form.banner.form.buttons.create')}
            pending={pending}
            onFileLoaded={onFileLoaded}
            onSubmit={onSubmit}
        />
    );
};

export default CreateBannerForm;
