import React, { Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import EyeIcon from 'component/icon/eye';
import Button from 'component/button';
import { IValue } from 'component/form/input-clubs/types';
import Form, { useRegistry } from 'component/form';
import Input from 'component/form/input';
import InputLocation from 'component/form/input-location';
import { useTranslation } from 'react-i18next';
import { Person, UpdateRequestPersonData } from 'src/api/persons/types';
import { IProps } from 'component/person-edit/edit-form/types';
import { useClassnames } from 'hook/use-classnames';
import style from 'component/person-edit/index.pcss';
import api from 'src/api';
import { useAlert } from 'component/alert/provider';
import InputPhotoFile from 'component/form/input-photo-file';

export default (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { form, field } = useRegistry();
    const { show } = useAlert();

    const [person, setPerson] = useState<Person>(props.person);
    const [image, setImage] = useState<Blob | null>(null);
    const [imageSrc, setImageSrc] = useState<string | null>(props.person.photo);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (person) {
            setIsLoading(false);
        }

    }, [person]);

    const onFileLoaded = (file: Blob): void => {
        if(file) {
            setImage(file);
            setImageSrc(window.URL.createObjectURL(file));
        }
    };

    const onClickRemove = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setImageSrc(null);
        setImage(null);
    }, []);

    const onSubmit = (): void => {
        let isValid = true;
        const fields = form.getFields();

        if (fields) {
            const fieldFirstName = fields.get('first_name');

            if (fieldFirstName && !fieldFirstName.value) {
                fieldFirstName.setError('Поле не может быть пустым!');
                isValid = false;
            }
            const fieldLastName = fields.get('last_name');

            if (fieldLastName && !fieldLastName.value) {
                fieldLastName.setError('Поле не может быть пустым!');
                isValid = false;
            }

            const fieldPhoto = fields.get('photo');

            if (fieldPhoto && !fieldPhoto.value) {
                fieldPhoto.setError('Фото должно быть загружено!');
                isValid = false;
            }

            if (isValid) {
                const payload = form.getPayload();

                const data: UpdateRequestPersonData = {
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    ...(payload.trainer_name && { trainer_name: payload.trainer_name }),
                    ...(payload.location.value && { location: payload.location.value })
                };

                api.persons.updateRequestPerson(person.id, data)
                    .then(
                        () => {
                            show('Ваши изменения отправлены на модерацию, после принятия администратором данные будут изменены.');
                            props.onSubmit();
                        },
                        (error) => {
                            if (error.response.data[0]) {
                                show(error.response.data[0], 'warning');
                            } else {
                                show('Не удалось отправить заявку на модерацию.', 'warning');
                            }
                        }
                );
            }
        }
    };

    const elTitle = () => {
        return (
            <a
                className={cn('person-edit__header')}
                target="_blank"
                href={`/persons/${person?.id}`}
                title="Перейти на страницу персоны"
            >
                {t('route.persons.content.modal.header')}
                <EyeIcon
                    width={24}
                    height={24}
                    className={cn('person-edit__header-eye')}
                />
            </a>
        );
    };

    const elUploadContent = useMemo(() => {
        return (
            <Fragment>
                {imageSrc && (
                    <div className={cn('person-edit__image-box')}>
                        <img alt={imageSrc} className={cn('person-edit__photo-image')} src={imageSrc} />
                    </div>
                )}
                {!imageSrc && (
                    <InputPhotoFile
                        registry={field}
                        name="photo"
                        noplaceholder={true}
                        onFileLoaded={onFileLoaded}
                    />
                )}
                {/* {imageSrc && (
                    <div className={cn('person-edit__photo-menu')}>
                        <div onClick={onClickRemove} className={cn('person-edit__photo-menu-item')}>
                            <div className={cn('person-edit__photo-menu-item-icon')}>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                            {t('components.person-edit.photo-menu.items.delete')}
                        </div>
                    </div>
                )} */}
            </Fragment>
        );
    }, [JSON.stringify(image), imageSrc]);

    const elButtons = () => {
        return (
            <div className={cn('person-edit__buttons')}>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    className={cn('person-edit__button')}
                    isSmall={true}
                >
                    Сохранить
                </Button>
            </div>
        );
    };

    const elEditForm = () => {
        const inputLocationDefault: IValue | undefined = person.location
            ? {
                label  : person.location.name,
                value  : String(person.location.id),
                payload: person.location
            }
            : undefined;

        return (
            <Form
                registry={form}
                onSubmit={onSubmit}
                className={{ 'form__element': cn('person-edit__form') }}
            >
                <div className={cn('person-edit__fields-content')}>
                    <div className={cn('person-edit__file')}>
                        {elUploadContent}
                    </div>
                    <div className={cn('person-edit__fields')}>
                        <Input
                            registry={field}
                            name="first_name"
                            children={t('route.persons.content.modal.first_name')}
                            direction="column"
                            defaultValue={person.first_name}
                        />
                        <Input
                            registry={field}
                            name="last_name"
                            children={t('route.persons.content.modal.last_name')}
                            direction="column"
                            defaultValue={person.last_name}
                        />
                        <InputLocation
                            registry={field}
                            name="location"
                            children={t('route.persons.content.modal.city')}
                            direction="column"
                            placeholder="Выберите город"
                            defaultValue={inputLocationDefault}
                        />
                        <Input
                            registry={field}
                            name="trainer_name"
                            children={t('route.persons.content.modal.trainer')}
                            direction="column"
                            defaultValue={person.trainer_name}
                        />
                    </div>
                </div>
                {elButtons()}
            </Form>
        );
    };

    return (
        <Fragment>
            {elTitle()}
            {elEditForm()}
        </Fragment>
    );
};
