import React from 'react';

import { useClassnames } from 'hook/use-classnames';

import style from './index.pcss';

const Success = () => {
    const cn = useClassnames(style);

    return (
        <div className={cn()}>
            Success
        </div>
    );
};

export default Success;
