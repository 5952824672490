import React, { useState, FC, ReactNode, useEffect, useMemo, useCallback } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select/src/types';

import useClassnames from 'hook/use-classnames';
import Error from 'component/error';
import { TError } from 'component/form/input/types';

import { IProps, IValue } from './types';
import styles from './style';
import style from './index.pcss';
import api from 'src/api';
import { Page } from 'src/api/base';
import { GetAlbumSimpleListData } from 'src/api/albums/types';

const ALBUMS_LIMIT = 100;

const InputAlbumSelect: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);

    const { t } = useTranslation();

    const [isFocus, setIsFocus] = useState(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.defaultValue);
    const [value, setValue] = useState<ValueType<IValue>>(props.defaultValue || []);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [suggests, setSuggests] = useState<Array<IValue>>([]);
    const [suggestsOriginal, setSuggestsOriginal] = useState<Array<IValue>>([]);

    const [isAlbumListLoading, setIsAlbumListLoading] = useState<boolean>(false);
    const [albumListPage, setAlbumListPage] = useState<number>(1);
    const [isAlbumListNext, setIsAlbumListNext] = useState<boolean>(true);
    const [isAlbumListLoadMore, setIsAlbumListLoadMore] = useState<boolean>(false);

    useEffect(() => {
        if (isAlbumListLoading || isAlbumListLoadMore) {
            const page: Page = {
                pageNumber: albumListPage,
                pageSize: ALBUMS_LIMIT
            };

            const params: GetAlbumSimpleListData = {
                is_video: props.is_video
            };

            api.albums.getAlbumSimpleList(page, params)
                .then((resp) => {
                    const options = resp.data.results.map((album): IValue => ({
                        label  : album.name,
                        value  : String(album.id),
                        payload: location
                    }));

                    setSuggests((prev) => isAlbumListLoadMore ? [...prev, ...options] : options);
                    setSuggestsOriginal((prev) => isAlbumListLoadMore ? [...prev, ...options] : options);

                    setIsAlbumListNext(!!resp.data.next);
                    setAlbumListPage((prev) => prev + 1);
                    setPending(false);
                    setIsAlbumListLoading(false);
                    setIsAlbumListLoadMore(false);
                })
                .catch(() => {
                    setIsAlbumListNext(false);
                    setPending(false);
                    setIsAlbumListLoading(false);
                    setIsAlbumListLoadMore(false);
                });
        }
    }, [isAlbumListLoading, isAlbumListLoadMore]);

    useEffect(() => {
        if(props.default_id) {
            const newValue = suggests.find((suggest) => suggest.value === String(props.default_id));

            if(newValue) {
                setValue(newValue);
            }
        }
    }, [JSON.stringify(suggests), props.default_id]);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required) {
            newIsValid = !!value;

            if(!newIsValid) {
                newErrorInternal = t('components.form.input-select.error');
            }
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect((): void => {
        checkValidity();
    }, [isWatch]);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            value,
            clear   : () => {
                setValue(null);
                setIsWatch(false);
            },
            isAutoFill: false,
            isValid: checkValidity()
        });
    }, [value]);

    useEffect(() => {
        setIsAlbumListLoading(true);

        return () => {
            props.registry.remove(props.name);
        };
    }, []);

    useEffect(() => {
        if(props.defaultValue) {
            setValue(props.defaultValue);
        }
    }, [JSON.stringify(props.defaultValue)]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [value, isValid]);

    const loadMoreAlbums = () => {
        if (isAlbumListNext && !isAlbumListLoading && !isAlbumListLoadMore) {
            setIsAlbumListLoadMore(true);
        }
    };

    const onChange = (inputValue: ValueType<IValue> | null): void => {
        setValue(inputValue);

        if(errorExternal) {
            setErrorExternal(null);
        }

        if(props.onChange) {
            props.onChange(inputValue);
        }
    };

    const onInputChange = (inputValue: string): void => {
        const newSuggests = suggestsOriginal.filter((suggest: IValue) => {
            return suggest.label && suggest.label.toLowerCase().includes(inputValue.toLowerCase());
        });

        setSuggests(newSuggests);
    };

    const onFocus = (): void => {
        if(!isFocus) {
            setIsFocus(true);
        }
    };

    const onBlur = (): void => {
        if(isFocus) {
            setIsFocus(false);
        }

        if(!isWatch) {
            setIsWatch(true);
        }
    };

    const elLabel = useMemo((): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('input__label', {
                        'input__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    }, [props.children, props.required]);

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('input__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    const elInput = (): ReactNode => {
        const selectStyles = props.styles ? { ...styles(!!props.icon), ...props.styles } : styles(!!props.icon);

        return (
            <ReactSelect
                className={cn('input__field', {
                    'input__field_invalid': isWatch && !isValid
                })}
                onMenuScrollToBottom={loadMoreAlbums}
                autoFocus={props.autoFocus}
                placeholder={props.placeholder || t('components.form.input-select.placeholder')}
                isMulti={props.isMulti}
                isSearchable={props.searchable}
                isClearable={props.clearable}
                tabIndex={props.tabIndex}
                isDisabled={props.disabled}
                value={value}
                defaultValue={value}
                onFocus={onFocus}
                onBlur={onBlur}
                options={suggests}
                onInputChange={onInputChange}
                onChange={onChange}
                styles={selectStyles}
            />
        );
    };

    return (
        <label className={cn('input')}>
            <div className={cn('input__wrapper', `input__wrapper_${props.direction}`)}>
                {elLabel}
                {elInput()}
            </div>
            {elError()}
        </label>
    );
};

InputAlbumSelect.defaultProps = {
    direction: 'row'
};

export default InputAlbumSelect;
