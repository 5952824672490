import React, { Fragment, useCallback } from 'react';
import Modal from 'component/modal';
import { IProps } from './types';
import style from './index.pcss';
import { useClassnames } from 'hook/use-classnames';
import Loader from 'component/loader';
import EditForm from 'component/person-edit/edit-form';

export default (props: IProps) => {
    const cn = useClassnames(style);

    const onClickClose = useCallback(() => {
        props.onCloseModal && props.onCloseModal();
    }, []);

    const elContent = () => {
        if(props.pending) {
            return <Loader />;
        }

        return (
            <Fragment>
                <EditForm person={props.person}  onSubmit={onClickClose} />
            </Fragment>
        );
    };

    return (
        <Modal onClickClose={onClickClose}>
            <div className={cn('person-edit')}>
                {elContent()}
            </div>
        </Modal>
    );
};
