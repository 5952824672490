import React, { FC } from 'react';
import * as classnames from 'classnames/bind';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import UI from 'component/ui';

import style from './style.pcss';

const NotFound: FC = () => {
    const cn = classnames.bind(style);

    const { t } = useTranslation();

    return (
        <UI.Main className={cn('not-found')}>
            <Helmet
                title={t('helmet.title.not-found')}
                meta={[{
                    name   : 'document-state',
                    content: 'static'
                }]}
            />
            <h1 className={cn('not-found__header')}>
                {t('route.not-found.header')}
            </h1>
            <div className={cn('not-found__desc')}>
                {t('route.not-found.desc')}
            </div>
        </UI.Main>
    );
};

export default NotFound;
