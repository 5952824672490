import React, { FC, useMemo } from 'react';

import useClassnames from 'hook/use-classnames';

import { IProps } from './types';
import style from 'component/button/circular/styles.pcss';
import { Link } from 'react-router-dom';

const ButtonCircular: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const elText = () => {
        return (
            <div className={cn('circular-btn__text')}>
                <span>
                    {props.text}
                </span>
            </div>
        );
    };

    const elButton = () => {
        const params = {
            className: cn('circular-btn__btn', {
                'circular-btn__btn_small': props?.isSmall
            })
        };

        if (props.to) {
                return (
                    <Link to={props.to} {...params}>
                        {props.children}
                    </Link>
            );
        }

        return (
            <button
                className={cn('circular-btn__btn', {
                    'circular-btn__btn_small': props?.isSmall
                })}
                onClick={props.onClick}
            >
                {props.children}
            </button>
        );
    };

    return (
        <div className={cn('circular-btn')}>
            {props.text && elText()}
            {elButton()}
        </div>
    );
};

export default ButtonCircular;
