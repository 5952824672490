import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import { TasksAPIType, YandexTasksErrorsRetrieve, YandexTasksListCreateData, YandexTasksListCreateInfo, YandexTasksListData, YandexTasksListItem, YandexTasksRetrieve } from './types';

const getYandexTasksList = (page?: Page, data?: YandexTasksListData): Promise<AxiosResponse<PaginationResponse<YandexTasksListItem>>> => {
    return httpClient.get<PaginationResponse<YandexTasksListItem>>('/api/v2/tasks/yandex/share-link/', {
        params: {
            ...(page && {page: page.pageNumber}),
            ...(page && {page_size: page.pageSize}),
            ...data
        }
    });
};

const createYandexTask = (data: YandexTasksListCreateInfo, photo_data?: YandexTasksListCreateData): Promise<AxiosResponse<YandexTasksListItem>> => {
    return httpClient.post<YandexTasksListItem>('/api/v2/tasks/yandex/share-link/', {
        ...(data.share_link && { share_link: data.share_link }),
        ...(data.recursive && { recursive: data.recursive }),
        photo_data
    });
};

const getYandexTasksItem = (id: string): Promise<AxiosResponse<YandexTasksRetrieve>> => {
    return httpClient.get<YandexTasksRetrieve>(`/api/v2/tasks/yandex/share-link/${id}/`);
};

const getYandexTasksErrors = (id: string): Promise<AxiosResponse<Array<YandexTasksErrorsRetrieve>>> => {
    return httpClient.get<Array<YandexTasksErrorsRetrieve>>(`/api/v2/tasks/yandex/share-link/${id}/errors/`);
};

const createSeenYandexTask = (id: string): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/tasks/yandex/share-link/${id}/seen/`);
};

const createStopYandexTask = (id: string): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/tasks/yandex/share-link/${id}/stop/`);
};

export default {
    getYandexTasksList,
    createYandexTask,
    getYandexTasksItem,
    getYandexTasksErrors,
    createSeenYandexTask,
    createStopYandexTask
} as TasksAPIType;
