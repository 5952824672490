import { useClassnames } from 'hook/use-classnames';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { CartItem } from 'src/api/cart/types';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import cropImage from 'component/helper/crop-image';
import style from 'route/cart/index.pcss';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';

const CartItem = ({ item, onClickRemove }: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const userId = useSelector<IStore, number | undefined>((storeApp) => storeApp[keyUser].id);
    const isOwner = isAuth && item.photographer?.id === userId;

    return (
        <div className={cn('cart__list-item')}>
            <Fragment>
                <a
                    target="_blank"
                    href={`/persons/photos/${item.photo.id}?event_id=${item.event?.id}`}
                    title="Перейти на страницу фото"
                    className={cn('cart__item-link')}
                >
                    <img
                        alt={String(item.photo.id)}
                        src={`${item.photo.photo_url}?photo_size=310x200`}
                        className={cn(
                            'cart__item-image', { 'cart__image-owner': isOwner }
                        )}
                    />
                </a>
                <div className={cn('cart__item-content')}>
                    <div className={cn('cart__item-block')}>
                        {
                            isOwner && (
                                <p className={cn('cart__item-text-owner')}>
                                    Нельзя купить свое фото!
                                </p>
                            )
                        }
                        <p className={cn('cart__item-text')}>{item.event?.name}</p>
                            <p className={cn('cart__item-text-sub')}>
                                {t(
                                    'route.cart.item.author',
                                    { name: `${item.photographer.name}` }
                                )}
                            </p>
                        <p
                            className={cn(
                                'cart__item-text-sub',
                                'cart__item-text-sub_remove'
                            )}
                            onClick={onClickRemove(item.id)}
                        >
                            {t('route.cart.item.remove')}
                        </p>
                    </div>
                    {Boolean(item.discount) && (
                        <div className={cn('cart__item-price')}>
                            <span className={cn('cart__item-price-new')}>{Number(item.price) * (100 - item.discount) / 100}</span>
                            <span className={cn('cart__item-price-old')}>{item.price}</span>
                            <span>Скидка: {item.discount}%</span>
                        </div>
                    )}
                    {Boolean(!item.discount) && (
                        <div>
                            <span className={cn('cart__item-price')}>
                                {t('route.cart.item.price', { count: Number(item.price) })}
                            </span>
                        </div>
                    )}
                </div>
            </Fragment>
        </div>
    );
};

export default CartItem;
