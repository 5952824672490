import React, { FC, useMemo } from 'react';
import { useClassnames } from 'hook/use-classnames';

import { IProps } from 'component/person-list/types';
import style from 'component/person-list/style.pcss';
import ListItem from 'component/person-list/list-item';

const PersonList: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const elContent = useMemo(() => {
        if (props.persons && props.persons.length) {
            return props.persons.map((person) => {
                return (
                    <ListItem
                        key={person.id}
                        person={person}
                        imgSrc={person.photo_url}
                        isButton={props.isButton}
                        onClickButton={props.onClickButton}
                    >
                        <div>{person.location?.name}</div>
                        <div>{person.location?.region_name}</div>
                    </ListItem>
                );
            });
        }
    }, [props.persons]);

    return (
        <div className={cn('person-list')}>
            <div className={cn('person-list__content')}>
                {elContent}
                {props.children}
            </div>
        </div>
    );
};

PersonList.defaultProps = {
    isButton: false
};

export default PersonList;
