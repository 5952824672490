import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { CartAPIType, CartItem, CreateCartListItem, GetCartItemsListData } from './types';
import { Page, PaginationResponse } from '../base';

const getCartItemsList = (page: Page, data?: GetCartItemsListData): Promise<AxiosResponse<PaginationResponse<CartItem>>> => {
    return httpClient.get<PaginationResponse<CartItem>>('/api/v2/carts/', {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 100 },
            ...data
        }
    });
};

const createCartItem = (object_type: string, photo: number): Promise<AxiosResponse<CreateCartListItem>> => {
    return httpClient.post<CreateCartListItem>(`/api/v2/carts/`, {
        object_type,
        photo
    });
};

const destroyCartItem = (id: number): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/carts/${id}/`);
};

export default {
    getCartItemsList,
    createCartItem,
    destroyCartItem
} as CartAPIType;
