import { createAction } from '@reduxjs/toolkit';

export const next = createAction('@carousel/NEXT');

export const prev = createAction('@carousel/PREV');

export const selected = createAction<number>('@carousel/SELECTED');

export const setTotal = createAction<number>('@carousel/SET-TOTAL');

export default {
    next,
    prev,
    selected,
    setTotal
};
