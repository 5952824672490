import React, { PureComponent, ReactNode } from 'react';
import * as classnames from 'classnames/bind';
import qaAttributes from 'component/helper/qa-attributes';

import { IProps } from './types/main';
import style from './main.pcss';

class Main extends PureComponent<IProps> {

    cn = classnames.bind(style);

    render(): ReactNode {
        return <div className={this.cn('main', this.props.className)} {...qaAttributes(this.props['data-qa'] || 'ui-main')}>{this.props.children}</div>;
    }

}

export default Main;
