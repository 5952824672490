import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';

import store from 'store/index';
import CoreProvide from 'component/core/provider';
import { AlertProvider } from 'component/alert/provider';
import { logout, userSelfInfo } from 'component/api/user';
import 'component/core/i18n';
import Routes from 'route/index';
import { set as setUser } from 'store/reducers/user/actions';
import api from './api';

moment.locale('ru');

const startApp = async () => {
    try {
        const refreshToken = localStorage.getItem('jwt_token_refresh');
        if (refreshToken && new Date() <= new Date(JSON.parse(atob(refreshToken.split('.')[1])).exp * 1000)) {
            await api.auth.authTokenRefreshCreate(refreshToken)
            .then(async (resp) => {
                localStorage.setItem('jwt_token', resp.data.access);
                localStorage.setItem('jwt_token_refresh', resp.data.refresh);

                const requestAuth = api.accounts.getAccountRetrieve();

                const payload = await requestAuth;
                store.dispatch(setUser(payload.data));
            })
            .catch((e) => {
                localStorage.removeItem('jwt_token');
                localStorage.removeItem('jwt_token_refresh');
                logout();
            });
        } else {
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('jwt_token_refresh');
            logout();
        }
    } catch(error) {
        console.warn(error);
    }

    render((
        <Provider store={store}>
            <CoreProvide>
                <AlertProvider>
                    <Routes />
                </AlertProvider>
            </CoreProvide>
        </Provider>
    ), document.getElementById('app'), () => {
        console.info('App version: %s', __VERSION__);
    });
};

if(module.hot) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    module.hot.accept('route/index', startApp);
}

if(!__DEVELOPMENT__) {
    startApp().catch(console.error);
} else {
    // Synthetic waiting loading css (style-loader chunk)
    window.onload = startApp;
}
