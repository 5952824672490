import React from 'react';

import Icon, { cn } from './';

export default Icon(() => (
    <path
        d="M74.5,3.8L39.6,42.2L28.9,27c-0.2-0.3-0.4-0.6-0.7-0.8c-0.3-0.2-0.6-0.3-1-0.4c-0.3-0.1-0.7-0.1-1,0
        s-0.7,0.2-1,0.4c-0.3,0.2-0.5,0.5-0.7,0.8s-0.3,0.7-0.4,1c0,0.4,0,0.7,0.1,1.1c0.1,0.3,0.3,0.7,0.5,1L37.2,48
        c0.2,0.3,0.5,0.6,0.9,0.8c0.3,0.2,0.7,0.3,1.1,0.3h0.2c0.4,0,0.7-0.1,1.1-0.2c0.3-0.2,0.6-0.4,0.9-0.7l37-40.8
        c0.2-0.3,0.4-0.6,0.5-0.9C79,6.2,79,5.9,79,5.5s-0.1-0.7-0.2-1s-0.3-0.6-0.6-0.8c-0.3-0.2-0.5-0.4-0.9-0.5C77,3.1,76.6,3,76.3,3
        s-0.7,0.1-1,0.2C75,3.3,74.7,3.5,74.5,3.8L74.5,3.8z M73.2,30.3c-0.7,0.1-1.3,0.5-1.7,1.1c-0.4,0.6-0.5,1.3-0.4,2
        c0.4,2.2,0.7,4.4,0.7,6.6c0,8.7-3.4,17-9.6,23.1C56,69.3,47.7,72.7,39,72.7s-17-3.4-23.1-9.6C9.8,57,6.3,48.7,6.3,40
        c0-5.7,1.5-11.2,4.3-16.2c2.8-4.9,6.8-9,11.7-11.9c4.8-2.9,10.4-4.5,16-4.6c5.7-0.1,11.3,1.2,16.3,3.9c0.6,0.3,1.3,0.4,2,0.2
        s1.2-0.7,1.6-1.3c0.3-0.6,0.4-1.3,0.2-2s-0.7-1.2-1.3-1.6c-7.3-4-15.7-5.4-24-4.1c-8.2,1.3-15.8,5.2-21.6,11.2s-9.4,13.7-10.3,22
        C0.3,44,2.1,52.3,6.4,59.5s10.8,12.7,18.5,15.8c7.7,3.1,16.3,3.6,24.3,1.4s15.1-7,20.2-13.6c5-6.6,7.8-14.7,7.8-23
        c0-2.6-0.3-5.1-0.8-7.6c-0.1-0.7-0.5-1.3-1.1-1.7C74.6,30.3,73.9,30.2,73.2,30.3z"
    />
), {
    viewBox: '0 0 80 80'
});
