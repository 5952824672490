import React from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const EMail = (props: IProps) => (
    <svg {...defaultProps} {...props} viewBox="0 0 24 24">
        <path d="M20 8l-8 5-8-5V6l8 5 8-5v2zm0-4H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2z" fill="#C5CAD0" />
    </svg>
);

export default EMail;
