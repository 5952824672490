import React from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Stop = (props: IProps) => (
    <svg {...defaultProps} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496">
        <path fill="#F73974" d="M496,248c0,136.8-111.2,248-248,248S0,384.8,0,248S111.2,0,248,0S496,111.2,496,248z" />
        <path fill="#DD0772" d="M320,238.4c0-16.8-13.6-30.4-30.4-30.4h-83.2c-16.8,0-30.4,13.6-30.4,30.4v84c0,16.8,13.6,30.4,30.4,30.4h84c16.8,0,30.4-13.6,30.4-30.4v-84H320z" />
        <path fill="#ED266E" d="M72.8,72.8c96.8-96.8,253.6-96.8,350.4,0s96.8,253.6,0,350.4" />
        <path fill="#DD0772" d="M248,0c136.8,0,248,111.2,248,248S384.8,496,248,496" />
        <path fill="#FFFFFF" d="M320,206.4c0-16.8-13.6-30.4-30.4-30.4h-83.2c-16.8,0-30.4,13.6-30.4,30.4v84c0,16.8,13.6,30.4,30.4,30.4h84c16.8,0,30.4-13.6,30.4-30.4v-84H320z" />
    </svg>
);

export default Stop;
