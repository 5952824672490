import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Exit: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props}>
        <path
            d="M23,12l-4.4-4.4v3.3H9.8v2.2h8.8v3.3L23,12z M20.8,18.6c-1.385,1.847-3.316,3.211-5.52,3.9
            c-2.204,0.688-4.568,0.666-6.758-0.064s-4.095-2.131-5.445-4.004C1.726,16.559,1,14.309,1,12c0-2.309,0.726-4.559,2.076-6.432
            c1.35-1.873,3.255-3.274,5.445-4.004c2.19-0.73,4.555-0.753,6.758-0.064c2.204,0.688,4.135,2.053,5.52,3.9h-3.003
            c-1.27-1.12-2.837-1.85-4.511-2.102c-1.675-0.252-3.387-0.015-4.93,0.681C6.812,4.676,5.502,5.804,4.584,7.226
            C3.665,8.649,3.176,10.306,3.176,12c0,1.694,0.489,3.351,1.407,4.774c0.919,1.423,2.228,2.55,3.772,3.247
            c1.544,0.697,3.255,0.933,4.93,0.681c1.675-0.252,3.241-0.982,4.511-2.102H20.8z"
        />
    </svg>
);

export default Exit;
