import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FileItem } from '../class-item';
import { useClassnames } from 'hook/use-classnames';
import style from './style.pcss';
import ReactTooltip from 'react-tooltip';
import Status from 'route/upload/status';

interface IProps {
    files: Array<FileItem>;
}

const LoadingPhotoList = ({ files }: IProps) => {
    const cn = useClassnames(style);

    return (
        <div>
            <div className={cn('loading-photo-list')}>
                {files.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            {item.error && (
                                <ReactTooltip
                                    className={cn('loading-photo-list__tooltip')}
                                    multiline={true}
                                    effect="solid"
                                />
                            )}
                            <div className={cn('loading-photo-list__list-item', 'loading-photo-list__list-item_loading')}>
                                <span className={cn('loading-photo-list__file-name')}>{item.file.name}</span>
                                <Status
                                    progress={item.progress}
                                    isError={!!item.error}
                                    isDone={!item.error && item.progress === 100 && item.status === 'success'}
                                    tooltip={item.error ? item.error : undefined}
                                    className={cn('loading-photo-list__status')}
                                />
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default LoadingPhotoList;
