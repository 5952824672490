import React, { useEffect, useMemo, useState, useCallback, FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';

import UI from 'component/ui';
import Error from 'component/error';
import Button from 'component/button';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

import { IProps } from './types';
import style from './index.pcss';
import api from 'src/api';
import { PersonSubscription } from 'src/api/persons/types';
import { SubscriptionsCreateData } from 'src/api/subscriptions/types';
import ReactTooltip from 'react-tooltip';

const Subscribe: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const { id }: {id: string} = useParams();
    const history = useHistory();
    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);
    const [tokenUnsubscribe, tokenSubscribe] = useCancelTokens(2);

    const [pendingSubscribe, setPendingSubscribe] = useState<boolean>(false);
    const [subscription, setSubscription] = useState<number | null | undefined>(props.subscription);
    const [errorSubscribe, setErrorSubscribe] = useState<string | null>(null);

    useEffect(() => {
        if(props.subscription) {
            setSubscription(props.subscription);
        }
    }, [JSON.stringify(props.subscription)]);

    const _requestUnsubscribe = useCallback(() => {
        if(!isAuth) {
            history.push('/login');

            return;
        }

        setPendingSubscribe(true);

        if(errorSubscribe) {
            setErrorSubscribe(null);
        }

        if (subscription) {
            api.subscriptions.deleteSubscription(subscription)
                .then(() => {
                    setSubscription(null);
                    setPendingSubscribe(false);
                })
                .catch((e) => {
                    if (e.message) {
                        setErrorSubscribe(e.message);
                    }

                    setPendingSubscribe(false);
                });
        }
    }, [JSON.stringify(props.persons), JSON.stringify(subscription), errorSubscribe]);

    const _requestSubscribe = useCallback(() => {
        if(!isAuth) {
            history.push('/login');

            return;
        }

        setPendingSubscribe(true);

        if(errorSubscribe) {
            setErrorSubscribe(null);
        }

        const data: SubscriptionsCreateData = {
            subscription_type: props.type,
            object_id: Number(id)
        };

        api.subscriptions.createSubscription(data)
            .then((resp) => {
                setSubscription(resp.data.id);

                setPendingSubscribe(false);
            })
            .catch((e) => {
                if (e.message) {
                    setErrorSubscribe(e.message);
                }

                setPendingSubscribe(false);
            });
    }, [JSON.stringify(props.persons), props.photographerId]);

    useEffect(() => {
        return () => {
            tokenSubscribe.remove();
            tokenUnsubscribe.remove();
        };
    }, []);

    const elSubscriptionError = useMemo(() => {
        if(errorSubscribe) {
            return <Error className={cn('subscribe__subscription-error')}>{errorSubscribe}</Error>;
        }
    }, [errorSubscribe]);

    const elSubscription = () => {
        const title = `Вы можете подписаться на ${props.type === 'person' ? 'персону' : 'фотографа'}, чтобы получать бесплатные уведомления о новых фото`;
        const buttonProps = {
            onClick    : subscription ? _requestUnsubscribe : _requestSubscribe,
            disabled   : pendingSubscribe || props.disabled,
            isLoading  : pendingSubscribe,
            children   : t('components.subscription.button', { context: subscription ? 'unsubscribe' : 'subscribe' }),
            isSecondary: !!subscription,
            className  : cn('subscribe__button'),
            ...(!subscription && { title })
        };

        return (
            <Fragment>
                <div className={cn('subscribe__button-wrapper')}>
                    <Button {...buttonProps} />
                </div>
            </Fragment>
        );
    };

    return (
        <>
            {elSubscription()}
        </>
    );
};

export default Subscribe;
