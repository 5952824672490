import generatePassword from 'generate-password';

const generate = (max: number) => {
    return generatePassword.generate({
        length   : max,
        numbers  : true,
        uppercase: true
    });
};

export default (max = 10) => {
    let string = generate(max);

    while(!/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/g.test(string)) {
        string = generate(max);
    }

    return string;
};
