import { createAction } from '@reduxjs/toolkit';

import { IActionSet } from './types/actions';

export const set = createAction<IActionSet>(
    '@@device-info/SET'
);

export const setWidth = createAction<number>(
    '@@device-info/SET-WIDTH'
);

export default {
    set,
    setWidth
};
