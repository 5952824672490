import { AxiosRequestConfig } from 'axios';

import { Data as Subscribe } from './types/api/subscription/subscription/create/post/code-200';
import { Data as EditSubscription } from './types/api/subscription/subscription/edit/post/code-200';
import { Data as GetSubscription } from './types/api/subscription/get-subscription-info/get/code-200';
import { Data as GetSubscriptionList } from './types/api/subscription/get-subscription-list/get/code-200';
import { Data as GetSubscriptionTarifList } from './types/api/subscription/subscription/get-tarif-list/get/code-200';

import { request } from './';

export const subscribe = (config?: AxiosRequestConfig) => {
    return request<Subscribe>({
        url   : '/api/subscription/subscription/create/',
        method: 'post',
        ...config
    });
};

export const unsubscribe = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/subscription/subscription/delete/',
        method: 'post',
        ...config
    });
};

export const editSubscription = (config?: AxiosRequestConfig) => {
    return request<EditSubscription>({
        url   : '/api/subscription/subscription/edit/',
        method: 'post',
        ...config
    });
};

export const getSubscription = (config?: AxiosRequestConfig) => {
    return request<GetSubscription>({
        url   : '/api/subscription/get-subscription-info/',
        ...config
    });
};

export const getSubscriptionList = (config?: AxiosRequestConfig) => {
    return request<GetSubscriptionList>({
        url   : '/api/subscription/get-subscription-list/',
        ...config
    });
};

export const getSubscriptionTarifList = (config?: AxiosRequestConfig) => {
    return request<GetSubscriptionTarifList>({
        url   : '/api/subscription/subscription/get-tarif-list/',
        ...config
    });
};

export default {

};
