import React, { useState, FC, ReactNode, ChangeEvent, useEffect, useMemo } from 'react';

import useClassnames from 'hook/use-classnames';
import i18n from 'component/core/i18n';

import { IProps, TError } from './types';
import style from './index.pcss';
import Error from 'component/error';

const Checkbox: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), [props.className]);
    const [checked, setChecked] = useState<boolean>(props.defaultValue || false);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isWatch, setIsWatch] = useState<boolean>(false);
    const [errorInternal, setErrorInternal] = useState<TError>(null);

    useEffect(() => {
        if (props.onlyOneSelect && typeof props.checked === 'boolean') {
            setChecked(props.checked);
        }
    }, [props.checked]);
    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required) {
            newIsValid = checked;
        }

        if(isValid && errorExternal) {
            newIsValid = false;
        }

        if(!newIsValid && !errorExternal && isWatch) {
            newErrorInternal = i18n.t('components.form.checkbox.error');
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect((): void => {
        checkValidity();
    }, [isWatch]);

    useEffect(() => {
        if(typeof props.defaultValue === 'boolean') {
            setChecked(props.defaultValue);
        }
    }, [props.defaultValue]);

    useEffect(() => {
        props.registry.set(props.name, {
            setError  : setErrorExternal,
            value     : checked,
            isAutoFill: false,
            clear     : () => {
                setChecked(false);
                setIsWatch(false);
            },
            isValid: checkValidity()
        });
    }, [checked]);

    useEffect(() => {
        return () => {
            props.registry.remove(props.name);
        };
    }, []);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [checked, isValid]);

    const elLabel = (): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('checkbox__label', {
                        'checkbox__label_disabled': props.disabled,
                        'checkbox__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if(!props.disabled) {
            const isChecked = e.target.checked;

            // В safari onBlur на checkbox не работает!
            if(!isWatch) {
                setIsWatch(true);
            }

            setChecked(isChecked);

            if(errorExternal) {
                setErrorExternal(null);
            }

            if(props.onChange) {
                props.onChange(isChecked);
            }
        }
    };

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('checkbox__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    return (
        <label className={cn('checkbox', props.className)}>
            <input
                className={cn('checkbox__input', props.classNames?.input, {
                    'checkbox__input_error': (isWatch && errorInternal) || errorExternal
                })}
                type="checkbox"
                name={props.name}
                disabled={props.disabled}
                checked={checked}
                onChange={onChange}
                required={props.required}
                tabIndex={props.tabIndex}
            />
            {elLabel()}
            {elError()}
        </label>
    );
};

Checkbox.defaultProps = {
    classNames: {}
};

export default Checkbox;
