import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { Page, PaginationResponse } from '../base';
import {
    FindPartnerAPIType,
    FindPartnerCreate,
    FindPartnerListItem,
    FindPartnerListFilter,
    FindPartnerPartialUpdate,
    FindPartnerCreateData,
    FindPartnerUpdateData,
    FindPartnerPartialUpdateData
} from './types';

const list = (
    page: Page,
    filter?: FindPartnerListFilter
): Promise<AxiosResponse<PaginationResponse<FindPartnerListItem>>> => {
    return httpClient.get<PaginationResponse<FindPartnerListItem>>('/api/v2/find-partner/profiles/', {
        params: {
            page: page.pageNumber,
            ...({ page_size: page.pageSize ? page.pageSize : 40 }),
            ...(filter?.search && { search: filter?.search }),
            ...(filter?.birthday_after && { birthday_after: filter?.birthday_after }),
            ...(filter?.birthday_before && { birthday_before: filter?.birthday_before }),
            ...(filter?.dance_grade_latin_id && { dance_grade_latin_id: filter?.dance_grade_latin_id }),
            ...(filter?.dance_grade_standard_id && { dance_grade_standard_id: filter?.dance_grade_standard_id }),
            ...(filter?.gender && { gender: filter?.gender }),
            ...(filter?.height_min && { height_min: filter?.height_min }),
            ...(filter?.height_max && { height_max: filter?.height_max }),
            ...(filter?.location_id && { location_id: filter?.location_id }),
            ...(filter?.status && { status: filter?.status })
        }
    });
};

const create = (
    data: FindPartnerCreateData
): Promise<AxiosResponse<FindPartnerListItem>> => {
    return httpClient.post<FindPartnerListItem>('/api/v2/find-partner/profiles/', {
        ...data
    });
};

const retrieve = (
    id: number
): Promise<AxiosResponse<FindPartnerListItem>> => {
    return httpClient.get<FindPartnerListItem>(`/api/v2/find-partner/profiles/${id}/`);
};

const update = (
    id: number,
    data: FindPartnerUpdateData
): Promise<AxiosResponse<FindPartnerCreate>> => {
    return httpClient.put<FindPartnerCreate>(`/api/v2/find-partner/profiles/${id}/`, {
        ...data
    });
};

const partialUpdate = (
    id: number,
    data: FindPartnerPartialUpdateData
): Promise<AxiosResponse<FindPartnerPartialUpdate>> => {
    return httpClient.patch<FindPartnerPartialUpdate>(`/api/v2/find-partner/profiles/${id}/`, {
        ...data
    });
};

const destroy = (
    id: number
): Promise<AxiosResponse> => {
    return httpClient.delete(`/api/v2/find-partner/profiles/${id}/`);
};

const respond = (
    id: number
): Promise<AxiosResponse> => {
    return httpClient.post(`/api/v2/find-partner/profiles/${id}/respond/`);
};

export default {
    list,
    create,
    retrieve,
    update,
    partialUpdate,
    destroy,
    respond
} as FindPartnerAPIType;
