import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import style from 'component/wireframes/styles/side-action.pcss';
import { IProps } from 'component/wireframes/types/side-action';
import SidebarActions from 'component/sidebar-actions';
import ActionBar from 'component/action-bar';
import SearchPerson from 'component/search-person';
import FilterForm from 'component/form/filter-form';

const SideAction: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const [visibleActionBar, setVisibleActionBar] = useState<boolean>(false);
    const isMobile = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].tablet);

    const onScroll = () => {
        const el = document.getElementById('sidebar-actions');

        if (el) {
            const bodyRect = document.body.getBoundingClientRect();
            const elRect = el.getBoundingClientRect();

            window.onscroll = () => {
                setVisibleActionBar(elRect.top - bodyRect.top + elRect.height < window.scrollY);
            };
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    const elPageHeader = useMemo(() => {
        return (
            <>
                <h1 className={cn('side-action__header')}>{props.title}</h1>
                <h3 className={cn('side-action__description')}>{props.description}</h3>
            </>
        );
    }, [props.title, props.description]);

    const elSidebarActions = useMemo(() => {
        return (
            <SidebarActions
                idsPhoto={props.idsList}
                update={props.update}
                checkedCount={props.idsList.length}
            />
        );
    }, [JSON.stringify(props.idsList)]);

    const elActionBar = useMemo(() => {
        if (props.idsList.length && (visibleActionBar || isMobile || isTablet)) {
            return (
                <ActionBar
                    update={props.update}
                    className={cn('side-action__actionbar')}
                />
            );
        }

    }, [JSON.stringify(props.idsList), visibleActionBar]);

    return (
        <UI.Main className={cn('side-action')}>
            {elPageHeader}
            <div className={cn('side-action__grid')}>
                <div className={cn('side-action__content')}>
                    {props.content}
                </div>
                <UI.Sidebar className={cn('side-action__sidebar')}>
                    {!isMobile && !isTablet && props.sidebar}
                    {!isMobile && !isTablet && props.idsList.length !== 0 && (
                        <UI.Box id={'sidebar-actions'} padding={true} className={cn('side-action__sidebar_box')}>
                            {elSidebarActions}
                        </UI.Box>
                    )}
                    {props.isSearchPerson && (
                        <SearchPerson
                            form={props.form}
                            field={props.field}
                            onFileLoaded={props.onFileLoaded}
                        />
                    )}
                    <FilterForm
                        registry={{
                            form: props.form,
                            field: props.field
                        }}
                        color={props.color && true}
                        personNumber={props.personNumber && true}
                        timeRange={props.isTimeRange}
                        name={props.isName}
                        eventDate={props.isEventDate}
                        price={props.isPrice}
                        albumYear={props.albumYear}
                        onChange={props.onChangeForm}
                        onChangeValidity={props.onChangeValidity}
                        onSubmitForm={props.onSubmitForm}
                        onReset={props.onReset}
                    />
                </UI.Sidebar>
            </div>
            {elActionBar}
            {props.children}
        </UI.Main>
    );
};

export default SideAction;
