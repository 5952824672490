import moment from 'moment';
import { Photo } from 'src/api/photos/types';

export interface ISortedMap {
    [key: string]: Array<Photo>
}

export default (list: Array<Photo>) => {
    return list.reduce((accumulator: ISortedMap, current) => {
        const date = moment(current.upload_at).format('YYYY-MM-DD');

        accumulator[date] = [
            ...(accumulator[date] ? accumulator[date] : []),
            current
        ];

        return accumulator;
    }, {});
};
