import { createAction } from '@reduxjs/toolkit';

export const setTask = createAction<number>(
    '@@task/SET'
);

export const resetTask = createAction(
    '@@task/RESET'
);

export default {
    setTask,
    resetTask
};
