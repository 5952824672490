import { createReducer } from '@reduxjs/toolkit';

import { show, hide, setText, setType } from './actions';
import { IAlert } from './types';

const initialState = {
    visible: false,
    text   : '',
    type   : 'default'
};

export default createReducer<IAlert>(initialState, (builder) => {
    builder
        .addCase(show, (state) => ({...state, visible: true}))
        .addCase(hide, (state) => ({...state, visible: false}))
        .addCase(setText, (state, action) => ({...state, text: action.payload}))
        .addCase(setType, (state, action) => ({...state, type: action.payload}));
});
