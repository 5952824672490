import React, { useState, useEffect, useRef, FC, ChangeEvent, ReactNode, useMemo } from 'react';
import autosize from 'autosize';

import useClassnames from 'hook/use-classnames';
import Error from 'component/error';
import i18n from 'component/core/i18n';

import { IProps, TError } from './types';
import style from './index.pcss';

const Textarea: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const $textarea = useRef<HTMLTextAreaElement>(null);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.defaultValue || '');
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.defaultValue);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required) {
            newIsValid = !!value;

            if(!newIsValid) {
                newErrorInternal = i18n.t('components.form.textarea.error');
            }
        }

        if(props.pattern && newIsValid) {
            newIsValid = value.search(props.pattern) !== -1;

            if(!newIsValid) {
                newErrorInternal = props.patternError || 'Custom pattern error!';
            }
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect((): void => {
        checkValidity();
    }, [value, isWatch]);

    useEffect(() => {
        props.registry.set(props.name, {
            setError  : setErrorExternal,
            clear     : () => {
                setValue('');
                setIsWatch(false);
                $textarea.current && $textarea.current.setAttribute('style', '');
            },
            isAutoFill: false,
            value,
            isValid: checkValidity()
        });
    }, [value]);

    useEffect(() => {
        if(props.autosize && $textarea.current) {
            autosize($textarea.current);

            $textarea.current.style.resize = 'none';
        }

        return () => {
            if(props.autosize && $textarea.current) {
                autosize.destroy($textarea.current);
            }

            props.registry.remove(props.name);
        };
    }, []);

    useEffect(() => {
        if(props.defaultValue) {
            setValue(props.defaultValue);
        }
    }, [props.defaultValue]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [value, isValid]);

    const elLabel = (): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('textarea__label', {
                        'textarea__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    };

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const targetValue = e.target.value;

        setValue(targetValue);

        if(errorExternal) {
            setErrorExternal(null);
        }

        if(props.isIgnoreEmpty && !targetValue) {
            setIsWatch(true);
        }
    };

    const onBlur = (): void => {
        if(!isWatch && !props.isIgnoreEmpty) {
            setIsWatch(true);
        }
    };

    const onFocus = (): void => {
        if(props.onFocus) {
            props.onFocus();
        }
    };

    const elError = useMemo(() => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('textarea__error')}>{errorInternal || errorExternal}</Error>;
        }
    }, [isWatch, errorInternal, errorExternal]);

    const elCounter = useMemo(() => {
        if(props.maxLength) {
            return (
                <span  className={cn('textarea__field-counter')}>
                    {i18n.t('components.form.textarea.counter', {
                        textLength: value.length,
                        maxLength : props.maxLength
                    })}
                </span>
            );
        }
    }, [props.maxLength, value]);

    return (
        <label
            className={cn('textarea', `textarea_${props.direction}`)}
        >
            {elLabel()}
            <textarea
                className={cn('textarea__field', {
                    'textarea__field_invalid': isWatch && !isValid
                })}
                ref={$textarea}
                name={props.name}
                disabled={props.disabled}
                required={props.required}
                placeholder={props.placeholder}
                value={value}
                onChange={onChange}
                autoFocus={props.autoFocus}
                onBlur={onBlur}
                onFocus={onFocus}
                tabIndex={props.tabIndex}
                maxLength={props.maxLength}
            >
                {value}
            </textarea>
            {elCounter}
            {elError}
        </label>
    );
};

Textarea.defaultProps = {
    direction    : 'row',
    isIgnoreEmpty: false
};

export default Textarea;
