import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Suit: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props} width="16px" height="16px" viewBox="0 0 700.000000 695.000000" preserveAspectRatio="xMidYMid meet">
        <g
            transform="translate(0.000000,695) scale(0.1,-0.1)"
            fill="#000000"
            stroke="none"
        >
        <path
            d="M2419 6920 c-111 -13 -152 -42 -190 -133 -10 -25 -50 -70 -113 -127
            -112 -103 -156 -123 -310 -145 -718 -102 -934 -145 -1026 -201 -71 -44 -117
            -119 -149 -246 -11 -40 -38 -131 -61 -203 -90 -280 -121 -390 -179 -635 -38
            -157 -131 -648 -160 -840 -12 -80 -27 -173 -32 -207 -10 -67 -17 -116 -53
            -403 -22 -174 -39 -315 -52 -435 -2 -22 -6 -56 -9 -75 -2 -19 -7 -78 -10 -130
            -3 -52 -8 -106 -10 -120 -5 -28 -12 -137 -20 -315 -3 -66 -8 -136 -10 -155 -3
            -19 -7 -116 -11 -215 -3 -99 -8 -200 -10 -225 -2 -25 -7 -239 -10 -476 l-7
            -432 21 -27 c12 -15 22 -34 22 -41 0 -30 84 -52 337 -89 54 -8 110 -17 124
            -20 l26 -6 -44 -217 c-59 -298 -59 -296 29 -355 224 -147 444 -206 877 -234
            189 -12 284 -14 366 -7 60 6 112 7 115 3 7 -10 118 -25 165 -23 147 9 293 5
            321 -7 16 -8 84 -50 149 -94 l119 -80 200 -3 c261 -4 295 3 498 106 4 2 14
            -10 22 -27 9 -17 36 -42 59 -56 41 -23 52 -25 182 -25 148 0 228 16 325 65 57
            29 130 106 164 174 15 28 31 51 37 51 20 0 75 -34 154 -95 80 -63 146 -101
            197 -116 41 -11 330 46 388 77 25 14 85 58 135 100 148 126 146 125 420 174
            270 49 430 107 475 171 25 34 117 96 156 104 121 27 302 131 425 243 149 137
            176 284 118 634 -21 123 -21 132 -5 159 27 45 65 153 70 200 12 96 -20 140
            -122 173 -31 11 -59 28 -70 42 -82 118 -143 148 -457 224 -192 47 -302 84
            -610 202 -261 100 -458 180 -630 255 -44 20 -100 44 -125 55 -121 53 -351 173
            -378 196 l-30 27 -6 345 c-3 190 -8 365 -11 390 -3 25 -12 104 -20 176 -24
            215 -65 385 -117 488 -11 23 -50 79 -87 126 -184 235 -200 287 -206 675 -7
            412 -12 499 -39 605 -30 120 -48 174 -92 269 -42 91 -57 109 -100 117 -24 4
            -39 16 -55 43 -24 41 -114 255 -134 318 -27 89 -81 135 -215 183 -164 60 -514
            94 -721 70z m536 -190 c115 -23 202 -55 202 -74 0 -8 8 -31 18 -52 10 -22 47
            -100 82 -174 36 -74 64 -144 64 -155 0 -11 -1 -28 -1 -39 -1 -10 8 -60 18
            -110 23 -104 39 -198 48 -273 l6 -52 -82 -126 c-74 -112 -100 -142 -100 -116
            0 18 -124 142 -189 190 -59 42 -71 57 -85 98 -26 80 -37 153 -37 245 0 103
            -18 138 -72 138 -83 0 -247 -179 -247 -270 0 -30 35 -241 55 -335 35 -165 95
            -352 130 -410 74 -123 100 -173 122 -238 28 -84 40 -219 25 -292 -34 -166 -63
            -316 -68 -358 -5 -42 -9 -49 -40 -62 -64 -27 -103 -85 -143 -215 -133 -431
            -172 -550 -182 -544 -5 3 -9 17 -9 32 0 21 -65 541 -84 677 -3 17 -10 77 -16
            135 -6 58 -13 119 -15 135 -10 66 -35 336 -41 425 -3 52 -7 115 -10 140 -10
            116 -17 923 -7 917 13 -8 25 71 17 111 -5 23 3 42 39 94 43 63 52 89 77 220 9
            45 18 63 46 88 33 29 36 36 41 104 l6 74 -42 44 c-22 25 -41 49 -41 53 0 16
            411 -4 515 -25z m-829 -316 c-3 -9 -6 -24 -6 -33 0 -10 -59 -78 -132 -150
            l-132 -132 -43 12 c-24 6 -43 17 -43 23 0 6 35 59 79 116 69 93 84 107 122
            118 24 7 64 24 89 36 55 29 74 31 66 10z m-456 -101 c0 -4 -19 -34 -42 -68
            -23 -33 -42 -61 -43 -63 -1 -1 -39 -4 -84 -6 -71 -3 -86 -6 -106 -27 -30 -29
            -32 -65 -12 -144 9 -33 27 -103 40 -155 14 -52 43 -162 66 -245 22 -82 56
            -208 75 -280 64 -242 244 -812 309 -980 9 -22 45 -121 80 -220 71 -198 128
            -342 177 -450 18 -38 42 -92 55 -120 12 -27 41 -102 64 -165 27 -74 51 -122
            66 -135 33 -27 44 -101 22 -147 -123 -264 -204 -589 -268 -1078 -5 -41 -19
            -145 -30 -230 -48 -367 -57 -496 -54 -775 2 -243 14 -401 39 -517 7 -29 -32
            -61 -117 -96 -54 -22 -81 -26 -212 -30 -181 -5 -539 21 -678 49 -53 11 -127
            31 -165 43 -88 30 -222 96 -228 111 -5 12 70 412 78 421 3 2 31 6 64 9 85 8
            125 31 144 81 8 22 27 55 42 72 33 40 34 49 53 462 9 179 17 352 20 385 2 33
            7 119 10 190 4 72 9 146 10 165 2 19 4 70 4 114 l1 79 108 -7 c59 -4 123 -9
            142 -11 19 -1 90 -6 158 -10 151 -10 162 -4 162 86 0 45 -4 60 -17 67 -10 5
            -121 18 -248 28 -126 9 -231 19 -233 20 -2 1 10 46 27 101 64 208 86 410 96
            888 3 138 9 300 14 360 12 156 4 446 -19 685 -11 113 -22 212 -25 220 -2 8 -6
            38 -10 65 -15 129 -73 442 -121 655 -38 165 -112 459 -120 471 -2 4 -1 10 4
            13 13 8 468 90 567 101 28 4 66 10 85 15 47 11 50 11 50 3z m444 -693 c3 -261
            8 -491 10 -510 2 -19 7 -91 11 -160 6 -120 21 -307 30 -360 2 -14 9 -72 15
            -130 6 -58 13 -121 16 -140 2 -19 16 -121 29 -226 13 -105 27 -210 31 -233 4
            -22 4 -41 1 -41 -7 0 -112 266 -182 465 -123 344 -280 832 -330 1020 -56 214
            -75 285 -115 435 -77 286 -76 264 -7 244 28 -8 100 -31 159 -51 68 -23 115
            -33 131 -29 14 3 44 30 68 58 46 57 122 139 125 135 0 -1 4 -216 8 -477z
            m-1265 315 c100 -390 179 -828 217 -1200 3 -33 7 -73 10 -90 2 -16 6 -124 10
            -240 7 -243 -6 -448 -36 -550 -33 -113 -90 -435 -129 -725 -21 -152 -35 -361
            -56 -795 -3 -66 -8 -145 -10 -175 -3 -30 -7 -113 -10 -185 -3 -71 -8 -166 -10
            -210 -2 -44 -7 -149 -11 -233 l-7 -154 -36 7 c-112 21 -151 24 -306 20 -93 -2
            -198 -9 -233 -15 -35 -7 -66 -10 -69 -6 -7 6 -6 286 2 476 2 69 7 195 10 280
            3 85 8 178 10 205 3 28 7 102 10 165 12 272 16 352 26 465 5 66 12 140 14 165
            55 519 99 889 125 1040 5 30 21 136 36 235 26 177 114 633 153 790 36 151 62
            250 70 275 4 14 16 50 25 80 9 30 40 129 70 220 30 91 59 185 65 210 16 60 21
            74 25 69 2 -2 18 -58 35 -124z m2109 -2398 c37 -125 128 -373 191 -517 22 -52
            41 -101 41 -107 0 -7 -44 -59 -98 -116 -199 -210 -702 -827 -702 -862 0 -8 -6
            -18 -14 -22 -7 -4 -21 -39 -31 -78 -29 -117 -13 -236 59 -443 39 -112 63 -246
            48 -274 -7 -13 -23 -66 -36 -118 -14 -52 -30 -108 -37 -125 -7 -16 -11 -33
            -11 -38 5 -22 -45 -92 -109 -151 -39 -36 -75 -66 -79 -66 -4 0 -8 19 -9 43 -1
            23 -4 56 -7 72 -13 70 -12 553 1 675 46 429 124 946 179 1180 l13 55 61 0 c53
            0 64 4 90 28 42 41 73 109 74 166 2 58 -17 91 -67 124 -19 12 -35 26 -35 31 0
            19 52 113 96 174 79 111 123 174 227 329 56 84 107 153 112 153 6 0 25 -51 43
            -113z"
        />
        </g>
    </svg>
);

export default Suit;
