import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import BannerForm from 'component/form/banner-form';
import { DataFilesItem } from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { IPayload } from 'component/form/types';
import { useAlert } from 'component/alert/provider';
import { useCancelToken } from 'component/core/cancel-token';
import { updateBanner } from 'component/api/banners';

import { IProps } from './types';

const UpdateBannerForm: FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { show } = useAlert();
    const token = useCancelToken();

    const [image, setImage] = useState<DataFilesItem | null>(null);
    const [pending, setPending] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const onFileLoaded = (file: DataFilesItem): void => {
        setImage(file);
    };

    const onSubmit = useCallback((formData: IPayload) => {
        if (!pending) {
            setPending(true);

            if (!image) {
                show(t('components.form.banner.form.messages.not-banner'), 'danger');
                setPending(false);

                return;
            }

            const payload = formData;
            const data = {
                ...(payload.name && {'name': payload.name}),
                ...(payload.theme && {'theme_id': payload.theme.value}),
                ...(payload.text && {'text': payload.text}),
                ...(payload.link && {'link': payload.link}),
                ...(image.id && {'tmp_file_id': image.id})
            };

            updateBanner({
                cancelToken: token.new(),
                data: {
                    banner_id: props.banner.id,
                    banner_data: {
                        ...data
                    }
                }
            }).then(() => {
                show(t('components.form.banner.form.messages.update-success'), 'success');

                if (props.onSubmit) {
                    props.onSubmit(formData);
                }
            }).catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    show(t('components.form.banner.form.messages.update-error'), 'warning');

                    setPending(false);
                }
            });
        }
    }, [image]);

    return (
        <BannerForm
            registry={props.registry}
            button={t('components.form.banner.form.buttons.update')}
            pending={pending}
            banner={props.banner}
            onFileLoaded={onFileLoaded}
            onSubmit={onSubmit}
        />
    );
};

export default UpdateBannerForm;
