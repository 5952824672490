import { createReducer } from '@reduxjs/toolkit';

import { addCart, clearCart, removeCartItem } from './actions';
import { CartItemsStore, IStore } from './types/reducer';

export const key = 'cart' as const;

const initialState: CartItemsStore = {
    count: 0,
    items: []
};

export default createReducer<IStore>(initialState, (builder) => {
    builder
        .addCase(
            addCart, (store, action) => {
                if (action.payload) {
                    return {
                        ...store,
                        ...action.payload
                    };
                }

                localStorage.removeItem('cart');

                return store;
            }
        )
        .addCase(
            removeCartItem,
            (
                store,
                action
            ) => {
                const cart: IStore = { ...store };

                // tslint:disable-next-line:no-dynamic-delete
                delete cart.items?.filter((item) => item.photo.id === Number(action.payload))[0];

                localStorage.setItem('cart', JSON.stringify(cart));

                return cart;
            }
        )
        .addCase(
            clearCart,
            () => {
                localStorage.removeItem('cart');

                return {};
            }
        );
});
