import Modal from 'component/modal';
import React, { useState, useEffect } from 'react';

import { IProps } from './types';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import Form, { useRegistry } from 'component/form';
import Input from 'component/form/input';
import InputLocation from 'component/form/input-location';
import { useTranslation } from 'react-i18next';
import InputSelect from 'component/form/input-select';
import InputDate from 'component/form/input-date';
import InputDance from 'component/form/input-dance-class';
import InputDanceLatina from 'component/form/input-dance-class-latina';

import style from './style.pcss';
import { useClassnames } from 'hook/use-classnames';
import Button from 'component/button';
import api from 'src/api';
import { Person, UserProfileRetrieve } from 'src/api/persons/types';
import { useSelector } from 'react-redux';
import { useAlert } from 'component/alert/provider';
import Textarea from 'component/form/textarea';
import { FindPartnerCreateData, FindPartnerUpdateData } from 'src/api/find-partner/types';

const SearchPartnerModal = (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { form, field } = useRegistry();
    const { show } = useAlert();

    const phoneNumber = useSelector<IStore, string | undefined>((store) => store[keyUser].phone_number);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const [error, setError] = useState<boolean>(false);
    const [personRetrieve, setPersonRetrieve] = useState<UserProfileRetrieve>();
    const [personInfo, setPersonInfo] = useState<Person>();

    const [pending, setPending] = useState<boolean>(false);

    useEffect(() => {
        setPending(true);

        api.persons.getPersonProfile(Number(props.id))
        .then((resp) => {
            setPersonRetrieve(resp.data);
        })
        .catch((err) => {
            console.warn(err);

            setPending(false);
        });

        api.persons.getPersonItem(props.id)
        .then((data) => {
            setPersonInfo(data.data);

            setPending(false);
        })
        .catch((err) => {
            console.warn(err);

            setPending(false);
        });
    }, []);

    const variants = [
        {
            value: 'MALE',
            label: 'Мужской'
        },
        {
            value: 'FEMALE',
            label: 'Женский'
        }
    ];

    const phoneCheckValidity = () => {
        const fields = form.getFields();

        if(fields) {
            const phone = fields.get('phone');

            if (phone) {
                if (phone.value.length < 10) {
                    setError(true);

                    return {
                        isValid: false,
                        error: 'Введите корректный номер'
                    };
                }

                setError(false);

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    const firstNameCheckValidity = () => {
        const fields = form.getFields();

        if(fields) {
            const name = fields.get('first_name');

            if (name) {
                if (name.value === '') {
                    setError(true);

                    return {
                        isValid: false,
                        error: 'Введите имя персоны'
                    };
                }

                setError(false);

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    const lastNameCheckValidity = () => {
        const fields = form.getFields();

        if(fields) {
            const name = fields.get('last_name');

            if (name) {
                if (name.value === '') {
                    setError(true);

                    return {
                        isValid: false,
                        error: 'Введите фамилию персоны'
                    };
                }

                setError(false);

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    const onCreateForm = () => {
        if (error) {
            return;
        }

        const formData = form.getPayload();
        if (!formData.first_name.length || !formData.last_name.length) {
            return;
        }

        if (personRetrieve) {
            const data: FindPartnerUpdateData = {
                ...(phoneNumber ? {phone_number: phoneNumber} : {phone_number: formData.phone}),
                user: Number(userId),
                person: props.id,
                ...(personInfo?.first_name ? {first_name: personInfo.first_name} : {first_name: formData.first_name}),
                ...(personInfo?.last_name ? {last_name: personInfo.last_name} : {last_name: formData.last_name}),
                gender: formData.gender.value,
                ...(formData.location?.value && {location: Number(formData.location.value)}),
                ...(formData.birthday && {birthday: formData.birthday}),
                ...(formData.height && {height: Number(formData.height)}),
                ...(formData.dance_grade_standard?.value && {dance_grade_standard: Number(formData.dance_grade_standard.value)}),
                ...(formData.dance_grade_latin?.value && {dance_grade_latin: Number(formData.dance_grade_latin.value)}),
                ...(formData.link && {dance_site_link: formData.link}),
                ...(formData.comment && {comment: formData.comment}),
                ...(formData.coach_phone && {coach_phone_number: formData.coach_phone})};
            api.partner.update(
                personRetrieve.id,
                data
            );
        } else {
            const data: FindPartnerCreateData = {
                ...(phoneNumber ? {phone_number: phoneNumber} : {phone_number: formData.phone}),
                user: Number(userId),
                person: props.id,
                ...(personInfo?.first_name ? {first_name: personInfo.first_name} : {first_name: formData.first_name}),
                ...(personInfo?.last_name ? {last_name: personInfo.last_name} : {last_name: formData.last_name}),
                gender: formData.gender.value,
                ...(formData.location?.value && {location: Number(formData.location.value)}),
                ...(formData.birthday && {birthday: formData.birthday}),
                ...(formData.height && {height: Number(formData.height)}),
                ...(formData.dance_grade_standard?.value && {dance_grade_standard: Number(formData.dance_grade_standard.value)}),
                ...(formData.dance_grade_latin?.value && {dance_grade_latin: Number(formData.dance_grade_latin.value)}),
                ...(formData.link && {dance_site_link: formData.link}),
                ...(formData.comment && {comment: formData.comment}),
                ...(formData.coach_phone && {coach_phone_number: formData.coach_phone})
            };
            api.partner.create(
                data
            ).then(() => {
                show('Ваша анкета отправлена на модерацию. Ожидаемый срок размещения анкеты до 48 часов.');
            });
        }

        props.onCloseModal();
    };

    const onDeleteForm = () => {
        if (personRetrieve) {
            api.partner.destroy(personRetrieve.id);
        }
        props.onCloseModal();
    };

    if (pending) {
        return (
            <></>
        );
    }

    return (
        <Modal onClickClose={props.onCloseModal}>
            <div>
                <h3>Поиск партнера</h3>
                <Form
                    registry={form}
                    onSubmit={onCreateForm}
                >
                    <div>
                        <Input
                            registry={field}
                            name="phone"
                            children={t('route.persons.search-partner.phone')}
                            direction="column"
                            placeholder="(XXX) XXX-XX-XX"
                            onlyNumbers={true}
                            required={true}
                            defaultValue={phoneNumber ? phoneNumber : ''}
                            disabled={!!phoneNumber}
                            checkValidity={phoneCheckValidity}
                        />
                        <Input
                            registry={field}
                            name="first_name"
                            children={t('route.persons.search-partner.first_name')}
                            direction="column"
                            required={true}
                            defaultValue={personRetrieve ? personRetrieve.person.first_name : personInfo?.first_name ? personInfo?.first_name : ''}
                            disabled={personRetrieve ? true : !!personInfo?.first_name}
                            checkValidity={firstNameCheckValidity}
                        />
                        <Input
                            registry={field}
                            name="last_name"
                            children={t('route.persons.search-partner.last_name')}
                            direction="column"
                            required={true}
                            defaultValue={personRetrieve ? personRetrieve.person.first_name : personInfo?.last_name ? personInfo?.last_name : ''}
                            disabled={personRetrieve ? true : !!personInfo?.last_name}
                            checkValidity={lastNameCheckValidity}
                        />
                        <InputLocation
                            registry={field}
                            name="location"
                            children={t('route.persons.search-partner.city')}
                            direction="column"
                            placeholder="Выберите город"
                            defaultNumber={personRetrieve?.location && personRetrieve.location}
                        />
                        <InputSelect
                            className={cn('search-partner__input-block', 'margin-right')}
                            registry={field}
                            name="gender"
                            direction="column"
                            children={t('route.persons.search-partner.gender')}
                            options={variants}
                            required={true}
                            defaultValue={personRetrieve?.gender === 'MALE' ? variants[0] : variants[1]}
                        />
                        <InputDate
                            registry={field}
                            name="birthday"
                            minYear={1950}
                            children={t('route.persons.search-partner.birthday')}
                            direction="column"
                            defaultValue={personRetrieve?.birthday && personRetrieve.birthday}
                        />
                        <Input
                            registry={field}
                            name="height"
                            children={t('route.persons.search-partner.height')}
                            direction="column"
                            defaultValue={personRetrieve?.height ? `${personRetrieve.height}` : null}
                            min={100}
                            max={220}
                        />
                        <InputDance
                            registry={field}
                            clearable={true}
                            name="dance_grade_standard"
                            children={t('route.persons.search-partner.standard')}
                            direction="column"
                            className={cn('search__input-block')}
                            defaultNumber={personRetrieve?.dance_grade_standard && personRetrieve?.dance_grade_standard}
                        />
                        <InputDanceLatina
                            registry={field}
                            clearable={true}
                            name="dance_grade_latin"
                            children={t('route.persons.search-partner.latina')}
                            direction="column"
                            className={cn('search__input-block')}
                            defaultNumber={personRetrieve?.dance_grade_latin && personRetrieve?.dance_grade_latin}
                        />
                        <Input
                            registry={field}
                            name="link"
                            children={t('route.persons.search-partner.link')}
                            direction="column"
                            defaultValue={personRetrieve?.dance_site_link ? personRetrieve?.dance_site_link : ''}
                        />
                        <Input
                            registry={field}
                            name="coach_phone"
                            children={'Номер тренера'}
                            direction="column"
                            placeholder="(XXX) XXX-XX-XX"
                            onlyNumbers={true}
                            defaultValue={personRetrieve?.coach_phone_number ? personRetrieve?.coach_phone_number : ''}
                            checkValidity={phoneCheckValidity}
                        />
                        <Textarea
                            registry={field}
                            name="comment"
                            children={'Комментарий'}
                            direction="column"
                            maxLength={200}
                            defaultValue={personRetrieve?.comment ? personRetrieve?.comment : ''}
                        />
                        <div className={cn('search-partner__submit')}>
                            {personRetrieve ?
                            (
                                <>
                                <Button
                                    isSecondary={true}
                                    className={cn('search-partner__action-button')}
                                    onClick={onDeleteForm}
                                >
                                    Удалить
                                </Button>
                                <Button
                                    className={cn('search-partner__action-button')}
                                    type="submit"
                                >
                                    Сохранить
                                </Button>
                                </>
                            )
                            :
                                (
                                <Button
                                    className={cn('search-partner__action-button')}
                                    type="submit"
                                >
                                    Разместить анкету
                                </Button>
                                )
                            }
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default SearchPartnerModal;
