import { IStackItem, TCallback } from './types/observer';

class Observer {

    instance!: IntersectionObserver;

    callbacks: Array<IStackItem> = [];

    constructor(options?: IntersectionObserverInit) {
        if(IntersectionObserver) {
            this.instance = new IntersectionObserver(this.callback, options);
        }
    }

    callback = (entries: Array<IntersectionObserverEntry>): void => {
        for(const entry of entries) {
            if(entry.isIntersecting) {
                const index = this.callbacks.findIndex(({ $el }) => entry.target === $el);

                if(index !== -1) {
                    this.callbacks[index].callback(entry, index);
                }
            }
        }
    };

    observe = ($el: Element, callback: TCallback) => {
        if(this.instance && $el) {
            this.instance.observe($el);
            this.callbacks.push({ $el, callback });

            return () => {
                this.unobserve($el);
            };
        }
    };

    unobserve = ($el: Element, index?: number): void => {
        if(this.instance && $el) {
            this.instance.unobserve($el);

            if(typeof index !== 'number') {
                const findIndex = this.callbacks.findIndex((callback) => callback.$el === $el);

                this.callbacks.splice(findIndex, 1);
            } else {
                this.callbacks.splice(index, 1);
            }
        }
    };

}

export default new Observer();
