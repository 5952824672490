import React, { Fragment, useEffect, useCallback, useMemo, useState, KeyboardEvent } from 'react';
import { IProps } from 'component/person-edit/types';
import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import style from 'component/person-merge/index.pcss';
import Loader from 'component/loader';
import Modal from 'component/modal';
import EyeIcon from 'component/icon/eye';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Button from 'component/button';
import { useAlert } from 'component/alert/provider';
import api from 'src/api';
import { Page } from 'src/api/base';
import { PersonItem, PersonsSearchFilter } from 'src/api/persons/types';
import debounce from 'lodash.debounce';
import useIntersect from 'hook/use-intersect';
import Input from 'component/form/input';
import { useRegistry } from 'component/form';

export default ({ person, onCloseModal, pending }: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { show, hide } = useAlert();
    const registry = useRegistry();

    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedPerson, setSelectedPerson] = useState<PersonItem | null>();

    const [personsTotal, setPersonsTotal] = useState<number>(0);
    const [personsList, setPersonsList] = useState<Array<PersonItem>>([]);
    const [isPersonsLoadMore, setIsPersonsLoadMore] = useState<boolean>(false);
    const [isPersonsPending, setIsPersonsPending] = useState<boolean>(false);
    const [isPersonsMerge, setIsPersonsMerge] = useState<boolean>(false);
    const [isPersonsNext, setIsPersonsNext] = useState<boolean>(false);
    const [personsPage, setPersonsPage] = useState<number>(1);
    const [isPendingMerge, setIsPendingMerge] = useState<boolean>(false);

    const loadPersons = debounce(useCallback(() => {
        setIsPersonsMerge(false);
        setIsPersonsNext(true);
        setPersonsPage(1);
        setIsPersonsNext(true);
        setIsPersonsPending(true);
    }, []), 300);

    const loadMorePersons = () => {
        if (isPersonsNext && !isPersonsLoadMore && !isPersonsPending) {
            setIsPersonsMerge(true);
            setPersonsPage((prev) => prev + 1);
            setIsPersonsLoadMore(true);
        }
    };

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting) {
            loadMorePersons();
        }
    }, {
        rootMargin: '25px 0px'
    });

    useEffect(() => {
        if (!selectedPerson || !isPendingMerge) {
            return;
        }

        api.persons.createMergePerson(person.id, selectedPerson.id)
            .then(() => {
                onClickClose();
                show(t('modal.merge-persons.success'));
                setIsPendingMerge(false);

                setTimeout(() => { hide(); }, 5000);
            })
            .catch((error) => {
                if (error.response.data[0]) {
                    show(error.response.data[0], 'warning');
                } else {
                    show(t('modal.merge-persons.error'), 'warning');
                }

                setIsPendingMerge(false);
                setTimeout(() => { hide(); }, 5000);
            });
    }, [isPendingMerge]);

    useEffect(() => {
        loadPersons();
    }, []);

    useEffect(() => {
        if (isPersonsPending || isPersonsLoadMore) {
            const page: Page = {
                pageNumber: personsPage,
                pageSize: 10
            };

            const filter: PersonsSearchFilter = {
                search: searchValue
            };

            if (!isPersonsNext) {
                return;
            }

            api.persons.getPersonsList(page, filter)
            .then((resp) => {
                const filteredList = resp.data.results.filter((item) => item.id !== person.id);

                setPersonsList(
                    isPersonsMerge ?
                        [...personsList, ...filteredList] : filteredList
                );
                setIsPersonsNext(!Boolean(resp.data.next === null));
                setPersonsTotal(resp.data.count);
            })
            .finally(() => {
                setIsPersonsPending(false);
                setIsPersonsLoadMore(false);
            })
            .catch(() => {
                setIsPersonsNext(false);
                setIsPersonsPending(false);
                setIsPersonsLoadMore(false);
            });
        }
    }, [isPersonsPending, isPersonsLoadMore]);

    const onClickClose = useCallback(() => {
        onCloseModal && onCloseModal();
    }, []);

    const onClickSubmit = useCallback(() => {
        setIsPendingMerge(true);
    }, []);

    const onClickSelectPerson = useCallback((item: PersonItem | null) => {
        setSelectedPerson(item);
    }, []);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        // @ts-ignore
        if (event.key === 'Enter') {
            const payload = registry.form.getPayload();
            setSearchValue(payload.search);

            loadPersons();
        }
    };

    const onClickSubmitButton = () => {
        const payload = registry.form.getPayload();
        setSearchValue(payload.search);
        setTimeout(() => {
            loadPersons();
        }, 0);
    };

    const elSearchInput = () => {
        return (
            <div className={cn('person-merge__search')}>
                <Input
                    registry={registry.field}
                    name="search"
                    defaultValue={searchValue}
                    type="text"
                    direction="column"
                    children={(
                        <>
                            <ReactTooltip
                                id="search"
                                place="left"
                                effect="solid"
                                border={true}
                                borderColor="#dee1e4"
                                backgroundColor="#fff"
                                className={cn('person-merge__search__sale-tooltip')}
                            >
                                {t('modal.merge-persons.search-tooltip')} <b>{t('modal.merge-persons.search-tooltip-bold')}</b>
                            </ReactTooltip>
                            <strong className={cn('person-merge__label')}>
                                {t('modal.merge-persons.search')}
                            </strong>
                            <span className={cn('person-merge__search__sale-icon')}>
                                <FontAwesomeIcon data-tip={true} data-type="light" data-for="search" icon={faQuestionCircle} />
                            </span>
                        </>
                    )}
                    onKeyDown={handleKeyDown}
                    onClickSubmitButton={onClickSubmitButton}
                    isSubmitButton={true}
                />
            </div>
        );
    };

    const elTargetPerson = () => {
        return (
            <div>
                <div className={cn('person-merge__image-box')}>
                    <img alt={t('modal.merge-persons.person.photo')} className={cn('person-merge__photo-image')} src={person.photo} />
                </div>
                <div className={cn('person-merge__person-info')}>
                    <a
                        target="_blank"
                        href={`/persons/${person.id}`}
                        title={t('modal.merge-persons.person.eye')}
                    >
                        {t('modal.merge-persons.info')}
                        <EyeIcon width={24} height={24} />
                    </a>
                    <ul>
                        <li>{t('modal.merge-persons.person.name')}: {person.first_name ? person.first_name : t('modal.merge-persons.person.not-specified')}</li>
                        <li>{t('modal.merge-persons.person.last-name')}: {person.last_name ? person.last_name : t('modal.merge-persons.person.not-specified')}</li>
                    </ul>
                </div>
            </div>
        );
    };

    const elChosenPerson = () => {
        if (selectedPerson) {
            return (
                <div>
                    <div className={cn('person-merge__image-box')}>
                        <img alt={t('modal.merge-persons.person.photo')} className={cn('person-merge__photo-image')} src={selectedPerson.photo} />
                    </div>
                    <div className={cn('person-merge__person-info')}>
                        <a
                            target="_blank"
                            href={`/persons/${selectedPerson.id}`}
                            title={t('modal.merge-persons.person.eye')}
                        >
                            {t('modal.merge-persons.info')}
                            <EyeIcon width={24} height={24} />
                        </a>
                        <ul>
                            <li>{t('modal.merge-persons.person.name')}: {selectedPerson.first_name ? selectedPerson.first_name : t('modal.merge-persons.person.not-specified')}</li>
                            <li>{t('modal.merge-persons.person.last-name')}: {selectedPerson.last_name ? selectedPerson.last_name : t('modal.merge-persons.person.not-specified')}</li>
                        </ul>
                    </div>
                </div>
            );
        }
    };

    const elPersonList = useMemo(() => {
        if(isPersonsPending) {
            return <Loader />;
        }

        if (personsList.length === 0) {
            return (
                <div className={cn('person-merge__choose__list__empty')}>
                    {t('modal.merge-persons.not-found')}
                </div>
            );
        }

        return (
            <>
                {personsList.map((item) => {
                    return (
                        <div key={item.id} className={cn('person-merge__choose__list__item')}>
                            <img src={item.photo} alt="photo" className={cn('person-merge__choose__list__item__photo')} />
                            <div className={cn('person-merge__choose__list__item__person-info')}>
                                {item.full_name}
                            </div>
                            <Button
                                className={cn('person-merge__choose__list__item__button')}
                                isSmall={true}
                                onClick={() => onClickSelectPerson(item)}
                            >
                                {t('modal.merge-persons.submit-merge')}
                            </Button>
                        </div>
                    );
                })}
            </>
        );
    }, [searchValue, personsList, isPersonsPending]);

    const elControls = useMemo(() => {
        if (selectedPerson) {
            return (
                <>
                    <Button
                        className={cn('person-merge__controls__button')}
                        onClick={() => onClickSelectPerson(null)}
                        isSecondary={true}
                    >
                        {t('modal.merge-persons.cancel')}
                    </Button>
                    <Button
                        className={cn('person-merge__controls__button')}
                        onClick={onClickSubmit}
                    >
                        {t('modal.merge-persons.submit')}
                    </Button>
                </>
            );
        }
    }, [selectedPerson]);

    const elButtonBeforePosts = () => {
        if(!isPersonsPending && !isPersonsLoadMore && personsList.length) {
            if(personsList.length === personsTotal) {
                return null;
            }

            return <Loader ref={$bottomPreviousPosts} />;
        }
    };

    if (pending) {
        return <Loader />;
    }

    return (
        <Modal onClickClose={onClickClose}>
            <div className={cn('person-merge', selectedPerson && 'person-merge__selected')}>
                <Fragment>
                    <div className={cn('person-merge__header')}>
                        {t('route.persons.content.modal.merge')}
                    </div>
                    <div className={cn('person-merge__merge-person')}>
                        {elTargetPerson()}
                        {selectedPerson ? (
                           elChosenPerson()
                        ) : (
                            <div className={cn('person-merge__choose')}>
                                {elSearchInput()}
                                <div className={cn('person-merge__choose__list')}>
                                    {elPersonList}
                                    {elButtonBeforePosts()}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={cn('person-merge__controls')}>
                        {elControls}
                    </div>
                </Fragment>
            </div>
        </Modal>
    );
};
