import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Tags: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props}>
        <path
            d="M4.85,8.7c0.438,0,0.857-0.174,1.167-0.483C6.326,7.907,6.5,7.488,6.5,7.05S6.326,6.193,6.017,5.883
            C5.707,5.574,5.288,5.4,4.85,5.4S3.993,5.574,3.683,5.883C3.374,6.193,3.2,6.612,3.2,7.05s0.174,0.857,0.483,1.167
            C3.993,8.526,4.412,8.7,4.85,8.7z M17.951,11.538c0.396,0.396,0.649,0.946,0.649,1.562c0,0.605-0.242,1.155-0.649,1.551l-5.5,5.5
            C12.055,20.547,11.505,20.8,10.9,20.8c-0.605,0-1.155-0.242-1.562-0.649l-7.689-7.689C1.242,12.055,1,11.505,1,10.9V5.4
            c0-1.221,0.979-2.2,2.2-2.2h5.5c0.605,0,1.155,0.242,1.551,0.638L17.951,11.538z M13.694,5.081l1.1-1.1l7.557,7.557
            C22.758,11.934,23,12.495,23,13.1c0,0.605-0.242,1.155-0.638,1.551l-5.918,5.918l-1.1-1.1l6.281-6.369L13.694,5.081z"
        />
    </svg>
);

export default Tags;
