import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Loader from 'component/loader';
import Form, { useRegistry } from 'component/form';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import * as moment from 'moment';
import { parse, stringify } from 'query-string';
import { normalizeObject } from 'component/helper/normalize-object';
import style from './index.pcss';
import NewPost from './post/new-post';
import api from 'src/api';
import { FindPartnerListFilter, FindPartnerListItem } from 'src/api/find-partner/types';
import InputSelect from 'component/form/input-select';
import InputRange from 'component/form/input-range';
import InputLocation from 'component/form/input-location';
import InputDance from 'component/form/input-dance-class';
import InputDanceLatina from 'component/form/input-dance-class-latina';
import debounce from 'lodash.debounce';
import history from 'component/core/history';
import Button from 'component/button';
import Pagination from 'component/pagination';
import Input from 'component/form/input';

const getNormalizedQuery = () => {
    return normalizeObject(parse(location.search));
};

const pageLimit = 10;

const SearchPartner = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const registry = useRegistry();

    const [isPending, setIsPending] = useState(true);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());
    const [profileList, setProfileList] = useState<Array<FindPartnerListItem>>([]);
    const [profileListPage, setProfileListPage] = useState<number>(1);
    const [profileTotal, setProfileTotal] = useState<number>(0);
    const [isPendingProfileList, setIsPendingProfileList] = useState<boolean>(false);
    const [isMergeProfileList, setIsMergeProfileList] = useState<boolean>(true);
    const [isNextPage, setIsNextPage] = useState<boolean>(true);

    useEffect(() => {
        if (isPendingProfileList) {
            let filter: FindPartnerListFilter = {
                ...(queryParams.dance_grade_standard_id && { dance_grade_standard_id: queryParams.dance_grade_standard_id }),
                ...(queryParams.dance_grade_latin_id && { dance_grade_latin_id: queryParams.dance_grade_standard_id }),
                ...(queryParams.gender && { gender: queryParams.gender }),
                ...(queryParams.height_gte && { height_min: queryParams.height_gte }),
                ...(queryParams.height_lte && { height_max: queryParams.height_lte }),
                ...(queryParams.location_id && { location_id: queryParams.location_id }),
                ...(queryParams.search && { search: queryParams.search })
            };
            const today = new Date();
            const years = today.getFullYear();

            if (queryParams.age_from) {
                const newDateFrom = today.setFullYear(years - Number(queryParams.age_from) - 1);
                const dateFrom = moment(new Date(newDateFrom)).format('YYYY-MM-DD');
                filter = {
                    birthday_before: dateFrom,
                    ...filter
                };
            }

            if (queryParams.age_to) {
                const newDateTo = today.setFullYear(years - Number(queryParams.age_to));
                const dateTo = moment(new Date(newDateTo)).format('YYYY-MM-DD');
                filter = {
                    birthday_after: dateTo,
                    ...filter
                };
            }

            api.partner.list({ pageNumber: profileListPage, pageSize: pageLimit }, {
                status: 'Accepted',
                ...filter
            }).then((resp) => {
                setProfileTotal(resp.data.count);
                setProfileList(
                    isMergeProfileList
                        ? [...profileList, ...resp.data.results]
                        : resp.data.results
                );
                setIsNextPage(!Boolean(resp.data.next === null));
            }).finally(() => {
                setIsPendingProfileList(false);
                setIsPending(false);
            });
        }
    }, [isPendingProfileList]);

    useEffect(() => {
        setProfileListPage(1);
        setIsMergeProfileList(false);
        setIsPendingProfileList(true);
        setIsPending(true);
    }, [queryParams]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    const onPageChanged = (page: number) => {
        setProfileListPage(page);
        setIsPendingProfileList(true);
    };

    const elContent = useMemo(() => {
        if (!isPendingProfileList && profileList.length === 0) {
            return <span className={cn('search-partner__empty')}>Предложений не найдено</span>;
        }

        return (
            <div className={cn('search-partner__content-photo')}>
                {
                    profileList.map((item) => {
                        return <NewPost key={item.id} person={item} />;
                    })
                }
                <Pagination
                    pageLimit={pageLimit}
                    totalRecords={profileTotal}
                    currentPage={profileListPage}
                    onPageChanged={onPageChanged}
                />
            </div>
        );
    }, [JSON.stringify(profileList), isPendingProfileList, isNextPage]);

    const onChangeForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();
        const data = {
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.gender && { gender: payload.gender.value }),
            ...(payload.age?.value_from && { age_from: payload.age?.value_from }),
            ...(payload.age?.value_to && { age_to: payload.age?.value_to }),
            ...(payload.dance_program_standard && { dance_grade_standard_id: payload.dance_program_standard.value }),
            ...(payload.dance_program_standard && { dance_grade_standard_name: payload.dance_program_standard.label }),
            ...(payload.dance_program_latina && { dance_grade_latin_id: payload.dance_program_latina.value }),
            ...(payload.dance_program_latina && { dance_grade_latin_name: payload.dance_program_latina.label }),
            ...(payload.height_range?.value_from && { height_gte: payload.height_range?.value_from }),
            ...(payload.height_range?.value_to && { height_lte: payload.height_range?.value_to }),
            ...(queryParams.search && payload.search && { search: queryParams.search })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [JSON.stringify(registry.form.getPayload()), queryParams]), 300);

    const onSubmitForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();
        const data = {
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.gender && { gender: payload.gender.value }),
            ...(payload.age?.value_from && { age_from: payload.age?.value_from }),
            ...(payload.age?.value_to && { age_to: payload.age?.value_to }),
            ...(payload.dance_program_standard && { dance_grade_standard_id: payload.dance_program_standard.value }),
            ...(payload.dance_program_standard && { dance_grade_standard_name: payload.dance_program_standard.label }),
            ...(payload.dance_program_latina && { dance_grade_latin_id: payload.dance_program_latina.value }),
            ...(payload.dance_program_latina && { dance_grade_latin_name: payload.dance_program_latina.label }),
            ...(payload.height_range?.value_from && { height_gte: payload.height_range?.value_from }),
            ...(payload.height_range?.value_to && { height_lte: payload.height_range?.value_to }),
            ...(payload.search && { search: payload.search })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [JSON.stringify(registry.form.getPayload())]), 300);

    const onReset = () => {
        registry.form.clearForm();
        window.scrollTo(0, 0);
    };

    const elFilterForm = () => {
        let defaultLocation = null;
        let defaultDance = null;
        let defaultDanceLatin = null;
        let defaultSearch = null;

        if (queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        if (queryParams.dance_grade_standard_id && queryParams.dance_grade_standard_name) {
            defaultDance = {
                value: queryParams.dance_grade_standard_id,
                label: queryParams.dance_grade_standard_name
            };
        }

        if (queryParams.dance_grade_latin_id && queryParams.dance_grade_latin_name) {
            defaultDanceLatin = {
                value: queryParams.dance_grade_latin_id,
                label: queryParams.dance_grade_latin_name
            };
        }

        if (queryParams.search) {
            defaultSearch = queryParams.search;
        }

        const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                onSubmitForm();
            }
        };

        return (
            <div className={cn('search-partner__sidebar')}>
                <Form registry={registry.form} onChange={onChangeForm} onSubmit={onSubmitForm}>
                    <UI.Box padding={true} className={cn('search-partner__box')}>
                        <Input
                            registry={registry.field}
                            defaultValue={defaultSearch}
                            name="search"
                            children={t('global.form.items.search')}
                            direction="column"
                            className={cn('search__input-block')}
                            placeholder={'Введите имя персоны'}
                            isSubmitButton={true}
                            onClickSubmitButton={onSubmitForm}
                            onKeyDown={handleKeyDown}
                        />
                        <InputLocation
                            registry={registry.field}
                            clearable={true}
                            defaultValue={defaultLocation}
                            name="location"
                            children={t('global.form.items.city')}
                            direction="column"
                            className={cn('search__input-block')}
                        />
                        <InputSelect
                            className={cn('search-partner__input-block', 'margin-right')}
                            registry={registry.field}
                            name="gender"
                            direction="column"
                            children="Пол"
                            options={[
                                {value: 'MALE', label: 'Мужской'},
                                {value: 'FEMALE', label: 'Женский'}
                            ]}
                        />
                        <InputRange
                            registry={registry.field}
                            minValue={3}
                            maxValue={99}
                            name="age"
                            direction="column"
                            children="Возраст"
                        />
                        <InputDance
                            registry={registry.field}
                            clearable={true}
                            name="dance_program_standard"
                            defaultValue={defaultDance}
                            children={'Класс стандарт'}
                            direction="column"
                            className={cn('search__input-block')}
                        />
                        <InputDanceLatina
                            registry={registry.field}
                            clearable={true}
                            name="dance_program_latina"
                            defaultValue={defaultDanceLatin}
                            children={'Класс латина'}
                            direction="column"
                            className={cn('search__input-block')}
                        />
                        <InputRange
                            registry={registry.field}
                            minValue={100}
                            maxValue={300}
                            name="height_range"
                            direction="column"
                            children="Рост"
                        />
                        <Button
                            onClick={onReset}
                            type="reset"
                            className={cn('search-partner__button-submit')}
                        >
                            Сбросить
                        </Button>
                    </UI.Box>
                </Form>
            </div>
        );
    };

    return (
        <UI.Main className={cn('search-partner')}>
            <UI.PageHeader text="Поиск партнера" />
            <div className={cn('search-partner__grid')}>
                {
                    isPending
                    ? <Loader />
                    : elContent
                }
                {elFilterForm()}
            </div>
        </UI.Main>
    );
};

export default SearchPartner;
