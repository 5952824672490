import React, { useMemo } from 'react';

import useClassnames from 'hook/use-classnames';

import IconError from 'component/icon/error';

import { IProps } from './types';
import style from './index.pcss';

const Error: React.FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);

    if(props.children) {
        if(props.elIcon) {
            return (
                <div className={cn('error', 'error_icon')}>
                    <IconError width={24} height={24} className={cn('error__icon')} />
                    <span className={cn('error__text')}>{props.children}</span>
                </div>
            );
        }

        return <span className={cn('error')}>{props.children}</span>;
    }

    return null;
};

export default Error;
