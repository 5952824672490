import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const ArrowLeft: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props} viewBox="0 0 512 512">
        <polygon points="353.652,1 413.348,60.696 218.044,256 413.348,451.304 353.652,511 98.652,256 " />
    </svg>
);

export default ArrowLeft;
