import React, { useMemo, useState } from 'react';
import { IProps } from './types';
import { useClassnames } from 'hook/use-classnames';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import UI from 'component/ui';
import history from 'component/core/history';
import style from './index.pcss';
import ADS from 'component/advertisements';
import PersonIcon from 'component/icon/person';
import BellIcon from 'component/icon/bell';

const AdvertisementCard = (props: IProps) => {
    const cn = useClassnames(style);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const adType = useMemo(() => {
        switch (props.item.object_type) {
            case 'suit':
                return 'Продажа костюма';
        }
    }, [props.item]);

    const isOnModeration = useMemo(() => {
        if (props.item.moderation_status === 'On moderation') {
            return true;
        }

        return false;
    }, [props.item]);

    const [isAdModalOpen, setIsAdModalOpen] = useState<boolean>(false);

    const onClickClose = () => {
        setIsAdModalOpen(false);
    };

    const onClickAd = () => {

        if (!isAuth) {
            history.push('/login?from=/advertisements');

            return;
        }

        setIsAdModalOpen(true);
    };

    const elCardModal = useMemo(() => {
        if (isAdModalOpen) {
            return (
                <ADS.Modal
                    item={props.item}
                    type={adType}
                    isOnModeration={isOnModeration}
                    onClickClose={onClickClose}
                    updateList={props.updateList}
                    isOwner={props.isOwner}
                />
            );
        }
    }, [isAdModalOpen]);

    return (
        <>
            {elCardModal}
            <UI.Box className={cn(`${props.className} card`)} onClick={onClickAd}>
                <UI.Content className={cn('card__content')}>
                    <div className={cn('card__content__avatar')}>
                        <div className={cn('card__content__avatar__photo')}>
                            <img
                                alt={'photo'}
                                src={props.item.cover}
                                className={cn('card__content__avatar__photo-item')}
                            />
                        </div>
                    </div>
                    <div className={cn('card__content__info')}>
                        <div className={cn('card__content__info__item')}>
                            <div className={cn('card__content__info__item-icon')}>
                                <PersonIcon className={cn('card__content__info__item-icon-img')} />
                            </div>
                            <div className={cn('card__content__info__item-text')}>
                                <div>{props.item.person.name}</div>
                            </div>
                        </div>
                        <div className={cn('card__content__info__item')}>
                            <div className={cn('card__content__info__item-icon')}>
                                <BellIcon className={cn('card__content__info__item-icon-img')} />
                            </div>
                            <div className={cn('card__content__info__item-text')}>
                                <div>{props.item.event.name}</div>
                            </div>
                        </div>
                        <div className={cn('card__content__info__name')}>
                            <div className={cn('card__content__info__name-text')}>
                                {/* {adType} */}
                                Ознакомиться с подробностями
                            </div>
                        </div>
                        <div className={cn('card__content__info-border', isOnModeration && 'card__content__info-border-moderation')} />
                    </div>
                </UI.Content>
            </UI.Box>
        </>
    );
};

export default AdvertisementCard;
