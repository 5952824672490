import { createAction } from '@reduxjs/toolkit';
import { CartItemsStore } from './types/reducer';

export const addCart = createAction<CartItemsStore>('@@cart/ADD-CART');

export const removeCartItem = createAction<string>('@@cart/REMOVE-CART-ITEM');

export const clearCart = createAction('@@cart/CLEAR-CART');

export default {
    addCart,
    clearCart,
    removeCartItem
};
