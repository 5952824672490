import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useClassnames } from 'hook/use-classnames';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import IconError from 'component/icon/error';
import style from './index.pcss';
import { SharedAlbumListItem, GetSharedAlbumListData } from 'src/api/shared-albums/types';
import api from 'src/api';
import { Page } from 'src/api/base';
import NewAlbum from 'component/new-album';

const ALBUMS_LIMIT = 15;

const InvitedAlbums = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [error, setError] = useState<string | null>(null);

        // photo list;
    const [albumList, setAlbumList] = useState<Array<SharedAlbumListItem>>([]);
    const [albumTotal, setAlbumTotal] = useState<number>();
    const [albumPage, setAlbumPage] = useState<number>(1);
    const [isAlbumPending, setIsAlbumPending] = useState<boolean>(true);
    const [isAlbumLoadMore, setIsAlbumLoadMore] = useState<boolean>(false);
    const [isAlbumNext, setIsAlbumNext] = useState<boolean>(true);

    if (!isAuth) {
        return <Redirect to="/login" />;
    }

    useEffect(() => {
        // Get invited albums
        if (isAlbumPending || isAlbumLoadMore) {
            const page: Page = {
                pageNumber: albumPage,
                pageSize: ALBUMS_LIMIT
            };

            const data: GetSharedAlbumListData = {
            };

            api.shared.getSharedAlbumList(page, data)
                .then((resp) => {
                    setAlbumTotal(resp.data.count);
                    setAlbumList(isAlbumLoadMore ?
                        [...albumList, ...resp.data.results] : resp.data.results
                    );

                    setIsAlbumLoadMore(false);
                    setIsAlbumPending(false);

                    setIsAlbumNext(!!resp.data.next);
                })
                .catch(() => {
                    setIsAlbumLoadMore(false);
                    setIsAlbumPending(false);

                    setIsAlbumNext(false);
                });
        }
    }, [isAlbumPending, isAlbumLoadMore]);

    const onClickMoreAlbums = (e: MouseEvent): void => {
        e.preventDefault();

        // Get more invited albums
        if (isAlbumNext && !isAlbumPending && !isAlbumLoadMore) {
            setAlbumPage((prev) => prev + 1);
            setIsAlbumLoadMore(true);
        }
    };

    const elButtonBeforeAlbums = () => {
        if (!isAlbumPending && !isAlbumLoadMore && albumList?.length) {
            if(albumList?.length === albumTotal) {
                return (
                    <span className={cn('invite__empty')}>{t('route.invited-albums.end-empty')}</span>
                );
            }

            return (
                <Button
                    disabled={isAlbumPending || isAlbumLoadMore}
                    isLoading={isAlbumPending || isAlbumLoadMore}
                    isSecondary={true}
                    className={cn('invite__button-before')}
                    onClick={onClickMoreAlbums}
                >
                    Загрузить ещё
                </Button>
            );
        }
    };

    const elContent = useMemo(() => {
        if (isAlbumPending || isAlbumLoadMore) {
            return <Loader />;
        }

        if (albumList.length) {
            return (
                <div className={cn('invite__items')}>
                    {albumList.map((item, index) => (
                        <NewAlbum
                            key={index}
                            name={item.name}
                            id={item.id}
                            isInvited={true}
                            photos={item.photos}
                            photos_count={albumTotal}
                            className={cn('invite__post')}
                        />
                    ))}
                </div>
            );
        }

        return (
            <div className={cn('invite__empty-container')}>
                <UI.Box padding={true} className={cn('invite__empty')}>
                    {t('route.invited-albums.album-empty')}
                </UI.Box>
            </div>
        );
    }, [JSON.stringify(albumList), isAlbumPending]);

    const elError = useMemo(() => {
        if (error) {
            return (
                <div className={cn('invite__error')}>
                    <IconError className={cn('invite__error-icon')} />
                    {error}
                </div>
            );
        }
    }, [error]);

    return (
        <UI.Main className={cn('invite')}>
            <UI.PageHeader text={t('route.invited-albums.header')} />
            {elError}
            {elContent}
            {elButtonBeforeAlbums()}
        </UI.Main>
    );
};

export default InvitedAlbums;
