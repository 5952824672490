import React, { useMemo, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useClassnames } from 'hook/use-classnames';
import IconUser from 'component/icon/user';

import { IProps } from './types';
import style from './index.pcss';

const Avatar = (props: IProps) => {
    const cn = useClassnames(style, props.className, true);
    const size = useMemo(() => props.size || 40, [props.size]);

    const elImage = useMemo(() => {
        if(props.imgSrc) {
            return (
                <img
                    src={props.imgSrc}
                    alt={props.imgAlt}
                    className={cn('avatar__image')}
                />
            );
        }

        return <IconUser className={cn('avatar__placeholder')} />;
    }, [props.imgSrc, props.imgAlt, size]);

    const attrs = {
        onClick: props.onClick,
        style  : {
            minWidth : size,
            width    : size,
            minHeight: size,
            height   : size
        },
        className: cn('avatar', {
            'avatar_interactive': props.onClick
        }),
        children: elImage
    };

    if(props.linkTo) {
        return (
            <Link
                to={props.linkTo}
                title={props.linkTitle || props.imgAlt}
                {...attrs}
            />
        );
    }

    return <div {...attrs} />;
};

export default Avatar;
