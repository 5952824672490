import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';

import { ISelectState } from './types';

const styles = (isIcon?: boolean) => {
    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);

    return {
        placeholder: (provided: CSSProperties): CSSProperties => ({
            ...provided,
            color     : '#a9a9a9',
            fontSize  : '14px',
            lineHeight: isMobile ? '22px' : '48px',
            marginLeft: '4px'
        }),
        container: (provided: CSSProperties, state: ISelectState): CSSProperties => ({
            ...provided,
            border      : 'none',
            borderRadius: '4px',
            flexGrow    : 1,
            borderColor : state.isFocused ? '#f7b322' : '#d9d9d9',
            boxShadow   : state.isFocused ? '0 0 0 1px #f7b322' : 'none'
        }),
        control: (provided: CSSProperties, state: ISelectState): CSSProperties => ({
            ...provided,
            height         : '100%',
            paddingLeft    : isIcon ? isMobile ? '40px' : '80px' : '',
            backgroundColor: state.isDisabled ? 'rgba(224, 224, 224, 0.25)' : '#fff',
            justifyContent : 'center',
            color          : '#414854',
            fontSize       : '14px',
            fontWeight     : 400
        }),
        menu: (provided: CSSProperties): CSSProperties => ({
            ...provided,
            zIndex: 2
        }),
        singleValue: (provided: CSSProperties): CSSProperties => ({
            ...provided,
            color: '#414854'
        }),
        menuList: (provided: CSSProperties): CSSProperties => ({
            ...provided,
            color   : '#414854',
            fontSize: '14px'
        })
    };
};

export default styles;
