import React, { useCallback, useEffect, useState, useMemo, useRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBook, faImages, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { useCancelTokens } from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Loader from 'component/loader';
import { useRegistry } from 'component/form';
import { IValue } from 'component/form/input-clubs/types';
import Button from 'component/button';
import { key as keyUser } from 'store/reducers/user/reducer';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import Modal from 'component/modal';
import SideAction from 'component/wireframes/side-action';
import AlbumModal from 'component/modal/album';
import ActionButton from 'component/button/circular/action-button';
import ButtonCircular from 'component/button/circular';
import { useAlert } from 'component/alert/provider';
import { IStore } from 'store/reducers/types/reducers';
import {
    DataFilesTmpFacesItem,
    DataFilesTmpFacesPersonsItem
} from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { IPayload } from 'component/form/types';

import style from './index.pcss';
import PhotoSaleModal from 'component/modal/sale';
import TarifForm from 'route/album/tarif-form';
import CustomAlert from 'component/custom-alert';
import { Page } from 'src/api/base';
import api from 'src/api';
import { AlbumListItem, CreateAlbum, GetAlbumListData } from 'src/api/albums/types';
import NewAlbum from 'component/new-album';
import { CreateTempFile } from 'src/api/files/types';

const ALBUMS_LIMIT = 6;

const Dashboard = () => {
    const cn = useClassnames(style);
    const [tokenEdit, tokenEditUser, tokenUserSelfInfo] = useCancelTokens(3);
    const [tokenAlbums, tokenCreateAlbum] = useCancelTokens(2);
    const { t } = useTranslation();
    const { field, form } = useRegistry();
    const { show, hide } = useAlert();
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const isMobile = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].tablet);

    const [validity, setValidity] = useState<boolean>(false);
    const [idsList, setIdsList] = useState<Array<number>>([]);

    const registry = useRegistry();
    const [modalForm, setModalForm] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [tarifModal, setTarifModal] = useState<boolean>(false);
    const [albumID, setAlbumID] = useState<number>(0);
    const tarifRegistry = useRegistry();

    const [image, setImage] = useState<CreateTempFile | null>(null);

    const [isAlbumPending, setIsAlbumPending] = useState<boolean>(false);
    const [isAlbumLoadMore, setIsAlbumLoadMore] = useState<boolean>(false);
    const [albumTotal, setAlbumTotal] = useState<number>(0);
    const [albumList, setAlbumList] = useState<Array<AlbumListItem>>([]);
    const [albumPage, setAlbumPage] = useState<number>(1);
    const [isAlbumNext, setIsAlbumNext] = useState<boolean>(true);
    const [isAlbumMerge, setIsAlbumMerge] = useState<boolean>(false);
    const [photoSaleModal, setPhotoSaleModal] = useState<boolean>(false);

    useEffect(() => {
        if (isAlbumPending || isAlbumLoadMore) {
            const formParams = getFormParams(form.getPayload());

            const page: Page = {
                pageNumber: albumPage,
                pageSize: ALBUMS_LIMIT
            };

            const data: GetAlbumListData = {
                ...(formParams.year && { year: formParams.year })
            };

            if (formParams.year) {
                localStorage.setItem('album_year', formParams.year);
            } else {
                localStorage.removeItem('album_year');
            }

            api.albums.getAlbumList(page, data)
                .then((resp) => {
                    setAlbumTotal(resp.data.count);
                    setAlbumList(isAlbumMerge ?
                        [...albumList, ...resp.data.results] : resp.data.results);
                    setIsAlbumPending(false);
                    setIsAlbumLoadMore(false);

                    setIsAlbumMerge(false);
                    setIsAlbumNext(!!resp.data.next);
                })
                .catch(() => {
                    setIsAlbumPending(false);
                    setIsAlbumLoadMore(false);
                    setIsAlbumMerge(false);
                    setIsAlbumNext(false);
                });
        }
    }, [isAlbumPending, isAlbumLoadMore]);

    const onShowAlert = (type?: string, text?: string, isCustom?: boolean) => {
        if (type && text && !isCustom) {
            setShowWarningModal(false);
            show(text, type);
        }
        if (isCustom) {
            hide();
            setShowWarningModal(true);
        }

    };

    useEffect(() => {
        return () => {
            tokenEdit.remove();
            tokenEditUser.remove();
            tokenUserSelfInfo.remove();
            tokenAlbums.remove();
            tokenCreateAlbum.remove();
        };
    }, []);

    const onClickMoreAlbums = (e: MouseEvent): void => {
        e.preventDefault();

        if (isAlbumNext) {
            setAlbumPage((prev) => prev + 1);
            setIsAlbumMerge(true);
            setIsAlbumLoadMore(true);
        }
    };

    const onChangeForm = debounce(useCallback(() => {
        if(form.checkValidity()) {
            setAlbumPage(1);
            setIsAlbumNext(true);
            setIsAlbumPending(true);
        }
    }, [validity, image]), 300);

    const onFileLoaded = (file: CreateTempFile): void => {
        setImage(file);
    };

    const getFormParams = (data: IPayload) => {
        const faces = data.photo?.tmp_faces;
        let personsNew: Array<DataFilesTmpFacesPersonsItem> = [];

        if(faces?.length) {
            personsNew = faces.reduce((acc: Array<DataFilesTmpFacesPersonsItem>, curr: DataFilesTmpFacesItem) => {
                if(curr.persons?.length) {
                    curr.persons.forEach((person) => {
                        const isExist = acc.find((item) => item.id === person.id);

                        if(person.id && !isExist) {
                            acc.push(person);
                        }
                    });
                }

                return acc;
            }, []);
        }

        return {
            ...(personsNew.length && { person_ids: personsNew.map((item) => item.id) }),
            ...(data.name && { person_name: data.name }),
            ...(data.location?.value && { location_id: data.location?.value }),
            ...(data.event?.value && { event_id: data.event.value }),
            ...(data.price_range?.value_from && { price_from: data.price_range?.value_from }),
            ...(data.price_range?.value_to && { price_to: data.price_range?.value_to }),
            ...(data.event_date_range?.date_from && { date_from: data.event_date_range?.date_from }),
            ...(data.event_date_range?.date_to && { date_end: data.event_date_range?.date_to }),
            ...(data.clubs?.length && { club_ids: data.clubs.map((club: IValue) => club.value) }),
            ...(data.color && { color_ids: data.color }),
            ...(data.sport_number && { number: data.sport_number }),
            ...(data.time && { time: data.time }),
            ...(data.timeRange?.valueFrom && { time_from: data.timeRange.valueFrom }),
            ...(data.timeRange?.valueTo && { time_to: data.timeRange.valueTo }),
            ...(data.album_year?.value && { year: data.album_year.value })
        };
    };

    useEffect(() => {
        setIsAlbumMerge(false);
        setAlbumPage(1);
        setIsAlbumNext(true);
        setIsAlbumPending(true);
    }, []);

    if(!userId) {
        return <Redirect to="/login" />;
    }

    const onSubmit = useCallback(() => {
        const fields = registry.form.getFields();

        if (fields) {
            const fieldName = fields.get('album_name');
            const fieldDescription = fields.get('album_description');
            const fieldPrivate = fields.get('album_private');
            const fieldPrepaid = fields.get('album_prepaid');
            const fieldVideo = fields.get('album_video');

            if (fieldName) {
                if (fieldName.value === t('global.reserved-names.my-photo')) {
                    setError(t('global.massage.reserved-name'));
                } else {
                    const params: CreateAlbum = {
                        name: String(fieldName.value),
                        ...(fieldDescription?.value.length && { description: String(fieldDescription.value) }),
                        is_private: fieldPrivate?.value,
                        is_prepaid: fieldPrepaid?.value,
                        is_video: fieldVideo?.value
                    };

                    api.albums.createAlbum(params)
                        .then((resp) => {
                            setModalForm(false);

                            setAlbumPage(1);
                            setIsAlbumMerge(false);
                            setIsAlbumPending(true);

                            onShowAlert('success', t('route.dashboard.photos.modal.message-ok'));
                        })
                        .catch((err) => {
                            setError('Ошибка при выполнении запроса');
                            if (err.response.status === 404) {
                                onShowAlert('warning', `${Object.values(err.response.data)[0]}`, true);
                            } else if (err.response.data.status_code) {
                                onShowAlert('warning', `${Object.values(err.response.data)[0]}`);
                            } else {
                                onShowAlert('warning', 'Что-то пошло не так.');
                            }

                        });
                }
            }
        }
    }, [modalForm]);

    const onClickModal = useCallback(() => {
        setModalForm(true);
    }, [modalForm]);

    const onClickCloseModal = (): void => {
        setModalForm(false);
    };

    const elModal = useMemo(() => {
        if (modalForm) {
            return (
                <AlbumModal
                    title={'Создать альбом'}
                    registry={registry}
                    onClickClose={onClickCloseModal}
                    onSubmitClick={onSubmit}
                />
            );
        }
    }, [error, modalForm]);

    const elTarifModal = useMemo(() => {
        if (tarifModal) {
            const closeModal = () => setTarifModal(false);

            return (
                <Modal onClickClose={closeModal}>
                    <TarifForm
                        registry={tarifRegistry}
                        albumId={albumID}
                        onSubmit={closeModal}
                    />
                </Modal>
            );
        }
    }, [tarifModal, albumID]);

    const elAlbumContent = useMemo(() => {
        if (isAlbumPending) {
            return <Loader />;
        }

        if(albumList.length) {
            return (
                <div className={cn('photos__items')}>
                    {albumList.map((item, index) => {
                        return (
                            <NewAlbum
                                key={index}
                                name={item.name}
                                id={item.id}
                                isInvited={false}
                                photos={item.photos}
                                photos_count={albumTotal}
                                className={cn('invite__post')}
                            />
                        );
                    })}
                </div>
            );
        }
    }, [JSON.stringify(albumList), isAlbumPending]);

    const elButtonBeforeAlbums = () => {
        if(isAlbumLoadMore) {
            return <Loader />;
        }

        if (!isAlbumPending && !isAlbumLoadMore && albumList?.length && isAlbumNext) {
            if(!(albumList?.length - 1 === albumTotal)) {
                return (
                    <Button
                        disabled={isAlbumPending}
                        isLoading={isAlbumPending}
                        isSecondary={true}
                        className={cn('home__button-before')}
                        onClick={onClickMoreAlbums}
                    >
                        Загрузить ещё
                    </Button>
                );
            }
        }
    };

    const elSidebarButton = () => {
        return (
            <div>
                <UI.Box padding={true} className={cn('photos__sidebar_box')}>
                    <Button
                        to="/upload-photo"
                        className={cn('photos__sidebar_button')}
                    >
                        <div className={cn('photos__sidebar_button-icon')}>
                            <FontAwesomeIcon icon={faPlusSquare} />
                        </div>
                        <div className={cn('photos__sidebar_button-text')}>
                            {t('route.dashboard.photos.button-photo-upload')}
                        </div>
                    </Button>
                    <Button
                        to="/upload-video"
                        className={cn('photos__sidebar_button')}
                    >
                        <div className={cn('photos__sidebar_button-icon')}>
                            <FontAwesomeIcon icon={faPlusSquare} />
                        </div>
                        <div className={cn('photos__sidebar_button-text')}>
                            {t('route.dashboard.photos.button-video-upload')}
                        </div>
                    </Button>
                    <Button
                        isSecondary={true}
                        className={cn('photos__sidebar_button')}
                        onClick={onClickModal}
                    >
                        <div className={cn('photos__sidebar_button-icon')}>
                            <FontAwesomeIcon icon={faPlusSquare} />
                        </div>
                        <div className={cn('photos__sidebar_button-text')}>
                            {t('route.dashboard.photos.button-album-create')}
                        </div>
                    </Button>
                    <Button
                        className={cn('photos__sidebar_button')}
                        onClick={() => setPhotoSaleModal(true)}
                    >
                        Скидки
                    </Button>
                </UI.Box>
            </div>
        );
    };

    const elAddBtn = () => {
        if (isMobile || isTablet) {
            return (
                <ActionButton
                    className={cn('photos__add-btn')}
                    icon={faPlus}
                >
                    <ButtonCircular
                        to="/upload"
                        className={cn('add-btn__item')}
                        text={'Загрузить фото'}
                        isSmall={true}
                    >
                        <FontAwesomeIcon icon={faImages} />
                    </ButtonCircular>
                    <ButtonCircular
                        className={cn('add-btn__item')}
                        text={'Создать альбом'}
                        isSmall={true}
                        onClick={onClickModal}
                    >
                        <FontAwesomeIcon icon={faBook} />
                    </ButtonCircular>
                </ActionButton>
            );
        }
    };

    const onClickHideWarningModal = () => {
        setShowWarningModal(false);
    };

    const elWarningModal = useMemo(() => {
        if (showWarningModal) {
            return (
                <CustomAlert onClick={onClickHideWarningModal} />
            );
        }
    }, [showWarningModal]);

    const elPhotoSaleModal = useMemo(() => {
        if (photoSaleModal) {
            return (
                <PhotoSaleModal
                    registry={registry}
                    onClickClose={() => setPhotoSaleModal(false)}
                />
            );
        }
    }, [photoSaleModal]);

    return (
        <SideAction
            title={t('route.dashboard.photos.header')}
            content={(
                <div>
                    {elAddBtn()}
                    <section className={cn('photos__top')}>
                        <UI.Main className={cn('photos__top-content')}>
                            {elAlbumContent}
                            {elButtonBeforeAlbums()}
                        </UI.Main>
                    </section>
                </div>
            )}
            sidebar={elSidebarButton()}
            idsList={idsList}
            form={form}
            field={field}
            albumYear={true}
            onChangeForm={onChangeForm}
            onChangeValidity={setValidity}
            onFileLoaded={onFileLoaded}
        >
            {elWarningModal}
            {elModal}
            {elPhotoSaleModal}
            {elTarifModal}
        </SideAction>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Dashboard;
