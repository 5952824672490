import { get } from 'js-cookie';

export interface IParams {
    ['data-qa']?: string
}

const isQA = get('qa') === 'enable';

export default (params: string | IParams): IParams => {
    const result: IParams = {};

    if(isQA) {
        if(typeof params === 'string') {
            result['data-qa'] = params;
        } else {
            return params;
        }
    }

    return result;
};
