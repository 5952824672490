import { AxiosResponse, AxiosRequestConfig } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { FilesVideoItem, CreateFilesVideoData, FilesAPIType, CreateFilesPhotoData, FilesPhotoItem, CreateTempFileData, CreateTempFile } from './types';

const createFilesPhoto = (data: FormData, config: AxiosRequestConfig): Promise<AxiosResponse<FilesPhotoItem>> => {
    return httpClient.post<FilesPhotoItem>('/api/v2/files/photos/', data, config);
};

const getFilesPhotoRetrieve = (id: string): Promise<AxiosResponse<FilesPhotoItem>> => {
    return httpClient.get<FilesPhotoItem>(`/api/v2/files/photos/${id}`);
};

const destroyFilesPhoto = (id: string): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/files/photos/${id}`);
};

const createTempFile = (data: FormData): Promise<AxiosResponse<CreateTempFile>> => {
    return httpClient.post<CreateTempFile>(`/api/v2/files/tmp-files/`, data);
};

const getTempFile = (id: string): Promise<AxiosResponse> => {
    return httpClient.get(`/api/v2/files/tmp-files/${id}/`);
};

const createFilesVideo = (data: CreateFilesVideoData): Promise<AxiosResponse<FilesVideoItem>> => {
    return httpClient.post<FilesVideoItem>('/api/v2/files/videos/', data);
};

const getFilesVideoRetrieve = (id: string): Promise<AxiosResponse<FilesVideoItem>> => {
    return httpClient.get<FilesVideoItem>(`/api/v2/files/videos/${id}/`);
};

export default {
    createFilesPhoto,
    getFilesPhotoRetrieve,
    destroyFilesPhoto,
    createTempFile,
    getTempFile,
    createFilesVideo,
    getFilesVideoRetrieve
} as FilesAPIType;
