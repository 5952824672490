import { combineReducers } from 'redux';

import deviceInfoReducer, { key as deviceInfoKey } from 'store/reducers/deviceInfo/reducer';
import userReducer, { key as userKey } from 'store/reducers/user/reducer';
import cartReducer, { key as cartKey } from 'store/reducers/cart/reducer';
import localConfigReducer, { key as localConfigKey } from 'store/reducers/localConfig/reducer';
import taskReducer, { key as taskKey } from 'store/reducers/task/reducer';

import { IStore } from './types/reducers';

export default combineReducers<IStore>({
    [userKey]      : userReducer,
    [deviceInfoKey]: deviceInfoReducer,
    [cartKey]: cartReducer,
    [localConfigKey]: localConfigReducer,
    [taskKey]: taskReducer
});
