import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { parse, stringify } from 'query-string';
import moment from 'moment';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import cropImage from 'component/helper/crop-image';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import ErrorBlock from 'component/error';
import Carousel from 'component/carousel';
import MiniInfo from 'component/mini-info';

import { smartSearchPhotos } from 'component/api/photo';
import { DataPhotoListItem } from 'component/api/types/api/photo/smart-search/photos/get/code-200';
import { IStore } from 'store/reducers/types/reducers';
import { key as cartKey } from 'store/reducers/cart/reducer';
import { CartItemsStore, IStore as IStoreCart } from 'store/reducers/cart/types/reducer';

import { DataEventsPhotosItem } from 'component/api/types/api/main/smart-search/events/get/code-200';

import style from './index.pcss';
import api from 'src/api';
import { addCart } from 'store/reducers/cart/actions';
import { CartItem } from 'src/api/cart/types';

const PhotoSearch = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, tokenRelative] = useCancelTokens(2);
    const history = useHistory();
    const dispatch = useDispatch();
    const qs = useMemo(() => parse(history.location.search), [history.location.search]);

    const [pending, setPending] = useState<boolean>(true);
    const [relativePending, setRelativePending] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [list, setList] = useState<Array<DataPhotoListItem>>();
    const [listRelative, setListRelative] = useState<Array<DataPhotoListItem>>();
    const [currentPhoto, setCurrentPhoto] = useState<DataPhotoListItem | null>(null);
    const [selected, setSelected] = useState<number>(0);

    const cart = useSelector<IStore, IStoreCart>((store) => store[cartKey]);

    const [cartItemsListPage, setCartItemsListPage] = useState<number>(1);
    const [isCartItemsListLoading, setIsCartItemsListLoading] = useState<boolean>(false);
    const [isCartItemsListLoadMore, setIsCartItemsListLoadMore] = useState<boolean>(false);
    const [cartItemsList, setCartItemsList] = useState<Array<CartItem>>([]);

    useEffect(() => {
        if (isCartItemsListLoading || isCartItemsListLoadMore) {
            api.cart.getCartItemsList({
                pageNumber: cartItemsListPage
            })
            .then((resp) => {
                const filteredList: CartItemsStore = {
                    count: 0,
                    items: []
                };

                setCartItemsList((prev) => isCartItemsListLoadMore ?
                    [...prev, ...resp.data.results] : resp.data.results);
                if (!resp.data.next) {
                    const newList = isCartItemsListLoadMore ? [...cartItemsList, ...resp.data.results].map((item) => filteredList.items.push(item))
                        : resp.data.results.map((item) => filteredList.items.push(item));
                    filteredList.count = resp.data.count;
                    dispatch(addCart(filteredList));
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);
                } else {
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);

                    setCartItemsListPage((prev) => prev + 1);
                    setIsCartItemsListLoadMore(true);
                }
            });
        }
    }, [isCartItemsListLoading, isCartItemsListLoadMore]);

    const _request = useCallback(() => {
        setPending(true);

        smartSearchPhotos({
            params     : qs,
            cancelToken: token.new()
        })
            .then((resp) => {
                setList(resp.photo_list);
                setPending(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setError(err.message);
                    setPending(false);
                }
            });
    }, [JSON.stringify(qs)]);

    const _requestRelative = useCallback(() => {
        setRelativePending(true);

        const { event_id, ...otherQsParams} = qs;

        smartSearchPhotos({
            params     : {
                ...otherQsParams,
                exclude_event_id: event_id
            },
            cancelToken: tokenRelative.new()
        })
            .then((resp) => {
                setListRelative(resp.photo_list);
                setRelativePending(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setRelativePending(false);
                }
            });
    }, [JSON.stringify(qs), JSON.stringify(currentPhoto)]);

    const onClickAddToCart = useCallback(() => {
        if(currentPhoto) {
            api.cart.createCartItem('photo', currentPhoto.id)
                .then(() => {
                    setCartItemsListPage(1);
                    setIsCartItemsListLoading(true);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [JSON.stringify(currentPhoto)]);

    const onClickRemoveFromCart = useCallback(() => {
        if(currentPhoto) {
            api.cart.destroyCartItem(currentPhoto.id)
            .then(() => {
                setCartItemsListPage(1);
                setIsCartItemsListLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
        }
    }, [JSON.stringify(currentPhoto)]);

    const onSelectSlide = useCallback((slide: DataEventsPhotosItem) => {
        const newCurrentPhoto = list?.find((item) => item.url === slide.url) || null;
        const personIds = newCurrentPhoto?.faces?.map((face) => face?.person?.id);

        if(newCurrentPhoto?.id) {
            history.replace({
                search: stringify({
                    ...qs,
                    cursor  : newCurrentPhoto.id,
                    person_ids: personIds
                }, {
                    arrayFormat: 'none'
                })
            });
        }

        setCurrentPhoto(newCurrentPhoto);
    }, [JSON.stringify(list)]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('search-photo__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    const elRemoveButton = (show: boolean) => {
        if(show) {
            return (
                <Button
                    className={cn('search-photo__button', 'search-photo__button_remove')}
                    isSecondary={true}
                    onClick={onClickRemoveFromCart}
                >
                    {t('route.event.sidebar.remove-from-cart')}
                </Button>
            );
        }
    };

    const elBuy = useMemo(() => {
        const alreadyInCart = cart.items?.find((item) => item.photo.id === currentPhoto?.id);
        const title = currentPhoto?.purchased_status === 'OWNER' ? 'Нельзя купить собственное фото' : '';
        const mainButtonProps = {
            onClick  : alreadyInCart ? undefined : onClickAddToCart,
            to       : alreadyInCart ? '/cart' : undefined,
            className: cn('search-photo__button'),
            disabled : !currentPhoto || currentPhoto?.purchased_status === 'OWNER',
            title,
            children : alreadyInCart ? t('route.event.sidebar.already-in-cart') : t('route.event.sidebar.add-to-cart')
        };

        return (
            <UI.Box padding={true} className={cn('search-photo__like')}>
                <UI.BoxHeader className={cn('search-photo__like-header')}>{t('route.event.sidebar.like')}</UI.BoxHeader>
                <p className={cn('search-photo__like-text')}>{t('route.event.sidebar.buy', { price: currentPhoto?.price })}</p>
                {elRemoveButton(!!alreadyInCart)}
                <Button {...mainButtonProps} />
            </UI.Box>
        );
    }, [JSON.stringify(currentPhoto), JSON.stringify(cart)]);

    const elPersons = useMemo(() => {
        const faces = currentPhoto?.faces;

        if(faces?.length) {
            return (
                <UI.Box padding={true} className={cn('search-photo__persons')}>
                    <UI.BoxHeader className={cn('search-photo__persons-header')}>Персоны на фото</UI.BoxHeader>
                    {
                        faces.map((face, idx) => {
                            if(face.person) {
                                return (
                                    <MiniInfo
                                        titleTo={`/persons/${face.person.id}`}
                                        key={idx}
                                        className={cn('search-photo__person')}
                                        title={face.person.name}
                                        desc={face.person.club?.name || t('global.massage.empty.club-empty')}
                                        descTo={face.person.club?.id ? `/clubs/${face.person.club?.id}` : undefined}
                                        avatar={{
                                            imgSrc: face.person.photo_url,
                                            linkTo: `/persons/${face.person.id}`
                                        }}
                                    />
                                );
                            }
                        })
                    }
                </UI.Box>
            );
        }

        return null;
    }, [JSON.stringify(currentPhoto)]);

    const elPhotographer = () => {
        const fname = currentPhoto?.photographer?.first_name;
        const lname = currentPhoto?.photographer?.last_name;

        if(currentPhoto?.photographer?.id) {
            return (
                <Link to={`/photographer/${currentPhoto.photographer.id}`} className={cn('search-photo__info-item-text')}>
                    {`${fname} ${lname}`}
                </Link>
            );
        }

        return <span>{fname && lname ? `${fname} ${lname}` : 'Фотограф не указан'}</span>;
    };

    const elInfo = useMemo(() => {
        return (
            <UI.Box padding={true} className={cn('search-photo__info')}>
                <UI.BoxHeader className={cn('search-photo__info-header')}>О снимке</UI.BoxHeader>
                <div className={cn('search-photo__info-list')}>
                    <div className={cn('search-photo__info-list-item')}>
                        <span>Событие</span>
                        <span>{currentPhoto?.event?.name || 'Событие не указано'}</span>
                    </div>
                    <div className={cn('search-photo__info-list-item')}>
                        <span>Дата</span>
                        <span>{currentPhoto?.created_at ? moment(currentPhoto?.created_at).format('LL') : 'Дата не указана'}</span>
                    </div>
                    <div className={cn('search-photo__info-list-item')}>
                        <span>Фотограф</span>
                        {elPhotographer()}
                    </div>
                </div>
            </UI.Box>
        );
    }, [JSON.stringify(currentPhoto)]);

    const elSidebar = useMemo(() => {
        return (
            <div className={cn('search-photo__sidebar')}>
                {elBuy}
                {elPersons}
                {elInfo}
            </div>
        );
    }, [JSON.stringify(currentPhoto), JSON.stringify(cart)]);

    const elContent = useMemo(() => {
        if(!pending) {
            const eventName = currentPhoto?.event?.name;

            return (
                <UI.Box padding={true} className={cn('search-photo__content-box')}>
                    {eventName && <h2 className={cn('search-photo__block-header')}>{eventName}</h2>}
                    <Carousel
                        disableButtons={true}
                        noScroll={true}
                        onSelectSlide={onSelectSlide}
                        isModal={false}
                        selected={selected}
                        className={cn('search-photo__carousel')}
                    >
                        {list}
                    </Carousel>
                </UI.Box>
            );
        }
    }, [JSON.stringify(list), pending, JSON.stringify(currentPhoto)]);

    const elRelative = useMemo(() => {
        if(relativePending) {
            return <Loader />;
        }

        if(listRelative?.length) {
            return (
                <UI.Box padding={true} className={cn('search-photo__relative')}>
                    <UI.BoxHeader className={cn('search-photo__relative-header')}>Персоны на других событиях</UI.BoxHeader>
                    <div className={cn('search-photo__relative-list')}>
                        {
                            listRelative.map((rphoto) => {
                                const personIds = rphoto?.faces?.map((face) => face?.person?.id);
                                const params = stringify({
                                    ...(rphoto.event?.id && { event_id: rphoto.event?.id }),
                                    ...(personIds && { person_ids: personIds }),
                                    cursor: rphoto.id
                                });

                                return (
                                    <a
                                        key={rphoto.id}
                                        href={`/search-photo?${params}`}
                                        className={cn('search-photo__relative-item')}
                                    >
                                        <img src={cropImage(rphoto.url, '310x200')} />
                                    </a>
                                );
                            })
                        }
                    </div>
                </UI.Box>
            );
        }
    }, [JSON.stringify(listRelative), relativePending, JSON.stringify(qs)]);

    useEffect(() => {
        _request();
        _requestRelative();

        return () => {
            token.remove();
            tokenRelative.remove();
        };
    }, []);

    useEffect(() => {
        _requestRelative();
    }, [JSON.stringify(currentPhoto)]);

    useEffect(() => {
        const { cursor } = qs;

        if(cursor) {
            const newSelected = list ? list.findIndex((item) => item.id === parseInt(cursor as string, 10)) : null;

            setSelected(newSelected || 0);
        }
    }, [JSON.stringify(qs), JSON.stringify(list)]);

    if(pending) {
        return <Loader className={cn('search-photo__loader')} />;
    }

    return (
        <UI.Main className={cn('search-photo')}>
            <div className={cn('search-photo__grid')}>
                <div className={cn('search-photo__content')}>
                    {elContent}
                    {elRelative}
                    {elError}
                </div>
                {elSidebar}
            </div>
        </UI.Main>
    );
};

export default PhotoSearch;
