import React, { useState, useEffect, useMemo } from 'react';
import { useClassnames } from 'hook/use-classnames';
import style from './index.pcss';
import UI from 'component/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import DownloadModalItem from './item';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as taskKey } from 'store/reducers/task/reducer';
import { YandexTasksErrorsRetrieve, YandexTasksListData, YandexTasksListItem } from 'src/api/tasks/types';
import useIntersect from 'hook/use-intersect';
import api from 'src/api';
import { Page } from 'src/api/base';
import Loader from 'component/loader';
import Modal from 'component/modal';
import DownloadModalError from './error';

const DownloadModal = () => {
    const cn = useClassnames(style);

    const [isErrorsModalActive, setIsErrorsModalActive] = useState<string | null>(null);
    const [errorsList, setErrorsList] = useState<Array<YandexTasksErrorsRetrieve>>([]);
    const [isTaskListPending, setIsTaskListPending] = useState<boolean>(true);
    const [isShowContent, setIsShowContent] = useState<boolean>(true);
    const [listPending, setListPending] = useState<boolean>(false);
    const [isNextPage, setIsNextPage] = useState<boolean>(false);
    const [isTaskListMerge, setIsTaskListMerge] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [taskList, setTaskList] = useState<Array<YandexTasksListItem>>([]);
    const tasksCount = useSelector<IStore, number | undefined>((store) => store[taskKey].count);

    const onClickCollapse = () => {
        setIsShowContent((prev) => !prev);
    };

    useEffect(() => {
        setCurrentPage(1);
        setIsTaskListMerge(false);
        setListPending(true);
        setIsTaskListPending(true);
    }, [tasksCount]);

    useEffect(() => {
        if (isTaskListPending) {
            const page: Page = {
                pageNumber: currentPage,
                pageSize: 5
            };

            const filter: YandexTasksListData = {
                seen: false
            };

            api.tasks.getYandexTasksList(page, filter)
                .then((resp) => {
                    setTaskList((prev) => isTaskListMerge ? [...prev, ...resp.data.results] : resp.data.results);
                    setIsNextPage(!!resp.data.next);
                    setIsTaskListPending(false);
                    setListPending(false);
                    setIsTaskListMerge(false);
                })
                .catch((e) => {
                    console.warn(e.message);
                    setIsNextPage(false);
                    setIsTaskListPending(false);
                    setListPending(false);
                });
        }
    }, [isTaskListPending]);

    const onUpdateList = () => {
        setIsNextPage(true);
        setCurrentPage(1);
        setIsTaskListMerge(false);
        setIsTaskListPending(true);
    };

    const $LoadMoreTasks = useIntersect((entry) => {
        if(entry.isIntersecting && !isTaskListPending) {
            setCurrentPage((prev) => prev + 1);
            setIsTaskListMerge(true);
            setListPending(true);
            setIsTaskListPending(true);
        }
    }, {
        rootMargin: '50px 0px'
    });

    const elLoadMoreEvent = () => {
        if (!listPending && taskList?.length && tasksCount && isNextPage) {
            if (tasksCount > taskList.length) {
                return <Loader ref={$LoadMoreTasks} />;
            }
        }
    };

    const onClickClose = () => {
        setIsErrorsModalActive(null);
        setErrorsList([]);
    };

    const elTaskList = useMemo(() => {
        if (isShowContent) {
            return (
                <div className={cn('download__modal__content')}>
                    {taskList?.map((item) => {
                        if (item && !item.seen) {
                            return (
                                <DownloadModalItem
                                    onClickUpdateList={onUpdateList}
                                    isOpen={isShowContent}
                                    item={item}
                                    key={item.id}
                                    id={item.id}
                                    isSeen={item.seen}
                                    onClickOpenErrors={setIsErrorsModalActive}
                                />
                            );
                        }
                    })}
                    {elLoadMoreEvent()}
                </div>
            );
        }
    }, [isShowContent, taskList]);

    return (
        <div className={cn('download__modal')}>
            {isErrorsModalActive && <DownloadModalError id={isErrorsModalActive} onClickClose={onClickClose} />}
            <UI.Box padding={true} className={cn('download__modal__container')}>
                <div className={cn('download__modal__header')} onClick={onClickCollapse}>
                    <h3>Загрузка фото</h3>
                    <FontAwesomeIcon icon={faCaretUp} className={cn('download__modal__header-icon', isShowContent && 'download__modal__header-icon-rotate')} />
                </div>
                {elTaskList}
            </UI.Box>
        </div>
    );
};

export default DownloadModal;
