import React, { useEffect, useMemo, useState, Fragment, useCallback, MouseEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router';
import debounce from 'lodash.debounce';

import { useClassnames } from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import ErrorBlock from 'component/error';
import Input from 'component/form/input';
import Form, { useRegistry } from 'component/form';
import InputClubs from 'component/form/input-clubs';
import PhotoList from 'component/photo-list';

import { DataPhotoListItem } from 'component/api/types/api/photo/smart-search/photos/get/code-200';
import { getOwnPhotoList } from 'component/api/photo';

import style from './index.pcss';

import useIntersect from 'hook/use-intersect';

const PHOTOS_LIMIT = 100;

const PhotoListDate = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const token = useCancelToken();

    const { date }: { date?: string } = useParams();
    const { form, field } = useRegistry();

    const [validity, setValidity] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [list, setList] = useState<Array<DataPhotoListItem>>([]);
    const [pendingBefore, setPendingBefore] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);

    const init = useRef(false);

    const _requestPhotos = (merge?: boolean, isBefore?: boolean) => {
        if(date && !pending) {
            const payload = form.getPayload();

            if(isBefore) {
                setPendingBefore(true);
            } else {
                setPending(true);
            }

            getOwnPhotoList({
                params: {
                    date_from: date,
                    date_end : date,
                    ...(payload.name && { person_name: payload.name }),
                    ...(payload.club?.value && { club_id: payload.club.value }),
                    limit    : PHOTOS_LIMIT,
                    offset   : merge ? list.length : 0
                },
                cancelToken: token.new()
            })
                .then((resp) => {
                    const newList = merge ? [...list, ...resp.photo_list] : resp.photo_list;

                    setList(newList);
                    setTotal(resp.total_count);
                    setPending(false);
                    setPendingBefore(false);
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);

                        setError(err.message);
                        setPending(false);
                        setPendingBefore(false);
                    }
                });
        }
    };

    const onReset = useCallback(() => {
        form.clearForm();
        window.scrollTo(0, 0);

        _requestPhotos();
    }, []);

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting) {
            _requestPhotos(true, true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const onClickButtonBefore = (e: MouseEvent): void => {
        e.preventDefault();

        _requestPhotos(false, true);
    };

    const onChangeForm = debounce(useCallback(() => {
        if(init.current) {
            _requestPhotos();
        } else {
            init.current = true;
        }
    }, [pending, date, JSON.stringify(form.getPayload())]), 300);

    useEffect(() => {
        _requestPhotos();
    }, []);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const elButtonBeforePosts = () => {
        if(!pending && list.length) {
            if(list.length === total) {
                return (
                    <span className={cn('event__empty-photos')}>{t('route.event.button.end')}</span>
                );
            }

            return (
                <Button
                    ref={$bottomPreviousPosts}
                    disabled={pendingBefore}
                    isLoading={pendingBefore}
                    isSecondary={true}
                    className={cn('event__button-before')}
                    onClick={onClickButtonBefore}
                >
                    {t('route.event.button.before')}
                </Button>
            );
        }
    };

    const elPhotos = useMemo(() => {
        if(list?.length) {
            return <PhotoList disableButtons={true} className={cn('event__photo-list')} elIcons={false} elControls={false} list={list} />;
        }

        return <span className={cn('event__empty')}>{t('route.event.no-photos-found')}</span>;
    }, [JSON.stringify(list)]);

    const elContent = useMemo(() => {
        if(pending) {
            return <Loader className={cn('event__loader')} />;
        }

        return (
            <Fragment>
                {elPhotos}
                {elError}
            </Fragment>
        );
    }, [JSON.stringify(list), error, pending]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('event__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    return (
        <UI.Main className={cn('event')}>
            <h1 className={cn('event__header')}>{t('route.event.header-date', { date })}</h1>
            <div className={cn('event__grid')}>
                <UI.Box padding={true} className={cn('event__content')}>
                    {elContent}
                    {elButtonBeforePosts()}
                </UI.Box>
                <div className={cn('event__sidebar')}>
                    <Form
                        registry={form}
                        onChange={onChangeForm}
                        onChangeValidity={setValidity}
                    >
                        <UI.Box padding={true} className={cn('event__box')}>
                            <UI.BoxHeader>{t('route.search.sidebar.filter-header')}</UI.BoxHeader>
                            <Input
                                registry={field}
                                name="name"
                                type="text"
                                direction="column"
                                children={t('route.search.sidebar.name')}
                                className={cn('event__input-block')}
                            />
                            <InputClubs
                                registry={field}
                                name="club"
                                clearable={true}
                                children={t('route.search.sidebar.club')}
                                direction="column"
                                className={cn('event__input-block')}
                            />
                            <Button
                                onClick={onReset}
                                type="reset"
                                disabled={pending}
                            >
                                {t('route.search.sidebar.button-reset')}
                            </Button>
                        </UI.Box>
                    </Form>
                </div>
            </div>
        </UI.Main>
    );
};

export default PhotoListDate;
