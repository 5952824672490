import React, { forwardRef, ReactNode, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import { useClassnames } from 'hook/use-classnames';
import Loader from 'component/loader';
import qaAttributes from 'component/helper/qa-attributes';

import { IProps } from './types';
import style from './style.pcss';

// tslint:disable-next-line no-any
const Button = forwardRef<any, IProps>((props, ref) => {
    const cn = useClassnames(style, props.className, true);

    const elChildren = (): ReactNode => {
        if(props.isLoading) {
            return (
                <div className={cn('button__loader')}>
                    <Loader
                        {...qaAttributes('button')}
                        className={cn('button__loader-icon')}
                    />
                </div>
            );
        }

        return props.children;
    };

    const onClick = (e: MouseEvent): void => {
        if(props.onClick) {
            props.onClick(e);
        }
    };

    const params = {
        ...qaAttributes(props['data-qa'] ? `button:${props['data-qa']}` : 'button'),
        className: cn('button', {
            'button_secondary': props.isSecondary,
            'button_small'    : props.isSmall,
            'button_success'  : props.isSuccess,
            'button_error'    : props.isError
        }),
        children: elChildren(),
        tabIndex: props.tabIndex,
        title: props.title,
        ref
    };

    if(props.href) {
        if(props.download) {
            return <a download={props.download} onClick={onClick} href={props.href} {...params} />;
        }

        return <a href={props.href} target={props.target} {...params} />;
    } else if(props.to) {
        return <Link to={props.to} target={props.target} {...params} />;
    }

    return (
        <button
            type={props.type}
            disabled={props.disabled}
            onClick={onClick}
            {...params}
        />
    );
});

Button.defaultProps = {
    disabled : false,
    isLoading: false,
    type     : 'button'
};

export default Button;
