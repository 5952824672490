import React from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Link = (props: IProps) => (
    <svg {...defaultProps} {...props} viewBox="0 0 24 24">
        <path d="M8.59031 11.4101C9.00031 11.8001 9.00031 12.4401 8.59031 12.8301C8.20031 13.2201 7.56031 13.2201 7.17031 12.8301C5.22031 10.8801 5.22031 7.71007 7.17031 5.76007L10.7103 2.22007C12.6603 0.270068 15.8303 0.270068 17.7803 2.22007C19.7303 4.17007 19.7303 7.34007 17.7803 9.29007L16.2903 10.7801C16.3003 9.96007 16.1703 9.14007 15.8903 8.36007L16.3603 7.88007C17.5403 6.71007 17.5403 4.81007 16.3603 3.64007C15.1903 2.46007 13.2903 2.46007 12.1203 3.64007L8.59031 7.17007C7.41031 8.34007 7.41031 10.2401 8.59031 11.4101ZM11.4103 7.17007C11.8003 6.78007 12.4403 6.78007 12.8303 7.17007C14.7803 9.12007 14.7803 12.2901 12.8303 14.2401L9.29031 17.7801C7.34031 19.7301 4.17031 19.7301 2.22031 17.7801C0.270313 15.8301 0.270313 12.6601 2.22031 10.7101L3.71031 9.22007C3.70031 10.0401 3.83031 10.8601 4.11031 11.6501L3.64031 12.1201C2.46031 13.2901 2.46031 15.1901 3.64031 16.3601C4.81031 17.5401 6.71031 17.5401 7.88031 16.3601L11.4103 12.8301C12.5903 11.6601 12.5903 9.76007 11.4103 8.59007C11.0003 8.20007 11.0003 7.56007 11.4103 7.17007Z" fill="#C5CAD0" />
    </svg>
);

export default Link;
