import { createReducer } from '@reduxjs/toolkit';

import { set, reset } from './actions';
import { IStore } from './types/reducer';

export const key = 'user' as const;

export default createReducer<IStore>({}, (builder) => {
    builder
        .addCase(set, (store, action) => {
            if(action.payload) {
                return {
                    ...store,
                    ...action.payload
                };
            }

            return store;
        })
        .addCase(reset, () => {
            sessionStorage.removeItem('user');

            return {};
        });
});
