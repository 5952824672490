import React, { FC, Fragment, MouseEvent, useState } from 'react';
import { IProps } from './types';
import { useClassnames } from 'hook/use-classnames';
import style from './style.pcss';

const range = (from: number, to: number, step = 1): Array<number> => {
    let i = from;
    const result: Array<number> = [];

    while (i <= to) {
        result.push(i);
        i += step;
    }

    return result;
};
const LEFT_PAGE = -1;
const RIGHT_PAGE = 0;

const Pagination: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const totalPages = Math.ceil(props.totalRecords / props.pageLimit);
    const pageNeighbours = 1;
    const [ currentPage, setCurrentPage ] = useState(props.currentPage);

    const fetchPageNumbers = (): Array<number> => {
        const totalNumbers = pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    const onPageChanged = (page: number) => {
        props.onPageChanged && props.onPageChanged(page);
        setCurrentPage(page);
    };

    const handleClick = (page: number) => (event: MouseEvent) => {
        event.preventDefault();
        onPageChanged(page);
    };

    const handleMoveLeft = (event: MouseEvent) => {
        event.preventDefault();
        onPageChanged(currentPage - (pageNeighbours * 2) - 1);
    };

    const handleMoveRight = (event: MouseEvent) => {
        event.preventDefault();
        onPageChanged(currentPage + (pageNeighbours * 2) + 1);
    };

    if (totalPages === 1) {
        return null;
    }

    return (
        <Fragment>
            <nav>
                <ul className={cn('pagination')}>
                    {
                        fetchPageNumbers().map((page, index) => {
                            if (page === LEFT_PAGE) {
                                return (
                                    <li key={index} className={cn('pagination__item')}>
                                        <a href="#" aria-label="Previous" onClick={handleMoveLeft}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                );
                            }

                            if (page === RIGHT_PAGE) {
                                return (
                                    <li key={index} className={cn('pagination__item')}>
                                        <a href="#" aria-label="Next" onClick={handleMoveRight}>
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                );
                            }

                            return (
                                <li
                                    key={index}
                                    className={cn('pagination__item', {
                                        'pagination__item--active': currentPage === page
                                    })}
                                >
                                    <a href="#" onClick={handleClick(page)}>{page}</a>
                                </li>
                            );
                        })
                    }
                </ul>
            </nav>
        </Fragment>
    );
};

export default Pagination;
