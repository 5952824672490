import React, { forwardRef } from 'react';

import LoaderIcon from 'component/icon/loader';
import qaAttributes from 'component/helper/qa-attributes';
import { useClassnames } from 'hook/use-classnames';

import { IProps } from './types';
import style from './style.pcss';

// tslint:disable-next-line:no-any
const Loader = forwardRef<any, IProps>((props, ref) => {
    const cn = useClassnames(style, props.className, true);
    const params = {
        ...qaAttributes(props['data-qa'] ? `loader:${props['data-qa']}` : 'loader'),
        className: cn('loader', props.className),
        ref
    };

    return (
        <div {...params}>
            <LoaderIcon theme={props.theme} />
            <span className={cn(`loader__text-${props.theme}`)}>
                {props.text || 'Загрузка...'}
            </span>
        </div>
    );
});

Loader.defaultProps = {
    theme: 'gray'
};

export default Loader;
