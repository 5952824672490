import { createReducer } from '@reduxjs/toolkit';

import { set, setSimilarPersonList } from './actions';
import { IStore } from './types/reducer';

const setLocalStorage = (value: IStore) => {
    try {
        window.localStorage.setItem('config', JSON.stringify(value));
    } catch (err) {
        console.warn(err);
    }
};

const getLocalStorage = (initialValue: IStore) => {
    try {
        const value = window.localStorage.getItem('config');

        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        return initialValue;
    }
};

const initialConfig: IStore = {
    similarPersonList: true
};

const initialState: IStore = getLocalStorage(initialConfig);

export const key: 'localConfig' = 'localConfig';

export default createReducer<IStore>(initialState, (builder) => {
    builder
        .addCase(set, (store, action) => {
            const newStore = {
                ...store,
                ...action.payload
            };
            setLocalStorage(newStore);

            return newStore;
        })
        .addCase(setSimilarPersonList, (store, action) => {
            const newStore = {
                ...store,
                similarPersonList: action.payload
            };
            setLocalStorage(newStore);

            return newStore;
        });
});
