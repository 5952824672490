import React, { FC, useState, useEffect, useRef } from 'react';

import scriptload from 'component/scriptload';
import config from 'config';

const ReCaptcha: FC<ReCaptchaV2.Parameters> = (parameters) => {
    const $captcha = useRef<HTMLDivElement>(null);

    const [loaded, setLoaded] = useState<boolean>(false);

    const isExist = (): boolean => {
        return window.widgetId !== undefined && !!window.grecaptcha;
    };

    const destroy = () => {
        if(isExist()) {
            window.grecaptcha.reset(window.widgetId);
            window.widgetId = undefined;
        }
    };

    const execute = () => {
        if(isExist()) {
            window.grecaptcha.execute(window.widgetId);
        }
    };

    const reset = () => {
        if(isExist()) {
            window.grecaptcha.reset(window.widgetId);
        }
    };

    const reload = () => {
        reset();
        execute();
    };

    const render = () => {
        const wrapper = document.createElement('div');

        window.widgetId = window.grecaptcha && window.grecaptcha.render(wrapper, {
            ...parameters,
            'expired-callback': reload
        });

        $captcha.current && $captcha.current.appendChild(wrapper);

        execute();
    };

    useEffect(() => {
        if(window.grecaptcha) {
            render();
        } else {
            window.recaptchaOnLoad = () => {
                setLoaded(true);
            };

            const result = scriptload(config.recaptcha.js, {
                async: true,
                defer: true
            });

            if(result && !Array.isArray(result)) {
                result
                    .catch(console.error);
            }
        }

        return () => {
            destroy();
        };
    }, []);

    useEffect(() => {
        if(loaded) {
            render();
        }
    }, [loaded]);

    return <div ref={$captcha} />;
};

ReCaptcha.defaultProps = {
    size   : 'invisible',
    sitekey: config.recaptcha.key
};

export default ReCaptcha;
