import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { CreateVideoData, CreateVideoItem, GetVideoListData, PartialUpdateVideoData, UpdateVideoData, UpdateVideoItem, VideoListItem, VideoRetrieveItem, VideosAPIType } from './types';
import { Page, PaginationResponse } from '../base';

const getVideoList = (page: Page, data: GetVideoListData): Promise<AxiosResponse<PaginationResponse<VideoListItem>>> => {
    return httpClient.get<PaginationResponse<VideoListItem>>('/api/v2/videos/', {
        params: {
            ...data
        }
    });
};

const createVideo = (data: CreateVideoData): Promise<AxiosResponse<CreateVideoItem>> => {
    return httpClient.post<CreateVideoItem>('/api/v2/videos/', data);
};

const getVideoRetrieve = (id: string): Promise<AxiosResponse<VideoRetrieveItem>> => {
    return httpClient.get<VideoRetrieveItem>(`/api/v2/videos/${id}/`);
};

const updateVideo = (id: string, data: UpdateVideoData): Promise<AxiosResponse<UpdateVideoItem>> => {
    return httpClient.put<UpdateVideoItem>(`/api/v2/videos/${id}/`, data);
};

const partialUpdateVideo = (id: string, data: PartialUpdateVideoData): Promise<AxiosResponse<UpdateVideoItem>> => {
    return httpClient.patch<UpdateVideoItem>(`/api/v2/videos/${id}/`, data);
};

const deleteVideo = (id: string): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/videos/${id}/`);
};

export default {
    getVideoList,
    createVideo,
    getVideoRetrieve,
    updateVideo,
    partialUpdateVideo,
    deleteVideo
} as VideosAPIType;
