import React, { Fragment } from 'react';

import Icon, { cn } from './';

export default Icon(() => (
    <Fragment>
        <circle cx="12" cy="12" r="10" />
        <g fill="#fff">
            <path d="M13,18h-2v-2h2V18z M13,14h-2V6h2V14z" />
        </g>
    </Fragment>
), {
    className: cn('svg-icon')
});
