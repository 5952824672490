import { useClassnames } from 'hook/use-classnames';
import React, { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './styles.pcss';

interface IProps {
  event: {
    id: number;
    name: string;
  };
  photo: number;
}

const Breadcrumbs = ({event, photo}: IProps) => {
  const cn = useClassnames(style);

  return (
    <div className={cn('breadcrumbs')}>
        <Link className={cn('breadcrumbs__link')} to={'/'}>Faceport</Link>
        <span className={cn('breadcrumbs__line')}>/</span>
        <Link className={cn('breadcrumbs__link')} to={`/events/${event.id}`}>{event.name}</Link>
        <span className={cn('breadcrumbs__line')}>/</span>
        <span>{photo}</span>
    </div>
  );
};

export default Breadcrumbs;
